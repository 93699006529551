/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../../services/http/http.service';

import { useForm } from 'react-hook-form';

import ActividadEconomicaCliente from './ActividadEconomicaCliente';
import DteClientes from './DteClientes';
import CredencialesClientes from './CredencialesClientes';

import ClienteSucursales from './ClienteSucursales';
import ApiKeyCliente from './ApiKeyCliente';
import ClienteUpdate from './ClienteUpdate';
import FirmaMh from './FirmaMh';
import Logo from './Logo';
import ColorPicker from './ColorPicker';
export default function DetalleClientes() {
  const { id } = useParams();

  const [cliente, setCliente] = useState([]);
  const { setValue } = useForm();

  useEffect(() => {
    loadClientById(id);
  }, [id]);

  const loadClientById = async (id) => {
    if (id) {
      const { data } = await get(`/clientes/${id}`);
      setValue('nit', data.nit);
      setValue('nrc', data.nrc);
      setValue('email', data.correo);
      setValue('nombre', data.nombre);
      setValue('nombre_comercial', data.nombreComercial);
      setValue('telefono', data.telefono);
      setCliente(data);
      return data;
    }
  };

  const updateClientData = async () => {
    const updatedData = await loadClientById(id);
    setCliente(updatedData);
  };

  return (
    <div>
      <div className="row">
        <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <ClienteUpdate id={id} readOnly={false} />
        </div>

        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <Logo id={id} readOnly={false} />
        </div>
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <ColorPicker id={id} readOnly={false} />
        </div>
        <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <DteClientes id={id} readOnly={false} />
        </div>

        <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <FirmaMh id={id} readOnly={false} />
        </div>
        <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <CredencialesClientes id={id} readOnly={false} />
        </div>

        <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <ActividadEconomicaCliente id={id} readOnly={false} />
        </div>
        <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <ClienteSucursales id={id} readOnly={false} />
        </div>

        <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <ApiKeyCliente
            id={id}
            realoadClient={updateClientData}
            apiKeyValue={cliente?.apiKey}
          />
        </div>
      </div>
    </div>
  );
}
