// import { useEffect, useState } from 'react'

// Components

import {
    CardWrapper
} from '../../../Components/Common/Cards'

import { CommonTable } from '../../../Components/Common/Tables';

const Contribuyentes = () => {
  const contribuyentesMock = [
  {
    id: 1,
    tipoIdentificacion: 'DUI',
    numeroDocumento: '12345678-D',
    nombreReceptor: 'Ricky Antony',
    departamento: 'San Salvador',
    municipio: 'Municipio1',
    correoElectronico: 'ricky@example.com',
    telefono: '(503) 2001-1234',
    estado: 'Activo'
  },
  {
    id: 2,
    tipoIdentificacion: 'NIT',
    numeroDocumento: '0614-050785-101-8',
    nombreReceptor: 'Emma Watson',
    departamento: 'La Libertad',
    municipio: 'Municipio2',
    correoElectronico: 'emma@example.com',
    telefono: '(503) 2111-4321',
    estado: 'Inactivo'
  },
  {
    id: 3,
    tipoIdentificacion: 'DUI',
    numeroDocumento: '98765432-D',
    nombreReceptor: 'John Doe',
    departamento: 'Santa Ana',
    municipio: 'Municipio3',
    correoElectronico: 'john@example.com',
    telefono: '(503) 2555-6789',
    estado: 'Activo'
  }
];

	return (
    contribuyentesMock && contribuyentesMock.length === 0 ? (
		<>
		 <div className="card">
        <div className="card-body overflow-hidden p-lg-6">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="../assets/img/icons/spot-illustrations/21.png"
                alt="..."
              />
            </div>
            <div className="col-lg-6 ps-lg-4 my-5 text-center text-lg-start">
              <h3 className="text-primary">¡Aún no tienes ningún contribuyente registrado!</h3>
              <p className="lead">Empieza ahora mismo.</p>
              <a
                className="btn btn-falcon-primary"
                href="/contribuyentes/create"
              >
                Crear Contribuyente
              </a>
            </div>
          </div>
        </div>
      </div>
		</>
    ) : (
      <CardWrapper title="Contribuyentes">
        <CommonTable data={contribuyentesMock} />
        </CardWrapper>
    )
	)
}


export default Contribuyentes