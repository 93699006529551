import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Configurar dayjs con los plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const YearSelect = ({ onYearChange, selectedYear }) => {
  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = dayjs().tz('America/El_Salvador').year();
    const startYear = 2023;
    const yearArray = [];

    for (let year = startYear; year <= currentYear; year++) {
      yearArray.push(year);
    }

    setYears(yearArray);
  }, []);

  return (
    <select
      value={selectedYear}
      onChange={onYearChange}
      className="form-select"
    >
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default YearSelect;
