import httpAuth from '../../../../../services/http/http.auth.services';
import { get } from '../../../../../services/http/http.service';
import * as Yup from 'yup';
import { capitalizeWords } from '../../facturav2/utils/FacturaV2';

const defaultOptions = {
  defaultDepartemento: '6505204d62bac256b1348960',
};
export const getTiposDocumentosReceptor = async () => {
  const { data } = await get(
    'generales/tipos-documentos-identificacion-receptor',
  );

  return data;
};

export const getActividadesEconomicas = async (inputValue = '') => {
  const { data } = await get(
    `generales/actividades-economicas?search=${inputValue}`,
  );

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
  }));
  return options;
};

let timeOutActividadesEconomicas = null;
export const loadActividadesEconomicas = (inputValue) =>
  new Promise((resolve) => {
    clearTimeout(timeOutActividadesEconomicas);
    timeOutActividadesEconomicas = setTimeout(() => {
      resolve(getActividadesEconomicas(inputValue));
    }, 300);
  });

export const getDepartamentos = async () => {
  const { data } = await get(`generales/departamentos`);

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
    id: item.id,
  }));
  return {
    data: options,
    default: options.find(
      (item) => item.id === defaultOptions.defaultDepartemento,
    ),
  };
};

export const getMunicipios = async (
  departamentoId = defaultOptions.defaultDepartemento,
) => {
  const { data } = await get(
    `generales/municipios?departamentoId=${departamentoId}`,
  );

  const options = data.map((item) => ({
    value: item.code,
    label: capitalizeWords(item.value),
  }));
  return options;
};

export const getActividadesEconomicasEmisor = async (clienteId) => {
  const { data } = await get(`clientes/actividad-economica/${clienteId}`);

  const options = data.map((item) => ({
    value: item.code,
    label: `${item.code} - ${item.value}`,
    default: item.default,
  }));
  return {
    data: options,
    default: options.find((item) => item.default === true),
  };
};

export const getDataEmisor = async (clienteId) => {
  const { data } = await get(`clientes/${clienteId}`);

  return data;
};

export const getSucursalesEmisor = async (
  clienteId,
  sucursalDefault = null,
) => {
  const { data } = await httpAuth.get(`sucursales/${clienteId}`);

  const options = data?.results
    .filter((sucursal) => sucursal.activo === true)
    .map((item) => ({
      value: item._id,
      label:
        item.codigo_punto_venta_mh +
        ' - ' +
        item.tipo_establecimiento.value +
        ' - ' +
        item.direccion,
      default: item.default,
    }));

  return {
    data: options,
    default: !sucursalDefault
      ? options.find((item) => item.default === true)
      : sucursalDefault,
  };
};

export const getDatosDte = async ({
  clienteId,
  ambiente,
  nitReceptor,
  tipoDocumento,
  fechaGeneracionDesde,
  fechaGeneracionHasta,
}) => {
  const { data } = await get(`dte/${clienteId}/by-cliente-id`, {
    limit: 1000,
    ambiente,
    tipo: tipoDocumento,
    nitReceptor,
    enNotaCredito: false,
    startDate: fechaGeneracionDesde,
    endDate: fechaGeneracionHasta,
  });

  return data;
};

export const getTipoGeneracionDte = (tipo) => {
  if (tipo?.toLowerCase() === 'documento electrónico') {
    return 2;
  }

  if (tipo?.toLowerCase() === 'documento físico') {
    return 1;
  }
};

export const tiposProductos = [
  {
    value: 1,
    label: 'Bien',
  },
  {
    value: 2,
    label: 'Servicio',
  },
  {
    value: 3,
    label: 'Bien y servicio',
  },
];

export const getUnidadesMedida = async () => {
  const { data } = await get(`generales/unidades-medida`);

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
  }));
  return options;
};

export const tributosHabilitados = [
  '20',
  'C3',
  '59',
  '71',
  'D1',
  'C5',
  'C6',
  'C7',
  'C8',
  'D5',
  'D4',
  '19',
  '28',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '42',
  '43',
  '44',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '58',
  '77',
  '78',
  '79',
  '85',
  '86',
  '91',
  '92',
  'A1',
  'A5',
  'A7',
  'A9',
];

export const getFormaDePago = async () => {
  const { data } = await get(`generales/formas-pago`);

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
  }));
  return options;
};

export const getTiposDeDocumentoIdentificacionReceptor = async () => {
  const { data } = await get(
    `generales/tipos-documentos-identificacion-receptor`,
  );

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
  }));

  //Añadimos nit homologado
  options.push({
    value: '36.2',
    label: 'NIT(DUI Homologado)',
  });

  return options;
};

export const notSelectedValue = null;
export const validationSchema = Yup.object().shape({
  tipoDocumentoReceptor: Yup.object()
    .required('Tipo de documento es requerido')
    .notOneOf([notSelectedValue]),
  nombreClienteReceptor: Yup.string().required(
    'Nombre de receptor es requerido',
  ),
  numeroDocumentoReceptor: Yup.string()
    .required('El número de documento es requerido')
    .test(
      'Pattern test',
      'El número de documento debe tener el formato del tipo seleccionado',
      function (value) {
        if (
          typeof this.parent.tipoDocumentoReceptor === 'undefined' ||
          this.parent.tipoDocumentoReceptor === null
        ) {
          return true;
        }
        if (
          this.parent.tipoDocumentoReceptor.value === '13' ||
          this.parent.tipoDocumentoReceptor.value === '36.2'
        ) {
          //DUI
          return /^[0-9]{8}-[0-9]{1}$/.test(value);
        }
        if (this.parent.tipoDocumentoReceptor.value === '36') {
          //NIT
          return /^([0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]{1})$/.test(value);
          //return /^([0-9]{14}|[0-9]{9})$/.test(value);
        }
        return true;
      },
    ),
  telefonoReceptor: Yup.string().required('Teléfono de receptor es requerido'),
  actividadEconomicaReceptor: Yup.object()
    .notOneOf([notSelectedValue])
    .required('Actividad económica es requerida'),
  correoElectronicoReceptor: Yup.string()
    .required('Correo electrónico es requerido')
    .email('Correo electrónico con formato incorrecto'),

  departamentoReceptor: Yup.object()
    .notOneOf([notSelectedValue])
    .required('El departamento es requerido'),
  municipioReceptor: Yup.object()
    .notOneOf([notSelectedValue])
    .required('El municipio es requerido'),
  complementoReceptor: Yup.string().required('La dirección es requerida'),

  actividadEconomicaEmisor: Yup.object().required(
    'Actividad económica es requerida',
  ),
  establecimientoEmisor: Yup.object().required('Establecimiento es requerido'),
  correoElectronicoEmisor: Yup.string()
    .required('Correo electrónico es requerido')
    .email('Correo electrónico con formato incorrecto'),

  telefonoEmisor: Yup.string().required('Teléfono de emisor es requerido'),

  cuerpoDocumento: Yup.array().required(
    'Los datos de la Factura de Sujeto Excluido son requeridos',
  ),

  condicionOperacion: Yup.string().required(
    'Condición de operación es requerida',
  ),

  pagos: Yup.array().min(1, 'Los datos de los pagos son requeridos'),
});

export const processNumeroDocumentoReceptor = (
  tipoDocumento,
  numeroDocumento,
) => {
  switch (tipoDocumento) {
    case '13':
      return {
        numero: numeroDocumento.replaceAll('-', '').trim(),
        tipo: '13',
      };
    case '36':
      return {
        numero: numeroDocumento.replaceAll('-', '').trim(),
        tipo: '36',
      };
    case '36.2':
      return {
        numero: numeroDocumento.replaceAll('-', '').trim(),
        tipo: '36',
      };

    default:
      return { numero: numeroDocumento, tipo: tipoDocumento };
  }
};
