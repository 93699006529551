import React from 'react';
import { getDteType, getUnidadMedida } from '../../../../../utils/dte/dte.util';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';

const TablaTotalesNotaCredito = ({ data }) => {
  return (
    <>
      <CardWrapperTools title="Documentos Relacionados">
        <div className="row">
          <div className="col-12">
            <div className="col-sm-auto ms-auto">
              <div className="table-responsive">
                <table className="table table-striped border-bottom fs-1">
                  <thead data-bs-theme="light">
                    <tr className="bg-primary text-white dark__bg-1000">
                      <th className="border-0 text-center">Tipo Documento</th>
                      <th className="border-0 text-center">N° Documento</th>
                      <th className="border-0 text-center">Fecha Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.datos_dte?.documentoRelacionado?.map((item) => (
                      <tr>
                        <td className="align-middle text-center">
                          {getDteType(item.tipoDocumento)}
                        </td>
                        <td className="align-middle text-center">
                          {item.numeroDocumento}
                        </td>
                        <td className="align-middle text-center">
                          {item.fechaEmision}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CardWrapperTools>
      <CardWrapperTools title="Detalle de documento">
        <div className="row">
          <div className="col-12">
            <div className="col-sm-auto ms-auto">
              <div className="table-responsive">
                <table className="table table-striped border-bottom fs-1">
                  <thead data-bs-theme="light">
                    <tr className="bg-primary text-white dark__bg-1000">
                      <th className="border-0">N°</th>
                      <th className="border-0 text-center">Cantidad</th>
                      <th className="border-0 text-center">U/M</th>
                      <th className="border-0">Descripción</th>
                      <th className="border-0 text-center">P. Unitario</th>
                      <th className="border-0 text-center">Dcto. item</th>
                      <th className="border-0 text-center">V. No Sujeta</th>
                      <th className="border-0 text-center">V. Exenta</th>

                      <th className="border-0 text-center">V. Gravada</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.datos_dte?.cuerpoDocumento?.map((item) => (
                      <tr>
                        <td className="align-middle text-center">
                          {item.numItem}
                        </td>
                        <td className="align-middle text-center">
                          {item.cantidad}
                        </td>
                        <td className="align-middle text-center">
                          {getUnidadMedida(item.uniMedida)}
                        </td>

                        <td className="align-middle">
                          <strong>{item.descripcion}</strong>
                        </td>
                        <td className="align-middle text-center">
                          ${parseFloat(item.precioUni).toFixed(2)}
                        </td>
                        <td className="align-middle text-center">
                          ${parseFloat(item.montoDescu).toFixed(2)}
                        </td>
                        <td className="align-middle text-center">
                          ${parseFloat(item.ventaNoSuj).toFixed(2)}
                        </td>
                        <td className="align-middle text-center">
                          ${parseFloat(item.ventaExenta).toFixed(2)}
                        </td>
                        <td className="align-middle text-center">
                          ${parseFloat(item.ventaGravada).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="col-auto">
            <table className="table table-sm table-borderless fs-1 text-end">
              <tbody>
                <tr>
                  <th className="text-1100 bold">Sumatoria de ventas:</th>
                  <td className="fw-semi-bold">
                    $
                    {parseFloat(
                      data?.datos_dte?.resumen?.subTotalVentas,
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <th className="text-900">
                    Monto global Desc., Rebajas y otros a ventas no sujetas:
                  </th>
                  <td className="fw-semi-bold">
                    $
                    {parseFloat(data?.datos_dte?.resumen?.descuNoSuj).toFixed(
                      2,
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="text-900">
                    Monto global Desc., Rebajas y otros a ventas exentas:
                  </th>
                  <td className="fw-semi-bold">
                    $
                    {parseFloat(data?.datos_dte?.resumen?.descuExenta).toFixed(
                      2,
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="text-900">
                    Monto global Desc., Rebajas y otros a ventas gravadas:
                  </th>
                  <td className="fw-semi-bold">
                    $
                    {parseFloat(data?.datos_dte?.resumen?.descuGravada).toFixed(
                      2,
                    )}
                  </td>
                </tr>

                {data?.datos_dte?.resumen?.tributos &&
                  data?.datos_dte?.resumen?.tributos.length > 0 &&
                  data?.datos_dte?.resumen?.tributos.map((item) => (
                    <tr className="border-top">
                      <th className="text-900">{item?.descripcion}:</th>
                      <td className="fw-semi-bold">
                        ${parseFloat(item?.valor).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                <tr className="border-top">
                  <th className="text-900">Sub-Total:</th>
                  <td className="fw-semi-bold">
                    ${parseFloat(data?.datos_dte?.resumen?.subTotal).toFixed(2)}
                  </td>
                </tr>
                <tr className="border-top">
                  <th className="text-900">IVA Retenido:</th>
                  <td className="fw-semi-bold">
                    ${parseFloat(data?.datos_dte?.resumen?.ivaRete1).toFixed(2)}
                  </td>
                </tr>
                <tr className="border-top">
                  <th className="text-900">Retención Renta:</th>
                  <td className="fw-semi-bold">
                    $
                    {parseFloat(data?.datos_dte?.resumen?.reteRenta).toFixed(2)}
                  </td>
                </tr>
                <tr className="border-top">
                  <th className="text-900">Monto Total de la Operación:</th>
                  <td className="fw-semi-bold">
                    $
                    {parseFloat(
                      data?.datos_dte?.resumen?.montoTotalOperacion,
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default TablaTotalesNotaCredito;
