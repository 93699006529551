/** @format */

import './App.css';
import { SnackbarProvider } from 'notistack';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { PrivateRoute } from './utils/router/Router';

// Components
import MainLayout from './Components/Template/Layouts/MainLayout';

// Pages
import Home from './pages/home/Home';
import ForgotPassword from './pages/auth/ForgotPassword';
import Modulos from './pages/admin/modulos/Modulos';
import SetPassword from './pages/auth/SetPassword';
import Login from './pages/auth/Login';
import Contribuyentes from './pages/admin/contribuyentes/Contribuyentes';
import CreateContribuyentes from './pages/admin/contribuyentes/CreateContribuyentes';
import SelectionClient from './pages/auth/SelectionClient';
import Dte from './pages/facturador/Dte';
import Usuarios from './pages/admin/usuarios/Usuarios';
import CreateUsuario from './pages/admin/usuarios/createUsuario';
import ConsumidorFinal from './pages/facturador/dte/factura/ConsumidorFinal';
import ConsultaDetalle from './pages/facturador/dte/consulta/ConsultaDetalle';
import NotaCredito from './pages/facturador/dte/nota-credito/NotaCredito';
import Clientes from './pages/admin/clientes/Clientes';
import CreateClientes from './pages/admin/clientes/CreateClientes';
import DetalleClientes from './pages/admin/clientes/DetalleClientes';
import Roles from './pages/admin/roles/Roles';
import RolesCreate from './pages/admin/roles/RolesCreate';
import CreditoFiscal from './pages/facturador/dte/credito-fiscal/CreditoFiscal';

import FacturaSujetoExcluido from './pages/facturador/dte/factura-sujeto-excluido/FacturaSujetoExcluido';
import FacturaV2 from './pages/facturador/dte/facturav2/FacturaV2';
import ClientesUsuarios from './pages/admin/usuarios/ClientesUsuarios';
import MisClientes from './pages/mis-clientes/MisClientes';
import CreateMisClientes from './pages/mis-clientes/CreateMisClientes';
import Productos from './pages/productos/Productos';
import { CreateProductos } from './pages/productos/CreateProductos';
import ConsultaV2 from './pages/facturador/dte/consulta/ConsultaV2';
import GestionClientes from './pages/Contabilidad/GestionClientes';
import CargaProductos from './pages/admin/carga-productos/carga-productos';

function App() {
  return (
    <Router>
      <SnackbarProvider />
      <Routes>
        <Route exact path="/login" element={<Login />}></Route>
        <Route
          exact
          path="/forgot-password"
          element={<ForgotPassword />}
        ></Route>
        <Route
          exact
          path="/selection-client"
          element={<SelectionClient />}
        ></Route>
        <Route
          exact
          path="/set-password/:user/:token"
          element={<SetPassword />}
        ></Route>
        <Route
          exact
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/admin/modulos"
          exact
          element={
            <PrivateRoute uuid={'40d5e820-ceb7-4596-9d1c-645878c3e551'}>
              <Modulos />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="/contribuyentes"
          element={
            <MainLayout>
              <Contribuyentes />
            </MainLayout>
          }
        ></Route>
        <Route
          exact
          path="/contribuyentes/create"
          element={
            <MainLayout>
              <CreateContribuyentes />
            </MainLayout>
          }
        ></Route>
        <Route
          exact
          path="/contribuyentes"
          element={
            <MainLayout>
              <Contribuyentes />
            </MainLayout>
          }
        ></Route>
        <Route
          exact
          path="/contribuyentes/create"
          element={
            <MainLayout>
              <CreateContribuyentes />
            </MainLayout>
          }
        ></Route>

        <Route
          path="/dte"
          exact
          element={
            <PrivateRoute uuid={'f6670a5f-777c-41a9-a251-9473dcfb88f9'}>
              <Dte />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/dte/consulta"
          exact
          element={
            <PrivateRoute uuid={'aab63cb6-8aa8-422c-801a-20f1154a3b56'}>
              <ConsultaV2 />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/mis-clientes"
          exact
          element={
            <PrivateRoute uuid={'aab63cb6-8aa8-422c-801a-20f1154a3b56'}>
              <MisClientes />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/productos"
          exact
          element={
            <PrivateRoute uuid={'aab63cb6-8aa8-422c-801a-20f1154a3b56'}>
              <Productos />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/productos/create"
          exact
          element={
            <PrivateRoute uuid={'aab63cb6-8aa8-422c-801a-20f1154a3b56'}>
              <CreateProductos />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/productos/update/:id"
          exact
          element={
            <PrivateRoute uuid={'aab63cb6-8aa8-422c-801a-20f1154a3b56'}>
              <CreateProductos />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/mis-clientes/create"
          exact
          element={
            <PrivateRoute uuid={'aab63cb6-8aa8-422c-801a-20f1154a3b56'}>
              <CreateMisClientes />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/mis-clientes/update/:id"
          exact
          element={
            <PrivateRoute uuid={'aab63cb6-8aa8-422c-801a-20f1154a3b56'}>
              <CreateMisClientes />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/dte/consulta/:codigoGeneracion/detalle/:clienteId"
          exact
          element={
            <PrivateRoute uuid={'aab63cb6-8aa8-422c-801a-20f1154a3b56'}>
              <ConsultaDetalle />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="dte/factura"
          element={
            <PrivateRoute uuid={'f6670a5f-777c-41a9-a251-9473dcfb88f9'}>
              <FacturaV2 />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="dte/facturav1"
          element={
            <PrivateRoute uuid={'f6670a5f-777c-41a9-a251-9473dcfb88f9'}>
              <ConsumidorFinal />
            </PrivateRoute>
          }
        ></Route>

        <Route
          exact
          path="dte/nota-credito"
          element={
            <PrivateRoute uuid={'f6670a5f-777c-41a9-a251-9473dcfb88f9'}>
              <NotaCredito />
            </PrivateRoute>
          }
        ></Route>

        <Route
          exact
          path="dte/credito-fiscal"
          element={
            <PrivateRoute uuid={'f6670a5f-777c-41a9-a251-9473dcfb88f9'}>
              <CreditoFiscal />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="dte/factura-sujeto-excluido"
          element={
            <PrivateRoute uuid={'f6670a5f-777c-41a9-a251-9473dcfb88f9'}>
              <FacturaSujetoExcluido />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/admin/usuarios"
          exact
          element={
            <PrivateRoute uuid={'df733611-13da-41cf-8fb9-437a0e9a8a2d'}>
              <Usuarios />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/admin/usuarios/create"
          exact
          element={
            <PrivateRoute uuid={'df733611-13da-41cf-8fb9-437a0e9a8a2d'}>
              <CreateUsuario />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/admin/usuarios/update/:id"
          exact
          element={
            <PrivateRoute uuid={'df733611-13da-41cf-8fb9-437a0e9a8a2d'}>
              <CreateUsuario />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/admin/usuarios/clientes/:id"
          exact
          element={
            <PrivateRoute uuid={'df733611-13da-41cf-8fb9-437a0e9a8a2d'}>
              <ClientesUsuarios />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="/admin/clientes"
          element={
            <PrivateRoute>
              <Clientes />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="/admin/clientes/create"
          element={
            <PrivateRoute>
              <CreateClientes />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="/admin/clientes/detalles/:id"
          element={
            <PrivateRoute>
              <DetalleClientes />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="/admin/roles"
          element={
            <PrivateRoute uuid={'f0b84fdb-fc73-4daa-b3b9-479f44f93490'}>
              <Roles />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="/admin/roles/create"
          element={
            <PrivateRoute uuid={'f0b84fdb-fc73-4daa-b3b9-479f44f93490'}>
              <RolesCreate />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="/admin/roles/update/:id"
          element={
            <PrivateRoute uuid={'f0b84fdb-fc73-4daa-b3b9-479f44f93490'}>
              <RolesCreate />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/admin/carga-productos"
          exact
          element={
            <PrivateRoute uuid={'5bbf40c0-323d-4561-a006-55a8745dc7b1'}>
              <CargaProductos />
            </PrivateRoute>
          }
        ></Route>
        {/* contabilidad*/}
        <Route
          path="/contabilidad/gestion-clientes"
          exact
          element={
            <PrivateRoute uuid={'5bbf40c0-323d-4561-a006-55a8745dc7b1'}>
              <GestionClientes />
            </PrivateRoute>
          }
        ></Route>
        {/* Ruta catch-all para redirigir al home */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
