import React, { useState } from 'react';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { serviciosMedicos } from '../utils/CreditoFiscal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
const OtrosDocumentosAsociados = () => {
  const validationSchema = Yup.object().shape({
    documentoAsociado: Yup.object().required(
      'El documento asociado es requerido',
    ),
    identificacionDocumento: Yup.string()
      .optional()
      .when('documentoAsociado', (documentoAsociado, schema) => {
        const [documentoAsociadoValue] = documentoAsociado;
        return documentoAsociadoValue?.value === 1 ||
          documentoAsociadoValue?.value === 2
          ? schema.required('La identificación del documento es requerida')
          : schema.nullable();
      }),
    descripcionDocumento: Yup.string()
      .optional()
      .when('documentoAsociado', (documentoAsociado, schema) => {
        const [documentoAsociadoValue] = documentoAsociado;
        return documentoAsociadoValue?.value === 1 ||
          documentoAsociadoValue?.value === 2
          ? schema.required('La descripción del documento es requerida')
          : schema.nullable();
      }),
    servicioMedico: Yup.object()
      .optional()
      .when('documentoAsociado', (documentoAsociado, schema) => {
        const [documentoAsociadoValue] = documentoAsociado;
        return documentoAsociadoValue?.value === 3
          ? schema.required('El servicio médico es requerido')
          : schema.nullable();
      }),

    nombreMedico: Yup.string()
      .optional()
      .when('documentoAsociado', (documentoAsociado, schema) => {
        const [documentoAsociadoValue] = documentoAsociado;
        return documentoAsociadoValue?.value === 3
          ? schema.required('El servicio médico es requerido')
          : schema.nullable();
      }),

    tipoDocumento: Yup.object()
      .optional()
      .when('documentoAsociado', (documentoAsociado, schema) => {
        const [documentoAsociadoValue] = documentoAsociado;
        return documentoAsociadoValue?.value === 3
          ? schema.required('El servicio médico es requerido')
          : schema.nullable();
      }),
    numeroDocumento: Yup.string()
      .optional()
      .when('documentoAsociado', (documentoAsociado, schema) => {
        const [documentoAsociadoValue] = documentoAsociado;
        return documentoAsociadoValue?.value === 3
          ? schema.required('NIT o número de documento es requerido')
          : schema.nullable();
      }),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      documentoAsociado: null,
      identificacionDocumento: '',
      descripcionDocumento: '',
      servicioMedico: null,
      nombreMedico: '',

      tipoDocumento: {
        value: 1,
        label: 'NIT',
      },
    },
  };

  const {
    register,
    control,
    watch: watchForm,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const { setValue } = useFormContext();

  const [documentosAsociados, setDocumentosAsociados] = useState([]);
  const onSubmit = () => {
    const documentoAsociado = watchForm('documentoAsociado');
    const identificacionDocumento = watchForm('identificacionDocumento');
    const descripcionDocumento = watchForm('descripcionDocumento');
    const servicioMedico = watchForm('servicioMedico');
    const nombreMedico = watchForm('nombreMedico');
    const tipoDocumento = watchForm('tipoDocumento');
    const numeroDocumento = watchForm('numeroDocumento');
    const documento = {
      codDocAsociado: documentoAsociado?.value,
      detalleDocumento: identificacionDocumento || null,
      descDocumento: descripcionDocumento || null,
      id: new Date().getTime(),
      medico:
        documentoAsociado?.value === 3
          ? {
              tipoServicio: servicioMedico?.value,
              tipoServicioDesc: servicioMedico?.label,
              nombre: nombreMedico,
              nit: tipoDocumento?.value === 1 ? numeroDocumento : null,
              docIdentificacion:
                tipoDocumento?.value === 2 ? numeroDocumento : null,
            }
          : null,
    };

    setDocumentosAsociados([...documentosAsociados, documento]);
    setValue('documentosAsociados', [...documentosAsociados, documento]);
    reset();
  };
  return (
    <>
      <CardWrapperTools
        title="Información de documento asociado"
        footer={
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-primary btn-lg"
              onClick={handleSubmit(onSubmit)}
            >
              <span className="fa fa-plus me-1" />
              Agregar
            </button>
          </div>
        }
      >
        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="documentoAsociado">
                Documento asociado:
              </label>
              <Controller
                name="documentoAsociado"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    placeholder="Seleccione un documento asociado"
                    options={[
                      {
                        value: 1,
                        label: 'Emisor',
                      },
                      {
                        value: 2,
                        label: 'Receptor',
                      },
                      {
                        value: 3,
                        label: 'Médico',
                      },
                    ]}
                    {...field}
                  />
                )}
              />
              {errors.documentoAsociado && (
                <div className="invalid-feedback">
                  {errors.documentoAsociado?.message}
                </div>
              )}
            </div>
            {watchForm('documentoAsociado')?.value === 1 ||
            watchForm('documentoAsociado')?.value === 2 ? (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label htmlFor="">Identificación del Documento:</label>
                <input
                  className="form-control"
                  id="identificacionDocumento"
                  name="identificacionDocumento"
                  type="text"
                  placeholder="Digite la identificación del documento"
                  {...register('identificacionDocumento')}
                />

                {errors.identificacionDocumento && (
                  <div className="invalid-feedback">
                    {errors.identificacionDocumento?.message}
                  </div>
                )}
              </div>
            ) : null}
            {watchForm('documentoAsociado')?.value === 1 ||
            watchForm('documentoAsociado')?.value === 2 ? (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label htmlFor="">Descripción del documento:</label>
                <input
                  className="form-control"
                  id="descripcionDocumento"
                  name="descripcionDocumento"
                  type="text"
                  placeholder="Digite la descripción "
                  {...register('descripcionDocumento')}
                />

                {errors.descripcionDocumento && (
                  <div className="invalid-feedback">
                    {errors.descripcionDocumento?.message}
                  </div>
                )}
              </div>
            ) : null}

            {watchForm('documentoAsociado')?.value === 3 ? (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label className="form-label" htmlFor="servicioMedico">
                  Tipo de servicio:
                </label>
                <Controller
                  name="servicioMedico"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      placeholder="Seleccione un servicio médico"
                      options={serviciosMedicos}
                      {...field}
                    />
                  )}
                />
                {errors.servicioMedico && (
                  <div className="invalid-feedback">
                    {errors.servicioMedico?.message}
                  </div>
                )}
              </div>
            ) : null}

            {watchForm('documentoAsociado')?.value === 3 ? (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label htmlFor="">Nombre:</label>
                <input
                  className="form-control"
                  id="nombreMedico"
                  name="nombreMedico"
                  type="text"
                  placeholder="Digite el nombre del médico"
                  {...register('nombreMedico')}
                />

                {errors.nombreMedico && (
                  <div className="invalid-feedback">
                    {errors.nombreMedico?.message}
                  </div>
                )}
              </div>
            ) : null}

            {watchForm('documentoAsociado')?.value === 3 ? (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label className="form-label" htmlFor="tipoDocumento">
                  Tipo documento:
                </label>
                <Controller
                  name="tipoDocumento"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      placeholder="Seleccione un servicio médico"
                      options={[
                        {
                          value: 1,
                          label: 'NIT',
                        },
                        {
                          value: 2,
                          label: 'OTRO',
                        },
                      ]}
                      {...field}
                    />
                  )}
                />
                {errors.tipoDocumento && (
                  <div className="invalid-feedback">
                    {errors.tipoDocumento?.message}
                  </div>
                )}
              </div>
            ) : null}

            {watchForm('documentoAsociado')?.value === 3 ? (
              <div className="col-lg-4 col-md-6 col-sm-12">
                {watchForm('tipoDocumento')?.value === 1 ? (
                  <label htmlFor="">NIT:</label>
                ) : (
                  <label htmlFor="">Número de documento:</label>
                )}
                <input
                  className="form-control"
                  id="numeroDocumento"
                  name="numeroDocumento"
                  type="text"
                  placeholder={
                    watchForm('tipoDocumento')?.value === 1
                      ? 'Digite el NIT'
                      : 'Digite el número de documento'
                  }
                  {...register('numeroDocumento')}
                />

                {errors.numeroDocumento && (
                  <div className="invalid-feedback">
                    {errors.numeroDocumento?.message}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </CardWrapperTools>

      {documentosAsociados.filter((da) => da.medico === null).length > 0 ? (
        <CardWrapperTools title="Documentos asociados">
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-12">
                <table className="table table-hover table-striped table-bordered border-3 overflow-hidden">
                  <thead className="table-primary">
                    <tr>
                      <th scope="col" className="text-center">
                        Documento Asociado
                      </th>
                      <th scope="col" className="text-center">
                        Descripción
                      </th>
                      <th scope="col" className="text-center">
                        Detalle del documento
                      </th>
                      <th scope="col" className="text-center">
                        Acciones
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {documentosAsociados
                      .filter((da) => da.medico === null)
                      .map((documento) => (
                        <tr>
                          <td className="text-center">
                            {documento.codDocAsociado === 1
                              ? 'Emisor'
                              : 'Receptor'}
                          </td>
                          <td className="text-center">
                            {documento.descDocumento}
                          </td>
                          <td className="text-center">
                            {documento.detalleDocumento}
                          </td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                const docAsoc = documentosAsociados.filter(
                                  (doc) => doc.id !== documento.id,
                                );
                                setDocumentosAsociados(docAsoc);

                                setValue('documentosAsociados', docAsoc);
                              }}
                            >
                              <span className="fas fa-trash-alt me-1"></span>
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </CardWrapperTools>
      ) : null}

      {documentosAsociados.filter((da) => da.medico !== null).length > 0 ? (
        <CardWrapperTools title="Médicos relacionados">
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-12">
                <table className="table table-hover table-striped table-bordered border-3 overflow-hidden">
                  <thead className="table-primary">
                    <tr>
                      <th scope="col" className="text-center">
                        Nombre
                      </th>
                      <th scope="col" className="text-center">
                        Tipo de servicio
                      </th>
                      <th scope="col" className="text-center">
                        NIT
                      </th>
                      <th scope="col" className="text-center">
                        Identificación de Doc.
                      </th>
                      <th scope="col" className="text-center">
                        Acciones
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {documentosAsociados
                      .filter((da) => da.medico !== null)
                      .map((documento) => (
                        <tr>
                          <td className="text-center">
                            {documento.medico.nombre}
                          </td>
                          <td className="text-center">
                            {documento.medico.tipoServicioDesc}
                          </td>
                          <td className="text-center">
                            {documento.medico.nit ?? 'N/A'}
                          </td>
                          <td className="text-center">
                            {documento.medico.docIdentificacion ?? 'N/A'}
                          </td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                const docAsoc = documentosAsociados.filter(
                                  (doc) => doc.id !== documento.id,
                                );

                                setDocumentosAsociados(docAsoc);

                                setValue('documentosAsociados', docAsoc);
                              }}
                            >
                              <span className="fas fa-trash-alt me-1"></span>
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </CardWrapperTools>
      ) : null}
    </>
  );
};

export default OtrosDocumentosAsociados;
