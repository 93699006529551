import { isMobile } from 'react-device-detect';

export const CollapseNav = () => {
  if (isMobile) {
    return {
      'data-bs-toggle': 'collapse',
      'data-bs-target': '#navbarVerticalCollapse',
    };
  }
  return {};
};
