import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import {
  destroyItemSession,
  getSession,
} from '../../../../services/encrytion/encryption.service';
import { enqueueSnackbar } from 'notistack';
import { get } from '../../../../services/http/http.service';
import _debounce from 'lodash/debounce';
import { useSearchParams } from 'react-router-dom';
import ModalReporte from '../components/ModalReporte';
import { useNavigate } from 'react-router-dom';
import { getDteType, getTipoDocumento } from '../../../../utils/dte/dte.util';
import { getLocalDate } from '../../../../services/date/date';
import ValidateErrorDte from './components/ValidateErrorDte';
import Action from './components/Action';

const ConsultaV2 = () => {
  const navigate = useNavigate();
  const modalReporteRef = useRef(null);
  const pageDefault = 1;
  const limitDefault = 10;
  const ambienteDefault = '01';
  const [searchParams, setSearchParams] = useSearchParams();
  const [dteByClient, setDteByCliente] = useState([]);
  const [page, setPage] = useState(searchParams.get('page') || pageDefault);
  const [limit, setLimit] = useState(searchParams.get('limit') || limitDefault);
  const [ambiente, setAmbiente] = useState(ambienteDefault);
  const [search, setSearch] = useState('');
  const [cliente, setCliente] = useState(null);
  const [tiposDte, setTiposDte] = useState([]);
  const [tipoDte, setTipoDte] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const getDteByClienteId = useCallback(async () => {
    try {
      const cliente = getSession('clienteSeleccionado');
      setCliente(cliente);
      const { data } = await get(`dte/${cliente.id}/by-cliente-id`, {
        page,
        limit,
        search,
        ambiente,
        tipo: tipoDte,
        startDate,
        endDate,
      });

      if (data.results.length === 0) {
        setPage(pageDefault);
      }
      setDteByCliente(data);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los dte!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, [page, limit, search, ambiente, tipoDte, startDate, endDate]);

  useEffect(() => {
    searchParams.set('page', page);
    searchParams.set('limit', limit);
    setSearchParams(searchParams);
  }, [page, limit, searchParams, setSearchParams]);

  useEffect(() => {
    async function getDte() {
      await getDteByClienteId();
    }

    async function getTipoDte() {
      const clienteData = getSession('clienteSeleccionado');
      const { data } = await get(`clientes/dte/${clienteData.id}`);
      setTiposDte(data);
    }
    getDte();

    getTipoDte();
  }, [getDteByClienteId]);

  useEffect(() => {
    destroyItemSession('queryParamsConsulta');
  }, []);

  const setPageDefault = () => {
    setPage(pageDefault);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page', pageDefault);
    setSearchParams(newSearchParams);
  };

  const debouncedHandleSearch = useMemo(
    () => _debounce(setSearch, 500),
    [setSearch],
  );

  const setQueryParams = (name, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(name, value);
    setSearchParams(newSearchParams);
    if (name === 'page') {
      setPage(value);
    } else {
      setLimit(value);
    }
  };

  const handleSearchChange = (e) => {
    setPageDefault();
    debouncedHandleSearch(e.target.value);
  };

  return (
    <div className="row gx-3">
      <div className="col-xxl-10 col-xl-9">
        <div className="card">
          <div className="card-header border-bottom border-200 px-0">
            <div className="d-lg-flex justify-content-between">
              <div className="row flex-between-center gy-2 px-x1">
                <div className="col-auto pe-0">
                  <h6 className="mb-0">DTE emitidos</h6>
                </div>
                <div className="col-auto">
                  <form>
                    <div className="input-group">
                      <input
                        className="form-control form-control-lg"
                        type="search"
                        placeholder="Buscar..."
                        onChange={handleSearchChange}
                        aria-label="search"
                      />
                      <button className="btn btn-sm btn-outline-secondary border-300 hover-border-secondary">
                        <span className="fa fa-search fs--1" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="border-bottom border-200 my-3" />
              <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
                <button
                  className="btn btn-sm btn-falcon-default d-xl-none"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#ticketOffcanvas"
                  aria-controls="ticketOffcanvas"
                >
                  <span
                    className="fas fa-filter"
                    data-fa-transform="shrink-4 down-1"
                  />
                  <span className="ms-1 d-none d-sm-inline-block">Filter</span>
                </button>

                <div
                  className="d-flex align-items-center"
                  id="table-ticket-replace-element"
                >
                  <button
                    className="btn btn-falcon-default btn-sm mx-2"
                    type="button"
                    onClick={() => navigate('/dte')}
                  >
                    <span
                      className="fas fa-plus"
                      data-fa-transform="shrink-3"
                    />
                    <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                      Emitir nuevo DTE
                    </span>
                  </button>
                  {/* <button
                    className="btn btn-falcon-default btn-sm"
                    type="button"
                  >
                    <span
                      className="fas fa-external-link-alt"
                      data-fa-transform="shrink-3"
                    />
                    <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                      Export
                    </span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <div className="table-responsive scrollbar">
              <table className="table mb-0 fs--1 table-hover table-bordered border-2">
                <thead className="text-800 bg-light">
                  <tr>
                    <th
                      className="text-center ps-2"
                      style={{
                        minWidth: '15rem',
                      }}
                    >
                      Tipo
                    </th>
                    <th
                      className="text-center ps-2"
                      style={{
                        minWidth: '15rem',
                      }}
                    >
                      Cliente
                    </th>
                    <th
                      className="text-center ps-2"
                      style={{
                        minWidth: '6rem',
                      }}
                    >
                      Tipo Doc.
                    </th>
                    <th className="text-center ps-2">Num. Doc.</th>
                    <th
                      className="text-center"
                      style={{
                        minWidth: '15.625rem',
                      }}
                    >
                      Num. Control
                    </th>
                    <th className="text-center">Cod. Generación</th>
                    <th className="text-center">Fecha Generación</th>
                    <th className="text-center">Monto</th>
                    <th className="text-center">Estatus</th>

                    <th
                      className="text-center"
                      style={{
                        minWidth: '8rem',
                      }}
                    >
                      Opciones
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="table-ticket-body">
                  {dteByClient?.results?.length > 0
                    ? dteByClient?.results.map((dte) => (
                        <tr key={dte?._id}>
                          <td className="text-start">
                            <div className="d-flex align-items-center gap-2 ">
                              <span className="fas fa-circle text-success fs--2" />
                              {getDteType(
                                dte?.datos_dte?.identificacion?.tipoDte,
                              )}
                            </div>
                          </td>
                          <td>
                            {dte?.datos_dte?.receptor?.nombre ||
                              dte?.datos_dte?.sujetoExcluido?.nombre ||
                              'Cliente General'}
                          </td>

                          {dte?.datos_dte?.receptor?.tipoDocumento ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              {getTipoDocumento(
                                dte?.datos_dte?.receptor?.tipoDocumento,
                              ) || 'N/A'}
                            </td>
                          ) : null}

                          {dte?.datos_dte?.sujetoExcluido?.tipoDocumento ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              {getTipoDocumento(
                                dte?.datos_dte?.sujetoExcluido?.tipoDocumento,
                              ) || 'N/A'}
                            </td>
                          ) : null}
                          {dte?.datos_dte?.receptor?.nit ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              NIT
                            </td>
                          ) : null}

                          {!dte?.datos_dte?.receptor?.tipoDocumento &&
                          !dte?.datos_dte?.sujetoExcluido?.tipoDocumento &&
                          !dte?.datos_dte?.receptor?.nit ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              N/A
                            </td>
                          ) : null}

                          {dte?.datos_dte?.receptor?.tipoDocumento ||
                          dte?.datos_dte?.sujetoExcluido?.tipoDocumento ? (
                            <td className="align-middle text-center pe-4">
                              {dte?.datos_dte?.receptor?.numDocumento ||
                                dte?.datos_dte?.sujetoExcluido?.numDocumento ||
                                'N/A'}
                            </td>
                          ) : null}

                          {dte?.datos_dte?.receptor?.nit ? (
                            <td className="align-middle text-center pe-4">
                              {dte?.datos_dte?.receptor?.nit}
                            </td>
                          ) : null}

                          {!dte?.datos_dte?.receptor?.tipoDocumento &&
                          !dte?.datos_dte?.sujetoExcluido?.tipoDocumento &&
                          !dte?.datos_dte?.receptor?.nit ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              N/A
                            </td>
                          ) : null}

                          <td className="align-middle text-center">
                            {dte.numero_control}
                          </td>

                          <td className="align-middle text-center">
                            {dte.codigo_generacion}
                          </td>

                          <td className="align-middle text-center">
                            {getLocalDate(
                              dte.created_at,
                              'dddd DD MMMM YYYY, h:mm:ss a',
                            )}
                          </td>
                          <td className="align-middle text-center">
                            <div className="d-grid gap-2">
                              <span className="badge bg-primary fs--1">
                                $
                                {dte?.datos_dte?.resumen?.totalPagar
                                  ? parseFloat(
                                      dte?.datos_dte?.resumen?.totalPagar,
                                    ).toFixed(2)
                                  : parseFloat(
                                      dte?.datos_dte?.resumen
                                        ?.montoTotalOperacion,
                                    ).toFixed(2)}
                              </span>
                            </div>
                          </td>

                          <td className="align-middle text-center">
                            <div className="d-grid gap-2">
                              {dte?.invalidado ? (
                                <span className="badge badge-subtle-danger fs--1">
                                  <span>Invalidado</span>
                                </span>
                              ) : (
                                <ValidateErrorDte dte={dte} />
                              )}
                            </div>
                          </td>
                          <td className="align-middle text-center">
                            <Action
                              dte={dte}
                              cliente={cliente}
                              modalReporteRef={modalReporteRef}
                            />
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
              {dteByClient?.results?.length === 0 ? (
                <div className="text-center" id="tickets-table-fallback">
                  <p className="fw-bold fs-1 mt-3">
                    No se encontraron resultados
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          {dteByClient?.pageLinks?.length > 0 && (
            <div className="card-footer">
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-sm btn-falcon-default me-1"
                  type="button"
                  title="Anterior"
                  data-list-pagination="prev"
                  disabled={!dteByClient?.hasPreviousPage}
                  onClick={() =>
                    setQueryParams('page', dteByClient?.previousPage)
                  }
                >
                  <span className="fas fa-chevron-left"></span>
                </button>
                <ul className="pagination mb-0">
                  {dteByClient?.pageLinks?.map((page) =>
                    page !== '...' ? (
                      <button
                        className="btn btn-sm btn-falcon-default text-primary me-2"
                        key={page}
                        onClick={() => setQueryParams('page', page)}
                        disabled={page === dteByClient?.currentPage}
                      >
                        {page}
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-falcon-default me-2"
                        disabled={true}
                      >
                        <span className="fas fa-ellipsis-h" />
                      </button>
                    ),
                  )}
                </ul>
                <button
                  className="btn btn-sm btn-falcon-default ms-1"
                  type="button"
                  title="Siguiente"
                  data-list-pagination="next"
                  disabled={!dteByClient?.hasNextPage}
                  onClick={() => setQueryParams('page', dteByClient?.nextPage)}
                >
                  <span className="fas fa-chevron-right"></span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-xxl-2 col-xl-3">
        <div
          className="offcanvas offcanvas-end offcanvas-filter-sidebar border-0 dark__bg-card-dark h-auto rounded-xl-3"
          tabIndex="-1"
          id="ticketOffcanvas"
          aria-labelledby="ticketOffcanvasLabel"
        >
          <div className="offcanvas-header d-flex flex-between-center d-xl-none bg-light">
            <h6 className="fs-0 mb-0 fw-semi-bold">Filtros</h6>
            <button
              className="btn-close text-reset d-xl-none shadow-none"
              id="ticketOffcanvasLabel"
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="card scrollbar shadow-none shadow-show-xl">
            <div className="card-header bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtros</h6>
            </div>
            <div className="card-body">
              <form>
                <div className="mb-2 mt-n2">
                  <label className="mb-1">Ambiente:</label>
                  <select
                    className="form-select"
                    aria-label="Ambientes"
                    onChange={(e) => setAmbiente(e.target.value)}
                    value={ambiente}
                  >
                    <option key={'01'} value="01">
                      Producción
                    </option>
                    <option key={'00'} value="00">
                      Pruebas
                    </option>
                  </select>
                </div>
                <div className="mb-2">
                  <label className="mb-1 mt-2">Tipo DTE:</label>
                  <select
                    className="form-select"
                    aria-label="Tipos de DTE"
                    value={tipoDte}
                    onChange={(e) => setTipoDte(e.target.value)}
                  >
                    <option value={''} disabled={true}>
                      Seleccione un opción
                    </option>

                    {tiposDte?.map((tipo) => (
                      <option key={tipo.code} value={tipo.code}>
                        {tipo.value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-2">
                  <label className="mb-1 mt-2">Fecha desde:</label>
                  <div className="mb-3">
                    <input
                      className="form-control"
                      type="date"
                      placeholder="dd/mm/yy"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label className="mb-1 mt-2">Fecha hasta:</label>

                  <input
                    className="form-control"
                    type="date"
                    placeholder="dd/mm/yy"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="card-footer border-top border-200 py-x1">
              <button
                onClick={() => {
                  setAmbiente(ambienteDefault);
                  setTipoDte('');
                  setStartDate('');
                  setEndDate('');
                }}
                className="btn btn-primary w-100"
              >
                Restablecer filtros
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalReporte ref={modalReporteRef} />
    </div>
  );
};

export default ConsultaV2;
