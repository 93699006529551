import React, { useCallback, useEffect, useRef, useState } from 'react';
import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import ModalWrapper from '../../../Components/Common/Modals/Modal';
import { Alert, loadingReload } from '../../../services/alerts/alerts';
import { get, patch } from '../../../services/http/http.service';

const Logo = ({ id, readOnly = false }) => {
  const modalLogoRef = useRef();
  const [fileLogo, setFileLogo] = useState(null);
  const [fileLogoDark, setFileLogoDark] = useState(null);

  const [sourceLogo, setSourceLogo] = useState(null);
  const [sourceLogoDark, setSourceLogoDark] = useState(null);

  const loadClientById = useCallback(async (id) => {
    if (id) {
      const { data } = await get(`/clientes/logos/${id}`);

      setSourceLogo(data.logo);
      setSourceLogoDark(data.logoDark);
    }
  }, []);

  useEffect(() => {
    if (id) {
      loadClientById(id);
    }
  }, [id, loadClientById]);

  const handleFileLogoChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileLogo(selectedFile);
  };

  const handleFileLogoDarkChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileLogoDark(selectedFile);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (fileLogo || fileLogoDark) {
      const formData = new FormData();
      if (fileLogo) {
        formData.append('files', fileLogo, 'logo.jpg');
      }
      if (fileLogoDark) {
        formData.append('files', fileLogoDark, 'logo-dark.jpg');
      }

      // Aquí puedes enviar formData a tu servidor o hacer lo que necesites con él
      try {
        loadingReload(true, 'Cargando logo');
        const { data } = await patch(`clientes/logos/${id}`, formData, {
          'Content-Type': 'multipart/form-data',
        });

        if (data) {
          loadingReload(false);
          Alert({
            title: 'Éxito',
            text: 'Logo cargado correctamente.',
            icon: 'success',
          });

          if (id) {
            loadClientById(id);

            modalLogoRef.current.toggle();
          }
        }
      } catch (error) {
        loadingReload(false);
        Alert({
          title: 'Error',
          text: 'Ocurrió un error al cargar el logo.',
          icon: 'error',
        });
      }
    } else {
      Alert({
        title: 'Error',
        text: 'Por favor seleccione un archivo para cargar',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <CardWrapperTools
        title={'Logotipos empresariales'}
        footer={
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-lg"
              onClick={() => {
                modalLogoRef.current.toggle();
              }}
            >
              <span className="fas fa-image"></span> Actualizar Logos
            </button>
          </div>
        }
      >
        {!readOnly && (
          <div className="row">
            <div className="col-6">
              <label>Logo Facturas:</label>

              <div className="text-center">
                {sourceLogo ? (
                  <img
                    className="img-fluid rounded-1 w-50 "
                    width={'60%'}
                    src={sourceLogo}
                    alt=""
                  />
                ) : (
                  <h3>
                    <span className="fas fa-image"></span> Sin logo
                  </h3>
                )}
              </div>
            </div>

            <div className="col-6">
              <label>Logo Tickets:</label>

              <div className="text-center">
                {sourceLogoDark ? (
                  <img
                    className="img-fluid rounded-1 w-50 "
                    width={'60%'}
                    src={sourceLogoDark}
                    alt=""
                  />
                ) : (
                  <h3>
                    <span className="fas fa-image"></span> Sin logo
                  </h3>
                )}
              </div>
            </div>
          </div>
        )}
      </CardWrapperTools>

      <ModalWrapper ref={modalLogoRef} title="Actualizar logo del cliente">
        <CardWrapperTools
          title={'Formulario de carga de logo'}
          footer={
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary btn-lg"
                onClick={(e) => {
                  uploadFile(e);
                }}
              >
                <span className="fas fa-upload"></span> Cargar
              </button>
            </div>
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <label htmlFor="">Logo facturas:</label>

                <input
                  type="file"
                  className="form-control file"
                  id="fileLogo"
                  onChange={handleFileLogoChange}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <label htmlFor="">Logo Tickets:</label>

                <input
                  type="file"
                  className="form-control file"
                  id="fileLogo"
                  onChange={handleFileLogoDarkChange}
                />
              </div>
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </>
  );
};

export default Logo;
