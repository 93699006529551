import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getUnidadMedida } from '../../../../../utils/dte/dte.util';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import { Alert } from '../../../../../services/alerts/alerts';
import FormaPagoForm from './FormaPagoForm';
import TabProductosServicios from './Tabs/TabProductosServicios';

const DatosFacturaSujetoExcluidoForm = () => {
  const {
    watch,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const calcularTotal = () => {
    const resumen = watch('resumen');
    const descu = Number(watch('descu')) || 0;

    resumen.subTotal = resumen?.totalCompra - descu;

    resumen.totalDescu = resumen.totalDescuItems + descu;

    resumen.totalPagar = resumen?.subTotal - watch('reteRenta');

    resumen.descu = descu;

    const itemResumen = {
      ...resumen,
    };

    if (resumen.montoTotalOperacion < 0) {
      Alert({
        title: 'Error',
        message: 'El monto total de la operación no puede ser negativo',
        type: 'error',
      });
    }

    setValue('resumen', itemResumen);
  };
  return (
    <>
      {errors.cuerpoDocumento && (
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="alert alert-danger" role="alert">
              {errors.cuerpoDocumento.message}
            </div>
          </div>
        </div>
      )}

      <CardWrapperTools title="Items DTE">
        <TabProductosServicios />
      </CardWrapperTools>

      <CardWrapperTools title="Datos de productos y servicios de la Factura de Sujeto Excluido">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive scrollbar">
                <table className="table table-hover table-striped table-bordered border-3 overflow-hidden">
                  <thead className="table-primary">
                    <tr>
                      <th scope="col" className="text-center">
                        Cantidad
                      </th>
                      <th scope="col" className="text-center">
                        Descripción
                      </th>
                      <th scope="col" className="text-center">
                        Precio unitario
                      </th>
                      <th scope="col" className="text-center">
                        Unidad de medida
                      </th>
                      <th scope="col" className="text-center">
                        Subtotal
                      </th>
                      <th scope="col" className="text-center">
                        Descuento
                      </th>
                      <th scope="col" className="text-center">
                        Venta
                      </th>

                      <th scope="col" className="text-center">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {watch('cuerpoDocumento') &&
                    watch('cuerpoDocumento').length > 0 ? (
                      watch('cuerpoDocumento').map((cuerpo, index) => (
                        <tr key={index}>
                          <td className="text-center">{cuerpo.cantidad}</td>
                          <td className="text-center">{cuerpo.descripcion}</td>
                          <td className="text-center">
                            ${parseFloat(cuerpo.precioUni).toFixed(2)}
                          </td>
                          <td className="text-center">
                            {getUnidadMedida(cuerpo.uniMedida)}
                          </td>
                          <td className="text-center">
                            $
                            {(
                              Number(cuerpo.compra) + Number(cuerpo.montoDescu)
                            ).toFixed(2)}
                          </td>
                          <td className="text-center">
                            ${parseFloat(cuerpo.montoDescu).toFixed(2)}
                          </td>
                          <td className="text-center">
                            ${parseFloat(cuerpo.compra).toFixed(2)}
                          </td>

                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                setValue('reteRenta', '0.00');
                                setValue('ivaRete1', '0.00');
                                setValue('ivaPerci1', '0.00');
                                const findItem = watch('cuerpoDocumento').find(
                                  (findCuerpo) =>
                                    findCuerpo.codigo === cuerpo.codigo,
                                );
                                const resumenDocumento =
                                  getValues('resumen') || {};
                                const cuerpoDocumento =
                                  watch('cuerpoDocumento');

                                cuerpoDocumento.splice(index, 1);
                                setValue('cuerpoDocumento', cuerpoDocumento);

                                setValue('resumen', {
                                  ivaRete1: 0,
                                  subTotal: cuerpoDocumento.reduce(
                                    (acc, item) =>
                                      acc +
                                      Number(item.precioUni) *
                                        Number(item.cantidad),
                                    0,
                                  ),

                                  reteRenta: Number(
                                    resumenDocumento?.reteRenta,
                                  ),
                                  descu: Number(resumenDocumento?.descu),
                                  totalDescu:
                                    Number(resumenDocumento?.totalDescu) -
                                    Number(
                                      findItem?.itemResumenDocumento?.descu,
                                    ),
                                  totalLetras: null,
                                  totalCompra:
                                    Number(resumenDocumento?.totalCompra) -
                                    Number(
                                      findItem?.itemResumenDocumento
                                        ?.totalCompra,
                                    ),
                                  condicionOperacion: null,
                                  montoTotalOperacion: cuerpoDocumento.reduce(
                                    (acc, item) =>
                                      acc +
                                      Number(item.precioUni) *
                                        Number(item.cantidad) -
                                      Number(item.montoDescu),
                                    0,
                                  ),

                                  totalPagar: cuerpoDocumento.reduce(
                                    (acc, item) =>
                                      acc +
                                      Number(item.precioUni) *
                                        Number(item.cantidad) -
                                      Number(item.montoDescu),
                                    0,
                                  ),
                                });
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No hay items
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex justify-content-end mb-2">
              <div className="row">
                <div className="col-lg-12">
                  <table className="table table-hover table-striped overflow-hidden">
                    <tbody>
                      <tr>
                        <td className="text-end text-black">
                          Total Operaciones
                        </td>
                        <td className="text-end">
                          $
                          {parseFloat(
                            watch('resumen')?.totalCompra || 0,
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">
                          Descuento Global
                        </td>
                        <td className="text-end">
                          <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                            <input
                              className="form-control"
                              name="descu"
                              id="descu"
                              type="number"
                              {...register('descu')}
                              disabled={
                                !watch('cuerpoDocumento') ||
                                watch('cuerpoDocumento')?.length === 0
                              }
                              onChange={(e) => {
                                setValue('descu', e.target.value);
                                calcularTotal();
                                if (e.target.value === '') {
                                  setValue('descu', 0);
                                  calcularTotal();
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              onFocus={(e) => e.target.select()}
                              onBlur={(e) => {
                                setValue(
                                  'descu',
                                  parseFloat(e.target.value).toFixed(2),
                                );
                                if (e.target.value === '') {
                                  setValue('descu', parseFloat(0).toFixed(2));
                                }
                              }}
                              placeholder="Digite el valor del descuento global"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">
                          Total del monto de descuento
                        </td>
                        <td className="text-end">
                          $
                          {parseFloat(
                            watch('resumen')?.totalDescu || 0,
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">Sub Total</td>
                        <td className="text-end">
                          $
                          {parseFloat(watch('resumen')?.subTotal || 0).toFixed(
                            2,
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">Retención Renta</td>
                        <td className="text-end">
                          <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                            <input
                              className="form-control"
                              name="reteRenta"
                              id="reteRenta"
                              type="number"
                              {...register('reteRenta')}
                              disabled={true}
                              onChange={(e) => {
                                setValue('reteRenta', e.target.value);
                                calcularTotal();
                                if (e.target.value === '') {
                                  setValue('reteRenta', 0);
                                  calcularTotal();
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              onFocus={(e) => e.target.select()}
                              onBlur={(e) => {
                                setValue(
                                  'reteRenta',
                                  parseFloat(e.target.value).toFixed(2),
                                );
                                if (e.target.value === '') {
                                  setValue(
                                    'reteRenta',
                                    parseFloat(0).toFixed(2),
                                  );
                                }
                              }}
                              placeholder="Digite el valor de renta retenida"
                            />

                            {watch('cuerpoDocumento') &&
                              watch('cuerpoDocumento')?.length > 0 && (
                                <span className="input-group-text">
                                  {watch('cuerpoDocumento') &&
                                  watch('cuerpoDocumento')?.length > 0 ? (
                                    <button
                                      className="btn btn-primary me-1"
                                      onClick={() => {
                                        setValue(
                                          'reteRenta',
                                          parseFloat(
                                            (watch('resumen')?.subTotal -
                                              watch('resumen')
                                                ?.totalDescuItems || 0) * 0.1,
                                          ).toFixed(2),
                                        );
                                        calcularTotal();
                                      }}
                                    >
                                      <span className="fas fa-calculator me-2"></span>
                                      Calcular
                                    </button>
                                  ) : null}
                                  {watch('reteRenta') > 0 && (
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        setValue('reteRenta', '0.00');
                                        calcularTotal();
                                      }}
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  )}
                                </span>
                              )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">Total a pagar</td>
                        <td className="text-end">
                          $
                          {parseFloat(
                            watch('resumen')?.totalPagar || 0,
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardWrapperTools>

      <CardWrapperTools title="Observaciones">
        <div className="row">
          <div className="col-12">
            <label className="form-label" htmlFor="observaciones">
              Observaciones:
            </label>
            <textarea
              className="form-control"
              name="observaciones"
              id="observaciones"
              type="text"
              placeholder="Digite las observaciones"
              {...register('observaciones')}
            />
          </div>
        </div>
      </CardWrapperTools>

      <FormaPagoForm />
    </>
  );
};

export default DatosFacturaSujetoExcluidoForm;
