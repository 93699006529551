import React from "react";

const Modulos = () => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="row g-0">
          <div className="col-12 mb-3">
            <div className="row justify-content-center justify-content-sm-between">
              <div className="col-sm-auto text-center">
                <h5 className="d-inline-block">Billed Annually</h5>
              </div>
            </div>
          </div>

          <div className="col-12 text-center">
            <h5 className="mt-5">
              Looking for personal or small team task management?
            </h5>
            <p className="fs-1">
              Try the <a href="!#">basic version</a> of Falcon
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modulos;
