/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { CardForm } from '../../../Components/Common/Cards/CardForm';
import { Alert, loadingReload } from '../../../services/alerts/alerts';
import { post } from '../../../services/http/http.service';
import { get } from '../../../services/http/http.service';
import { patch } from '../../../services/http/http.service';
import Select from 'react-select';
import { InputMask } from '@react-input/mask';
export default function CreateClientes() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [maskNumeroNit, setMaskNumeroNit] = useState(0); //0-other, 1- DUI, 2-NIT

  const validationSchema = Yup.object().shape({
    nit: Yup.string()
      .required('NIT es requerido')
      .test('len', 'NIT es requerido', (val) => {
        const lengthExpected = maskNumeroNit === 1 ? 9 : 14;
        return (
          val?.replaceAll('-', '').replaceAll('_', '').length === lengthExpected
        );
      }),
    nrc: Yup.number().max(99999999, 'Máximo 8 caracteres'),
    nombre: Yup.string().required('Nombre es requerido'),
    nombre_comercial: Yup.string().required('Nombre Comercial es requerido'),
    telefono: Yup.string().required('Teléfono es requerido'),
    email: Yup.string()
      .email('Debe ser un correo electrónico válido')
      .required('El correo electrónico es obligatorio'),

    usuarioFirma: Yup.string().required('Usuario Firma es requerido'),
    passwordFirma: Yup.string().required('Contraseña Firma es requerido'),
    usuarioMh: Yup.string(),
    passwordMh: Yup.string(),
    usuarioMhDev: Yup.string(),
    passwordMhDev: Yup.string(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (id) {
      loadClientId(id);
    }
    setMaskNumeroNit(2);
  }, []);

  const loadClientId = async (id) => {
    if (id) {
      const { data } = await get(`/clientes/${id}`);
      setValue('nit', data.nit);
      setValue('nrc', data.nrc);
      setValue('email', data.correo);
      setValue('nombre', data.nombre);
      setValue('nombre_comercial', data.nombreComercial);
      setValue('telefono', data.telefono);
      setValue('usuarioFirma', data.usuarioFirma);
      setValue('passwordFirma', data.passwordFirma);
      setValue('usuarioMh', data.usuarioMh);
      setValue('passwordMh', data.passwordMh);
      setValue('usuarioMhDev', data.usuarioMhDev);
      setValue('passwordMhDev', data.passwordMhDev);
    }
  };

  const onSubmit = async (response) => {
    loadingReload(true, 'Guardando cliente...');

    const payload = {
      nit: response.nit,
      nrc: String(response.nrc),
      correo: response.email,
      nombre: response.nombre,
      nombreComercial: response.nombre_comercial,
      telefono: response.telefono,
      logo: null,
      usuarioFirma: response?.usuarioFirma,
      passwordFirma: response?.passwordFirma,
      usuarioMh: response?.usuarioMh,
      passwordMh: response?.passwordMh,
      usuarioMhDev: response?.usuarioMhDev,
      passwordMhDev: response?.passwordMhDev,
    };
    if (!id) {
      try {
        const { data } = await post('/clientes', payload);
        if (data?.clientId) {
          loadingReload(false, null, 'success');
          Alert({
            title: 'Guardado',
            text: 'Administración de usuarios',
            icon: 'success',
          });
          navigate('/admin/clientes');
        }
      } catch (error) {
        loadingReload(false, null, 'error');
        Alert({
          title: error?.response?.data?.message ?? 'Error',
          text:
            JSON.stringify(error?.response?.data?.value) ??
            'Error al guardar los datos',
          icon: 'error',
        });
      }
    } else {
      try {
        payload.id = id;
        const { data } = await patch(`/clientes/${id}`, payload);
        if (data.status !== 200) {
          loadingReload(false, null, 'error');
          Alert({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
        }
        loadingReload(false, null, 'success');
        Alert({
          title: 'Guardado',
          text: 'Administración de usuarios',
          icon: 'success',
        });
        navigate('/admin/clientes');
      } catch (error) {
        Alert({
          title: 'Error',
          text: error?.response?.data?.message,
          icon: 'error',
        });
      }
    }
  };
  const updateInputMaskConfig = (value) => {
    if (maskNumeroNit === value) {
      return;
    }
    setMaskNumeroNit(value);
    setValue('nit', '');
  };
  const configureInputMaskForNumeroNit = (documentType) => {
    if (documentType === '13') {
      updateInputMaskConfig(1);
    } else if (documentType === '36.2') {
      //Dui homologado a NIT
      updateInputMaskConfig(1);
    } else if (documentType === '36') {
      updateInputMaskConfig(2);
    } else {
      updateInputMaskConfig(0);
    }
  };
  const nitNumeroDocumento = () => {
    const duiInput = (
      <InputMask
        key={maskNumeroNit}
        mask="________-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        className={`form-control ${errors.nit ? 'is-invalid' : ''}`}
        id="nit"
        placeholder="NIT"
        {...register('nit')}
      ></InputMask>
    );
    const nitInput = (
      <InputMask
        key={maskNumeroNit}
        mask="____-______-___-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        className={`form-control ${errors.nit ? 'is-invalid' : ''}`}
        id="nit"
        placeholder="NIT"
        {...register('nit')}
      ></InputMask>
    );
    switch (maskNumeroNit) {
      case 1:
        return duiInput;
      case 2:
        return nitInput;
      default:
        return (
          <input
            key={maskNumeroNit}
            type="text"
            className={`form-control ${errors.nit ? 'is-invalid' : ''}`}
            id="nit"
            placeholder="NIT"
            {...register('nit')}
          ></input>
        );
    }
  };

  const footer = (
    <div>
      <button
        className="btn btn-secondary me-2"
        onClick={() => {
          navigate('/admin/clientes');
        }}
      >
        Salir
      </button>
      <button className="btn btn-primary me-2" type="submit">
        {id ? 'Actualizar' : 'Guardar'}
      </button>
    </div>
  );
  return (
    <>
      <CardForm
        title="Formulario para clientes"
        footer={footer}
        submit={handleSubmit(onSubmit)}
      >
        <div className="card mb-3">
          <div className="card-header">Datos Generales del Cliente</div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-3">
                <label className="form-label" htmlFor="alias">
                  Tipo de NIT:
                </label>
                <Controller
                  key={101}
                  name="tipo_documento_nit"
                  id="tipo_documento_nit"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      cacheOptions
                      options={[
                        {
                          value: '36',
                          label: 'NIT',
                          code: '36',
                          slug: 'nit',
                        },
                        {
                          value: '36.2',
                          label: 'NIT (DUI Homologado)',
                          code: '36.2',
                          slug: 'dui',
                        },
                      ]}
                      defaultValue={{
                        value: '36',
                        label: 'NIT',
                        code: '36',
                        slug: 'nit',
                      }}
                      placeholder="Tipo NIT"
                      onChange={(event) => {
                        const documentType = event?.code; // es code el codigo
                        configureInputMaskForNumeroNit(documentType);
                        field.onChange(event);
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="nit" className="form-label">
                  NIT:
                </label>
                {nitNumeroDocumento()}
                {errors.nit && (
                  <div className="invalid-feedback">{errors.nit.message}</div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="nrc" className="form-label">
                  NRC:
                </label>
                <input
                  type="number"
                  className={`form-control ${errors.nrc ? 'is-invalid' : ''}`}
                  id="nrc"
                  placeholder="NRC"
                  {...register('nrc')}
                />
                {errors.nrc && (
                  <div className="invalid-feedback">{errors.nrc.message}</div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder="email"
                  {...register('email')}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email.message}</div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="nombre" className="form-label">
                  Nombre:
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.nombre ? 'is-invalid' : ''
                  }`}
                  id="nombre"
                  placeholder="Nombre"
                  {...register('nombre')}
                />
                {errors.nombre && (
                  <div className="invalid-feedback">
                    {errors.nombre.message}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="nombre_comercial" className="form-label">
                  Nombre Comercial:
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.nombre_comercial ? 'is-invalid' : ''
                  }`}
                  id="nombre_comercial"
                  placeholder="Nombre Comercial"
                  {...register('nombre_comercial')}
                />
                {errors.nombre_comercial && (
                  <div className="invalid-feedback">
                    {errors.nombre_comercial.message}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="telefono" className="form-label">
                  Teléfono:
                </label>
                <Controller
                  name="telefono"
                  control={control}
                  rules={{ required: 'Este campo es requerido' }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PhoneInput
                      country={'sv'}
                      enableSearch={true}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  )}
                />
                {errors.telefono && (
                  <div className="invalid-feedback d-block">
                    {errors.telefono.message}
                  </div>
                )}{' '}
              </div>
            </div>
          </div>
        </div>

        <div className="my-4">
          <hr />
        </div>

        <div className="card">
          <div className="card-header">
            Administración de Contraseña de firma electronica
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-12">
                <div className="card-header">
                  Administración de Contraseña de Ministerio de Hacienda
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="usuarioMh" className="form-label">
                  Usuario Ministerio de Hacienda:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="usuarioMh"
                  placeholder="Usuario MH"
                  {...register('usuarioMh')}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="passwordMh" className="form-label">
                  Contraseña Ministerio de Hacienda:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="passwordMh"
                  placeholder="Contraseña MH"
                  {...register('passwordMh')}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="usuarioMhDev" className="form-label">
                  Usuario Ministerio de Hacienda Dev:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="usuarioMhDev"
                  placeholder="Usuario MH Dev"
                  {...register('usuarioMhDev')}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="passwordMhDev" className="form-label">
                  Contraseña Ministerio de Hacienda Dev:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="passwordMhDev"
                  placeholder="Contraseña MH Dev"
                  {...register('passwordMhDev')}
                />
              </div>
            </div>
          </div>
        </div>
      </CardForm>
    </>
  );
}
