import React, { Fragment, useEffect } from 'react';
import NavBar from '../NavBar/NavBar';
import SideBar from '../NavBar/SideBar';
import FooterPage from '../Footer/FooterPage';
//import { getSession } from '../../../services/encrytion/encryption.service';
//import Config from "../Config/Config";

const MainLayout = ({ children }) => {
  //const [cliente, setCliente] = React.useState([]);
  useEffect(() => {
    // const cliente = getSession('clienteSeleccionado');

    // setCliente(cliente);

    const menu = document.getElementById('navbarVerticalCollapse');
    const body = document.getElementById('content');
    body.addEventListener('click', function (event) {
      if (menu.classList.contains('show')) {
        menu.classList.remove('show');
      }
    });
  }, []);
  useEffect(() => {
    const existingScript = document.querySelector(
      `script[src="${process.env.PUBLIC_URL}/assets/js/theme.js"]`,
    );
    if (existingScript) {
      existingScript.remove();
    }
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `${process.env.PUBLIC_URL}/assets/js/theme.js`;
      script.async = true;
      script.id = 'theme-script';
      document.body.appendChild(script);
    }
  }, []);
  return (
    <Fragment>
      <main className="main" id="top">
        <div className="container-fluid" data-layout="container">
          <SideBar />
          <div id="content" className="content">
            <NavBar />
            {/* {cliente ? (
              <div
                className="alert alert-info border-1 border border-info border-1 border-radius-lg d-flex align-items-center p-2 p-md-3 mb-4"
                role="alert"
              >
                <strong className="fs-0 me-1">Cliente Seleccionado: </strong>{' '}
                {cliente.nombre?.toUpperCase()}
              </div>
            ) : null} */}
            <div>{children}</div>

            <FooterPage />
          </div>
        </div>
      </main>

      {/* <Config /> */}
    </Fragment>
  );
};

export default MainLayout;
