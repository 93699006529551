import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { destroyItemSession } from '../../../services/encrytion/encryption.service';
import { enqueueSnackbar } from 'notistack';
import { get } from '../../../services/http/http.service';
import _debounce from 'lodash/debounce';
import { useSearchParams } from 'react-router-dom';
import ModalReporte from '../Components/ModalReporte';
import { getDteType, getTipoDocumento } from '../../../utils/dte/dte.util';
import { getLocalDate } from '../../../services/date/date';
import ValidateErrorDte from './components/ValidateErrorDte';
import Action from './components/Action';
const Dtes = ({ cliente, ambiente, year, month }) => {
  const modalReporteRef = useRef(null);
  const pageDefault = 1;
  const limitDefault = 10;
  const [searchParams, setSearchParams] = useSearchParams();
  const [dteByClient, setDteByCliente] = useState([]);
  const [page, setPage] = useState(searchParams.get('page') || pageDefault);
  const [limit, setLimit] = useState(searchParams.get('limit') || limitDefault);

  const [search, setSearch] = useState('');

  const [tiposDte, setTiposDte] = useState([]);
  const [tipoDte, setTipoDte] = useState('');

  const getDteByClienteId = useCallback(async () => {
    try {
      const { data } = await get(`contabilidad/cliente/dte/${cliente}`, {
        page,
        limit,
        search,
        ambiente,
        tipo: tipoDte,
        year,
        month,
      });

      if (data.results.length === 0) {
        setPage(pageDefault);
      }
      setDteByCliente(data);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los dte!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, [cliente, page, limit, search, ambiente, tipoDte, year, month]);

  useEffect(() => {
    searchParams.set('page', page);
    searchParams.set('limit', limit);
    setSearchParams(searchParams);
  }, [page, limit, searchParams, setSearchParams]);

  useEffect(() => {
    async function getDte() {
      await getDteByClienteId();
    }

    async function getTipoDte() {
      const { data } = await get(`clientes/dte/${cliente}`);
      setTiposDte(data);
    }
    getDte();

    getTipoDte();
  }, [getDteByClienteId, cliente]);

  useEffect(() => {
    destroyItemSession('queryParamsConsulta');
  }, []);

  const setPageDefault = () => {
    setPage(pageDefault);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page', pageDefault);
    setSearchParams(newSearchParams);
  };

  const debouncedHandleSearch = useMemo(
    () => _debounce(setSearch, 500),
    [setSearch],
  );

  const setQueryParams = (name, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(name, value);
    setSearchParams(newSearchParams);
    if (name === 'page') {
      setPage(value);
    } else {
      setLimit(value);
    }
  };

  const handleSearchChange = (e) => {
    setPageDefault();
    debouncedHandleSearch(e.target.value);
  };

  return (
    <div className="row gx-3">
      <div className="col-xxl-12 col-xl-12">
        <div className="row mb-2">
          <div className="col-8">
            <label className="mb-1 mt-2">Búsqueda:</label>
            <form>
              <div className="input-group">
                <input
                  className="form-control form-control-lg"
                  type="search"
                  placeholder="Buscar..."
                  onChange={handleSearchChange}
                  aria-label="search"
                />
                <button className="btn btn-sm btn-outline-secondary border-300 hover-border-secondary">
                  <span className="fa fa-search fs--1" />
                </button>
              </div>
            </form>
          </div>
          <div className="col-4">
            <label className="mb-1 mt-2">Tipo DTE:</label>
            <select
              className="form-select"
              aria-label="Tipos de DTE"
              value={tipoDte}
              onChange={(e) => setTipoDte(e.target.value)}
            >
              <option value={''} disabled={true}>
                Seleccione un opción
              </option>
              <option value={''}>Todos</option>
              {tiposDte?.map((tipo) => (
                <option key={tipo.code} value={tipo.code}>
                  {tipo.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="table-responsive scrollbar">
          <table className="table mb-0 fs--1 table-hover table-bordered border-2">
            <thead className="text-800 bg-light">
              <tr>
                <th
                  className="text-center ps-2"
                  style={{
                    minWidth: '15rem',
                  }}
                >
                  Tipo
                </th>
                <th
                  className="text-center ps-2"
                  style={{
                    minWidth: '15rem',
                  }}
                >
                  Cliente
                </th>
                <th
                  className="text-center ps-2"
                  style={{
                    minWidth: '6rem',
                  }}
                >
                  Tipo Doc.
                </th>
                <th className="text-center ps-2">Num. Doc.</th>
                <th
                  className="text-center"
                  style={{
                    minWidth: '15.625rem',
                  }}
                >
                  Num. Control
                </th>
                <th className="text-center">Cod. Generación</th>
                <th className="text-center">Fecha Generación</th>
                <th className="text-center">Monto</th>
                <th className="text-center">Estatus</th>

                <th
                  className="text-center sticky-col"
                  style={{
                    minWidth: '8rem',
                  }}
                >
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody className="list" id="table-ticket-body">
              {dteByClient?.results?.length > 0
                ? dteByClient?.results.map((dte) => (
                    <tr
                      key={dte?._id}
                      className={
                        dte?.datos_dte?.identificacion?.tipoDte === '14' &&
                        (dte?.informacion_contable === null ||
                          dte?.informacion_contable === undefined)
                          ? 'table-danger'
                          : ''
                      }
                    >
                      <td className="text-start">
                        <div className="d-flex align-items-center gap-2 ">
                          <span className="fas fa-circle text-success fs--2" />
                          {getDteType(dte?.datos_dte?.identificacion?.tipoDte)}
                        </div>
                      </td>
                      <td>
                        {dte?.datos_dte?.receptor?.nombre ||
                          dte?.datos_dte?.sujetoExcluido?.nombre ||
                          'Cliente General'}
                      </td>

                      {dte?.datos_dte?.receptor?.tipoDocumento ? (
                        <td className="align-middle text-center status fs-0 pe-4">
                          {getTipoDocumento(
                            dte?.datos_dte?.receptor?.tipoDocumento,
                          ) || 'N/A'}
                        </td>
                      ) : null}

                      {dte?.datos_dte?.sujetoExcluido?.tipoDocumento ? (
                        <td className="align-middle text-center status fs-0 pe-4">
                          {getTipoDocumento(
                            dte?.datos_dte?.sujetoExcluido?.tipoDocumento,
                          ) || 'N/A'}
                        </td>
                      ) : null}
                      {dte?.datos_dte?.receptor?.nit ? (
                        <td className="align-middle text-center status fs-0 pe-4">
                          NIT
                        </td>
                      ) : null}

                      {!dte?.datos_dte?.receptor?.tipoDocumento &&
                      !dte?.datos_dte?.sujetoExcluido?.tipoDocumento &&
                      !dte?.datos_dte?.receptor?.nit ? (
                        <td className="align-middle text-center status fs-0 pe-4">
                          N/A
                        </td>
                      ) : null}

                      {dte?.datos_dte?.receptor?.tipoDocumento ||
                      dte?.datos_dte?.sujetoExcluido?.tipoDocumento ? (
                        <td className="align-middle text-center pe-4">
                          {dte?.datos_dte?.receptor?.numDocumento ||
                            dte?.datos_dte?.sujetoExcluido?.numDocumento ||
                            'N/A'}
                        </td>
                      ) : null}

                      {dte?.datos_dte?.receptor?.nit ? (
                        <td className="align-middle text-center pe-4">
                          {dte?.datos_dte?.receptor?.nit}
                        </td>
                      ) : null}

                      {!dte?.datos_dte?.receptor?.tipoDocumento &&
                      !dte?.datos_dte?.sujetoExcluido?.tipoDocumento &&
                      !dte?.datos_dte?.receptor?.nit ? (
                        <td className="align-middle text-center status fs-0 pe-4">
                          N/A
                        </td>
                      ) : null}

                      <td className="align-middle text-center">
                        {dte.numero_control}
                      </td>

                      <td className="align-middle text-center">
                        {dte.codigo_generacion}
                      </td>

                      <td className="align-middle text-center">
                        {getLocalDate(
                          dte.created_at,
                          'dddd DD MMMM YYYY, h:mm:ss a',
                        )}
                      </td>
                      <td className="align-middle text-center">
                        <div className="d-grid gap-2">
                          <span className="badge bg-primary fs--1">
                            $
                            {dte?.datos_dte?.resumen?.totalPagar
                              ? parseFloat(
                                  dte?.datos_dte?.resumen?.totalPagar,
                                ).toFixed(2)
                              : parseFloat(
                                  dte?.datos_dte?.resumen?.montoTotalOperacion,
                                ).toFixed(2)}
                          </span>
                        </div>
                      </td>

                      <td className="align-middle text-center">
                        <div className="d-grid gap-2">
                          {dte?.invalidado ? (
                            <span className="badge badge-subtle-danger fs--1">
                              <span>Invalidado</span>
                            </span>
                          ) : (
                            <ValidateErrorDte dte={dte} />
                          )}
                        </div>
                      </td>
                      <td className="align-middle text-center sticky-col">
                        <Action
                          dte={dte}
                          cliente={cliente}
                          modalReporteRef={modalReporteRef}
                          getDteByClienteId={getDteByClienteId}
                        />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          {dteByClient?.results?.length === 0 ? (
            <div className="text-center" id="tickets-table-fallback">
              <p className="fw-bold fs-1 mt-3">No se encontraron resultados</p>
            </div>
          ) : null}
        </div>
        {dteByClient?.pageLinks?.length > 0 && (
          <div className="card-footer">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-sm btn-falcon-default me-1"
                type="button"
                title="Anterior"
                data-list-pagination="prev"
                disabled={!dteByClient?.hasPreviousPage}
                onClick={() =>
                  setQueryParams('page', dteByClient?.previousPage)
                }
              >
                <span className="fas fa-chevron-left"></span>
              </button>
              <ul className="pagination mb-0">
                {dteByClient?.pageLinks?.map((page) =>
                  page !== '...' ? (
                    <button
                      className="btn btn-sm btn-falcon-default text-primary me-2"
                      key={page}
                      onClick={() => setQueryParams('page', page)}
                      disabled={page === dteByClient?.currentPage}
                    >
                      {page}
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-falcon-default me-2"
                      disabled={true}
                    >
                      <span className="fas fa-ellipsis-h" />
                    </button>
                  ),
                )}
              </ul>
              <button
                className="btn btn-sm btn-falcon-default ms-1"
                type="button"
                title="Siguiente"
                data-list-pagination="next"
                disabled={!dteByClient?.hasNextPage}
                onClick={() => setQueryParams('page', dteByClient?.nextPage)}
              >
                <span className="fas fa-chevron-right"></span>
              </button>
            </div>
          </div>
        )}
      </div>

      <ModalReporte ref={modalReporteRef} />
    </div>
  );
};

export default Dtes;
