import React from 'react';
import '@inovua/reactdatagrid-community/index.css';
import { CardWrapper } from '../../../../../Components/Common/Cards';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const EmisorDetalleForm = ({
  useForm,
  actividadesEconomicas,
  establecimientosEmisor,
}) => {
  return (
    <>
      <CardWrapper title=" Datos emisor">
        <div className="row g-3">
          <div className="col-12">
            <label className="form-label" htmlFor="actividadEconomicaEmisor">
              Actividad Económica:
            </label>
            <Controller
              name="actividadEconomicaEmisor"
              control={useForm.control}
              render={({ field }) => (
                <Select options={actividadesEconomicas} {...field} />
              )}
            />
            {useForm.errors.actividadEconomicaEmisor && (
              <div className="invalid-feedback">
                {useForm.errors.actividadEconomicaEmisor?.message}
              </div>
            )}
          </div>

          <div className="col-12">
            <label className="form-label" htmlFor="establecimientoEmisor">
              Establecimiento/Dirección:
            </label>
            <Controller
              name="establecimientoEmisor"
              control={useForm.control}
              render={({ field }) => (
                <Select
                  options={establecimientosEmisor}
                  {...field}
                  placeholder="Seleccionar"
                />
              )}
            />
            {useForm.errors.establecimientoEmisor && (
              <div className="invalid-feedback">
                {useForm.errors.establecimientoEmisor?.message}
              </div>
            )}
          </div>
          <div className="col-8">
            <label className="form-label" htmlFor="correo">
              Correo Electrónico:
            </label>
            <input
              className="form-control"
              id="correoElectronicoEmisorreo"
              name="correoElectronicoEmisor"
              type="email"
              placeholder="mail@example.com"
              {...useForm.register('correoElectronicoEmisor')}
              disabled
            />
            {useForm.errors.correoElectronicoEmisor && (
              <div className="invalid-feedback">
                {useForm.errors.correoElectronicoEmisor?.message}
              </div>
            )}
          </div>
          <div className="col-4">
            <label className="form-label" htmlFor="telefono">
              Teléfono:
            </label>
            <input
              className="form-control"
              id="telefonoEmisor"
              name="telefonoEmisor"
              type="tel"
              placeholder="55555555"
              {...useForm.register('telefonoEmisor')}
              disabled
            />
            {useForm.errors.telefonoEmisor && (
              <div className="invalid-feedback">
                {useForm.errors.telefonoEmisor?.message}
              </div>
            )}
          </div>
        </div>
      </CardWrapper>
    </>
  );
};

export { EmisorDetalleForm };
