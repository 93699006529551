export const ItemOrigin = {
  BIENES_Y_SERVICIOS: 'bienes_servicios',
  NO_AFECTO: 'no_afecto',
  CON_AFECTACION_AL_IVA: 'con_afectacion_al_iva',
};
export const redondearA2Decimales = (numero) => {
  if (isNaN(numero)) return 0.0;
  const numeroProcesado = parseFloat(numero);
  const numeroRedondeado =
    Math.round((numeroProcesado + Number.EPSILON) * 100) / 100;
  return numeroRedondeado;
};

export const calcularIvaEnTotal = (total) => {
  const totalIva =  total - total / 1.13;
  return redondearA2Decimales(totalIva);
};

export const ifNotNumberReturnsZero = (value) => {
  if (isNaN(value)) {
    return 0.0;
  }
  return redondearA2Decimales(value);
};

// Constantes para tipos de ventas
export const TipoPago = {
  Contado: '1',
  Credito: '2',
  Otro: '3',
};

// Constantes para tipos de ventas
export const TipoVenta = {
  Gravada: '1',
  Exenta: '2',
  NoSujeta: '3',
  //Otros
  NoAfecta: '4',
};

// Tipos de venta mostrados en el select de tipo de venta
export const tiposVenta = [
  {
    value: TipoVenta.Gravada,
    label: 'Gravada',
  },
  {
    value: TipoVenta.Exenta,
    label: 'Exenta',
  },
  {
    value: TipoVenta.NoSujeta,
    label: 'No Sujeta',
  },
];
