import httpService from './http.config';
import { getSession } from '../encrytion/encryption.service';

export const getHeaders = () => {
  const token = getSession('token');
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

export const post = async (url, data, headers = {}, params = {}) => {
  return httpService.post(url, data, {
    headers: { ...getHeaders(), ...headers },
    params: { ...params },
  });
};

export const get = async (url, params, publicUrl = false) => {
  return httpService.get(url, {
    headers: !publicUrl ? getHeaders() : {},
    params: { ...params },
  });
};
export const patch = async (url, data, headers = {}, params = {}) => {
  return httpService.patch(url, data, {
    headers: { ...getHeaders(), ...headers },
    params: { ...params },
  });
};

export const deleted = async (url, data, headers = {}, params = {}) => {
  return httpService.delete(url, {
    headers: { ...getHeaders(), ...headers },
    params: { ...params },
  });
};
