import React from 'react';
import '@inovua/reactdatagrid-community/index.css';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  ItemOrigin,
  TipoVenta,
  calcularIvaEnTotal,
  redondearA2Decimales,
} from '../../../../../utils/Utils';

const ServicioProductoDetalleForm = ({
  tiposServiciosProductos,
  unidadesDeMedida,
  tiposVenta,
  impuestos,
  addItem,
}) => {
  const notSelectedValue = null;
  const validationSchema = Yup.object().shape({
    tipoItem: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El tipo de producto/servicio es obligatorio'),
    tipoVenta: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El tipo de venta es obligatorio'),
    unidadDeMedida: Yup.object()
      .notOneOf([notSelectedValue])
      .required('La unidad de medida es obligatoria'),
    descripcion: Yup.string().required(
      'El nombre del producto/servicio es obligatorio',
    ),
    cantidad: Yup.number().positive().required('La cantidad es obligatoria'),
    precio: Yup.number().positive().required('El precio es obligatorio'),
    descuento: Yup.number().moreThan(-0.01),
    impuestos: Yup.array()
      .test(
        'Si gravada debe tener IVA',
        'La venta gravada debe tener IVA',
        function (value) {
          if (this.parent.tipoVenta.value === TipoVenta.Gravada) {
            return value.some((x) => x.value === '20'); // 20 es el codigo del iva
          }
          return true;
        },
      )
      .nullable(),
    total: Yup.number().moreThan(-0.01),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tipoItem: tiposServiciosProductos.find((x) => x.value === '1'),
      unidadDeMedida: unidadesDeMedida.find((x) => x.value === 59),
      descripcion: '',
      cantidad: 0.0,
      precio: 0.0,
      tipoVenta: tiposVenta.find((x) => x.value === '1'),
      descuento: 0.0,
      impuestos: impuestos.filter((x) => x.value === '20'),
      subtotal: 0.0,
      total: 0.0,
      totalImpuestos: 0.0,
    },
  };

  const {
    control,
    setValue,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    const tributosList = getValues('impuestos');
    const tributosCodeList = tributosList
      .map((x) => x.value)
      .filter((x) => x !== '20' && x !== 'C3');
    const item = {
      origen: ItemOrigin.BIENES_Y_SERVICIOS,
      tipoItem: watch('tipoItem'),
      unidadDeMedida: watch('unidadDeMedida'),
      descripcion: watch('descripcion'),
      cantidad: watch('cantidad'),
      precio: watch('precio'),
      tipoVenta: watch('tipoVenta'),
      descuento: watch('descuento'),
      tributos: tributosCodeList,
      subTotal: watch('subtotal'),
      total: watch('total'),
      impuestosInfo: watch('impuestosInfo'),
    };

    addItem(item);
    //USar reset limpia tambien el form superior donde esta el receptor y emisor
    setValue(
      'tipoItem',
      tiposServiciosProductos.find((x) => x.value === '1'),
    );
    setValue('unidadDeMedida', {
      label: '59 - Unidad',
      value: 59,
    });
    setValue('descripcion', '');
    setValue('cantidad', 0.0);
    setValue('precio', 0.0);
    setValue(
      'tipoVenta',
      tiposVenta.find((x) => x.value === '1'),
    );
    setValue('descuento', 0.0);
    setValue(
      'impuestos',
      impuestos.filter((x) => x.value === '20'),
    );
    setValue('total', 0.0);
    setValue('totalImpuestos', 0.0);
    setValue('subtotal', 0.0);
  };
  const calculateTotal = () => {
    const listaImpuestosItem = getValues('impuestos');
    const listaTotalImpuestosDeItem = [];

    const precio = getValues('precio') ? getValues('precio') : 0;
    const cantidad = getValues('cantidad');
    if (precio <= 0 || cantidad <= 0) return;
    const descuento = getValues('descuento');
    let subtotal = precio * cantidad - descuento;

    listaImpuestosItem.forEach((impuesto) => {
      //Si el impuesto es calculable y es de tipo 1 (es decir, es un impuesto que afecta el total)
      if (impuesto.calculate && impuesto.type.some((x) => x === 1)) {
        const valorImpuesto = impuesto.amount[0];
        const formaCalculo = impuesto.mode;
        if (formaCalculo === 'porcentaje') {
          const itemIva = calcularIvaEnTotal(subtotal);
          const valor = redondearA2Decimales(
            (subtotal - itemIva) * valorImpuesto,
          );
          listaTotalImpuestosDeItem.push({
            label: impuesto.label,
            value: valor,
            code: impuesto.value,
            inputInResumen: false,
          });
        } else if (formaCalculo === 'fijo') {
          const valor = redondearA2Decimales(valorImpuesto);
          listaTotalImpuestosDeItem.push({
            label: impuesto.label,
            value: valor,
            code: impuesto.value,
            inputInResumen: false,
          });
        } else if (formaCalculo === 'unidad') {
          const valor = cantidad * valorImpuesto;
          listaTotalImpuestosDeItem.push({
            label: impuesto.label,
            value: valor,
            code: impuesto.value,
            inputInResumen: false,
          });
        }
      } else {
        const formaCalculo = impuesto.mode;
        if (formaCalculo === 'libre') {
          listaTotalImpuestosDeItem.push({
            label: impuesto.label,
            value: 0,
            code: impuesto.value,
            inputInResumen: true,
          });
        }
      }
    });
    const totalImpuestosSuma = listaTotalImpuestosDeItem.reduce((a, b) => {
      return a + parseFloat(b.value);
    }, 0);
    setValue('subtotal', redondearA2Decimales(subtotal));
    setValue('totalImpuestos', redondearA2Decimales(totalImpuestosSuma));
    const total = subtotal + totalImpuestosSuma;
    setValue('impuestosInfo', listaTotalImpuestosDeItem);
    setValue('total', redondearA2Decimales(total));
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-2">
          <label className="form-label" htmlFor="tipoProducto">
            Tipo:
          </label>

          <Controller
            name="tipoItem"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Seleccionar"
                {...field}
                isClearable
                options={tiposServiciosProductos.filter((x) => x.value !== 4)}
              />
            )}
          />
          {errors.tipoItem && (
            <div className="invalid-feedback">{errors.tipoItem?.message}</div>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label" htmlFor="unidadDeMedida">
            Unidad:
          </label>
          <Controller
            name="unidadDeMedida"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Seleccionar"
                {...field}
                isClearable
                options={unidadesDeMedida}
              />
            )}
          />
          {errors.unidadDeMedida && (
            <div className="invalid-feedback">
              {errors.unidadDeMedida?.message}
            </div>
          )}
        </div>
        <div className="col-4">
          <label className="form-label" htmlFor="descripcion">
            Producto:
          </label>
          <input
            className="form-control"
            name="nombreProducto"
            id="nombreProducto"
            type="text"
            placeholder="Bien o servicio"
            {...register('descripcion')}
          />
          {errors.descripcion && (
            <div className="invalid-feedback">
              {errors.descripcion?.message}
            </div>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label" htmlFor="cantidad">
            Cantidad:
          </label>
          <Controller
            name="cantidad"
            control={control}
            render={({ field }) => (
              <input
                className="form-control"
                min={0}
                {...field}
                type="number"
                onChange={(event) => {
                  field.onChange(event.target.value);
                  calculateTotal();
                }}
                onWheel={(event) => event.target.blur()}
                onClick={(event) => event.target.select()}
              />
            )}
          />

          {errors.cantidad && (
            <div className="invalid-feedback">{errors.cantidad?.message}</div>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label" htmlFor="precio">
            Precio:
          </label>
          <Controller
            name="precio"
            control={control}
            render={({ field }) => (
              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  min={0}
                  step={0.01}
                  {...field}
                  type="number"
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    calculateTotal();
                  }}
                  onWheel={(event) => event.target.blur()}
                  onClick={(event) => event.target.select()}
                />
              </div>
            )}
          />
          {errors.precio && (
            <div className="invalid-feedback">{errors.precio?.message}</div>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label" htmlFor="tipoVenta">
            Tipo de venta:
          </label>
          <Controller
            name="tipoVenta"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Seleccionar"
                {...field}
                isClearable
                options={tiposVenta}
                inputRef={field.ref}
                onChange={(event) => {
                  if (
                    event.value === TipoVenta.Gravada &&
                    !getValues('impuestos').some((x) => x.value === '20')
                  ) {
                    // Si es venta gravada y no esta el iva ya agregado se agrega el iva por defecto
                    const iva = impuestos.find((x) => x.value === '20');
                    setValue('impuestos', [...getValues('impuestos'), iva]);
                  }
                  if (event.value !== TipoVenta.Gravada) {
                    setValue('impuestos', [
                      ...getValues('impuestos').filter((x) => x.value !== '20'),
                    ]);
                  }
                  field.onChange(event);
                }}
              />
            )}
          />
          {errors.tipoVenta && (
            <div className="invalid-feedback">{errors.tipoVenta?.message}</div>
          )}
        </div>
        <div className="col-2">
          <label className="form-label" htmlFor="descuentoServicio">
            Descuento:
          </label>
          <Controller
            name="descuento"
            control={control}
            render={({ field }) => (
              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  min={0}
                  step={0.01}
                  {...field}
                  type="number"
                  onChange={(event) => {
                    field.onChange(event);
                    calculateTotal();
                  }}
                  onWheel={(event) => event.target.blur()}
                  onClick={(event) => event.target.select()}
                />
              </div>
            )}
          />

          {errors.descuento && (
            <div className="invalid-feedback">{errors.descuento?.message}</div>
          )}
        </div>
        <div className="col-md-3">
          <label className="form-label" htmlFor="descuentoServicio">
            Impuestos:
          </label>

          <Controller
            name="impuestos"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Seleccionar"
                className="basic-multi-select"
                {...field}
                isMulti
                isClearable
                options={impuestos}
                onChange={(event) => {
                  field.onChange(event);
                  calculateTotal();
                }}
              />
            )}
          />
          {errors.impuestos && (
            <div className="invalid-feedback">{errors.impuestos?.message}</div>
          )}
        </div>
        <div className="col-1">
          <label className="form-label" htmlFor="totalServicioProducto">
            SubTotal:
          </label>
          <h3 className="text-primary">
            {redondearA2Decimales(watch('subtotal')).toFixed(2)}
          </h3>
        </div>
        <div className="col-1">
          <label className="form-label" htmlFor="totalServicioProducto">
            Impuestos:
          </label>
          <h3 className="text-primary">
            {redondearA2Decimales(watch('totalImpuestos')).toFixed(2)}
          </h3>
        </div>
        <div className="col-1">
          <label className="form-label" htmlFor="totalServicioProducto">
            Total:
          </label>
          <h3 className="text-primary">
            {redondearA2Decimales(watch('total')).toFixed(2)}
          </h3>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center justify-content-end">
                <button
                  className="btn btn-lg btn-primary"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  <span className="fas fa-plus"></span>
                  <span className=""> Añadir item</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ServicioProductoDetalleForm };
