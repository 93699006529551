import '@inovua/reactdatagrid-community/index.css';
import { CardWrapper } from '../../../../../Components/Common/Cards';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const CondicionOtroForm = ({ formasDePago, plazosDePago, addPago }) => {
  const notSelectedValue = null;
  const validationSchema = Yup.object().shape({
    formaPago: Yup.object()
      .notOneOf([notSelectedValue])
      .required('La forma de pago es obligatoria'),
    plazoPago: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El plazo de pago es obligatorio'),
    monto: Yup.number().positive().required('El monto es obligatoria'),
    numeroDocumento: Yup.string().required('El documento es obligatorio'),
    periodo: Yup.number().positive().required('El periodo es obligatorio'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      formaPago: formasDePago.find((x) => x.value === "01"),
      plazoPago: notSelectedValue,
      monto: 0,
      numeroDocumento: '',
      periodo: 0
    },
  };

  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    const pago = {
      formaPago: data.formaPago,
      monto: data.monto,
      documento: data.numeroDocumento,
      plazo: data.plazoPago,
      periodo: data.periodo,
    };

    addPago(pago);

    setValue('formaPago', formasDePago.find((x) => x.value === '01'));
    setValue('monto', 0);
    setValue('numeroDocumento', '');
    setValue('plazoPago', notSelectedValue);
    setValue('periodo', 0);

  };

  return (
    <>
      <CardWrapper title="Otro">
        <div className="row">
          <div className="col-3">
            <label className="form-label" htmlFor="exampleFormControlTextarea1">
              Forma pago:
            </label>
            <Controller
              name="formaPago"
              control={control}
              render={({ field }) => (
                <Select {...field} options={formasDePago} />
              )}
            />
            {errors.formaPago && (
              <div className="invalid-feedback">
                {errors.formaPago?.message}
              </div>
            )}
          </div>
          <div className="col-2">
            <label className="form-label" htmlFor="monto">
              Monto:
            </label>
            <input
              className="form-control"
              name="monto"
              id="monto"
              type="number"
              placeholder="0.00"
              {...register('monto')}
              onWheel={(event) => event.target.blur()}
              onClick={(event) => event.target.select()}
            />
            {errors.monto && (
              <div className="invalid-feedback">{errors.monto?.message}</div>
            )}
          </div>
          {watch('formaPago')?.value === '01' ? (
            <></>
          ) : (
            <div className="col-3">
              <label className="form-label" htmlFor="numeroDocumento">
                N° Documento:
              </label>
              <input
                className="form-control"
                name="numeroDocumento"
                id="numeroDocumento"
                type="text"
                placeholder="Número"
                {...register('numeroDocumento')}
              />
              {errors.numeroDocumento && (
                <div className="invalid-feedback">
                  {errors.numeroDocumento?.message}
                </div>
              )}
            </div>
          )}
          <div className="col-2">
            <label className="form-label" htmlFor="exampleFormControlTextarea1">
              Plazo:
            </label>
            <Controller
              name="plazoPago"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={plazosDePago}
                  placeholder="Seleccionar"
                />
              )}
            />
            {errors.plazoPago && (
              <div className="invalid-feedback">
                {errors.formaPago?.message}
              </div>
            )}
          </div>
          <div className="col-1">
            <label className="form-label" htmlFor="periodo">
              Periodo:
            </label>
            <input
              className="form-control"
              name="periodo"
              id="periodo"
              type="number"
              placeholder="0"
              {...register('periodo')}
              onWheel={(event) => event.target.blur()}
              onClick={(event) => event.target.select()}
            />
            {errors.periodo && (
              <div className="invalid-feedback">{errors.periodo?.message}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-3 pt-4">
            <button
              className="btn btn-outline-primary me-1 mb-1"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              Agregar
            </button>
          </div>
        </div>
      </CardWrapper>
    </>
  );
};

export { CondicionOtroForm };
