import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ModalWrapper from '../../../../../../Components/Common/Modals/Modal';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { useForm, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const ModalDocumentoFisico = forwardRef((props, ref) => {
  const { setValue } = useFormContext();

  const validationSchema = Yup.object().shape({
    tipoDocumentoRel: Yup.string().required('Campo requerido'),
    numeroDocumentoRel: Yup.string().required('Campo requerido'),
    fechaGeneracionRel: Yup.string().required('Campo requerido'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    formState: { errors },
    handleSubmit,
    register,
    getValues,
    setValue: setValueForm,
  } = useForm(formOptions);
  const modalRef = useRef(null);

  const onSubmit = () => {
    setValue('documentoRelacionado', {
      tipoDocumentoRel: getValues('tipoDocumentoRel'),
      numeroDocumentoRel: getValues('numeroDocumentoRel'),
      fechaGeneracionRel: getValues('fechaGeneracionRel'),
      tipoGeneracion: 'Documento físico',
    });

    limpiar();
    modalRef.current.toggle();
  };
  useImperativeHandle(ref, () => ({
    async openModal() {
      limpiar();
      modalRef.current.toggle();
    },
  }));

  const limpiar = () => {
    setValueForm('tipoDocumentoRel', '');
    setValueForm('numeroDocumentoRel', '');
    setValueForm('fechaGeneracionRel', '');
  };

  return (
    <>
      <ModalWrapper title={`Relación documento físico`} ref={modalRef}>
        <CardWrapperTools
          title="Datos documento físico"
          footer={
            <div className="row">
              <div className="col-lg-12 text-center">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  <span className="fa fa-plus me-1" />
                  Agregar documento
                </button>
              </div>
            </div>
          }
        >
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <label htmlFor="">Tipo de documento:</label>

                <select
                  id="tipoDocumentoRel"
                  {...register('tipoDocumentoRel')}
                  defaultValue={''}
                  className="form-select"
                >
                  <option value="" disabled={true}>
                    Seleccione un opción
                  </option>
                  <option value="03">Comprobante de crédito fiscal</option>
                  {/* <option value="07">Comprobante de retención</option> */}
                </select>

                {errors.tipoDocumentoRel && (
                  <div className="invalid-feedback">
                    {errors.tipoDocumentoRel?.message}
                  </div>
                )}
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                <label htmlFor="">Número de documento:</label>
                <input
                  type="text"
                  className="form-control"
                  id="numeroDocumentoRel"
                  placeholder="Digite el número de documento"
                  {...register('numeroDocumentoRel')}
                />
                {errors.numeroDocumentoRel && (
                  <div className="invalid-feedback">
                    {errors.numeroDocumentoRel?.message}
                  </div>
                )}
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                <label htmlFor="">Fecha de generación:</label>
                <input
                  type="date"
                  className="form-control"
                  id="fechaGeneracionRel"
                  {...register('fechaGeneracionRel')}
                />
                {errors.fechaGeneracionRel && (
                  <div className="invalid-feedback">
                    {errors.fechaGeneracionRel?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </>
  );
});
export default ModalDocumentoFisico;
