import React, { useState } from 'react';
import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import { Alert, loadingReload } from '../../../services/alerts/alerts';
import http from '../../../services/http/http.auth.services';

export default function ApiKetCliente({ id, apiKeyValue, realoadClient }) {
  const [isApiKeyVisible, setApiKeyVisible] = useState(false);

  const toggleApiKeyVisibility = () => {
    const apiKeyIcon = document.getElementById('apiKeyIcon');
    if (isApiKeyVisible) {
      apiKeyIcon.classList.remove('fa-eye-slash');
      apiKeyIcon.classList.add('fa-eye');
    } else {
      apiKeyIcon.classList.remove('fa-eye');
      apiKeyIcon.classList.add('fa-eye-slash');
    }
    setApiKeyVisible(!isApiKeyVisible);
  };

  const generateApiKey = async () => {
    try {
      loadingReload(true, 'Generando Api-Key');

      const { data } = await http.post(`/clientes/create-api-key/${id}`);
      if (data) {
        loadingReload(false, null);
        realoadClient();
        Alert({
          title: 'Api-Key generado',
          icon: 'success',
          text: 'El Api-Key se genero correctamente',
        });
        return data;
      }
    } catch (error) {
      Alert({
        title: 'Error al generar Api-Key',
        icon: 'error',
        text: 'Ocurrio un error al generar el Api-Key',
      });
    }
  };
  const copyApiKeyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(apiKeyValue);
      Alert({
        title: 'Copiado',
        text: 'API Key copiado al portapapeles',
        icon: 'success',
      });
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo copiar el API Key',
        icon: 'error',
      });
    }
  };
  return (
    <CardWrapperTools
      title="Generar Api-Key"
      footer={
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary btn-lg"
                onClick={() => generateApiKey()}
              >
                <span className="fas fa-key"></span>
                {apiKeyValue ? ' Volver a generar api key' : ' Generar api key'}
              </button>
            </div>
          </div>
        </div>
      }
    >
      {apiKeyValue && (
        <div className="row g-3">
          <div className="table-responsive scrollbar">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Api-Key</th>
                  <th scope="col">Accion</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {isApiKeyVisible
                      ? apiKeyValue
                      : '************************************'}
                  </td>
                  <td>
                    <div className="d-flex flex-column flex-md-row">
                      <button
                        onClick={toggleApiKeyVisibility}
                        className="btn btn-outline-info me-1 mb-1"
                      >
                        <span id="apiKeyIcon" className="far fa-eye"></span>
                        {isApiKeyVisible ? 'Ocultar' : 'Mostrar'}
                      </button>
                      <button
                        onClick={copyApiKeyToClipboard}
                        className="btn btn-outline-secondary me-1 mb-1 mt-1 mt-md-0"
                      >
                        <span
                          className="far fa-copy"
                          style={{ marginRight: '3px' }}
                        ></span>
                        Copiar
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </CardWrapperTools>
  );
}
