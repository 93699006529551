import React, { useEffect } from 'react';
import CollapseCard from '../../../../Components/Common/Cards/CollapseCard';
import ReceptorForm from './components/ReceptorForm';
import { useForm, FormProvider } from 'react-hook-form';
import EmisorForm from './components/EmisorForm';
import DocumentoRelacionadoForm from './components/DocumentoRelacionadoForm';
import VentaTercerosForm from './components/VentaTercerosForm';
import DatosNotaCreditoForm from './components/DatosNotaCreditoForm';
import InformacionEntregaForm from './components/InformacionEntregaForm';
import CardWrapperTools from '../../../../Components/Common/Cards/CardWrapperTools';
import { yupResolver } from '@hookform/resolvers/yup';
import { getTipoGeneracionDte, validationSchema } from './utils/NotaCredito';
import { split } from 'lodash';
import {
  destroyItemSession,
  getSession,
} from '../../../../services/encrytion/encryption.service';
import { post } from '../../../../services/http/http.service';
import {
  Alert,
  AlertQuestionYesNo,
  loadingReload,
} from '../../../../services/alerts/alerts';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { convertirNumeroALetras } from '../../../../utils/NumeroLetras';

const NotaCredito = () => {
  //loadingReload(false);
  const ambiente = getSession('ambiente');
  const cliente = getSession('clienteSeleccionado');
  const navigate = useNavigate();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      reteRenta: '0.00',
      ivaPerci1: '0.00',
      ivaRete1: '0.00',
      descuentoGravada: '0.00',
      descuentoExenta: '0.00',
      descuentoNoSujeta: '0.00',
    },
  };

  const methods = useForm(formOptions);

  const onSubmit = async (data) => {
    const datosDocumentoRelacionado = methods.getValues('documentoRelacionado');
    const cuerpoDocumento = methods.getValues('cuerpoDocumento');
    const resumen = methods.getValues('resumen');
    const dataNotaCredito = {
      documentoRelacionado: [
        {
          tipoDocumento: datosDocumentoRelacionado?.tipoDocumentoRel,
          tipoGeneracion: getTipoGeneracionDte(
            datosDocumentoRelacionado?.tipoGeneracion,
          ),
          numeroDocumento: datosDocumentoRelacionado?.numeroDocumentoRel,
          fechaEmision: datosDocumentoRelacionado?.fechaGeneracionRel,
        },
      ],
      emisor: {
        codActividad: methods.getValues('actividadEconomicaEmisor')?.value,
        descActividad: split(
          methods.getValues('actividadEconomicaEmisor')?.label,
          '-',
          2,
        )[1].trim(),
      },
      receptor: {
        nit: methods.getValues('nitReceptor').replaceAll('-', ''),
        nrc: methods.getValues('nrcReceptor'),
        correo: methods.getValues('correoElectronicoReceptor'),
        nombre: methods.getValues('nombreClienteReceptor'),
        nombreComercial: methods.getValues('nombreComercialReceptor') || null,
        telefono: methods.getValues('telefonoReceptor'),
        direccion: {
          municipio: methods.getValues('municipioReceptor')?.value,
          complemento: methods.getValues('complementoReceptor'),
          departamento: methods.getValues('departamentoReceptor')?.value,
        },
        codActividad: methods.getValues('actividadEconomicaReceptor')?.value,
        descActividad: methods.getValues('actividadEconomicaReceptor')?.label,
      },
      ventaTercero:
        methods.getValues('nitVentaTerceros') ||
        methods.getValues('nombreVentaTerceros')
          ? {
              nit: methods.getValues('nitVentaTerceros') || null,
              nombre: methods.getValues('nombreVentaTerceros') || null,
            }
          : null,
      cuerpoDocumento: cuerpoDocumento.map((item, index) => ({
        numItem: index + 1,
        tipoItem: item.tipoItem,
        numeroDocumento: item.numeroDocumento,
        cantidad: item.cantidad,
        codigo: item.codigo?.toString(),
        codTributo: item.codTributo || null,
        uniMedida: item.uniMedida,
        descripcion: item.descripcion,
        precioUni: Number(parseFloat(item.precioUni).toFixed(2)),
        montoDescu: Number(parseFloat(item.montoDescu).toFixed(2)),
        ventaNoSuj: Number(parseFloat(item.ventaNoSuj).toFixed(2)),
        ventaExenta: Number(parseFloat(item.ventaExenta).toFixed(2)),
        ventaGravada: Number(parseFloat(item.ventaGravada).toFixed(2)),
        tributos: item.tributos,
      })),
      resumen: {
        tributos: resumen.tributos
          .map((item) => {
            if (item.mode === 'libre') {
              return {
                codigo: item.codigo,
                descripcion: item.descripcion,
                valor: methods.getValues(item.codigo)
                  ? Number(
                      parseFloat(methods.getValues(item.codigo)).toFixed(2),
                    )
                  : 0,
              };
            }

            if (item.mode === 'porcentaje') {
              return {
                codigo: item.codigo,
                descripcion: item.descripcion,
                valor: Number(
                  parseFloat(
                    item.valor -
                      methods.getValues('descuentoGravada') * item.amount[0],
                  ).toFixed(2),
                ),
              };
            }
            return {
              codigo: item.codigo,
              descripcion: item.descripcion,
              valor: Number(parseFloat(item.valor).toFixed(2)),
            };
          })
          .filter((item) => item.valor > 0),
        subTotal: Number(
          parseFloat(
            resumen.subTotal -
              methods.watch('descuentoGravada') -
              methods.watch('descuentoExenta') -
              methods.watch('descuentoNoSujeta'),
          ).toFixed(2),
        ),
        ivaRete1: Number(parseFloat(resumen.ivaRete1).toFixed(2)),
        ivaPerci1: Number(parseFloat(resumen.ivaPerci1).toFixed(2)),
        reteRenta: Number(parseFloat(resumen.reteRenta).toFixed(2)),
        descuNoSuj: Number(
          parseFloat(methods.watch('descuentoNoSujeta')).toFixed(2),
        ),
        totalDescu: Number(
          parseFloat(
            Number(resumen.totalDescu) +
              Number(methods.watch('descuentoGravada')) +
              Number(methods.watch('descuentoExenta')) +
              Number(methods.watch('descuentoNoSujeta')),
          ).toFixed(2),
        ),
        totalNoSuj: Number(parseFloat(resumen.totalNoSuj).toFixed(2)),
        descuExenta: Number(
          parseFloat(methods.watch('descuentoExenta')).toFixed(2),
        ),
        totalExenta: Number(parseFloat(resumen.totalExenta || 0).toFixed(2)),
        totalLetras: convertirNumeroALetras(
          Number(
            parseFloat(
              resumen.montoTotalOperacion -
                methods.watch('descuentoGravada') -
                methods.watch('descuentoExenta') -
                methods.watch('descuentoNoSujeta') -
                resumen?.tributos
                  .filter((t) => t.mode === 'porcentaje')
                  .reduce(
                    (acc, item) =>
                      acc + methods.watch('descuentoGravada') * item.amount[0],
                    0,
                  ),
            ).toFixed(2),
          ),
        )?.trim(),
        descuGravada: Number(
          parseFloat(methods.watch('descuentoGravada')).toFixed(2),
        ),
        totalGravada: Number(parseFloat(resumen.totalGravada).toFixed(2)),
        subTotalVentas: Number(parseFloat(resumen.subTotalVentas).toFixed(2)),
        condicionOperacion: Number(methods.getValues('condicionOperacion')),
        montoTotalOperacion: Number(
          parseFloat(
            resumen.montoTotalOperacion -
              methods.watch('descuentoGravada') -
              methods.watch('descuentoExenta') -
              methods.watch('descuentoNoSujeta') -
              resumen?.tributos
                .filter((t) => t.mode === 'porcentaje')
                .reduce(
                  (acc, item) =>
                    acc + methods.watch('descuentoGravada') * item.amount[0],
                  0,
                ),
          ).toFixed(2),
        ),
      },
      extension:
        methods.getValues('nombreResponsableEmisor') ||
        methods.getValues('numeroDocumentoResponsableEmisor') ||
        methods.getValues('nombreResponsableReceptor') ||
        methods.getValues('numeroDocumentoResponsableReceptor') ||
        methods.getValues('observaciones')
          ? {
              nombEntrega: methods.getValues('nombreResponsableEmisor') || null,
              docuEntrega:
                methods.getValues('numeroDocumentoResponsableEmisor') || null,
              nombRecibe:
                methods.getValues('nombreResponsableReceptor') || null,
              docuRecibe:
                methods.getValues('numeroDocumentoResponsableReceptor') || null,
              observaciones: methods.getValues('observaciones') || null,
            }
          : null,
    };

    AlertQuestionYesNo({
      title: 'Generar documento',
      text: '¿Está seguro que desea generar el documento de Nota de Crédito?',
      icon: 'info',
    }).then(async (result) => {
      if (result) {
        try {
          const headers = {
            'x-ambiente': ambiente,
            'x-branchoffice-id': methods.getValues('establecimientoEmisor')
              ?.value,
          };

          loadingReload(
            true,
            'Generando documento',
            'Espere mientras se genera el documento, esto puede tardar unos segundos',
          );
          const { data, status } = await post(
            'dte/nota-credito',
            dataNotaCredito,
            headers,
          );

          if (status === 201) {
            if (data?.selloRecibido) {
              loadingReload(false);
              enqueueSnackbar(
                'El documento de Nota de Crédito fue generado con éxito',
                {
                  variant: 'success',
                  preventDuplicate: true,
                },
              );
              navigate(
                `/dte/consulta/${data?.codigoGeneracion}/detalle/${cliente?.id}`,
              );

              return;
            }
            loadingReload(false);
            Alert({
              title:
                'El documento fue generado con éxito, pero tiene observaciones',
              text: 'Por favor contacte con el administrador del sistema para más información',
              icon: 'warning',
            });

            navigate(
              `/dte/consulta/${data?.codigoGeneracion}/detalle/${cliente?.id}`,
            );

            return;
          } else {
            loadingReload(false);
            Alert({
              title: 'Error al generar el documento',
              text: 'Por favor contacte con el administrador del sistema para más información',
              icon: 'error',
            });
          }
        } catch (error) {
          loadingReload(false);
          Alert({
            title: 'Error al generar el documento',
            text: 'Por favor contacte con el administrador del sistema para más información',
            icon: 'error',
          });
        }
      }
    });
  };

  useEffect(() => {
    if (!ambiente) {
      navigate('/dte');
    }

    return () => {
      destroyItemSession('ambiente');
    };
  }, [ambiente, navigate]);

  return (
    <FormProvider {...methods}>
      <CardWrapperTools
        title={
          <div className="d-flex justify-content-start">
            <h3 className="text-info-emphasis">
              Panel de emisión de Nota de Crédito Electrónica
            </h3>
          </div>
        }
        footer={
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={methods.handleSubmit(onSubmit)}
                  >
                    <span className="far fa-paper-plane"></span> Generar
                    documento
                  </button>
                </div>
              </div>
            </div>
            {ambiente === '00' && (
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="alert alert-warning text-center" role="alert">
                    <i className="fa fa-exclamation-triangle"></i>
                    <span className="p-1">
                      Este documento será generado como prueba, no tiene ninguna
                      validez fiscal.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        }
        tools={
          ambiente === '00' ? (
            <span className="badge badge-subtle-warning fs-1">
              <i className="fa fa-exclamation-triangle"></i>
              <span className="p-1">DTE de prueba</span>
            </span>
          ) : null
        }
        isHeaderFixed={true}
      >
        <div className="row">
          <div className="col-lg-12">
            <CollapseCard title={'Datos de emisor y receptor'}>
              <div className="row mt-2">
                <div className="col-12">
                  <>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          href="#datosReceptor"
                          role="tab"
                          aria-controls="datosReceptor"
                          aria-selected="true"
                        >
                          Datos del Receptor
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          href="#datosEmisor"
                          role="tab"
                          aria-controls="datosEmisor"
                          aria-selected="false"
                        >
                          Datos del Emisor
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="datosReceptor"
                        role="tabpanel"
                        aria-labelledby="datosReceptor"
                      >
                        <ReceptorForm />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="datosEmisor"
                        role="tabpanel"
                        aria-labelledby="datosEmisor"
                      >
                        <EmisorForm />
                      </div>
                    </div>
                  </>
                </div>
                {/* <div className="col-lg-6">
                  <ReceptorForm />
                </div>
                <div className="col-lg-6">
                  <EmisorForm />
                </div> */}
              </div>
            </CollapseCard>
          </div>

          <div className="col-lg-12">
            <CollapseCard title={'Documento relacionado (sección obligatoria)'}>
              <div className="row">
                <div className="col-lg-12">
                  <DocumentoRelacionadoForm />
                </div>
              </div>
            </CollapseCard>
          </div>

          <div className="col-lg-12">
            <CollapseCard
              show={false}
              title={
                'Ventas por cuenta de terceros (Sección opcional, debe completarse si se actúa de calidad de comisionista)'
              }
            >
              <div className="row">
                <div className="col-lg-12">
                  <VentaTercerosForm />
                </div>
              </div>
            </CollapseCard>
          </div>

          <div className="col-lg-12">
            <CollapseCard title={'Datos de la nota de crédito'}>
              <div className="row">
                <div className="col-lg-12">
                  <DatosNotaCreditoForm />
                </div>
              </div>
            </CollapseCard>
          </div>

          <div className="col-lg-12">
            <CollapseCard
              show={false}
              title={'Información de recepción y entrega del documento'}
            >
              <div className="row">
                <div className="col-lg-12">
                  <InformacionEntregaForm />
                </div>
              </div>
            </CollapseCard>
          </div>
        </div>
      </CardWrapperTools>
    </FormProvider>
  );
};

export default NotaCredito;
