import React from 'react';
import packageJson from '../../../../package.json';
const FooterPage = () => {
  const getYear = () => {
    const year = new Date().getFullYear();
    return year;
  };

  const [year] = React.useState(getYear());

  return (
    <footer className="footer">
      <div className="row g-0 justify-content-between fs--1 mt-4 mb-3">
        <div className="col-12 col-sm-auto text-center">
          <p className="mb-0 text-600">
            Todos los derechos reservados.{' '}
            <span className="d-none d-sm-inline-block">|</span>
            <br className="d-sm-none" /> {year} ©{' '}
            <a href="https://easyfact.com.sv/">EasyFact</a>
          </p>
        </div>
        <div className="col-12 col-sm-auto text-center">
          <p className="mb-0 text-600">{packageJson.version}</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterPage;
