import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import ModalWrapper from '../../../../../../Components/Common/Modals/Modal';
import { ModalBody } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Alert, loadingReload } from '../../../../../../services/alerts/alerts';
import { post } from '../../../../../../services/http/http.service';

const ModalReenviarDte = forwardRef((props, ref) => {
  const modalRef = useRef(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Correo electrónico inválido'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      telefono: '',
    },
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm(formOptions);
  useImperativeHandle(ref, () => ({
    async openModal() {
      modalRef.current.toggle();
      // reset();
    },
  }));

  useEffect(() => {
    setValue(
      'email',
      props?.dte?.datos_dte?.receptor?.correo ||
        props?.dte?.datos_dte?.sujetoExcluido?.correo ||
        '',
    );
    setValue(
      'telefono',
      props?.dte?.datos_dte?.receptor?.telefono ||
        props?.dte?.datos_dte?.sujetoExcluido?.telefono ||
        '',
    );

    return () => {
      reset();
    };
  }, [
    setValue,
    props?.dte?.datos_dte?.receptor?.telefono,
    props?.dte?.datos_dte?.receptor?.correo,
    props?.dte?.datos_dte?.sujetoExcluido?.correo,
    props?.dte?.datos_dte?.sujetoExcluido?.telefono,
    reset,
  ]);

  const onSubmit = async () => {
    if (!getValues('email') && !getValues('telefono')) {
      Alert({
        title: 'Atención',
        text: 'Debe ingresar un correo electrónico o un número de teléfono',
        icon: 'warning',
      });
      return;
    }
    try {
      loadingReload(true, 'Reenviando DTE');

      const dataReenvio = {
        correo: getValues('email'),
        telefono: getValues('telefono'),
      };
      const { data } = await post(
        'dte/reenviar-dte',
        dataReenvio,
        {
          'Content-Type': 'application/json',
          'x-ambiente': props?.dte?.ambiente,
          'x-branchoffice-id': props?.dte?.sucursal,
        },
        {
          codigoGeneracion: props?.dte?.codigo_generacion,
        },
      );
      loadingReload(false);

      modalRef.current.toggle();
      Alert({
        title: 'Reenvío exitoso',
        text: data.message,
        icon: 'success',
      });
    } catch (error) {
      loadingReload(false);

      Alert({
        title: 'Error',
        text: 'Error al reenviar DTE',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <ModalWrapper
        title={`Panel de reenvío de DTE`}
        ref={modalRef}
        size={'xl'}
      >
        <ModalBody>
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <label htmlFor="">Correo Electrónico:</label>

              <div className="input-group mb-3">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Correo Electrónico del destinatario"
                  {...register('email')}
                />
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setValue(
                      'email',
                      props?.dte?.datos_dte?.receptor?.correo ||
                        props?.dte?.datos_dte?.sujetoExcluido?.correo ||
                        '',
                    );
                    setValue(
                      'telefono',
                      props?.dte?.datos_dte?.receptor?.telefono ||
                        props?.dte?.datos_dte?.sujetoExcluido?.telefono ||
                        '',
                    );
                  }}
                >
                  Restablecer
                </button>
              </div>
              {errors.email && (
                <div className="invalid-feedback">{errors.email?.message}</div>
              )}
            </div>

            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <label htmlFor="">Teléfono Whatsapp:</label>
              <Controller
                name="telefono"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PhoneInput
                    country={'sv'}
                    enableSearch={true}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    inputStyle={{ width: '100%' }}
                  />
                )}
              />
            </div>

            <div className="col-12 text-center mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="fa fa-paper-plane me-2"></span> Reenviar
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalWrapper>
    </>
  );
});

export default ModalReenviarDte;
