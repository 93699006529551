export const getDteType = (dte) => {
  switch (dte) {
    case '07':
      return 'Comprobante de retención';
    case '11':
      return 'Facturas de exportación';
    case '08':
      return 'Comprobante de liquidación';

    case '03':
      return 'Comprobante de crédito fiscal';
    case '04':
      return 'Nota de remisión';

    case '15':
      return 'Comprobante de donación';

    case '09':
      return 'Documento contable de liquidación';

    case '01':
      return 'Factura';

    case '05':
      return 'Nota de crédito';

    case '14':
      return 'Factura de sujeto excluido';

    case '06':
      return 'Nota de débito';
    default:
      return 'No definido';
  }
};

export const getUnidadMedida = (unidad) => {
  const data = [
    {
      id: '6513ab12db1b25f1cb239c6a',
      code: 1,
      slug: 'metro',
      value: 'Metro',
    },
    {
      id: '6513ab12db1b25f1cb239c6b',
      code: 2,
      slug: 'yarda',
      value: 'Yarda',
    },
    {
      id: '6513ab12db1b25f1cb239c6c',
      code: 3,
      slug: 'vara',
      value: 'Vara',
    },
    {
      id: '6513ab12db1b25f1cb239c6d',
      code: 4,
      slug: 'pie',
      value: 'Pie',
    },
    {
      id: '6513ab12db1b25f1cb239c6e',
      code: 5,
      slug: 'pulgada',
      value: 'Pulgada',
    },
    {
      id: '6513ab12db1b25f1cb239c6f',
      code: 6,
      slug: 'milimetro',
      value: 'Milímetro',
    },
    {
      id: '6513ab12db1b25f1cb239c70',
      code: 8,
      slug: 'milla-cuadrada',
      value: 'Milla cuadrada',
    },
    {
      id: '6513ab12db1b25f1cb239c72',
      code: 10,
      slug: 'hectarea',
      value: 'Hectárea',
    },
    {
      id: '6513ab12db1b25f1cb239c73',
      code: 11,
      slug: 'manzana',
      value: 'Manzana',
    },
    {
      id: '6513ab12db1b25f1cb239c74',
      code: 12,
      slug: 'acre',
      value: 'Acre',
    },
    {
      id: '6513ab12db1b25f1cb239c75',
      code: 13,
      slug: 'metro-cuadrado',
      value: 'Metro cuadrado',
    },
    {
      id: '6513ab12db1b25f1cb239c76',
      code: 14,
      slug: 'yarda-cuadrada',
      value: 'Yarda cuadrada',
    },
    {
      id: '6513ab12db1b25f1cb239c77',
      code: 15,
      slug: 'vara-cuadrada',
      value: 'Vara cuadrada',
    },
    {
      id: '6513ab12db1b25f1cb239c79',
      code: 17,
      slug: 'pulgada-cuadrada',
      value: 'Pulgada cuadrada',
    },
    {
      id: '6513ab12db1b25f1cb239c7a',
      code: 18,
      slug: 'metro-cubico',
      value: 'Metro cúbico',
    },
    {
      id: '6513ab12db1b25f1cb239c78',
      code: 16,
      slug: 'pie-cuadrado',
      value: 'Pie cuadrado',
    },
    {
      id: '6513ab12db1b25f1cb239c7c',
      code: 20,
      slug: 'barril',
      value: 'Barril',
    },
    {
      id: '6513ab12db1b25f1cb239c7d',
      code: 21,
      slug: 'pie-cubico',
      value: 'Pie cúbico',
    },
    {
      id: '6513ab12db1b25f1cb239c7e',
      code: 22,
      slug: 'galon',
      value: 'Galón',
    },
    {
      id: '6513ab12db1b25f1cb239c7f',
      code: 23,
      slug: 'litro',
      value: 'Litro',
    },
    {
      id: '6513ab12db1b25f1cb239c80',
      code: 24,
      slug: 'botella',
      value: 'Botella',
    },
    {
      id: '6513ab12db1b25f1cb239c81',
      code: 25,
      slug: 'pulgada-cubica',
      value: 'Pulgada cúbica',
    },
    {
      id: '6513ab12db1b25f1cb239c82',
      code: 26,
      slug: 'mililitro',
      value: 'Mililitro',
    },
    {
      id: '6513ab12db1b25f1cb239c84',
      code: 29,
      slug: 'tonelada-metrica',
      value: 'Tonelada métrica',
    },
    {
      id: '6513ab12db1b25f1cb239c83',
      code: 27,
      slug: 'onza-fluida',
      value: 'Onza fluida',
    },
    {
      id: '6513ab12db1b25f1cb239c85',
      code: 30,
      slug: 'tonelada',
      value: 'Tonelada',
    },
    {
      id: '6513ab12db1b25f1cb239c86',
      code: 31,
      slug: 'quintal-metrico',
      value: 'Quintal métrico',
    },
    {
      id: '6513ab12db1b25f1cb239c87',
      code: 32,
      slug: 'quintal',
      value: 'Quintal',
    },
    {
      id: '6513ab12db1b25f1cb239c88',
      code: 33,
      slug: 'arroba',
      value: 'Arroba',
    },
    {
      id: '6513ab12db1b25f1cb239c89',
      code: 34,
      slug: 'kilogramo',
      value: 'Kilogramo',
    },
    {
      id: '6513ab12db1b25f1cb239c8a',
      code: 35,
      slug: 'libra-troy',
      value: 'Libra troy',
    },
    {
      id: '6513ab12db1b25f1cb239c8b',
      code: 36,
      slug: 'libra',
      value: 'Libra',
    },
    {
      id: '6513ab12db1b25f1cb239c8c',
      code: 37,
      slug: 'onza-troy',
      value: 'Onza troy',
    },
    {
      id: '6513ab12db1b25f1cb239c8d',
      code: 38,
      slug: 'onza',
      value: 'Onza',
    },
    {
      id: '6513ab12db1b25f1cb239c8e',
      code: 39,
      slug: 'gramo',
      value: 'Gramo',
    },
    {
      id: '6513ab12db1b25f1cb239c8f',
      code: 40,
      slug: 'miligramo',
      value: 'Miligramo',
    },
    {
      id: '6513ab12db1b25f1cb239c90',
      code: 42,
      slug: 'megawatt',
      value: 'Megawatt',
    },
    {
      id: '6513ab12db1b25f1cb239c91',
      code: 43,
      slug: 'kilowatt',
      value: 'Kilowatt',
    },
    {
      id: '6513ab12db1b25f1cb239c92',
      code: 44,
      slug: 'watt',
      value: 'Watt',
    },
    {
      id: '6513ab12db1b25f1cb239c71',
      code: 9,
      slug: 'kilometro-cuadrado',
      value: 'Kilómetro cuadrado',
    },
    {
      id: '6513ab12db1b25f1cb239c95',
      code: 47,
      slug: 'voltio-amperio',
      value: 'Voltio-amperio',
    },
    {
      id: '6513ab12db1b25f1cb239c94',
      code: 46,
      slug: 'kilovoltio-amperio',
      value: 'Kilovoltio-amperio',
    },
    {
      id: '6513ab12db1b25f1cb239c96',
      code: 49,
      slug: 'gigawatt-hora',
      value: 'Gigawatt-hora',
    },
    {
      id: '6513ab12db1b25f1cb239c97',
      code: 50,
      slug: 'megawatt-hora',
      value: 'Megawatt-hora',
    },
    {
      id: '6513ab12db1b25f1cb239c98',
      code: 51,
      slug: 'kilowatt-hora',
      value: 'Kilowatt-hora',
    },
    {
      id: '6513ab12db1b25f1cb239c99',
      code: 52,
      slug: 'watt-hora',
      value: 'Watt-hora',
    },
    {
      id: '6513ab12db1b25f1cb239c9a',
      code: 53,
      slug: 'kilovoltio',
      value: 'Kilovoltio',
    },
    {
      id: '6513ab12db1b25f1cb239c9b',
      code: 54,
      slug: 'voltio',
      value: 'Voltio',
    },
    {
      id: '6513ab12db1b25f1cb239c7b',
      code: 19,
      slug: 'yarda-cubica',
      value: 'Yarda cúbica',
    },
    {
      id: '6513ab12db1b25f1cb239c9d',
      code: 56,
      slug: 'medio-millar',
      value: 'Medio millar',
    },
    {
      id: '6513ab12db1b25f1cb239c9e',
      code: 57,
      slug: 'ciento',
      value: 'Ciento',
    },
    {
      id: '6513ab12db1b25f1cb239c9f',
      code: 58,
      slug: 'docena',
      value: 'Docena',
    },
    {
      id: '6513ab12db1b25f1cb239ca0',
      code: 59,
      slug: 'unidad',
      value: 'Unidad',
    },
    {
      id: '6513ab12db1b25f1cb239ca1',
      code: 99,
      slug: 'otra',
      value: 'otra',
    },
    {
      id: '6513ab12db1b25f1cb239c93',
      code: 45,
      slug: 'megavoltio-amperio',
      value: 'Megavoltio-amperio',
    },
    {
      id: '6513ab12db1b25f1cb239c9c',
      code: 55,
      slug: 'millar',
      value: 'Millar',
    },
  ];

  const unidadMedida = data.find((item) => item.code === unidad);

  return unidadMedida ? unidadMedida.value : 'No definido';
};

export const parseNit = (nit) => {
  // Convierte el número a una cadena
  const nitStr = nit.toString();

  // Aplica el formato deseado
  const resultado =
    nitStr.slice(0, 4) +
    '-' +
    nitStr.slice(4, 10) +
    '-' +
    nitStr.slice(10, 13) +
    '-' +
    nitStr.slice(13);

  return resultado;
};

export const getTipoPago = (pago) => {
  switch (pago) {
    case '09':
      return 'Monedero electrónico';
    case '14':
      return 'Giro bancario';
    case '11':
      return 'Bitcoin';

    case '05':
      return 'Transferencia Depósito Bancario';
    case '99':
      return 'Otros';

    case '13':
      return 'Cuentas por pagar del receptor';

    case '03':
      return 'Tarjeta Crédito';

    case '01':
      return 'Billetes y monedas';

    case '04':
      return 'Cheque';

    case '02':
      return 'Tarjeta Débito';

    case '06':
      return 'Vales o Cupones';

    case '12':
      return 'Otras Criptomonedas';

    case '08':
      return 'Dinero electrónico';

    case '10':
      return 'Certificado o tarjeta de regalo';
    default:
      return 'No definido';
  }
};

export const getPlazos = (pago) => {
  switch (pago) {
    case '03':
      return 'Años';
    case '02':
      return 'Meses';
    case '01':
      return 'Días';
    default:
      return 'No definido';
  }
};

export const getTipoDocumento = (tipo) => {
  switch (tipo) {
    case '03':
      return 'Pasaporte';
    case '02':
      return 'Carnet de Residente';
    case '13':
      return 'DUI';
    case '37':
      return 'Otro';
    case '36':
      return 'NIT';
    default:
      return 'No definido';
  }
};
