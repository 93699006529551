import { forwardRef, useImperativeHandle, useRef } from 'react';
import ModalWrapper from '../../../../../Components/Common/Modals/Modal';
import ProductosTable from './ProductosTable';

const ModalBuscadorProductos = forwardRef((props, ref) => {
  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    async openModal() {
      modalRef.current.toggle();
    },
  }));

  const handleProductSelection = (data) => {
    props.onSelect(data);
    modalRef.current.toggle();
  };

  return (
    <div>
      <ModalWrapper
        title="Buscar productos/servicios u otros"
        ref={modalRef}
        size={'xl'}
        style={{ maxWidth: '90%' }}
      >
        <ProductosTable onSelect={handleProductSelection}></ProductosTable>
      </ModalWrapper>
    </div>
  );
});
export default ModalBuscadorProductos;
