import React, { useRef } from 'react';
import ModalBuscadorClientes from './ModalBuscadorClientes';

const BuscadorClientes = ({
  onSelectClient,
  showCleanButton,
  onDeleteClient,
  contribuyente,
}) => {
  const modalBuscadorClientes = useRef(null);
  return (
    <div>
      {showCleanButton && (
        <button
          onClick={() => {
            onDeleteClient();
          }}
          className="btn btn-danger mx-2"
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          Borrar
        </button>
      )}
      <button
        onClick={() => {
          modalBuscadorClientes.current.openModal();
        }}
        className="btn btn-primary"
      >
        <i className="fa fa-search" aria-hidden="true"></i>
        Buscar
      </button>

      <ModalBuscadorClientes
        ref={modalBuscadorClientes}
        onSelect={onSelectClient}
        contribuyente={contribuyente}
      />
    </div>
  );
};

export default BuscadorClientes;
