import '@inovua/reactdatagrid-community/index.css';
import { CondicionContadoForm } from './CondicionContadoForm';
import { CondicionCreditoForm } from './CondicionCreditoForm';
import { CondicionOtroForm } from './CondicionOtroForm';
import { TipoPago } from '../../../../../utils/Utils';

let headers;

headers = ['Forma de Pago', 'Monto', 'Documento', 'Plazo', 'Periodo'];
const FormasDePago = ({
  formaPagoSelected,
  itemsPagos,
  addPago,
  deletePago,
  formasDePago,
  plazosDePago,
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-12">
          {renderFormaPagoForm(
            formaPagoSelected,
            addPago,
            formasDePago,
            plazosDePago,
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-hover table-striped overflow-hidden">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} scope="col">
                      {header.charAt(0).toUpperCase() + header.slice(1)}
                    </th>
                  ))}
                </tr>
                <tr></tr>
              </thead>
              <tbody>
                {itemsPagos != null && itemsPagos.length > 0 ? (
                  itemsPagos.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{row['formaPago'].label}</td>
                      <td>{row['monto'].toFixed(2)}</td>
                      <td>{row['documento']}</td>
                      <td>{row['plazo']?.label}</td>
                      <td>{row['periodo']}</td>
                      <td>{row['periodo']}</td>
                      <td>
                        <button
                          className="btn btn-link p-0"
                          type="button"
                          title="Delete"
                          onClick={() => {
                            deletePago({ correlativo: row['correlativo'] });
                          }}
                        >
                          <span className="text-500 fas fa-trash-alt"></span>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No hay pagos
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const renderFormaPagoForm = (
  formaDePago,
  addPago,
  formasDePago,
  plazosDePago,
) => {
  if (
    formaDePago === null ||
    typeof formaDePago === 'undefined' ||
    formaDePago.value === TipoPago.Contado
  ) {
    return (
      <CondicionContadoForm addPago={addPago} formasDePago={formasDePago} />
    );
  }
  if (formaDePago.value === TipoPago.Credito) {
    return (
      <CondicionCreditoForm plazosDePago={plazosDePago} addPago={addPago} />
    );
  }

  if (formaDePago.value === TipoPago.Otro) {
    return (
      <CondicionOtroForm
        addPago={addPago}
        formasDePago={formasDePago}
        plazosDePago={plazosDePago}
      />
    );
  }
  return <CondicionContadoForm addPago={addPago} formasDePago={formasDePago} />;
};
export { FormasDePago };
