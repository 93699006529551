/**
 * CommonTable - Component for a customizable table.
 * 
 * @param {Array<DataRow>} data - List of data items for the table rows.
 * 
 * @returns {JSX.Element}
 */
function CommonTable({ data }) {
  if (!data || data.length === 0) {
    return <p>No data available.</p>;
  }

  // Extract column headers from the keys of the first data item
  const headers = Object.keys(data[0]);

  return (
    <div className="table-responsive scrollbar">
      <table className="table table-hover table-striped overflow-hidden">
        <thead>
          <tr>
            {headers.map((header, index) => <th key={index} scope="col">{header.charAt(0).toUpperCase() + header.slice(1)}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, cellIndex) => <td key={cellIndex}>{row[header]}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export { CommonTable }