import React from 'react';
import AdminMenu from '../Menu/Admin/AdminMenu';
import { Link } from 'react-router-dom';
import DteMenu from '../Menu/Dte/DteMenu';
import { getSession } from '../../../services/encrytion/encryption.service';
import ContabilidadMenu from '../Menu/Contabilidad/ContabilidadMenu';

const SideBar = () => {
  const modulos = getSession('modulos');

  return (
    <nav className="navbar navbar-light navbar-vertical navbar-expand-xl navbar-inverted">
      <div className="d-flex align-items-center">
        <div className="toggle-icon-wrapper">
          <button
            className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Toggle Navigation"
          >
            <span className="navbar-toggle-icon">
              <span className="toggle-line" />
            </span>
          </button>
        </div>
        <a className="navbar-brand" href="/">
          <div className="d-flex align-items-center py-3">
            <img
              className="me-2"
              src="https://i.ibb.co/QF5Mjd8/004.png"
              alt="..."
              width={250}
            />
          </div>
        </a>
      </div>
      <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
        <div className="navbar-vertical-content scrollbar">
          <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            <li className="nav-item">
              <Link className="nav-link" to="/" role="button">
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <span className="fas fa-home" />
                  </span>
                  <span className="nav-link-text ps-1">Inicio</span>
                </div>
              </Link>
            </li>

            {modulos.includes('9e49e41e-dba9-428c-b46f-aced818c15a6') && (
              <DteMenu />
            )}

            {modulos.includes('5bbf40c0-323d-4561-a006-55a8745dc7b1') && (
              <ContabilidadMenu />
            )}
            <AdminMenu />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SideBar;
