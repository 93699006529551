import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ModalWrapper from '../../../../../../Components/Common/Modals/Modal';
import { getSession } from '../../../../../../services/encrytion/encryption.service';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { useForm, useFormContext } from 'react-hook-form';
import { getDteType, parseNit } from '../../../../../../utils/dte/dte.util';
import { getDatosDte } from '../../utils/FacturaV2';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocalDate } from '../../../../../../services/date/date';
import { loadingReload } from '../../../../../../services/alerts/alerts';
import { enqueueSnackbar } from 'notistack';
const ModalDocumentoElectronico = forwardRef((props, ref) => {
  const { watch, getValues, setValue } = useFormContext();

  const [dtes, setDtes] = useState([]);
  const validationSchema = Yup.object().shape({
    receptorRel: Yup.string().required('Campo requerido'),
    tipoDocumentoRel: Yup.string().required('Campo requerido'),
    fechaGeneracionDesdeRel: Yup.string().required('Campo requerido'),
    fechaGeneracionHastaRel: Yup.string().required('Campo requerido'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    setValue: setValueForm,
    getValues: getValuesForm,
    formState: { errors },
    handleSubmit,
  } = useForm(formOptions);

  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    async openModal() {
      limpiar();
      if (watch('numeroDocumentoReceptor')) {
        setValueForm('receptorRel', watch('numeroDocumentoReceptor'));
      }
      modalRef.current.toggle();
    },
  }));

  const getDtes = async () => {
    const cliente = getSession('clienteSeleccionado');
    const ambiente = getSession('ambiente');
    loadingReload(true, 'Consultando DTEs');
    const data = await getDatosDte({
      clienteId: cliente.id,
      ambiente,
      numeroDocumento: getValues('numeroDocumento'),
      tipoDocumento: getValuesForm('tipoDocumentoRel'),
      fechaGeneracionDesde: getValuesForm('fechaGeneracionDesdeRel'),
      fechaGeneracionHasta: getValuesForm('fechaGeneracionHastaRel'),
    });
    loadingReload(false);

    if (data?.results?.length === 0) {
      enqueueSnackbar(
        'No se encontraron DTEs con los criterios de búsqueda ingresados',
        {
          variant: 'warning',
          preventDuplicate: true,
        },
      );
    }
    setDtes(data?.results || []);
  };

  const seleccionarDte = (dte) => {
    setValue('documentoRelacionado', [
      ...getValues('documentoRelacionado'),
      {
        tipoDocumentoRel: getValuesForm('tipoDocumentoRel'),
        numeroDocumentoRel: dte?.datos_dte?.identificacion?.codigoGeneracion,
        fechaGeneracionRel: getLocalDate(dte?.created_at, 'YYYY-MM-DD'),
        tipoGeneracion: 'Documento electrónico',
      },
    ]);

    setValue('nombreClienteReceptor', dte?.datos_dte?.receptor?.nombre);
    setValue('nrcReceptor', dte?.datos_dte?.receptor?.nrc);
    setValue(
      'nombreComercialReceptor',
      dte?.datos_dte?.receptor?.nombreComercial,
    );
    setValue('actividadEconomicaReceptor', {
      value: dte?.datos_dte?.receptor?.codActividad,
      label: dte?.datos_dte?.receptor?.descActividad,
    });
    setValue('correoElectronicoReceptor', dte?.datos_dte?.receptor?.correo);
    setValue('telefonoReceptor', dte?.datos_dte?.receptor?.telefono);
    setValue('cuerpoDocumento', []);
    setValue('resumen', {});

    limpiar();
    modalRef.current.toggle();
  };

  const limpiar = () => {
    setValueForm('receptorRel', '');
    setValueForm('tipoDocumentoRel', '');
    setValueForm('fechaGeneracionDesdeRel', '');
    setValueForm('fechaGeneracionHastaRel', '');
    setDtes([]);
  };
  return (
    <div>
      <ModalWrapper
        title="Seleccione DTE a relacionar"
        ref={modalRef}
        size={'xl'}
        style={{ maxWidth: '75%' }}
      >
        <CardWrapperTools
          title="Criterios de consulta"
          footer={
            <div className="row">
              <div className="col-lg-12 text-center">
                <button
                  className="btn btn-outline-primary btn-lg"
                  onClick={handleSubmit(getDtes)}
                >
                  <span className="fas fa-search"></span> Consultar
                </button>
              </div>
            </div>
          }
        >
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label htmlFor="">Receptor:</label>
                <input
                  type="text"
                  className="form-control"
                  id="receptorRel"
                  disabled={true}
                  placeholder=""
                  {...register('receptorRel')}
                />
                {errors.receptorRel && (
                  <div className="invalid-feedback">
                    {errors.receptorRel?.message}
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <label htmlFor="">Tipo de documento:</label>

                <select
                  id="tipoDocumentoRel"
                  {...register('tipoDocumentoRel')}
                  defaultValue={''}
                  className="form-select"
                >
                  <option value="" disabled={true}>
                    Seleccione un opción
                  </option>
                  <option value="04">Nota de remisión</option>
                  <option value="09">Documento contable de liquidación</option>
                </select>

                {errors.tipoDocumentoRel && (
                  <div className="invalid-feedback">
                    {errors.tipoDocumentoRel?.message}
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <label htmlFor="">Fecha de generación desde:</label>
                <input
                  type="date"
                  className="form-control"
                  id="fechaGeneracionDesdeRel"
                  {...register('fechaGeneracionDesdeRel')}
                />
                {errors.fechaGeneracionDesdeRel && (
                  <div className="invalid-feedback">
                    {errors.fechaGeneracionDesdeRel?.message}
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <label htmlFor="">Fecha de generación hasta:</label>
                <input
                  type="date"
                  className="form-control"
                  id="fechaGeneracionHastaRel"
                  {...register('fechaGeneracionHastaRel')}
                />
                {errors.fechaGeneracionHastaRel && (
                  <div className="invalid-feedback">
                    {errors.fechaGeneracionHastaRel?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardWrapperTools>

        {dtes.length > 0 && (
          <CardWrapperTools title="DTE Emitidos">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive scrollbar">
                    <table className="table table-hover table-striped table-bordered border-3 overflow-hidden">
                      <thead className="table-primary">
                        <tr>
                          <th scope="col" className="text-center">
                            Documento del receptor
                          </th>
                          <th scope="col" className="text-center">
                            Nombre
                          </th>
                          <th scope="col" className="text-center">
                            Tipo de documento
                          </th>
                          <th scope="col" className="text-center">
                            Fecha de generación
                          </th>
                          <th scope="col" className="text-center">
                            Código de generación
                          </th>
                          <th scope="col" className="text-center">
                            Monto
                          </th>
                          <th scope="col" className="text-center">
                            Opciones
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dtes.map((dte, index) => {
                          return (
                            <tr className="align-middle" key={index}>
                              <td className="text-center">
                                {parseNit(dte?.datos_dte?.receptor?.nit)}
                              </td>
                              <td>{dte?.datos_dte?.receptor?.nombre}</td>
                              <td className="text-center">
                                {getDteType(
                                  dte?.datos_dte?.identificacion?.tipoDte,
                                )}
                              </td>
                              <td className="text-center">
                                {getLocalDate(dte?.created_at, 'DD/MM/YYYY')}
                              </td>
                              <td className="text-center">
                                {
                                  dte?.datos_dte?.identificacion
                                    ?.codigoGeneracion
                                }
                              </td>
                              <td className="text-center">
                                $
                                {parseFloat(
                                  dte?.datos_dte?.resumen?.totalPagar,
                                ).toFixed(2)}
                              </td>
                              <td className="text-center">
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  onClick={() => {
                                    seleccionarDte(dte);
                                  }}
                                >
                                  <span className="fas fa-check"></span>{' '}
                                  Seleccionar
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </CardWrapperTools>
        )}
      </ModalWrapper>
    </div>
  );
});
export default ModalDocumentoElectronico;
