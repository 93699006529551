import React from 'react';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import { useFormContext } from 'react-hook-form';

const VentaTercerosForm = () => {
  const { register } = useFormContext();
  return (
    <>
      <CardWrapperTools title="Datos venta por cuenta de terceros">
        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label htmlFor="">NIT:</label>
              <input
                className="form-control"
                id="nitVentaTerceros"
                name="nitVentaTerceros"
                type="text"
                placeholder="Digite el NIT"
                {...register('nitVentaTerceros')}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label htmlFor="">Nombres:</label>
              <input
                className="form-control"
                id="nombreVentaTerceros"
                name="nombreVentaTerceros"
                type="text"
                placeholder="Digite los nombres"
                {...register('nombreVentaTerceros')}
              />
            </div>
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default VentaTercerosForm;
