import React from 'react';
import { Link } from 'react-router-dom';

const CardForm = ({
  children,
  title = 'Title',
  col = 12,
  footer,
  urlForm,
  submit,
}) => {
  return (
    <div className="row g-0">
      <div className={`col-lg-${col} pe-lg-2`}></div>
      <div className="card mb-3">
        <div className="card-header">
          <div className="row justify-content-between align-items-center">
            <div className="col-md">
              <h5 className="mb-2 mb-md-0">{title}</h5>
            </div>
            <div className="col-auto">
              {urlForm && (
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <div className="icon-shape icon-sm bg-body-tertiary text-center rounded">
                        <Link to={urlForm} className="btn btn-primary btn-lg">
                          <i
                            className="fa fa-plus"
                            style={{ marginRight: '3px' }}
                          ></i>
                          Agregar Nuevo
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <form onSubmit={submit}>
          <div className="card-body bg-body-tertiary">{children}</div>
          <div className="card-footer">
            <div className="d-flex justify-content-center">{footer}</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { CardForm };
