import React, { useRef } from 'react';
import { CardWrapper } from '../../../Components/Common/Cards';
import { TableFilterV2 } from '../../../Components/Common/Tables/TableFilterV2';

import { useNavigate } from 'react-router-dom';
import {
  AlertQuestionYesNo,
  loadingReload,
} from '../../../services/alerts/alerts';
import httpAuth from '../../../services/http/http.auth.services';

export default function Usuarios() {
  const navigate = useNavigate();
  const tablaRef = useRef();

  const columns = [
    {
      selector: (row) => row.alias,
      name: 'Alias',
    },
    {
      selector: (row) => row.alias,
      name: 'Correo electrónico',
    },

    {
      selector: (row) => row.rol.nombre,
      name: 'Roles',
    },
    {
      name: 'Activo',
      selector: (row) =>
        row.activo ? (
          <span className="badge rounded-pill badge-subtle-primary">
            Activo
          </span>
        ) : (
          <span className="badge rounded-pill badge-subtle-warning">
            Inactivo
          </span>
        ),
    },
    {
      name: 'Acciones',
      selector: (row) => (
        <>
          <button
            className="btn btn-link p-0"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Edit"
            onClick={() => handleEdit(row?._id)}
          >
            <span className="text-500 fas fa-edit"></span>
          </button>
          <button
            className="btn btn-link p-0 ms-2"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Delete"
            onClick={() => handleDelete(row?._id, row.activo)}
          >
            <span className="text-500 fas fa-trash-alt"></span>
          </button>
          <button
            className="btn btn-link p-0 ms-2"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="clientes"
            onClick={() => handleAddClients(row?._id)}
          >
            <span className="text-500 far fa-address-card"></span>
          </button>
        </>
      ),
    },
  ];

  const handleEdit = (id) => navigate(`/admin/usuarios/update/${id}`);
  const handleAddClients = (id) => navigate(`/admin/usuarios/clientes/${id}`);

  async function handleDelete(id, activo) {
    const textoActivar = !activo ? 'activar' : 'desactivar';
    const confirm = await AlertQuestionYesNo({
      title: `¿Está seguro que desea ${textoActivar} este usuario?`,
    });
    if (confirm) {
      loadingReload(true);

      const response = {
        id: id,
        activo: !activo,
      };
      await httpAuth.patch('usuarios/status', response);
      tablaRef.current.clear();

      loadingReload(false);
    }
  }

  return (
    <>
      <CardWrapper title="Listado de usuarios" urlForm="/admin/usuarios/create">
        <TableFilterV2 columns={columns} endpoint={'usuarios'} />
      </CardWrapper>
    </>
  );
}
