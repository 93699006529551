import React from 'react';
import { getAnexoComprasSujetoExcluidoRequest } from '../../utils/anexos';
import { Alert, loadingReload } from '../../../../services/alerts/alerts';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';

const AnexosCompras = ({ clienteId, month, year, ambiente }) => {
  const getAnexoComprasSujetoExcluido = async ({
    clienteId,
    month,
    year,
    ambiente,
  }) => {
    try {
      loadingReload(true, 'Descargando anexo de documentos anulados');
      const data = await getAnexoComprasSujetoExcluidoRequest({
        clienteId,
        month,
        year,
        ambiente,
      });

      const response = await fetch(data?.url?.key);
      const fileBlob = await response.blob();
      saveAs(
        fileBlob,
        `compras-sujetos-excluidos-${clienteId}-${year}-${month}-${ambiente}`,
        {
          autoBom: true,
        },
      );

      loadingReload(false);
    } catch (error) {
      if (error.response?.status === 409) {
        loadingReload(false);
        Alert({
          title: 'Atención',
          text: error.response.data.message,
          icon: 'info',
        });
        return;
      }
      loadingReload(false);
      enqueueSnackbar(
        'Se ha producido un error al momento de generar la descarga',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  };
  return (
    <div className="row g-3">
      <div className="col-12">
        <div className="col-auto ms-auto">
          <div className="table-responsive">
            <table className="table table-striped border-bottom fs-1">
              <thead data-bs-theme="light">
                <tr className="bg-primary text-white dark__bg-100">
                  <th className="border-0 text-center">Anexo</th>
                  <th className="border-0 text-center">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>COMPRAS A SUJETOS EXCLUIDOS (CASILLA 66)</td>
                  <td className="text-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        getAnexoComprasSujetoExcluido({
                          clienteId,
                          month,
                          year,
                          ambiente,
                        });
                      }}
                    >
                      Descargar
                    </button>
                  </td>
                </tr>
                {/*
                <tr>
                  <td>DETALLE DE COMPRAS</td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    VENTAS GRAVADAS POR CUENTA DE TERCEROS DOMICILIADOS (CASILLA
                    108)
                  </td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>

                <tr>
                  <td>COMPRAS A SUJETOS EXCLUIDOS (CASILLA 66)</td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    ANTICIPO A CUENTA IVA DEL 2% EFECTUADA AL DECLARANTE
                    (CASILLA 161)
                  </td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    RETENCION DE IVA DEL 1% EFECTUADA AL DECLARANTE (CASILLA
                    162)
                  </td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    PERCEPCION DE IVA 1% EFECTUADA AL DECLARANTE (CASILLA 163)
                  </td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    PERCEPCION DE IVA 1% EFECTUADA POR EL DECLARANTE (169)
                  </td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    RETENCION DE 1% IVA A TERCEROS DOMICILIADOS EFECTUADA POR EL
                    DECLARANTE (CASILLA 170)
                  </td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>

                <tr>
                  <td>
                    ANTICIPO A CUENTA DEL 2% EFECTUADA POR EL DECLARANTE
                    (CASILLA 171)
                  </td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    RETENCION 13% IVA A TERCEROS DOMICILIADOS EFECTUADA POR EL
                    DECLARANTE (CASILLA 172)
                  </td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr>
                <tr>
                  <td>DOCUMENTOS ANULADOS Y/O EXTRAVIADOS</td>
                  <td className="text-center">
                    <button>Descargar</button>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnexosCompras;
