const ERROR_TYPE_MESSAGE = [11000];
const AMBIENTE_DTE = {
  PRODUCCION: '01',
  PRUEBAS: '00',
};

const TIPO_DOCUMENTOS = {
  FACTURA: '01',
  CREDITO_FISCAL: '03',
};

module.exports = {
  ERROR_TYPE_MESSAGE,
  AMBIENTE_DTE,
  TIPO_DOCUMENTOS,
};
