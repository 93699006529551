import { useState } from 'react';

/**
 * TextInputComponent - Component for a validated text input.
 *
 * @param {string} colSize - Size of the column (in Bootstrap grid).
 * @param {string} id - Unique identifier for the input element.
 * @param {string} name - Name attribute for the input element.
 * @param {string} labelText - Label to be displayed above the input.
 * @param {string} feedback - Feedback message for validation errors.
 * @param {function} onChange - Event handler for input changes.
 * @param {function} validator - Function to validate input content.
 * @param {boolean} disabled - Flag to disable the input.
 * @param {boolean} required - Flag to mark the input as required.
 *
 * @returns {JSX.Element}
 */
function TextInputComponent({
    colSize,
    id,
    name,
    labelText,
    feedback,
    placeholder,
    onChange,
    validator,
    disabled = false,
    required = false
}) {
    const [isValid, setIsValid] = useState(true);

    const handleChange = (e) => {
        const value = e.target.value;
        const validationStatus = validator ? validator(value) : true;
        setIsValid(validationStatus);
        
        if(onChange) {
            onChange(e);
        }
    };

    return (
        <div className={`col-${colSize}`}>
            <label htmlFor={id}>{labelText}</label>
            <input
                type="text"
                className={`form-control ${isValid ? '' : 'is-invalid'}`}
                id={id}
                name={name}
                placeholder={placeholder}
                onChange={handleChange}
                disabled={disabled}
                required={required}
            />
            {!isValid && <div className="invalid-feedback">
                {feedback}
            </div>}
        </div>
    );
}

/**
 * InputGroupComponent - Component for an input group with validation and prepended text.
 *
 * @param {string} colSize - Size of the column (in Bootstrap grid).
 * @param {string} id - Unique identifier for the input element.
 * @param {string} name - Name attribute for the input element.
 * @param {string} labelText - Label to be displayed above the input.
 * @param {string} feedback - Feedback message for validation errors.
 *
 * @returns {JSX.Element}
 */
function InputGroupComponent({
    colSize,
    id,
    name = "",
    labelText,
    feedback
}) {
    return (
        <div className={`col-md-${colSize}`}>
            <label className="form-label" htmlFor={id}>{labelText}</label>
            <div className="input-group has-validation">
                <span className="input-group-text" id="inputGroupPrepend">@</span>
                <input name={name} className="form-control" id={id} type="text" aria-describedby="inputGroupPrepend" required />
                <div className="invalid-feedback">{feedback}</div>
            </div>
        </div>
    );
}


/**
 * SelectComponent - Component for a validated dropdown select input.
 *
 * @param {string} colSize - Size of the column (in Bootstrap grid).
 * @param {string} id - Unique identifier for the select element.
 * @param {string} name - Name attribute for the select element.
 * @param {string} labelText - Label to be displayed above the select input.
 * @param {Array<{value: string, label: string}>} options - Array of options for the dropdown. Each option is an object with a `value` and a `label`.
 * @param {string} feedback - Feedback message for validation errors.
 * @param {function} onChange - Event handler for select input changes.
 * @param {boolean} disabled - Flag to disable the select input.
 *
 * @returns {JSX.Element}
 */
function SelectComponent({
    colSize,
    id,
    name = "",
    labelText,
    options,
    feedback,
    onChange,
    disabled = false
}) {
    return (
        <div className={`col-md-${colSize}`}>
            <label className="form-label" htmlFor={id}>{labelText}</label>
            <select className="form-select" name={name} disabled={disabled} id={id} required onChange={onChange}>
                {options.map(option => <option value={option.value} key={option.value}>{option.label}</option>)}
            </select>
            <div className="invalid-feedback">{feedback}</div>
        </div>
    );
}

// CheckboxComponent.js
function CheckboxComponent() {
    return (
        <div className="col-12">
            <div className="form-check">
                <input className="form-check-input" id="invalidCheck" type="checkbox" required />
                <label className="form-check-label mb-0" htmlFor="invalidCheck">Agree to terms and conditions</label>
                <div className="invalid-feedback mt-0">You must agree before submitting.</div>
            </div>
        </div>
    );
}

/**
 * SubmitButtonComponent represents a button that can be used to submit forms or trigger any other event.
 * 
 * @component
 * @param {Object} props - Props for the component.
 * @param {('button'|'submit'|'reset')} props.type - Specifies the type of button. Default is 'button'.
 * @param {Function} [props.onClick=() => {}] - Click event handler for the button.
 * @param {string} props.text - Text to display on the button.
 * 
 * @example
 * // For a submit button
 * <SubmitButtonComponent type="submit" text="Submit" onClick={handleSubmit} />
 * 
 * @returns {React.ElementType}
 */
function ButtonComponent({type="button", onClick=() => {}, ...props}) {
    return (
        <div className="col-12">
            <button className="btn btn-primary" onClick={onClick} type={type}>{props.text}</button>
        </div>
    );
}


export { TextInputComponent, InputGroupComponent, SelectComponent, CheckboxComponent, ButtonComponent }