import React from 'react';
import { dteNecesitaReemplazo } from '../../utils/consulta.util';

const Warning = () => {
  return (
    <div className="alert alert-warning" role="alert">
      <p>
        <strong>Advertencia:</strong> Para el tipo de invalidación seleccionado
        deberá generar y transmitir con anticipación el nuevo DTE con el que
        corregirá la operación, para posteriormente invalidar el DTE incorrecto,
        si ya realizó la transmición ingrese el código de generación en el campo{' '}
        <strong>Codigo Generación que reemplaza al documento con error</strong>.
      </p>
    </div>
  );
};

const WarningAnulacion = ({ tipoDte, tipoInvalidacion }) => {
  if (!tipoDte || !tipoInvalidacion) {
    return null;
  }

  if (
    (tipoInvalidacion === 1 || tipoInvalidacion === 3) &&
    dteNecesitaReemplazo.includes(tipoDte)
  ) {
    return <Warning />;
  }
};

export default WarningAnulacion;
