/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import {
  Alert,
  alertEmpty,
  loadingReload,
} from '../../../services/alerts/alerts';
import http from '../../../services/http/http.auth.services';

export default function ClientesUsuarios() {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      loadUserById(id);
      loadClientByUser();
    }
    getClientes();
  }, []);

  const [clientuser, setClientUser] = useState([]);
  const [sucursales, setSucursales] = useState([]);

  const validationSchema = Yup.object().shape({
    usuarioId: Yup.string().required('El usuario es requerido'),
    clienteId: Yup.object({
      value: Yup.string().required('El ID del cliente es requerido'),
      label: Yup.string().required('El nombre del cliente es requerido'),
    }).required('Cliente es requerido'),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const loadUserById = async (userId) => {
    const { data } = await http.get(`/usuarios/${userId}`);
    setValue('usuarioId', data.alias);

    return data;
  };
  const loadClientByUser = async () => {
    const { data } = await http.get(`/clientes/users?userId=${id}`);
    setClientUser(data);
    return data;
  };

  const loadSucursales = async (clienteId) => {
    const { data } = await http.get(`/sucursales/${clienteId}`, {
      params: {
        limit: 50,
      },
    });
    const results = data?.results?.map((result) => ({
      label: `${result?.tipo_establecimiento?.value} - ${result?.departamento?.value} - ${result?.municipio?.value} - ${result?.direccion}`,
      value: result?._id,
      data: result,
    }));

    setSucursales(results);
    return data;
  };
  let timeOutClientes = null;
  const loadclientes = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timeOutClientes);
      timeOutClientes = setTimeout(() => {
        resolve(getClientes(inputValue));
      }, 300);
    });

  const getClientes = async (inputValue) => {
    try {
      const { data } = await http.get(`clientes?search=${inputValue}`);
      const options = data.results.map((item) => ({
        value: item._id,
        label: item.nombre,
      }));
      return options;
    } catch (error) {
      alertEmpty(
        '!Error al guardar el usuario!',
        'Este usuatuio ya existe',
        'info',
      );
    }
  };
  const onSubmit = async (datos) => {
    try {
      loadingReload(true, 'Asignando cliente');

      const payload = {
        usuarioId: id,
        clienteId: datos.clienteId.value,
        tipo: datos.tipoAsignacion?.value,
        sucursal: datos?.sucursal?.data,
      };
      const { data } = await http.post('usuarios/asignar-cliente', payload);
      if (data) {
        loadingReload(false);
        loadClientByUser();

        setValue('clienteId', null);
        setValue('tipoAsignacion', null);
        setValue('sucursal', null);
        Alert({
          title: 'Asignando cliente',
          icon: 'success',
          text: 'Cliente asignado exitosamente',
        });
        return data;
      }
    } catch (error) {
      alertEmpty(
        '!Error al asingar cliente!',
        error.response.message ?? 'Este cliente ya esta asignado',
        'error',
      );
    }
  };
  const desvincular = async (usuarioId, clienteId) => {
    try {
      loadingReload(true, 'Eliminando cliente');

      const { data } = await http.delete(
        `usuarios/desasignar-cliente?usuarioId=${usuarioId}&clienteId=${clienteId}`,
      );
      if (data) {
        loadingReload(false, null);
        loadClientByUser();
        Alert({
          title: 'Eliminando cliente',
          icon: 'success',
          text: 'Cliente eliminado exitosamente',
        });
        return data;
      }
    } catch (error) {
      alertEmpty(
        '!Error al asingar cliente!',
        error.response.data.message ?? 'Este cliente ya esta asignado',
        'error',
      );
    }
  };
  return (
    <>
      <CardWrapperTools
        title="Asignación de clientes"
        footer={
          <div className="row">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-secondary me-2"
                onClick={() => {
                  navigate('/admin/usuarios');
                }}
              >
                <span
                  className="fas fa-arrow-left"
                  style={{ marginRight: '5px' }}
                ></span>
                Salir
              </button>
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={() => handleSubmit(onSubmit)()}
              >
                <span className="far fa-paper-plane"></span> Guardar
              </button>
            </div>
          </div>
        }
      >
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label" htmlFor="usuarioId">
              Alias:
            </label>
            <input
              className="form-control"
              id="usuarioId"
              {...register('usuarioId')}
              placeholder="Alias"
              readOnly={true}
              style={{
                backgroundColor: '#e9ecef',
                color: '#6c757d',
                cursor: 'not-allowed',
              }}
            />
            {errors.usuarioId && (
              <div className="invalid-feedback">{errors.usuarioId.message}</div>
            )}
          </div>
          <div className="col-md-4">
            <label className="form-label" htmlFor="clientes">
              Clientes:
            </label>
            <Controller
              name="clienteId"
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  onChange={async (e) => {
                    setValue('clienteId', undefined);
                    setSucursales([]);
                    if (e) {
                      setValue('clienteId', e);
                      await loadSucursales(e?.value);
                    }
                  }}
                  loadOptions={loadclientes}
                  defaultOptions
                  placeholder="Seleccione una opción"
                  className="basic-multi-select"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: errors.clienteId
                        ? 'red'
                        : provided.borderColor,
                    }),
                  }}
                />
              )}
            />
            {errors.clienteId && (
              <div className="invalid-feedback">{errors.clienteId.message}</div>
            )}
          </div>

          <div className="col-md-4">
            <label className="form-label" htmlFor="clientes">
              Tipo de Asignación:
            </label>
            <Controller
              name="tipoAsignacion"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    {
                      value: 1,
                      label: 'Facturador',
                    },
                    {
                      value: 2,
                      label: 'Administración',
                    },
                    {
                      value: 3,
                      label: 'Facturador y Administración',
                    },
                  ]}
                  defaultOptions
                  placeholder="Seleccione una opción"
                  className="basic-multi-select"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: errors.clienteId
                        ? 'red'
                        : provided.borderColor,
                    }),
                  }}
                />
              )}
            />
          </div>

          <div className="col-md-4">
            <label className="form-label" htmlFor="clientes">
              Sucursal por defecto:
            </label>
            <Controller
              name="sucursal"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={sucursales}
                  defaultOptions
                  placeholder="Seleccione una opción"
                  className="basic-multi-select"
                  isDisabled={sucursales.length === 0}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: errors.clienteId
                        ? 'red'
                        : provided.borderColor,
                    }),
                  }}
                />
              )}
            />
          </div>
        </div>
      </CardWrapperTools>

      <CardWrapperTools title="Clientes asignados">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="table-responsive scrollbar">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Correo</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Nombre comercial</th>
                    <th scope="col">Tipo</th>
                    <th scope="col" className="text-center">
                      Opciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clientuser.map((item) => (
                    <tr key={item.id}>
                      <td>{item.correo}</td>
                      <td>{item.nombre}</td>
                      <td>{item.nombre_comercial}</td>
                      <td>
                        {item.tipo === 1
                          ? 'Facturador'
                          : item.tipo === 2
                          ? 'Administración'
                          : 'Facturador y Administración'}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => desvincular(id, item.id)}
                        >
                          <span className="fas fa-trash-alt"></span> Desvincular
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
}
