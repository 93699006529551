import React from 'react';
import {
  getPlazos,
  getTipoPago,
  getUnidadMedida,
} from '../../../../../utils/dte/dte.util';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';

const TablaTotalesSujetoExcluido = ({ data }) => {
  return (
    <>
      <CardWrapperTools title="Detalle de pagos">
        <div className="row">
          <div className="col-12">
            <div className="col-sm-auto ms-auto">
              <div className="table-responsive">
                <table className="table table-striped border-bottom fs-1">
                  <thead data-bs-theme="light">
                    <tr className="bg-primary text-white dark__bg-1000">
                      <th className="border-0 text-center">Tipo pago</th>
                      <th className="border-0 text-center">Monto</th>
                      <th className="border-0 text-center">Plazo</th>
                      <th className="border-0 text-center">Periodo</th>
                      <th className="border-0 text-center">N° Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.datos_dte?.resumen?.pagos?.map((item) => (
                      <tr>
                        <td className="align-middle text-center">
                          {getTipoPago(item.codigo)}
                        </td>
                        <td className="align-middle text-center">
                          ${parseFloat(item.montoPago).toFixed(2)}
                        </td>
                        <td className="align-middle text-center">
                          {item.plazo ? getPlazos(item.plazo) : 'N/A'}
                        </td>
                        <td className="align-middle text-center">
                          {item.periodo ? item.periodo : 'N/A'}
                        </td>

                        <td className="align-middle text-center">
                          {item.referencia ? item.referencia : 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CardWrapperTools>
      <CardWrapperTools title="Detalle de documento">
        <div className="row">
          <div className="col-12">
            <div className="col-sm-auto ms-auto">
              <div className="table-responsive">
                <table className="table table-striped border-bottom fs-1">
                  <thead data-bs-theme="light">
                    <tr className="bg-primary text-white dark__bg-1000">
                      <th className="border-0">N°</th>
                      <th className="border-0 text-center">Cantidad</th>
                      <th className="border-0 text-center">U/M</th>
                      <th className="border-0">Descripción</th>
                      <th className="border-0 text-center">P. Unitario</th>
                      <th className="border-0 text-center">Dcto. item</th>
                      <th className="border-0 text-center">Ventas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.datos_dte?.cuerpoDocumento?.map((item) => (
                      <tr>
                        <td className="align-middle text-center">
                          {item.numItem}
                        </td>
                        <td className="align-middle text-center">
                          {item.cantidad}
                        </td>
                        <td className="align-middle text-center">
                          {getUnidadMedida(item.uniMedida)}
                        </td>

                        <td className="align-middle">
                          <h6 className="mb-0 text-nowrap">
                            {item.descripcion}
                          </h6>
                        </td>
                        <td className="align-middle text-center">
                          ${parseFloat(item.precioUni).toFixed(2)}
                        </td>

                        <td className="align-middle text-center">
                          ${parseFloat(item.montoDescu).toFixed(2)}
                        </td>

                        <td className="align-middle text-center">
                          ${parseFloat(item.compra).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row justify-content-end">
              <div className="col-auto">
                <table className="table table-sm table-borderless fs-1 text-end">
                  <tbody>
                    <tr>
                      <th className="text-1100 bold">Sumatoria de ventas:</th>
                      <td className="fw-semi-bold">
                        $
                        {parseFloat(
                          data?.datos_dte?.resumen?.totalCompra,
                        ).toFixed(2)}
                      </td>
                    </tr>

                    <tr className="border-top">
                      <th className="text-900">Sub-Total:</th>
                      <td className="fw-semi-bold">
                        $
                        {parseFloat(data?.datos_dte?.resumen?.subTotal).toFixed(
                          2,
                        )}
                      </td>
                    </tr>

                    <tr className="border-top">
                      <th className="text-900">Rentención de Renta:</th>
                      <td className="fw-semi-bold">
                        $
                        {parseFloat(
                          data?.datos_dte?.resumen?.reteRenta,
                        ).toFixed(2)}
                      </td>
                    </tr>

                    <tr className="border-top">
                      <th className="text-900">Total a pagar:</th>
                      <td className="fw-semi-bold">
                        $
                        {parseFloat(
                          data?.datos_dte?.resumen?.totalPagar,
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default TablaTotalesSujetoExcluido;
