import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ModalWrapper from '../../../../Components/Common/Modals/Modal';
import Iframe from 'react-iframe';
import { get } from '../../../../services/http/http.service';
import { loadingReload } from '../../../../services/alerts/alerts';
import CardWrapperTools from '../../../../Components/Common/Cards/CardWrapperTools';
import { saveAs } from 'file-saver';
import * as slug from 'slug';
const ModalReporte = forwardRef((props, ref) => {
  const [tipoDocumento, setTipoDocumento] = React.useState('');
  const [codigoGeneracion, setCodigoGeneracion] = React.useState('');
  const [comprobante, setComprobante] = React.useState(null);
  const modalRef = useRef(null);
  useImperativeHandle(ref, () => ({
    async generarReporte(clienteId, tipoDocumento, codigoGeneracion) {
      setTipoDocumento(tipoDocumento);
      setCodigoGeneracion(codigoGeneracion);
      loadingReload(true, 'Generando reporte');
      const { data } = await get(
        `dte/reporte/${codigoGeneracion}/cliente-id/${clienteId}`,
      );
      setComprobante(data?.file || null);
      loadingReload(false);
      modalRef.current.toggle();
    },
  }));

  const handlePrint = (base64String) => {
    base64String = base64String.split('data:application/pdf;base64,')[1];
    const data = atob(base64String);
    const arrayBuffer = new ArrayBuffer(data.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < data.length; i++) {
      uint8Array[i] = data.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.onload = () => {
      // Imprimir el PDF después de que el iframe se haya cargado
      iframe.contentWindow.print();
    };
  };

  const handleDownload = (base64String) => {
    base64String = base64String.split('data:application/pdf;base64,')[1];
    const data = atob(base64String);
    const arrayBuffer = new ArrayBuffer(data.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < data.length; i++) {
      uint8Array[i] = data.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    saveAs(blob, `${slug(tipoDocumento)}-${codigoGeneracion}.pdf`);
  };
  return (
    <div>
      <ModalWrapper
        title={`Documento legible de ${tipoDocumento} con código de generación: ${codigoGeneracion}`}
        ref={modalRef}
      >
        <CardWrapperTools
          title="Panel de generación de documento legible"
          footer={
            <div className="row">
              <div className="col-lg-12 text-center">
                <button
                  className="btn btn-primary ms-2 btn-lg"
                  onClick={() => {
                    handleDownload(comprobante);
                  }}
                >
                  <span className="fas fa-download" />
                  <span className="ms-2 d-none d-lg-inline">Descargar</span>
                </button>

                <button
                  className="btn btn-primary ms-2 btn-lg"
                  onClick={() => {
                    handlePrint(comprobante);
                  }}
                >
                  <span className="fas fa-print" />
                  <span className="ms-2 d-none d-lg-inline">Imprimir</span>
                </button>
              </div>
            </div>
          }
        >
          <div className="row">
            <div className="col-lg-12">
              <Iframe
                id="comprobante"
                src={`${comprobante}#toolbar=0&navpanes=0&statusbar=0`}
                width="100%"
                height="550px"
                display="initial"
                position="relative"
              />
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </div>
  );
});
export default ModalReporte;
