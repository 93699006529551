import React, { useState } from 'react';
import YearSelect from './Components/SelectYear';
import SelectMonth from './Components/SelectMonth';
import AsyncSelect from 'react-select/async';
import { get } from '../../services/http/http.service';
import { Alert } from '../../services/alerts/alerts';
import { getSession } from '../../services/encrytion/encryption.service';
import CollapseCard from '../../Components/Common/Cards/CollapseCard';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Dtes from './consulta/Dtes';
import JsonDownload from './Components/JsonDownload';
import PdfDownload from './Components/PdfDownload';
import AnexosVentas from './Components/Anexos/AnexosVentas';
import AnexosAnulados from './Components/Anexos/AnexosAnulados';
import AnexosCompras from './Components/Anexos/AnexosCompras';
import DetalleLibrosIva from './Components/LibrosIva/DetalleLibrosIva';

// Configurar dayjs con los plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const GestionClientes = () => {
  const [cliente, setCliente] = useState(null);
  const [ambiente, setAmbiente] = useState(
    process.env.REACT_APP_NODE_ENV === 'production' ? '01' : '00',
  );
  const [selectedYear, setSelectedYear] = useState(
    dayjs().tz('America/El_Salvador').year(),
  );
  const [selectedMonth, setSelectedMonth] = useState(
    dayjs().tz('America/El_Salvador').month() + 1,
  );

  const handleYearChange = (year) => {
    setSelectedYear(parseInt(year.target.value));
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(parseInt(month.target.value));
  };

  const loadClientes = async (inputValue) => {
    try {
      const userId = getSession('user');
      const { data } = await get(
        `/contabilidad/clientes/${userId}?search=${inputValue}`,
      );
      const options = data.map((item) => ({
        value: item.cliente_id,
        label: item.label,
        data: item.cliente_detalles,
      }));
      return options;
    } catch (error) {
      Alert('error', 'Error', 'No se pudo cargar los datos del cliente');
    }
  };
  return (
    <>
      <CollapseCard title="Datos del cliente">
        <div className="row g-3 p-3">
          <div className="col-4">
            <label htmlFor="">Cliente:</label>
            <AsyncSelect
              cacheOptions
              loadOptions={loadClientes}
              defaultOptions
              placeholder="Seleccione un cliente"
              onChange={(e) => {
                setCliente(e);
              }}
            />
          </div>

          {cliente && (
            <div className="col-4">
              <label htmlFor="">Nombre Comercial:</label>
              <input
                type="text"
                className="form-control"
                value={cliente?.data?.nombre_comercial}
                disabled
              />
            </div>
          )}
          {cliente && (
            <div className="col-2">
              <label htmlFor="">NIT:</label>
              <input
                type="text"
                className="form-control"
                value={cliente?.data?.nit}
                disabled
              />
            </div>
          )}

          {cliente && (
            <div className="col-2">
              <label htmlFor="">NRC:</label>
              <input
                type="text"
                className="form-control"
                value={cliente?.data?.nrc}
                disabled
              />
            </div>
          )}
        </div>
      </CollapseCard>
      {cliente && (
        <CollapseCard title="Detalles contables">
          <div className="row g-3 p-3">
            <div className="col-4">
              <label htmlFor="">Mes:</label>
              <SelectMonth
                selectedMonth={selectedMonth}
                onMonthChange={handleMonthChange}
              />
            </div>
            <div className="col-4 ">
              <label htmlFor="">Año:</label>
              <YearSelect
                selectedYear={selectedYear}
                onYearChange={handleYearChange}
              />
            </div>

            <div className="col-4">
              <label>Ambiente:</label>
              <select
                className="form-select"
                aria-label="Ambientes"
                onChange={(e) => setAmbiente(e.target.value)}
                value={ambiente}
                disabled={process.env.REACT_APP_NODE_ENV === 'production'}
              >
                <option key={'01'} value="01">
                  Producción
                </option>
                <option key={'00'} value="00">
                  Pruebas
                </option>
              </select>
            </div>
          </div>
          <div className="row p-3">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  href="#dteGenerados"
                  role="tab"
                  aria-controls="dteGenerados"
                  aria-selected="true"
                >
                  DTE Generados
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  href="#descargas"
                  role="tab"
                  aria-controls="descargas"
                  aria-selected="false"
                >
                  Descarga de documentos Contables
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  href="#anexos"
                  role="tab"
                  aria-controls="anexos"
                  aria-selected="false"
                >
                  Plantillas Fiscales
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  href="#librosIva"
                  role="tab"
                  aria-controls="librosIva"
                  aria-selected="false"
                >
                  Libros de IVA
                </a>
              </li>
            </ul>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="dteGenerados"
                role="tabpanel"
                aria-labelledby="dteGenerados"
              >
                <Dtes
                  cliente={cliente?.value}
                  year={selectedYear}
                  month={selectedMonth}
                  ambiente={ambiente}
                />
              </div>
              <div
                className="tab-pane fade"
                id="descargas"
                role="tabpanel"
                aria-labelledby="descargas"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <JsonDownload
                      cliente={cliente?.value}
                      year={selectedYear}
                      month={selectedMonth}
                      ambiente={ambiente}
                    />
                    <PdfDownload
                      cliente={cliente?.value}
                      year={selectedYear}
                      month={selectedMonth}
                      ambiente={ambiente}
                    />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="anexos"
                role="tabpanel"
                aria-labelledby="anexos"
              >
                <div className="row p-3">
                  <ul className="nav nav-tabs" id="myTabAnexos" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        href="#anexosVentas"
                        role="tab"
                        aria-controls="anexosVentas"
                        aria-selected="true"
                      >
                        Ventas
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="home-tab"
                        data-bs-toggle="tab"
                        href="#anexosCompras"
                        role="tab"
                        aria-controls="anexosCompras"
                        aria-selected="true"
                      >
                        Compras
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        href="#anexoAnulaciones"
                        role="tab"
                        aria-controls="anexoAnulaciones"
                        aria-selected="false"
                      >
                        Ventas Anuladas o Extraviadas
                      </a>
                    </li>{' '}
                    {/*
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#anexos"
                        role="tab"
                        aria-controls="anexos"
                        aria-selected="false"
                      >
                        Anexos Contables
                      </a>
                    </li> */}
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="anexosVentas"
                      role="tabpanel"
                      aria-labelledby="anexosVentas"
                    >
                      <AnexosVentas
                        clienteId={cliente?.value}
                        year={selectedYear}
                        month={selectedMonth}
                        ambiente={ambiente}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="anexosCompras"
                      role="tabpanel"
                      aria-labelledby="anexosCompras"
                    >
                      <AnexosCompras
                        clienteId={cliente?.value}
                        year={selectedYear}
                        month={selectedMonth}
                        ambiente={ambiente}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="anexoAnulaciones"
                      role="tabpanel"
                      aria-labelledby="anexoAnulaciones"
                    >
                      <AnexosAnulados
                        clienteId={cliente?.value}
                        year={selectedYear}
                        month={selectedMonth}
                        ambiente={ambiente}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="anexosVentas"
                      role="tabpanel"
                      aria-labelledby="anexosAnexos"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="librosIva"
                role="tabpanel"
                aria-labelledby="librosIva"
              >
                <DetalleLibrosIva
                  clienteId={cliente?.value}
                  clienteNombre={cliente?.label}
                  year={selectedYear}
                  month={selectedMonth}
                  ambiente={ambiente}
                />
              </div>
            </div>
          </div>
        </CollapseCard>
      )}
    </>
  );
};

export default GestionClientes;
