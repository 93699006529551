import dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/es-us';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.locale('es-us');

export const getLocalDate = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  return date ? dayjs(date).tz('America/El_Salvador').format(format) : null;
};

export const getDateDte = () => {
  return dayjs().tz('America/El_Salvador').format('YYYY-MM-DD');
};

export const getTimeDte = () => {
  return dayjs().tz('America/El_Salvador').format('HH:mm:ss');
};

export const getDateLocaleFormated = ({
  date,
  formatIn = 'YYYY-MM-DD HH:mm:ss',
  formatOut = 'DD/MM/YYYY HH:mm:ss',
}) => {
  return date
    ? dayjs(date, formatIn).tz('America/El_Salvador').format(formatOut)
    : null;
};
