import React, { useCallback, useEffect, useState } from 'react';
import { CardWrapper } from '../../../../../Components/Common/Cards';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import {
  getActividadesEconomicasEmisor,
  getDataEmisor,
  getSucursalesEmisor,
} from '../utils/NotaCredito';
import { enqueueSnackbar } from 'notistack';
import { getSession } from '../../../../../services/encrytion/encryption.service';

const EmisorForm = () => {
  const {
    setValue,
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const [actividadesEconomicas, setActividadesEconomicas] = useState([]);
  const [establecimientosEmisor, setEstablecimientosEmisor] = useState([]);

  const getDataSelect = useCallback(async () => {
    const cliente = getSession('clienteSeleccionado');
    try {
      const sucursal = getSession('sucursal');
      const sucursalDefecto = sucursal
        ? {
            label:
              sucursal.codigo_punto_venta_mh +
              ' - ' +
              sucursal.tipo_establecimiento.value +
              ' - ' +
              sucursal.direccion,
            value: sucursal._id,
            default: true,
          }
        : null;
      const [dataActividadesEconomicas, dataEmisor, dataSucursales] =
        await Promise.all([
          getActividadesEconomicasEmisor(cliente.id),
          getDataEmisor(cliente.id),
          getSucursalesEmisor(cliente.id, sucursalDefecto),
        ]);

      setActividadesEconomicas(dataActividadesEconomicas.data);
      setEstablecimientosEmisor(dataSucursales.data);
      setValue('establecimientoEmisor', dataSucursales.default);
      setValue('actividadEconomicaEmisor', dataActividadesEconomicas.default);
      setValue('correoElectronicoEmisor', dataEmisor.correo);
      setValue('telefonoEmisor', dataEmisor.telefono);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los datos generales de emisor!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, [setValue]);
  useEffect(() => {
    async function getData() {
      await getDataSelect();
    }

    getData();
  }, [getDataSelect]);
  return (
    <>
      <CardWrapper title="Emisor">
        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="actividadEconomicaEmisor">
                Actividad Económica:
              </label>
              <Controller
                name="actividadEconomicaEmisor"
                control={control}
                render={({ field }) => (
                  <Select options={actividadesEconomicas} {...field} />
                )}
              />
              {errors.actividadEconomicaEmisor && (
                <div className="invalid-feedback">
                  {errors.actividadEconomicaEmisor?.message}
                </div>
              )}
            </div>

            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="establecimientoEmisor">
                Establecimiento:
              </label>
              <Controller
                name="establecimientoEmisor"
                control={control}
                render={({ field }) => (
                  <Select options={establecimientosEmisor} {...field} />
                )}
              />
              {errors.establecimientoEmisor && (
                <div className="invalid-feedback">
                  {errors.establecimientoEmisor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="correo">
                Correo electrónico:
              </label>
              <input
                className="form-control"
                id="correoElectronicoEmisor"
                name="correoElectronicoEmisor"
                type="email"
                placeholder="mail@example.com"
                {...register('correoElectronicoEmisor')}
                disabled
              />
              {errors.correoElectronicoEmisor && (
                <div className="invalid-feedback">
                  {errors.correoElectronicoEmisor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="telefono">
                Teléfono:
              </label>
              <input
                className="form-control"
                id="telefonoEmisor"
                name="telefonoEmisor"
                type="tel"
                placeholder="22222121"
                {...register('telefonoEmisor')}
                disabled
              />
              {errors.telefonoEmisor && (
                <div className="invalid-feedback">
                  {errors.telefonoEmisor?.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardWrapper>
    </>
  );
};

export default EmisorForm;
