import React, { useCallback, useEffect, useState } from 'react';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { getTributosAfeccionIva, tiposVenta } from '../../utils/CreditoFiscal';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const tributoDefault = {
  value: '20',
  label: 'Impuesto al Valor Agregado 13%',
  clear: false,
  mode: 'porcentaje',
  amount: [0.13],
  type: [1],
  measurements: [],
  calculate: false,
};
const TabOtrasContribuciones = () => {
  const validationSchema = Yup.object().shape({
    tributoTasa: Yup.object().required(
      'Impuestos/Tasas con afección al IVA es requerido',
    ),
    descripcion: Yup.string().required('La descripción es requerida'),

    monto: Yup.number()
      .typeError('El precio debe ser un número')
      .required('El precio es requerido')
      .min(0.01, 'El precio debe ser mayor a 0.00'),
    descuento: Yup.number().typeError('El descuento debe ser un número'),
    tipo: Yup.object().required('El tipo es requerido'),
    documentosRelacionados: Yup.array(),
    documentoRelacionado: Yup.object()
      .optional()
      .when('documentosRelacionados', (documentosRelacionados, schema) => {
        const [documentosRelacionadosValue] = documentosRelacionados;
        return documentosRelacionadosValue.length > 0
          ? schema.required('documento relacionado es requerido')
          : schema.nullable();
      }),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tipo: {
        value: 1,
        label: 'Gravada',
      },
      unidadMedida: {
        value: 99,
        label: 'otra',
      },
      tipoVenta: {
        value: 4,
        label: 'Otros tributos por ítem',
      },
      descripcion: '',
      monto: '0.00',

      tributoTasa: null,
      tributo: [tributoDefault],
    },
  };

  const { getValues, setValue, watch, formState } = useFormContext();

  const {
    register,
    control,
    setValue: setValueForm,
    handleSubmit,
    watch: watchForm,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const [tributos, setTributos] = useState([]);
  const [tributosCalculados, setTributosCalculados] = useState([]);

  const getDataSelect = useCallback(async () => {
    const [tributosData] = await Promise.all([getTributosAfeccionIva()]);

    setTributos(tributosData);
    try {
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los datos generales de panel de producto!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, []);

  useEffect(() => {
    async function getData() {
      await getDataSelect();
    }

    getData();
  }, [getDataSelect]);

  const agregarOsumarTributos = (tributos, nuevoTributo) => {
    // Buscar el índice del tributo con el mismo código
    var index = tributos.findIndex((t) => t.codigo === nuevoTributo.id);
    // Si existe, sumar el valor; de lo contrario, agregar un nuevo tributo
    if (index !== -1) {
      tributos[index].valor += nuevoTributo.valor;
      return tributos;
    } else {
      tributos.push({
        codigo: nuevoTributo.id,
        descripcion: nuevoTributo.nombre,
        valor: nuevoTributo.valor,
        type: nuevoTributo.type,
        mode: nuevoTributo.mode,
      });
      return tributos;
    }
  };

  const calcularTributos = (tributo) => {
    if (tributo.mode === 'porcentaje') {
      return {
        id: tributo.value,
        nombre: tributo.label,
        mode: tributo.mode,
        type: tributo.type,
        valor: Number(watchForm('monto')) * tributo.amount[0],
      };
    }
  };
  const handleCalcularTributos = () => {
    const tributosCalculadosData = watchForm('tributo')?.map((item) => {
      return calcularTributos(item);
    });

    setTributosCalculados(tributosCalculadosData);
  };

  const onSubmit = () => {
    const itemCuerpoDocumento = {
      numItem: null,
      tipoItem: 4,
      numeroDocumento: watchForm('documentoRelacionado')?.value,
      cantidad: 1,
      codigo: new Date().getTime(),
      codTributo: watchForm('tributoTasa')?.value,
      uniMedida: 99,
      descripcion: watchForm('descripcion'),
      precioUni: watchForm('monto'),
      montoDescu: 0,
      ventaNoSuj: watchForm('tipo')?.value === 3 ? watchForm('monto') : 0,
      ventaExenta: watchForm('tipo')?.value === 2 ? watchForm('monto') : 0,
      ventaGravada: watchForm('tipo')?.value === 1 ? watchForm('monto') : 0,
      tributos:
        tributosCalculados.length > 0
          ? tributosCalculados.map((item) => item.id)
          : null,
      tributosData: tributosCalculados,
      itemResumenDocumento: null,
      noGravado: 0,
    };

    const resumenDocumento = getValues('resumen') || {};
    let tributosResumen = resumenDocumento?.tributos || [];

    for (let index = 0; index < tributosCalculados.length; index++) {
      agregarOsumarTributos(tributosResumen, tributosCalculados[index]);
    }
    const ivaRete1 =
      getValues('documentoRelacionado')?.tipoDocumentoRel !== '07'
        ? 0
        : watchForm('tipo')?.value === 1
        ? Number(watchForm('monto')) * 0.13
        : 0;
    const itemResumenDocumento = {
      tributos: tributosResumen?.length > 0 ? tributosResumen : null,
      subTotal: Number(watchForm('monto')),
      ivaRete1,
      ivaPerci1: 0,
      reteRenta: 0,
      descuNoSuj: 0,
      totalDescu: 0,
      totalNoSuj:
        watchForm('tipo')?.value === 3 ? Number(watchForm('monto')) : 0,
      descuExenta: 0,
      totalExenta:
        watchForm('tipo')?.value === 2 ? Number(watchForm('monto')) : 0,

      descuGravada: 0,
      totalGravada:
        watchForm('tipo')?.value === 1 ? Number(watchForm('monto')) : 0,
      subTotalVentas: Number(watchForm('monto')),
      totalNoGravado: 0,
      montoTotalOperacion:
        Number(watchForm('monto')) +
        tributosCalculados.reduce((acc, item) => acc + item.valor, 0),
      totalPagar:
        Number(watchForm('monto')) +
        tributosCalculados.reduce((acc, item) => acc + item.valor, 0),
    };

    const cuerpoDocumento = getValues('cuerpoDocumento') || [];
    itemCuerpoDocumento.itemResumenDocumento = itemResumenDocumento;

    setValue('cuerpoDocumento', [...cuerpoDocumento, itemCuerpoDocumento]);

    setValue('resumen', {
      tributos: tributosResumen,
      subTotal:
        (resumenDocumento?.subTotal ?? 0) + itemResumenDocumento.subTotal,
      ivaRete1:
        (resumenDocumento?.ivaRete1 ?? 0) + itemResumenDocumento.ivaRete1,
      ivaPerci1:
        (resumenDocumento?.ivaPerci1 ?? 0) + itemResumenDocumento.ivaPerci1,
      reteRenta:
        (resumenDocumento?.reteRenta ?? 0) + itemResumenDocumento.reteRenta,
      descuNoSuj:
        (resumenDocumento?.descuNoSuj ?? 0) + itemResumenDocumento.descuNoSuj,
      totalDescu:
        (resumenDocumento?.totalDescu ?? 0) + itemResumenDocumento.totalDescu,
      totalNoSuj:
        (resumenDocumento?.totalNoSuj ?? 0) + itemResumenDocumento.totalNoSuj,
      descuExenta:
        (resumenDocumento?.descuExenta ?? 0) + itemResumenDocumento.descuExenta,
      totalExenta:
        (resumenDocumento?.totalExenta ?? 0) + itemResumenDocumento.totalExenta,
      totalLetras:
        (resumenDocumento?.totalLetras ?? 0) + itemResumenDocumento.totalLetras,
      descuGravada:
        (resumenDocumento?.descuGravada ?? 0) +
        itemResumenDocumento.descuGravada,
      totalGravada:
        (resumenDocumento?.totalGravada ?? 0) +
        itemResumenDocumento.totalGravada,
      subTotalVentas:
        (resumenDocumento?.subTotalVentas ?? 0) +
        itemResumenDocumento.subTotalVentas,
      condicionOperacion: null,
      montoTotalOperacion:
        (resumenDocumento?.montoTotalOperacion ?? 0) +
        itemResumenDocumento.montoTotalOperacion,
      totalNoGravado: resumenDocumento?.totalNoGravado ?? 0,
      totalPagar:
        (resumenDocumento?.totalPagar ?? 0) + itemResumenDocumento.totalPagar,
    });

    if (getValues('documentoRelacionado')?.tipoDocumentoRel !== '07') {
      setValue('ivaRetenido', ivaRete1);
    }

    reset();
  };

  useEffect(() => {
    setValueForm('documentosRelacionados', watch('documentoRelacionado'));

    if (
      Array.isArray(watch('documentoRelacionado')) &&
      watch('documentoRelacionado').length > 0
    ) {
      setValueForm('documentoRelacionado', {
        label: watch('documentoRelacionado')[0].numeroDocumentoRel,
        value: watch('documentoRelacionado')[0].numeroDocumentoRel,
      });
    }
  }, [formState, setValueForm, watch]);

  return (
    <>
      <CardWrapperTools
        title="Adición detalle de DTE de impuestos/tasa con afección al IVA"
        footer={
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-lg"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              <span className="fas fa-plus me-1"></span>
              Agregar
            </button>
          </div>
        }
      >
        <div className="container-fluid">
          <div className="row g-3 mb-3">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="tributoTasa">
                Impuestos/Tasas con afección al IVA:
              </label>
              <Controller
                name="tributoTasa"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    placeholder="Seleccione un tributo"
                    options={tributos}
                    {...field}
                  />
                )}
              />
              {errors.tributoTasa && (
                <div className="invalid-feedback">
                  {errors.tributoTasa?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="descripcion">
                Descripción :
              </label>
              <input
                className="form-control"
                name="descripcion"
                id="descripcion"
                type="text"
                placeholder="Digite la descripción"
                {...register('descripcion')}
                onFocus={(e) => e.target.select()}
              />
              {errors.descripcion && (
                <div className="invalid-feedback">
                  {errors.descripcion?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="monto">
                Monto:
              </label>

              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  name="monto"
                  id="monto"
                  type="number"
                  {...register('monto')}
                  onWheel={(e) => e.target.blur()}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setValueForm('monto', e.target.value);
                    handleCalcularTributos();
                  }}
                  onBlur={(e) => {
                    setValueForm(
                      'monto',
                      parseFloat(e.target.value).toFixed(2),
                    );
                    if (e.target.value === '') {
                      setValueForm('monto', parseFloat(0).toFixed(2));
                    }
                  }}
                  placeholder="Digite el monto"
                />
              </div>
              {errors.monto && (
                <div className="invalid-feedback">{errors.monto?.message}</div>
              )}
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="tipo">
                Tipo:
              </label>
              <Controller
                name="tipo"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    options={tiposVenta}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);

                      if (event.value === 1) {
                        setValueForm('tributo', [tributoDefault]);
                        handleCalcularTributos();
                        return;
                      }

                      setValueForm('tributo', []);
                      handleCalcularTributos();
                    }}
                  />
                )}
              />
              {errors.tipo && (
                <div className="invalid-feedback">{errors.tipo?.message}</div>
              )}
            </div>
            {watch('documentoRelacionado') &&
            watch('documentoRelacionado').length > 0 ? (
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label className="form-label" htmlFor="documentoRelacionado">
                  Documento Relacionado:
                </label>
                <Controller
                  name="documentoRelacionado"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      options={watch('documentoRelacionado').map((item) => ({
                        value: item.numeroDocumentoRel,
                        label: item.numeroDocumentoRel,
                      }))}
                      placeholder="Seleccione el documento relacionado"
                      {...field}
                    />
                  )}
                />
                {errors.documentoRelacionado && (
                  <div className="invalid-feedback">
                    {errors.documentoRelacionado?.message}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default TabOtrasContribuciones;
