import { useState } from 'react';

import { isDUI, isNIT } from 'sivar-utils';

// Services
import { createContribuyente } from '../../../services/contribuyentes';

// Components
import {
	TextInputComponent, 
	SelectComponent, 
	ButtonComponent
} from '../../../Components/Common/Forms'
import {
    CardWrapper
} from '../../../Components/Common/Cards'

const  CreateContribuyente = () => {
    // States
    const [isDocumentValid, setIsDocumentValid] = useState(false);
    const [contribuyente, setContribuyente] = useState({
        tipoIdentificacion: '',
        numeroDocumento: '',
        nombreReceptor: '',
        departamento: '',
        municipio: '',
        correoElectronico: '',
        telefono: ''
    });

    // Handlers
    const handleSubmit = (event) => {
        event.preventDefault();
        if(!isDocumentValid) {
            alert('El documento no es valido');
            return;
        }
        alert(JSON.stringify(contribuyente));
        createContribuyente(contribuyente)
    }

    const handleContribuyenteChange = (event) => {
        setContribuyente({
            ...contribuyente,
            [event.target.name]: event.target.value
        });
    };

    const documentValidator = (value) => {
        if(contribuyente.tipoIdentificacion === 'DUI') {
            return isDUI(value);
        }
        if(contribuyente.tipoIdentificacion === 'NIT') {
            return isNIT(value);
        }
        return true;
    }

    // Wrappers
    const documentValidatorWrapper = (value) => {
        const isValid = documentValidator(value);
        setIsDocumentValid(isValid);
        return isValid;
    };



    return (
        <CardWrapper title="Crear Contribuyente">
            <form className="row g-3 needs-validation" onSubmit={handleSubmit}>
                <SelectComponent
                    colSize="6"
                    id="tipoIdentificacion"
                    name="tipoIdentificacion"
                    labelText="Tipo de documento de identificación"
                    options={[
                        { value: "", label: "Seleccione..." },
                        { value: "DUI", label: "DUI" },
                        { value: "NIT", label: "NIT" }
                    ]}
                    feedback="Por favor, seleccione un tipo de documento."
                    onChange={handleContribuyenteChange}
                />
                <TextInputComponent
                    colSize="6"
                    id="numeroDocumento"
                    name="numeroDocumento"
                    labelText="Documento de identificación"
                    feedback={`Ingrese un número de ${contribuyente.tipoIdentificacion} valido.`}
                    placeholder={`Digite el número de ${contribuyente.tipoIdentificacion} del receptor`} 
                    onChange={handleContribuyenteChange}
                    validator={documentValidatorWrapper}
                    disabled={contribuyente.tipoIdentificacion === ''}
                    required={true}
                />
                <TextInputComponent
                    colSize="12"
                    id="nombreReceptor"
                    name="nombreReceptor"
                    labelText="Nombre, denominación o razón social del contribuyente"
                    feedback="Ingrese el nombre completo del receptor."
                    placeholder="Nombre completo del receptor"
                    onChange={handleContribuyenteChange}
                    required={true}
                />
                <SelectComponent
                    colSize="6"
                    id="departamento"
                    name="departamento"
                    labelText="Departamento"
                    options={[
                        { value: "", label: "Seleccione..." },
                        { value: "San Salvador", label: "San Salvador" },
                        { value: "Santa Ana", label: "Santa Ana" },
                        { value: "San Miguel", label: "San Miguel" },
                        { value: "La Libertad", label: "La Libertad" },
                        { value: "Sonsonate", label: "Sonsonate" }
                    ]}
                    feedback="Por favor, seleccione un departamento."
                    onChange={handleContribuyenteChange}
                />
                <SelectComponent
                    colSize="6"
                    id="municipio"
                    name="municipio"
                    labelText="Municipio"
                    disabled={contribuyente.departamento === ''}
                    options={[
                        { value: "", label: "Seleccione..." },
                        { value: "Municipio1", label: "Municipio1" },
                        { value: "Municipio2", label: "Municipio2" },
                        { value: "Municipio3", label: "Municipio3" },
                        { value: "Municipio4", label: "Municipio4" },
                        { value: "Municipio5", label: "Municipio5" }
                    ]}
                    feedback="Por favor, seleccione un municipio."
                    onChange={handleContribuyenteChange}
                />
                <TextInputComponent
                    colSize="6"
                    id="correoElectronico"
                    name="correoElectronico"
                    labelText="Correo Electrónico"
                    feedback="Ingrese un correo electrónico válido."
                    placeholder="ejemplo@dominio.com"
                    onChange={handleContribuyenteChange}
                    required={true}
                />
                <TextInputComponent
                    colSize="6"
                    id="telefono"
                    name="telefono"
                    labelText="Teléfono"
                    feedback="Ingrese un número de teléfono válido."
                    placeholder="Teléfono del receptor"
                    onChange={handleContribuyenteChange}
                    required={true}
                />
                <ButtonComponent text="Crear Contribuyente" type='submit'/>
            </form>
        </CardWrapper>
    );
}

export default CreateContribuyente;
