import React, { useRef } from 'react';
import { CardWrapper } from '../../Components/Common/Cards';
import { TableFilterV2Services } from '../../Components/Common/Tables/TableFilterV2.service';
import { getSession } from '../../services/encrytion/encryption.service';
import { patch } from '../../services/http/http.service';
import {
  AlertQuestionYesNo,
  loadingReload,
} from '../../services/alerts/alerts';
import { useNavigate } from 'react-router-dom';

export default function Productos() {
  const navigate = useNavigate();
  const tablaRef = useRef();
  const clienteSeleccionado = getSession('clienteSeleccionado');

  const columns = [
    {
      name: 'Código',
      selector: (row) => row.codigo_producto || 'Sin código',
    },
    {
      name: 'Nombre',
      selector: (row) => row.nombre_producto,
      width: '300px',
    },
    {
      name: 'Precio',
      selector: (row) => '$' + parseFloat(row.precio).toFixed(2),
    },
    {
      name: 'Precio con IVA',
      selector: (row) => '$' + parseFloat(row.precio_iva).toFixed(2),
    },
    {
      name: 'Unidad de medida',
      selector: (row) => row.unidad_medida?.value,
    },
    {
      name: 'Tipo',
      selector: (row) => {
        return (
          <span className="badge rounded-pill badge-subtle-primary">
            {row.tipo?.value}
          </span>
        );
      },
    },
    {
      name: 'Activo',
      selector: (row) =>
        row.activo ? (
          <span className="badge rounded-pill badge-subtle-primary">
            Activo
          </span>
        ) : (
          <span className="badge rounded-pill badge-subtle-warning">
            Inactivo
          </span>
        ),
    },
    {
      name: 'Acciones',
      selector: (row) => (
        <>
          <button
            className="btn btn-link p-0"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Edit"
            onClick={() => handleEdit(row?._id)}
          >
            <span className="text-500 fas fa-edit"></span>
          </button>
          <button
            className="btn btn-link p-0 ms-2"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Delete"
            onClick={() => handleDelete(row?._id, row.activo)}
          >
            <span className="text-500 fas fa-trash-alt"></span>
          </button>
        </>
      ),
    },
  ];
  const handleEdit = (id) => navigate(`/productos/update/${id}`);

  async function handleDelete(id, activo) {
    const textoActivar = !activo ? 'activar' : 'desactivar';
    const confirm = await AlertQuestionYesNo({
      title: `¿Está seguro que desea ${textoActivar} este usuario?`,
    });
    if (confirm) {
      loadingReload(true);

      const response = {
        id: id,
        activo: !activo,
      };
      await patch('productos/status', response);
      loadingReload(false);
      tablaRef.current.refreshData();
    }
  }
  return (
    <CardWrapper title="Productos" urlForm="/productos/create">
      <TableFilterV2Services
        columns={columns}
        endpoint={`productos?params=${clienteSeleccionado.id}`}
        ref={tablaRef}
      />
    </CardWrapper>
  );
}
