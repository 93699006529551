import React from 'react';
import TabProductosServicios from './Tabs/TabProductosServicios';
import TabOtrasContribuciones from './Tabs/TabOtrasContribuciones';

const ItemsDteForm = () => {
  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            href="#productoServicio"
            role="tab"
            aria-controls="productoServicio"
            aria-selected="true"
          >
            Producto o servicio
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            href="#otrasContribuciones"
            role="tab"
            aria-controls="otrasContribuciones"
            aria-selected="false"
          >
            Otras contrituciones
          </a>
        </li>
      </ul>

      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="productoServicio"
          role="tabpanel"
          aria-labelledby="productoServicio"
        >
          <TabProductosServicios />
        </div>
        <div
          className="tab-pane fade"
          id="otrasContribuciones"
          role="tabpanel"
          aria-labelledby="otrasContribuciones"
        >
          <TabOtrasContribuciones />
        </div>
      </div>
    </>
  );
};

export default ItemsDteForm;
