import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ModalWrapper from '../../../../../Components/Common/Modals/Modal';
import { ModalBody } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Alert, loadingReload } from '../../../../../services/alerts/alerts';
import { patch } from '../../../../../services/http/http.service';

const ModaConfiguracionContable = forwardRef((props, ref) => {
  const modalRef = useRef(null);

  const validationSchema = Yup.object().shape({
    tipoOperacion: Yup.string().required('Este campo es requerido'),
    clasificacion: Yup.string().required('Este campo es requerido'),
    sector: Yup.string().required('Este campo es requerido'),
    tipoCostoGasto: Yup.string().required('Este campo es requerido'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tipoOperacion: '',
      clasificacion: '',
      sector: '',
      tipoCostoGasto: '',
    },
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm(formOptions);
  useImperativeHandle(ref, () => ({
    async openModal() {
      modalRef.current.toggle();

      if (props?.dte?.informacion_contable) {
        setValue(
          'tipoOperacion',
          props?.dte?.informacion_contable?.tipoOperacion,
        );
        setValue(
          'clasificacion',
          props?.dte?.informacion_contable?.clasificacion,
        );
        setValue('sector', props?.dte?.informacion_contable?.sector);
        setValue(
          'tipoCostoGasto',
          props?.dte?.informacion_contable?.tipoCostoGasto,
        );
      } else {
        reset();
      }
    },
  }));

  const onSubmit = async () => {
    try {
      loadingReload(true, 'Actualizando configuración contable');

      const dataConfiguracionContable = {
        informacion_contable: {
          tipoOperacion: getValues('tipoOperacion'),
          clasificacion: getValues('clasificacion'),
          sector: getValues('sector'),
          tipoCostoGasto: getValues('tipoCostoGasto'),
        },
      };
      const { status } = await patch(
        `dte/actualizar/${props?.dte?._id}`,
        dataConfiguracionContable,
      );
      loadingReload(false);

      if (status === 200) {
        await props?.getDteByClienteId();
        modalRef.current.toggle();
        Alert({
          title: 'Éxito',
          text: 'Configuración contable actualizada',
          icon: 'success',
        });
      }
    } catch (error) {
      loadingReload(false);

      Alert({
        title: 'Error',
        text: 'No se pudo actualizar la configuración contable',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <ModalWrapper
        title={`Configuración de tipo de compra o gasto con sujeto excluido`}
        ref={modalRef}
        size={'xl'}
        style={{ maxWidth: '75%' }}
        footer={
          <div className="align-content-end">
            <button
              className="btn btn-primary me-2"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
            <button
              type="button"
              onClick={() => modalRef.current.toggle()}
              className="btn  btn-secondary"
            >
              Cerrar
            </button>
          </div>
        }
      >
        <ModalBody>
          <div className="row g-3">
            <div className="col-xl-6">
              <label className="form-label">Tipo de Operación: </label>
              {/** select tipo de operacion */}

              <select
                id="tipoOperacion"
                {...register('tipoOperacion')}
                defaultValue={''}
                className={`form-control ${
                  errors.tipoOperacion ? 'is-invalid' : ''
                }`}
              >
                <option value="" disabled={true}>
                  Seleccione un opción
                </option>
                <option value="1">Gravada</option>
                <option value="2">No Gravada</option>
                <option value="3">Excluido o no constituye Renta</option>
                <option value="4">Mixta</option>
              </select>
              {errors.tipoOperacion && (
                <div className="invalid-feedback">
                  {errors.tipoOperacion.message}
                </div>
              )}
            </div>
            <div className="col-xl-6">
              <label className="form-label">Clasificación: </label>
              {/** select tipo de operacion */}

              <select
                id="clasificacion"
                {...register('clasificacion')}
                defaultValue={''}
                className={`form-control ${
                  errors.clasificacion ? 'is-invalid' : ''
                }`}
              >
                <option value="" disabled={true}>
                  Seleccione un opción
                </option>
                <option value="1">Costo</option>
                <option value="2">Gasto</option>
              </select>
              {errors.clasificacion && (
                <div className="invalid-feedback">
                  {errors.clasificacion.message}
                </div>
              )}
            </div>

            <div className="col-xl-6">
              <label className="form-label">Sector: </label>
              {/** select tipo de operacion */}

              <select
                id="sector"
                {...register('sector')}
                defaultValue={''}
                className={`form-control ${errors.sector ? 'is-invalid' : ''}`}
              >
                <option value="" disabled={true}>
                  Seleccione un opción
                </option>
                <option value="1">Industria</option>
                <option value="2">Comercio</option>
                <option value="3">Agropecuaria</option>
                <option value="4">
                  Servicios, Profesiones, Artes y Oficios
                </option>
              </select>
              {errors.sector && (
                <div className="invalid-feedback">{errors.sector.message}</div>
              )}
            </div>

            <div className="col-xl-6">
              <label className="form-label">Tipo costo/gasto: </label>
              {/** select tipo de operacion */}

              <select
                id="tipoCostoGasto"
                {...register('tipoCostoGasto')}
                defaultValue={''}
                className={`form-control ${
                  errors.tipoCostoGasto ? 'is-invalid' : ''
                }`}
              >
                <option value="" disabled={true}>
                  Seleccione un opción
                </option>
                <option value="1">Gasto de venta sin donación</option>
                <option value="2">Gasto de administración sin donación</option>
                <option value="3">Gastos financieros sin donación</option>
                <option value="4">
                  Costos Árticulos producidos/comprados
                  importaciones/internacionales
                </option>
                <option value="5">
                  Costos Árticulos producido/comprados interno
                </option>
                <option value="6">Costos indirectos de fabricación</option>
                <option value="7">Mano de obra</option>
              </select>
              {errors.tipoCostoGasto && (
                <div className="invalid-feedback">
                  {errors.tipoCostoGasto.message}
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </ModalWrapper>
    </>
  );
});

export default ModaConfiguracionContable;
