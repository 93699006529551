import React, { useState, useEffect } from 'react';
import { TablaDetalle } from './Components/TablaDetalle.jsx';
import { ReceptorDetailForm } from './Components/ReceptorDetailForm.jsx';
import { EmisorDetalleForm } from './Components/EmisorDetalleform.jsx';
import { CardWrapper } from '../../../../Components/Common/Cards/index.jsx';
import { get, post } from '../../../../services/http/http.service.js';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  ItemOrigin,
  calcularIvaEnTotal,
  ifNotNumberReturnsZero,
  redondearA2Decimales,
  tiposVenta,
  TipoVenta,
} from '../../../../utils/Utils.js';
import { getSession } from '../../../../services/encrytion/encryption.service.js';
import { numeroALetras } from '../../../../utils/NumeroALetras.ts';
import Select from 'react-select';
import { FormasDePago } from './Components/FormasDePago.jsx';
import { LoaderComponent } from 'react-fullscreen-loader';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import httpAuth from '../../../../services/http/http.auth.services.js';

const OTRA_UNIDAD_MEDIDA = 99;
const OTRA_TIPO_ITEM = '4';
const AMBOS_TIPO_ITEM = '3';

const ConsumidorFinal = () => {
  const navigate = useNavigate();

  const cliente = getSession('clienteSeleccionado');
  const ambiente = getSession('ambiente');
  const debug = getSession('devModeDebug') | false;

  if (!ambiente) {
    navigate('/dte');
  }

  // Estado para guardar el valor del actividad Economica seleccionada
  const [actividadEconomica, setActividadesEconomica] = useState([]);
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [establecimientosEmisor, setEstablecimientosEmisor] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [departamentos, setDepartamentosList] = useState([]);
  const [tipoItems, setTipoItemsList] = useState([]);
  const [unidadesDeMedidas, setUnidadesDeMedidasList] = useState([]);
  const [impuestos, setImpuestosList] = useState([]);
  const [clienteInformation, setClienteInformation] = useState([]);
  const [condicionesOperacion, setCondicionesOperacion] = useState([]);
  const [formasDePago, setFormasDePago] = useState([]);
  const [plazosDePago, setPlazosDePago] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tributosLibres, setTributosLibres] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    try {
      setLoading(true);
      const establecimientosEmisor = await httpAuth.get(
        'sucursales/' + cliente.id,
      );
      const actividades = await get(
        'clientes/actividad-economica/' + cliente.id,
      );
      const tiposDocumentos = await get(
        'generales/tipos-documentos-identificacion-receptor',
      );
      const clienteInfo = await get('clientes/' + cliente.id);

      //
      const listaDepartamentos = await get('generales/departamentos');
      const listaTipoItems = await get('generales/tipos-item');
      const listaUnidadesMedidas = await get('generales/unidades-medida');
      const listaImpuestos = await get('generales/tributos');
      const listaCondicionesOperacion = await get(
        'generales/condiciones-operacion',
      );
      const listaFormasPago = await get('generales/formas-pago');
      const listaPlazosDePago = await get('generales/plazos');

      const actividadesProcesadas = actividades.data.map((item) => {
        return {
          value: item.code, // Usamos el code como value
          label: item.code + ' - ' + item.value, // Usamos el value como label
          default: item.default,
        };
      });

      const tiposDocumentosProcesados = tiposDocumentos.data.map((item) => {
        return {
          value: item.code, // Usamos el code como value
          label: item.code + ' - ' + item.value, // Usamos el value como label
        };
      });

      const establecimientosEmisorProcesados =
        establecimientosEmisor.data.results.map((item) => {
          return {
            value: item._id, // Usamos el code como value
            label:
              item.codigo_punto_venta_mh +
              ' - ' +
              item.tipo_establecimiento.value +
              ' - ' +
              item.direccion,
            default: item.default,
          };
        });

      const listaDepartamentosProcesados = listaDepartamentos.data.map(
        (item) => {
          return {
            code: item.code,
            value: item.id, // Usamos el code como value
            label: item.code + ' - ' + item.value, // Usamos el value como label
          };
        },
      );

      const listaTipoItemsProcesados = listaTipoItems.data.map((item) => {
        return {
          value: item.code, // Usamos el code como value
          label: item.code + ' - ' + item.value, // Usamos el value como label
        };
      });
      const listaUnidadesMedidasProcesados = listaUnidadesMedidas.data.map(
        (item) => {
          return {
            value: item.code, // Usamos el code como value
            label: item.code + ' - ' + item.value, // Usamos el value como label
          };
        },
      );

      const listaImpuestosProcesados = listaImpuestos.data.map((item) => {
        return {
          value: item.code, // Usamos el code como value
          label: item.code + ' - ' + item.value, // Usamos el value como label
          type: item.type,
          mode: item.mode,
          measurements: item.measurements,
          amount: item.amount,
          calculate: item.calculate,
        };
      });

      const listaCondicionesOperacionProcesadas =
        listaCondicionesOperacion.data.map((item) => {
          return {
            value: item.code, // Usamos el code como value
            label: item.code + ' - ' + item.value, // Usamos el value como label
          };
        });

      const listaFormasPagoProcesadas = listaFormasPago.data.map((item) => {
        return {
          value: item.code, // Usamos el code como value
          label: item.code + ' - ' + item.value, // Usamos el value como label
        };
      });

      const listaPlazosDePagoProcesadas = listaPlazosDePago.data.map((item) => {
        return {
          value: item.code, // Usamos el code como value
          label: item.code + ' - ' + item.value, // Usamos el value como label
        };
      });
      // Actualizamos el estado de actividad Economicas con los datos de la respuesta
      setActividadesEconomica(actividadesProcesadas);
      setTiposDocumentos(tiposDocumentosProcesados);
      setDepartamentosList(listaDepartamentosProcesados);
      setEstablecimientosEmisor(establecimientosEmisorProcesados);
      setTipoItemsList(listaTipoItemsProcesados);
      setUnidadesDeMedidasList(listaUnidadesMedidasProcesados);
      setImpuestosList(listaImpuestosProcesados);
      setClienteInformation(clienteInfo.data);
      setCondicionesOperacion(listaCondicionesOperacionProcesadas);
      setFormasDePago(listaFormasPagoProcesadas);
      setPlazosDePago(listaPlazosDePagoProcesadas);

      setValue(
        'tipoDocumentoReceptor',
        tiposDocumentosProcesados.find((item) => item.value === '13'),
      );
      setValue(
        'condicionDeOperacion',
        listaCondicionesOperacionProcesadas.find((item) => item.value === '1'),
      );

      const defaultActividadEconomica = actividadesProcesadas.find(
        (item) => item.default === true,
      );

      setValue('actividadEconomicaEmisor', defaultActividadEconomica);
      setValue('correoElectronicoEmisor', clienteInfo.data.correo);
      setValue('telefonoEmisor', clienteInfo.data.telefono);
    } catch (error) {
      // Manejamos el error si ocurre
      enqueueSnackbar('Error cargando formulario para factura', {
        variant: 'error',
        preventDuplicate: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Usamos el hook useEffect para llamar a la función obteneractividad Economicas cuando se monta el componente
  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    recalculateResumen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Detalle Items
   */
  const [detalleItems, setDetalleItemsList] = useState([]);

  /**
   *
   * Items de pagos
   */
  const [pagosItems, setPagosItems] = useState([]);

  const onAddPago = (data) => {
    const correlativo = pagosItems.length + 1;
    const newPago = {
      correlativo: correlativo,
      formaPago: data.formaPago,
      monto: data.monto,
      documento: data.documento,
      plazo: data.plazo,
      periodo: data.periodo,
    };
    const newPagos = [...pagosItems, newPago];
    //suma de los montos de los pagos
    const montoTotalPagos = newPagos.reduce((a, b) => a + b.monto, 0);
    setValue('montoTotalPagos', montoTotalPagos);
    setPagosItems(newPagos);
  };

  const deletePago = (pago) => {
    const newPagos = pagosItems.filter(
      (x) => x.correlativo !== pago.correlativo,
    );

    const newPagosWithCorrelativeFixed = newPagos.map((x, index) => {
      return {
        ...x,
        correlativo: index + 1,
      };
    });

    //suma de los montos de los pagos
    const montoTotalPagos = newPagosWithCorrelativeFixed.reduce(
      (a, b) => a + b.monto,
      0,
    );
    setValue('montoTotalPagos', montoTotalPagos);
    setPagosItems(newPagosWithCorrelativeFixed);
  };

  const onUpdateRetencionRenta = (value) => {
    recalculateResumen(undefined, undefined, value);
  };

  const onUpdateRetencionIva = (value) => {
    recalculateResumen(undefined, undefined, undefined, value);
  };

  // Obtener municipios al cambiar departamento

  const onChangeDepartamento = (value) => {
    obtenerMunicipios(value.value);
  };
  const obtenerMunicipios = async (value) => {
    try {
      // Hacemos la petición con axios y guardamos la respuesta
      const respuesta = await get(
        `generales/municipios/?departamentoId=${value}`,
      );
      const listaMunicipiosProcesados = respuesta.data.map((item) => {
        return {
          value: item.code, // Usamos el code como value
          label: item.code + ' - ' + item.value, // Usamos el value como label
        };
      });
      // Actualizamos el estado con el array de opciones de la respuesta
      setMunicipios(listaMunicipiosProcesados);
    } catch (error) {
      // Manejamos el error si ocurre
    }
  };
  /**
   * Otros datos necesarios para construir items
   *
   */
  const otraUnidadMedida = unidadesDeMedidas.find(
    (x) => x.value === OTRA_UNIDAD_MEDIDA,
  );

  const otroTipoItem = tipoItems.find((x) => x.value === OTRA_TIPO_ITEM);
  const ambosTipoItem = tipoItems.find((x) => x.value === AMBOS_TIPO_ITEM);
  /**
   * Funcion que agrega un nuevo item a la lista de items
   * @param item
   */
  const addNewItemDetail = (item) => {
    const nextItemCorrelative = detalleItems.length + 1;
    const newItem = {
      numItem: nextItemCorrelative, //numero correlativo del item
      tipoItem: (() => {
        if (item.origen === ItemOrigin.BIENES_Y_SERVICIOS) return item.tipoItem;
        if (item.origen === ItemOrigin.NO_AFECTO) return ambosTipoItem;
        return otroTipoItem;
      })(),
      numeroDocumento: null, // null siempre por ahora
      codigo: null, // es codigo custom del contribuyente
      // aqui van los codigos de tributos que afectan a iva
      codTributo: (() => {
        if (item.origen === ItemOrigin.CON_AFECTACION_AL_IVA)
          return item.impuesto;
        return null;
      })(),
      descripcion: item.descripcion,
      cantidad: ifNotNumberReturnsZero(item.cantidad),
      uniMedida: (() => {
        if (item.origen === ItemOrigin.BIENES_Y_SERVICIOS)
          return item.unidadDeMedida;
        return otraUnidadMedida;
      })(),
      precioUni: ifNotNumberReturnsZero(item.precio),
      montoDescu: ifNotNumberReturnsZero(item.descuento),
      ventaNoSuj:
        item.tipoVenta && item.tipoVenta.value === TipoVenta.NoSujeta
          ? ifNotNumberReturnsZero(item.subTotal)
          : 0.0,
      ventaExenta:
        item.tipoVenta && item.tipoVenta.value === TipoVenta.Exenta
          ? ifNotNumberReturnsZero(item.subTotal)
          : 0.0,
      ventaGravada:
        item.tipoVenta && item.tipoVenta.value === TipoVenta.Gravada
          ? ifNotNumberReturnsZero(item.subTotal)
          : 0.0,
      tributos: Array.isArray(item.tributos) ? item.tributos : null, // tributos otros
      psv: ifNotNumberReturnsZero(item.psv),
      noGravado:
        typeof item.tipoVenta === 'undefined'
          ? redondearA2Decimales(item.subTotal)
          : 0.0,
      ivaItem: (() => {
        if (item.tipoVenta && item.tipoVenta.value === TipoVenta.Gravada)
          return calcularIvaEnTotal(item.subTotal);
        return 0.0;
      })(),

      //Otros campos custom
      tipoVenta: item.tipoVenta,
      subTotal: redondearA2Decimales(item.subTotal),
      total: redondearA2Decimales(item.total),
      impuestosInfo: item.impuestosInfo,
    };
    //Actualizamos la lista de items
    const newListaItems = [...detalleItems, newItem];
    setDetalleItemsList(newListaItems);

    //Recalculamos el resumen
    recalculateResumen(newListaItems);
  };

  const deleteItemDetail = (item) => {
    const newListaItems = detalleItems.filter(
      (x) => x.numItem !== item.numItem,
    );
    const newListaItemsWithCorrelativeFixed = newListaItems.map((x, index) => {
      return {
        ...x,
        numItem: index + 1,
      };
    });
    setDetalleItemsList(newListaItemsWithCorrelativeFixed);
    //Recalculamos el resumen
    recalculateResumen(newListaItems);
  };

  /**
   * Funcion que agrega descuentos globales
   */
  const addNewGlobalDiscount = (
    descuentoVentaGravada,
    descuentoVentaExenta,
    descuentoVentaNoSujeta,
  ) => {
    const newResumenData = {
      ...resumenData,
      descuentoVentaGravada,
      descuentoVentaExenta,
      descuentoVentaNoSujeta,
    };
    //Actualizamos el resumen
    setResumenData(newResumenData);
    //Recalculamos el resumen
    recalculateResumen(detalleItems, newResumenData);
  };

  /**
   * Estado de resumen de la factura
   */
  const [resumenData, setResumenData] = useState({
    totalVentaNoSujeta: 0.0, //Calculado
    totalVentaExenta: 0.0, //Calculado
    totalVentaGravada: 0.0, //Calculado
    subTotalVentas: 0.0,
    descuentoVentaNoSujeta: 0.0,
    descuentoVentaExenta: 0.0,
    descuentoVentaGravada: 0.0,
    porcentajeDescuento: 0.0,
    totalDescuento: 0.0,
    tributos: [],
    subTotal: 0.0,
    retencionRenta: 0.0, // ?
    retencionIVA: 0.0, // mismo que total iva?
    montoTotalOperacion: 0.0, //mismo que total pagar?
    totalNoGravado: 0.0, // suma de excenta y no sujeta?
    totalPagar: 0.0,
    totalEnLetras: '', //Se calcula al final cuando se genere el DTE
    totalIva: 0.0,
    saldoFavor: 0.0, // Esto que es
    condicionOperacion: '',
    pagos: [],
    numeroPagoElectronico: null,
  });
  const recalculateResumen = (
    items,
    newResumen,
    rentaRetenida,
    ivaRetenido,
  ) => {
    const resumen = newResumen || resumenData;

    let totalVentaNoSujeta = 0;
    let totalVentaExenta = 0;
    let totalVentaGravada = 0;
    let subTotalVentas = 0;
    //Estos valores son descuentos globales
    let descuentoVentaNoSujeta = resumen.descuentoVentaNoSujeta;
    let descuentoVentaExenta = resumen.descuentoVentaExenta;
    let descuentoVentaGravada = resumen.descuentoVentaGravada;
    // No soportado por ahora
    let porcentajeDescuento = 0;
    //Este valor incluye los descuentos por item
    let totalDescuento = 0;
    let tributos = [];
    let subTotal = 0;
    let retencionRenta =
      typeof rentaRetenida != 'undefined'
        ? redondearA2Decimales(rentaRetenida)
        : resumen.retencionRenta;
    let retencionIVA =
      typeof ivaRetenido != 'undefined'
        ? redondearA2Decimales(ivaRetenido)
        : resumen.retencionIVA;
    let montoTotalOperacion = 0;
    let totalNoGravado = 0; // Este es monto no afecto
    let totalPagar = 0;
    let totalEnLetras = '';
    let totalIva = 0;
    let saldoFavor = 0;
    let condicionOperacion = '';
    let pagos = [];
    let numeroPagoElectronico = null;

    // calculamos el total de ventas de los items
    //Verificamos si el array de items fue pasdo si o usamos el del estado
    //Si fue pasado es que la actualizacion viene despues de actualizar items
    const arrayItems = Array.isArray(items) ? items : detalleItems;

    let totalImpuestos = redondearA2Decimales(
      calculateImpuestosForResumen(arrayItems).reduce(
        (acc, imp) => acc + imp.valor,
        0,
      ),
    );

    if (isNaN(totalImpuestos)) totalImpuestos = 0;

    for (const item of arrayItems) {
      //Calculamos totales de ventas por tipo, si un item no es de ese tipo el valor sera 0
      totalVentaGravada += item.ventaGravada;
      totalVentaExenta += item.ventaExenta;
      totalVentaNoSujeta += item.ventaNoSuj;
      totalNoGravado += item.noGravado;

      //Sumamos los descuentos por item al monto total de descuentos
      totalDescuento += item.montoDescu;
      totalIva += redondearA2Decimales(item.ivaItem); // si no es venta gravada, ivaItem es 0
    }

    //Calculamos el subtotal de ventas
    subTotalVentas += totalVentaGravada + totalVentaExenta + totalVentaNoSujeta;

    //A las ventas restamos los decuentos globales
    subTotalVentas -=
      descuentoVentaGravada + descuentoVentaExenta + descuentoVentaNoSujeta;

    //calculamos el monto total de descuento global
    totalDescuento +=
      descuentoVentaNoSujeta + descuentoVentaGravada + descuentoVentaExenta;

    //Calculamos el subtotal general
    subTotal = subTotalVentas; //+ que

    montoTotalOperacion = subTotal + totalImpuestos;
    totalPagar = montoTotalOperacion + totalNoGravado;
    // Si el total a pagar es mayor que cero restamos las retenciones de renta e iva
    if (totalPagar > 0) {
      totalPagar = totalPagar - retencionRenta - retencionIVA;
      if (totalPagar < 0) {
        //saldoFavor = totalPagar * -1 ??????????????????????????? Sera asi??
        totalPagar = 0;
      }
    }
    //Calculamos el total en letras
    totalEnLetras = numeroALetras(totalPagar, {
      plural: 'DOLARES',
      singular: 'DOLAR',
      centPlural: 'CENTAVOS',
      centSingular: 'CENTAVO',
    });
    //Actualizamos el resumen
    const newResumenData = {
      totalVentaNoSujeta: totalVentaNoSujeta,
      totalVentaExenta: totalVentaExenta,
      totalVentaGravada: totalVentaGravada,
      subTotalVentas: subTotalVentas,
      descuentoVentaNoSujeta: descuentoVentaNoSujeta,
      descuentoVentaExenta: descuentoVentaExenta,
      descuentoVentaGravada: descuentoVentaGravada,
      porcentajeDescuento: porcentajeDescuento,
      totalDescuento: totalDescuento,
      tributos: tributos,
      subTotal: subTotal,
      retencionRenta: retencionRenta,
      retencionIVA: retencionIVA,
      montoTotalOperacion: montoTotalOperacion,
      totalNoGravado: totalNoGravado,
      totalPagar: totalPagar,
      totalEnLetras: totalEnLetras,
      totalIva: totalIva,
      saldoFavor: saldoFavor,
      condicionOperacion: condicionOperacion,
      pagos: pagos,
      numeroPagoElectronico: numeroPagoElectronico,
    };

    setResumenData(newResumenData);
  };

  const notSelectedValue = null;
  const validationSchema = Yup.object().shape({
    // Campos para receptor
    tipoDocumentoReceptor: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El tipo de documenyo es obligatorio'),
    numeroReceptor: Yup.string()
      .test(
        'DuiPatternTest',
        'El DUI debe cumplir con el formato 00000000-0',
        function (value) {
          if (this.parent.tipoDocumentoReceptor.value === '13') {
            //DUI
            return /^[0-9]{8}-[0-9]{1}$/.test(value);
          }
          if (this.parent.tipoDocumentoReceptor.value === '36') {
            //NIT
            return /^([0-9]{14}|[0-9]{9})$/.test(value);
          }
          return true;
        },
      )
      .test(
        'NITPatternTest',
        'El NIT debe cumplir con el formato 000000000/00000000000000',
        function (value) {
          if (this.parent.tipoDocumentoReceptor.value === '13') {
            //DUI
            return /^[0-9]{8}-[0-9]{1}$/.test(value);
          }
          if (this.parent.tipoDocumentoReceptor.value === '36') {
            //NIT
            return /^([0-9]{14}|[0-9]{9})$/.test(value);
          }
          return true;
        },
      )
      .required('El número de documento es obligatorio'),
    correoElectronicoReceptor: Yup.string()
      .email()
      .required('El correo electrónico es obligatorio'),
    telefonoReceptor: Yup.number()
      .min(8, 'El nombre debe tener al menos 5 caracteres')
      .required('El teléfono es obligatorio'),
    nombreReceptor: Yup.string().required(
      'El nombre, denominación o razón social es obligatorio',
    ),
    departamentoReceptor: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El departamento es obligatorio'),
    municipioReceptor: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El municipio es obligatorio'),
    complementoReceptor: Yup.string()
      .min(5, 'El complemento debe tener al menos 5 caracteres')
      .max(200, 'El complemento debe tener máximo 200 caracteres')
      .required('El complemento es obligatorio'),

    // campos para emisor
    actividadEconomicaEmisor: Yup.object()
      .notOneOf([notSelectedValue])
      .required('La actividad económica es obligatoria'),
    establecimientoEmisor: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El establecimiento es obligatorio'),
    correoElectronicoEmisor: Yup.string()
      .email('Tiene que ser un correo electrónico válido')
      .required('El correo electrónico es obligatorio'),
    telefonoEmisor: Yup.string().required('El teléfono es obligatorio'),

    observaciones: Yup.string(),

    retencionIVA: Yup.number(),
    retencionRenta: Yup.number(),

    condicionDeOperacion: Yup.object()
      .notOneOf([notSelectedValue])
      .required('La condición de operación es obligatoria'),

    documentoResponsableEmisor: Yup.string().test(
      'documentoTest',
      'El documento debe tener al menos 5 caracteres',
      function (value) {
        if (value === '') return true;
        return false;
      },
    ),
    nombreResponsableEmisor: Yup.string(),
    documentoResponsableReceptor: Yup.string().test(
      'documentoTest',
      'El documento debe tener al menos 5 caracteres',
      function (value) {
        if (value === '') return true;
        return false;
      },
    ),
    nombreResponsableReceptor: Yup.string(),

    //Valor auxiliar para pagos, aqui se acumula el monto de los pagos para validar que sea igual al total a pagar
    montoTotalPagos: Yup.number()
      .test(
        'PagosTest',
        'Debe haber al menos un pago y el monto total de los pagos debe ser igual al total a pagar',
        function (value) {
          if (pagosItems.length === 0) return false;
          return true;
        },
      )
      .min(
        resumenData.totalPagar,
        'El monto total de los pagos debe ser igual al total a pagar',
      )
      .max(
        resumenData.totalPagar,
        'El monto total de los pagos debe ser igual al total a pagar',
      ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      // Campos para receptor
      tipoDocumentoReceptor: notSelectedValue,
      numeroReceptor: '',
      correoElectronicoReceptor: '',
      telefonoReceptor: '',
      nombreReceptor: '',
      departamentoReceptor: null,
      municipioReceptor: null,
      complementoReceptor: '',
      // campos para emisor
      actividadEconomicaEmisor: null,
      establecimientoEmisor: null,
      correoElectronicoEmisor: '',
      telefonoEmisor: '',
      //Observaciones
      observaciones: '',

      retencionIVA: 0.0,
      retencionRenta: 0.0,

      descuentoVentaExenta: 0.0,
      descuentoVentaGravada: 0.0,
      descuentoVentaNoSujeta: 0.0,
      condicionDeOperacion: null,
      documentoResponsableEmisor: '',
      nombreResponsableEmisor: '',
      documentoResponsableReceptor: '',
      nombreResponsableReceptor: '',
      montoTotalPagos: 0.0,
    },
  };
  const {
    watch,
    getValues,
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const calculateImpuestosForResumen = (itemsActualizados) => {
    const items = itemsActualizados || detalleItems;
    const mapImpuestos = {};
    for (const item of items) {
      if (item.tributos === null) continue;
      for (const impuesto of item.impuestosInfo) {
        if (impuesto.inputInResumen) continue;
        if (impuesto.code in mapImpuestos) {
          mapImpuestos[impuesto.code].push(impuesto);
        } else {
          mapImpuestos[impuesto.code] = [];
          mapImpuestos[impuesto.code].push(impuesto);
        }
      }
    }
    const result = Object.keys(mapImpuestos).map((impuestoCode) => {
      const impuesto = mapImpuestos[impuestoCode];
      return {
        codigo: impuestoCode,
        descripcion: impuesto[0].label,
        valor: redondearA2Decimales(
          impuesto.reduce((acc, imp) => acc + imp.value, 0),
        ),
      };
    });

    return result;
  };

  const calculateTributosLibres = () => {
    let impuestosLibres = [];
    Object.keys(tributosLibres).forEach((impuestoCode) => {
      const impuesto = tributosLibres[impuestoCode];
      impuestosLibres.push({
        codigo: impuestoCode,
        descripcion: impuesto.label,
        valor: redondearA2Decimales(impuesto.value),
      });
    });
    return impuestosLibres;
  };

  const onSubmit = async (data) => {
    try {
      const dte = {
        //identificacion se genera en backend
        emisor: {
          codActividad: getValues('actividadEconomicaEmisor').value,
          descActividad: getValues('actividadEconomicaEmisor').label,
        },
        receptor: {
          correo: getValues('correoElectronicoReceptor'),
          nombre: getValues('nombreReceptor'),
          telefono: getValues('telefonoReceptor'),
          direccion: {
            departamento: getValues('departamentoReceptor').code,
            municipio: getValues('municipioReceptor').value,
            complemento: getValues('complementoReceptor'),
          },
          tipoDocumento: getValues('tipoDocumentoReceptor').value,
          numDocumento: getValues('numeroReceptor'),
          nrc: null, // En consumidor final no se envia NRC
        },
        cuerpoDocumento: detalleItems.map((item) => {
          return {
            numItem: item.numItem,
            tipoItem: parseInt(item.tipoItem.value),
            numeroDocumento: item.numeroDocumento,
            cantidad: item.cantidad,
            codigo: item.codigo,
            codTributo: (() => {
              if (item.ventaGravada === 0) return null;
              if (item.tipoItem.value === '4') return item.codTributo.value;
              else return null;
            })(), // tributos con afectacion iva
            uniMedida: item.uniMedida.value,
            descripcion: item.descripcion,
            precioUni: item.precioUni,
            montoDescu: item.montoDescu,
            ventaNoSuj: item.ventaNoSuj,
            ventaExenta: item.ventaExenta,
            ventaGravada: item.ventaGravada,
            tributos: (() => {
              if (item.tipoItem.value === '4') return null;
              else if (Array.isArray(item.tributos) && item.tributos.length > 0)
                return item.tributos;
              else return null;
            })(), // tributos otros
            psv: item.psv,
            noGravado: item.noGravado,
            ivaItem: item.ivaItem,
          };
        }),
        resumen: {
          pagos: pagosItems.map((item) => {
            return {
              codigo: item.formaPago.value,
              montoPago: item.monto,
              referencia: item.documento ? item.documento : null,
              plazo: item.plazo ? item.plazo.value : null,
              periodo: item.periodo ? item.periodo.value : null,
            };
          }),
          tributos: [
            ...calculateImpuestosForResumen(),
            ...calculateTributosLibres(),
          ],
          subTotal: resumenData.subTotal,
          ivaRete1: resumenData.retencionIVA,
          reteRenta: resumenData.retencionRenta,
          descuNoSuj: resumenData.descuentoVentaNoSujeta,
          saldoFavor: resumenData.saldoFavor,
          totalDescu: resumenData.totalDescuento,
          totalNoSuj: resumenData.totalVentaNoSujeta,
          totalPagar: resumenData.totalPagar,
          descuExenta: resumenData.descuentoVentaExenta,
          totalExenta: resumenData.totalVentaExenta,
          totalLetras: resumenData.totalEnLetras,
          totalNoGravado: resumenData.totalNoGravado, //monto no afecto
          descuGravada: resumenData.descuentoVentaGravada,
          totalGravada: resumenData.totalVentaGravada,
          subTotalVentas: resumenData.subTotalVentas,
          totalIva: redondearA2Decimales(resumenData.totalIva),
          condicionOperacion: parseInt(getValues('condicionDeOperacion').value),
          numPagoElectronico: resumenData.numeroPagoElectronico,
          montoTotalOperacion: resumenData.montoTotalOperacion,
          porcentajeDescuento: resumenData.porcentajeDescuento,
        },
        extension: {
          docuRecibe: (() => {
            let value = getValues('documentoResponsableReceptor');
            if (value === '') return null;
            else return value;
          })(),
          nombRecibe: (() => {
            let value = getValues('nombreResponsableReceptor');
            if (value === '') return null;
            else return value;
          })(),
          docuEntrega: (() => {
            let value = getValues('documentoResponsableEmisor');
            if (value === '') return null;
            else return value;
          })(),
          nombEntrega: (() => {
            let value = getValues('nombreResponsableEmisor');
            if (value === '') return null;
            else return value;
          })(),
          observaciones: (() => {
            let value = getValues('observaciones');
            if (value === '') return null;
            else return value;
          })(),
          placaVehiculo: null,
        },
      };
      enviarDte(dte, getValues('establecimientoEmisor').value, ambiente);
    } catch (error) {
      return error;
    }
  };
  const enviarDte = async (dte, sucursalId, ambiente) => {
    try {
      enqueueSnackbar('Enviando Factura...', {
        variant: 'info',
        persist: true,
        preventDuplicate: true,
      });
      await post('dte/factura-consumidor-final', dte, {
        'x-ambiente': ambiente,
        'x-branchoffice-id': sucursalId,
      });
      closeSnackbar();
      enqueueSnackbar('Factura enviada', {
        variant: 'success',
        preventDuplicate: true,
      });
      navigate('/dte/consulta');
    } catch (error) {
      enqueueSnackbar('Error enviando Factura', {
        variant: 'error',
        persist: true,
        preventDuplicate: true,
      });
    } finally {
    }
  };
  return (
    <>
      {loading ? (
        <LoaderComponent loading={loading} loadingColor="blue" />
      ) : (
        <CardWrapper title="Factura">
          <form>
            {/* Emisor y receptor */}
            <div className="row">
              <div className="col-12">
                <div className="accordion" id="collapseEmisorReceptorWrapper">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading1">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEmisorReceptor"
                        aria-expanded="true"
                        aria-controls="collapseEmisorReceptor"
                      >
                        Receptor y Emisor
                        {debug ? (
                          <span className="badge bg-warning text-dark">
                            Debug
                          </span>
                        ) : (
                          ''
                        )}
                      </button>
                    </h2>
                    <div
                      className="accordion-collapse collapse show"
                      id="collapseEmisorReceptor"
                      aria-labelledby="heading1"
                      data-bs-parent="#collapseEmisorReceptorWrapper"
                    >
                      <div className="row">
                        <div className="col-6">
                          <ReceptorDetailForm
                            useForm={{
                              control,
                              register,
                              errors,
                            }}
                            tiposDocumentos={tiposDocumentos}
                            departamentos={departamentos}
                            onChangeDepartamento={onChangeDepartamento}
                            municipios={municipios}
                          />
                        </div>
                        <div className="col-6">
                          <EmisorDetalleForm
                            useForm={{
                              getValues,
                              setValue,
                              control,
                              register,
                              errors,
                            }}
                            actividadesEconomicas={actividadEconomica}
                            establecimientosEmisor={establecimientosEmisor}
                            clienteInfo={clienteInformation}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <CardWrapper title="Observaciones del documento">
                            <label
                              className="form-label"
                              htmlFor="observacionesValue"
                            >
                              Observaciones al documento:
                            </label>
                            <textarea
                              className="form-control"
                              id="observacionesValue"
                              placeholder="Escribir observaciones al documento"
                              rows="3"
                              {...register('observaciones')}
                            ></textarea>
                          </CardWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Detalle de items */}
            <div className="row">
              <div className="col-12">
                <div className="accordion" id="DetalleAcordionWrapper">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading1">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#DetalleAcordion"
                        aria-expanded="true"
                        aria-controls="DetalleAcordion"
                      >
                        Detalle de Items
                      </button>
                    </h2>
                    <div
                      className="accordion-collapse collapse show"
                      id="DetalleAcordion"
                      aria-labelledby="heading1"
                      data-bs-parent="#DetalleAcordionWrapper"
                    >
                      <div className="row">
                        <div className="col-12">
                          <TablaDetalle
                            title="Detalle de Factura"
                            data={detalleItems}
                            resumen={resumenData}
                            updateResumenDataOnglobalDiscount={
                              addNewGlobalDiscount
                            }
                            tiposServicioProducto={tipoItems}
                            unidadesDeMedida={unidadesDeMedidas}
                            tiposVenta={tiposVenta}
                            impuestos={impuestos}
                            addItem={addNewItemDetail}
                            deleteItem={deleteItemDetail}
                            retencionIva={resumenData.retencionIVA}
                            setRetencionIva={onUpdateRetencionIva}
                            retencionRenta={resumenData.retencionRenta}
                            setRetencionRenta={onUpdateRetencionRenta}
                            tributosLibres={tributosLibres}
                            updateTributosLibres={setTributosLibres}
                            recalculateResumen={recalculateResumen}
                          ></TablaDetalle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Condicion de la operacion */}
            <div className="row">
              <div className="col-12">
                <div className="accordion" id="CondicionOperacionWrapper">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading1">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#CondicionOperacionAcordion"
                        aria-expanded="true"
                        aria-controls="CondicionOperacionAcordion"
                      >
                        Condicion de la operación
                      </button>
                    </h2>
                    <div
                      className="accordion-collapse collapse show"
                      id="CondicionOperacionAcordion"
                      aria-labelledby="heading1"
                      data-bs-parent="#CondicionOperacionWrapper"
                    >
                      <div className="row">
                        <div className="col-12">
                          {errors.montoTotalPagos && (
                            <div className="alert alert-danger" role="alert">
                              {errors.montoTotalPagos?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row ">
                        <div className=" col-3 m-3">
                          <Controller
                            name="condicionDeOperacion"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isClearable
                                options={condicionesOperacion}
                                onChange={(event) => {
                                  field.onChange(event);
                                }}
                              />
                            )}
                          />
                          {errors.condicionDeOperacion && (
                            <div className="invalid-feedback">
                              {errors.condicionDeOperacion?.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <FormasDePago
                            formaPagoSelected={watch('condicionDeOperacion')}
                            itemsPagos={pagosItems}
                            addPago={onAddPago}
                            deletePago={deletePago}
                            formasDePago={formasDePago}
                            plazosDePago={plazosDePago}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Datos adicionales de entrega */}
            <div className="row">
              <div className="col-12">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading1">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse100"
                        aria-expanded="false"
                        aria-controls="collapse100"
                      >
                        Datos Adicionales Entrega
                      </button>
                    </h2>
                    <div
                      className="accordion-collapse collapse"
                      id="collapse100"
                      aria-labelledby="heading1"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="row g-3 m-3">
                        <div className="col-6">
                          <label
                            className="form-label"
                            htmlFor="nombreProducto"
                          >
                            N° Documento:
                          </label>
                          <input
                            className="form-control"
                            name="nombreProducto"
                            id="nombreProducto"
                            type="text"
                            placeholder="Responsable de emitir el documento"
                            {...register('documentoResponsableEmisor')}
                          />
                          {errors.documentoResponsableEmisor && (
                            <div className="invalid-feedback">
                              {errors.documentoResponsableEmisor?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-6">
                          <label
                            className="form-label"
                            htmlFor="nombreProducto"
                          >
                            Nombre:
                          </label>
                          <input
                            className="form-control"
                            name="nombreProducto"
                            id="nombreProducto"
                            type="text"
                            placeholder="Responsable de emitir el documento"
                            {...register('nombreResponsableEmisor')}
                          />
                        </div>
                        <div className="col-6">
                          <label
                            className="form-label"
                            htmlFor="nombreProducto"
                          >
                            N° Documento:
                          </label>
                          <input
                            className="form-control"
                            name="nombreProducto"
                            id="nombreProducto"
                            type="text"
                            placeholder="Responsable de recibir el documento"
                            {...register('documentoResponsableReceptor')}
                          />
                          {errors.documentoResponsableReceptor && (
                            <div className="invalid-feedback">
                              {errors.documentoResponsableReceptor?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-6">
                          <label
                            className="form-label"
                            htmlFor="nombreProducto"
                          >
                            Nombre:
                          </label>
                          <input
                            className="form-control"
                            name="nombreProducto"
                            id="nombreProducto"
                            type="text"
                            placeholder="Responsable de recibir el documento"
                            {...register('nombreResponsableReceptor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <button
                    className="btn btn-lg btn-primary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(onSubmit)();
                    }}
                  >
                    <span className="fas fa-plus"></span>
                    <span className=""> Generar DTE</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </CardWrapper>
      )}
    </>
  );
};

export default ConsumidorFinal;
