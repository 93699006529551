/** @format */

import Swal from 'sweetalert2';

export const Alert = ({ title, text, icon }) => {
  Swal.fire({
    title,
    text,
    icon,
    confirmButtonText: 'Aceptar',
    confirmButtonColor: '#2C7BE5',
  });
};

export const loadingReload = (
  flag = true,
  text = 'Cargando',
  html = 'Por favor espere...',
) => {
  let myalert;
  if (flag) {
    myalert = Swal.fire({
      title: `¡${text}...!`,
      html,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  } else {
    Swal.close(myalert);
  }
};

export const alertEmpty = (titulo_principal, texto, tipo) => {
  Swal.fire({
    icon: tipo,
    title: titulo_principal,
    allowOutsideClick: false,
    text: texto,
    confirmButtonText: '<i className="fa fa-check"></i> Aceptar',
    showCloseButton: true,
    focusConfirm: true,
    footer: 'EasyFact',
  });
};

export const AlertQuestionYesNo = async ({
  title,
  text,
  type = 'question',
  focus = 1,
}) => {
  return await Swal.fire({
    title: title,
    text: text,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#2C7BE5',
    cancelButtonColor: '#93908F',
    confirmButtonText: '<i className="fa fa-check"></i> SI',
    cancelButtonText: '<i className="fa fa-times-circle"></i> NO',
    focusCancel: focus === 1 ? true : false,
    focusConfirm: focus === 2 ? true : false,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.value) {
      return true;
    } else {
      return false;
    }
  });
};
