import React from "react";
import { Route, Navigate } from "react-router-dom";
import MainLayout from "../../Components/Template/Layouts/MainLayout";
import { getSession } from "../../services/encrytion/encryption.service";

const PrivateRoute = ({ children, uuid }) => {
  const authed = getSession("token"); // isauth() returns true or false based on localStorage
  const ModulosSesion = getSession("modulos");

  if (authed) {
    if (uuid !== undefined && !ModulosSesion.includes(uuid)) {
      return <Navigate to="/" />;
    }

    return <MainLayout>{children}</MainLayout>;
  }

  return <Navigate to="/login" />;
};
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  // Aquí necesitarás lógica para verificar si el usuario está autenticado
  // Por ahora, vamos a simular esto con una variable booleana
  const isAuthenticated = true;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && restricted ? (
          <Navigate to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export { PrivateRoute, PublicRoute };
