import React from 'react';
import * as slug from 'slug';

const CollapseCard = ({ title, show = true, children }) => {
  const idCollapse = slug(title, { lower: true });
  return (
    <div className="accordion" id={`collapse${idCollapse}Wrapper`}>
      <div className="accordion-item">
        <h2 className="accordion-header" id={String(slug)}>
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${idCollapse}`}
            aria-expanded="true"
            aria-controls={`collapse${idCollapse}`}
          >
            {title}
          </button>
        </h2>
        <div
          className={
            show
              ? 'accordion-collapse collapse show'
              : 'accordion-collapse collapse'
          }
          id={`collapse${idCollapse}`}
          aria-labelledby={String(slug)}
          data-bs-parent={`collapse${idCollapse}Wrapper`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default CollapseCard;
