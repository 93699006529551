import React, { useCallback, useEffect, useRef, useState } from 'react';
import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';

import ModalWrapper from '../../../Components/Common/Modals/Modal';
import { useForm } from 'react-hook-form';
import { Alert, loadingReload } from '../../../services/alerts/alerts';
import { get, patch } from '../../../services/http/http.service';
import { getSession } from '../../../services/encrytion/encryption.service';
export default function CredencialesClientes({ id, readOnly = false }) {
  const modalRef = useRef();

  const [cliente, setCliente] = useState([]);
  const { register, handleSubmit, reset, setValue } = useForm();

  const loadClientById = useCallback(
    async (id) => {
      if (id) {
        const { data } = await get(`/clientes/${id}`);
        setValue('nit', data.nit);
        setValue('nrc', data.nrc);
        setValue('email', data.correo);
        setValue('nombre', data.nombre);
        setValue('nombre_comercial', data.nombreComercial);
        setValue('telefono', data.telefono);
        setCliente(data);
        return data;
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (id) {
      loadClientById(id);
    } else {
      const { id } = getSession('clienteSeleccionado') ?? {};
      loadClientById(id);
    }
  }, [id, loadClientById]);

  const handleOpenModal = () => {
    if (modalRef.current) {
      reset();
      modalRef.current.toggle();
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      ...Object.fromEntries(
        Object.entries({
          passwordFirma: data.passwordFirma,
          passwordMh: data.passwordMh,
          passwordDev: data.passwordMhDev,
          usuarioFirma: data.usuarioFirma,
          usuarioMh: data.usuarioMh,
          usuarioDev: data.usuarioMhDev,
        }).filter(([key, value]) => value),
      ),
    };
    try {
      loadingReload(true, 'Guardando datos');
      const { data } = await patch(`clientes/update-password/${id}`, payload);
      if (data?.clientId) {
        loadingReload(false, null);
        loadClientById(id);
        modalRef.current.toggle();
        Alert({
          title: 'Datos guardados',
          text: 'Los datos se guardaron correctamente',
          icon: 'success',
        });
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: error.message ?? 'Error al guardar los datos',
        icon: 'error',
      });
    }
  };

  if (readOnly) {
    return (
      <CardWrapperTools title="Configuración credenciales Ministerio de Hacienda">
        <div className="row row-cols-1 row-cols-md-2 g-3">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Usuario Firma Electrónica:</h5>
                {(cliente?.tieneCredencialesFirma ?? 0) > 0 ? (
                  <div
                    className="alert alert-success border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">Configurado exitosamente!</p>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">
                      No se ha realizado la configuración, por favor contacte a
                      soporte técnico.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Contraseña Firma Electrónica:</h5>
                {(cliente?.tieneCredencialesFirma ?? 0) > 0 ? (
                  <div
                    className="alert alert-success border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">Configurado exitosamente!</p>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">
                      No se ha realizado la configuración, por favor contacte a
                      soporte técnico.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Usuario servicio API ambiente de Pruebas:
                </h5>
                {(cliente?.tieneCredencialesMhDev ?? 0) > 0 ? (
                  <div
                    className="alert alert-success border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">Configurado exitosamente!</p>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">
                      No se ha realizado la configuración, no podrá firmar
                      documentos para el ambiente pruebas. Por favor contacte a
                      soporte técnico, si lo estima necesario.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Constraseña servicio API ambiente de Pruebas:
                </h5>
                {(cliente?.tieneCredencialesMhDev ?? 0) > 0 ? (
                  <div
                    className="alert alert-success border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">Configurado exitosamente!</p>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">
                      No se ha realizado la configuración, no podrá enviar
                      documentos en el ambiente pruebas. Por favor contacte a
                      soporte técnico, si lo estima necesario.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Usuario servicio API ambiente de Producción:
                </h5>
                {(cliente?.tieneCredencialesMhProd ?? 0) > 0 ? (
                  <div
                    className="alert alert-success border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">Configurado exitosamente!</p>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">
                      No se ha realizado la configuración, no podrá firmar
                      documentos para el ambiente de producción. Por favor
                      contacte a soporte técnico, para realizar la configuración
                      lo más pronto posible.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Contraseña servicio API ambiente de Producción:
                </h5>
                {(cliente?.tieneCredencialesMhProd ?? 0) > 0 ? (
                  <div
                    className="alert alert-success border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">Configurado exitosamente!</p>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger border-2 d-flex align-items-center"
                    role="alert"
                  >
                    <p className="mb-0 flex-1">
                      No se ha realizado la configuración, no podrá firmar
                      documentos para el ambiente de producción. Por favor
                      contacte a soporte técnico, para realizar la configuración
                      lo más pronto posible.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardWrapperTools>
    );
  }

  return (
    <div>
      <CardWrapperTools
        title="Credenciales del Ministerio de Hacienda"
        tools={
          !readOnly && (
            <div className="d-flex align-items-center">
              <div className="me-3">
                <div className="icon-shape icon-sm bg-body-tertiary text-center rounded">
                  <button className="btn btn-primary" onClick={handleOpenModal}>
                    <i
                      className="far fa-edit"
                      style={{ marginRight: '3px' }}
                    ></i>
                    Actualizar Credenciales
                  </button>
                </div>
              </div>
            </div>
          )
        }
      >
        <div className="row g-3">
          <div className="table-responsive scrollbar">
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th>Usuario Ambiente de pruebas</th>
                  <th>Clave Ambiente de pruebas</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {(cliente?.tieneCredencialesMhDev ?? 0) > 0 ? (
                      <span className="badge rounded-pill badge-subtle-primary">
                        SI
                      </span>
                    ) : (
                      <span className="badge rounded-pill badge-subtle-warning">
                        NO
                      </span>
                    )}
                  </td>
                  <td>
                    {(cliente?.tieneCredencialesMhDev ?? 0) > 0 ? (
                      <span className="badge rounded-pill badge-subtle-primary">
                        SI
                      </span>
                    ) : (
                      <span className="badge rounded-pill badge-subtle-warning">
                        NO
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th>Usuario Ambiente de producción</th>
                  <th>Clave Ambiente de producción</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {(cliente?.tieneCredencialesMhProd ?? 0) > 0 ? (
                      <span className="badge rounded-pill badge-subtle-primary">
                        SI
                      </span>
                    ) : (
                      <span className="badge rounded-pill badge-subtle-warning">
                        NO
                      </span>
                    )}
                  </td>
                  <td>
                    {(cliente?.tieneCredencialesMhProd ?? 0) > 0 ? (
                      <span className="badge rounded-pill badge-subtle-primary">
                        SI
                      </span>
                    ) : (
                      <span className="badge rounded-pill badge-subtle-warning">
                        NO
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ModalWrapper ref={modalRef}>
          <CardWrapperTools
            title=" Administración de Contraseñas de Ministerio de Hacienda"
            footer={
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <span className="far fa-paper-plane"></span> Guardar
                    </button>
                  </div>
                </div>
              </div>
            }
          >
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="usuarioMh" className="form-label">
                  Usuario Ministerio de Hacienda Producción:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="usuarioMh"
                  placeholder="Usuario MH"
                  {...register('usuarioMh')}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="passwordMh" className="form-label">
                  Contraseña Ministerio de Hacienda Producción:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="passwordMh"
                  placeholder="Contraseña MH"
                  {...register('passwordMh')}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="usuarioMhDev" className="form-label">
                  Usuario Ministerio de Hacienda Pruebas:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="usuarioMhDev"
                  placeholder="Usuario MH Dev"
                  {...register('usuarioMhDev')}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="passwordMhDev" className="form-label">
                  Contraseña Ministerio de Hacienda Pruebas:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="passwordMhDev"
                  placeholder="Contraseña MH Dev"
                  {...register('passwordMhDev')}
                />
              </div>
            </div>
          </CardWrapperTools>
        </ModalWrapper>
      </CardWrapperTools>
    </div>
  );
}
