import React from 'react';
import { CardWrapper } from '../../../../../Components/Common/Cards';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const ReceptorDetailForm = ({
  useForm,
  tiposDocumentos,
  departamentos,
  onChangeDepartamento,
  municipios,
}) => {
  return (
    <>
      <CardWrapper title="Datos receptor">
        <div className="row g-3">
          <div className="col-4">
            <label className="form-label" htmlFor="receptorTipoDocumentoValue">
              Tipo Documento:
            </label>

            <Controller
              name="tipoDocumentoReceptor"
              control={useForm.control}
              render={({ field }) => (
                <Select
                  options={tiposDocumentos}
                  {...field}
                  name="tipoDocumentoReceptor"
                  id="tipoDocumentoReceptor"
                />
              )}
            />
            {useForm.errors.tipoDocumentoReceptor && (
              <div className="invalid-feedback">
                {useForm.errors.tipoDocumentoReceptor?.message}
              </div>
            )}
          </div>
          <div className="col-8">
            <label className="form-label" htmlFor="numeroReceptor">
              Número:
            </label>
            <input
              className="form-control"
              name="numeroReceptor"
              id="numeroReceptor"
              type="text"
              placeholder="Número"
              {...useForm.register('numeroReceptor')}
            />
            {useForm.errors.numeroReceptor && (
              <div className="invalid-feedback">
                {useForm.errors.numeroReceptor?.message}
              </div>
            )}
          </div>
          <div className="col-8">
            <label className="form-label" htmlFor="correoElectronicoReceptor">
              Correo Electrónico:
            </label>
            <input
              className="form-control"
              name="correoElectronicoReceptor"
              id="correoElectronicoReceptor"
              type="email"
              placeholder="ejemplo@correo.com"
              {...useForm.register('correoElectronicoReceptor')}
            />
            {useForm.errors.correoElectronicoReceptor && (
              <div className="invalid-feedback">
                {useForm.errors.correoElectronicoReceptor?.message}
              </div>
            )}
          </div>
          <div className="col-4">
            <label className="form-label" htmlFor="receptorTelefonoValue">
              Teléfono:
            </label>
            <input
              className="form-control"
              name="receptorTelefonoValue"
              id="receptorTelefonoValue"
              type="tel"
              placeholder="7123 5555"
              {...useForm.register('telefonoReceptor')}
            />
            {useForm.errors.telefonoReceptor && (
              <div className="invalid-feedback">
                {useForm.errors.telefonoReceptor?.message}
              </div>
            )}
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="receptorNombreValue">
              Nombre, denominación o razón social del contribuyente:
            </label>
            <input
              className="form-control"
              name="receptorNombreValue"
              id="receptorNombreValue"
              type="text"
              placeholder="Nombre"
              {...useForm.register('nombreReceptor')}
            />
            {useForm.errors.nombreReceptor && (
              <div className="invalid-feedback">
                {useForm.errors.nombreReceptor?.message}
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label className="form-label" htmlFor="departamentoReceptor">
              Departamento:
            </label>
            <Controller
              name="departamentoReceptor"
              control={useForm.control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  options={departamentos}
                  onChange={(event) => {
                    onChangeDepartamento(event);
                    field.onChange(event);
                  }}
                  placeholder="Seleccionar"
                />
              )}
            />
            {useForm.errors.departamentoReceptor && (
              <div className="invalid-feedback">
                {useForm.errors.departamentoReceptor?.message}
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label className="form-label" htmlFor="municipioReceptor">
              Municipio:
            </label>

            <Controller
              name="municipioReceptor"
              control={useForm.control}
              render={({ field }) => (
                <Select
                  options={municipios}
                  {...field}
                  placeholder="Seleccionar"
                />
              )}
            />
            {useForm.errors.municipioReceptor && (
              <div className="invalid-feedback">
                {useForm.errors.municipioReceptor?.message}
              </div>
            )}
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="complementoReceptor">
              Complemento:
            </label>
            <input
              className="form-control"
              name="complementoReceptor"
              id="complementoReceptor"
              type="text"
              placeholder="Complemento de dirección"
              {...useForm.register('complementoReceptor')}
            />
            {useForm.errors.complementoReceptor && (
              <div className="invalid-feedback">
                {useForm.errors.complementoReceptor?.message}
              </div>
            )}
          </div>
        </div>
      </CardWrapper>
    </>
  );
};

export { ReceptorDetailForm };
