import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ModalWrapper from '../../../../../../Components/Common/Modals/Modal';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import WarningAnulacion from './WarningAnulacion';
import {
  documentosReceptor,
  dteNecesitaReemplazo,
} from '../../utils/consulta.util';
import {
  Alert,
  AlertQuestionYesNo,
  loadingReload,
} from '../../../../../../services/alerts/alerts';
import { post } from '../../../../../../services/http/http.service';
import { InputMask } from '@react-input/mask';

const ModalOpcionesAnulacion = forwardRef((props, ref) => {
  const { setValue } = useFormContext();
  const [maskNumeroResponsableAnulacion, setMaskNumeroResponsableAnulacion] =
    useState(0); //0-other, 1- DUI, 2-NIT
  const [maskNumeroSolicitanteAnulacion, setMaskNumeroSolicitanteAnulacion] =
    useState(0); //0-other, 1- DUI, 2-NIT

  const validationSchema = Yup.object().shape({
    tipoInvalidacion: Yup.object().required(
      'El tipo de invalidación es requerido',
    ),
    motivoInvalidacion: Yup.string().required(
      'El motivo de la invalidación es requerido',
    ),
    nombreResponsable: Yup.string().required(
      'El nombre del responsable es requerido',
    ),

    tipoDte: Yup.string(),
    tipoDocumentoResponsable: Yup.object().required(
      'El tipo de documento del responsable es requerido',
    ),
    numeroDocumentoResponsable: Yup.string().required(
      'El número de documento del responsable es requerido',
    ),
    nombreSolicitante: Yup.string().required(
      'El nombre del solicitante es requerido',
    ),
    tipoDocumentoSolicitante: Yup.object().required(
      'El tipo de documento del solicitante es requerido',
    ),
    numeroDocumentoSolicitante: Yup.string().required(
      'El número de documento del solicitante es requerido',
    ),

    codigoGeneracionR: Yup.string()

      .optional()
      .when(['tipoInvalidacion', 'tipoDte'], {
        is: (tipoInvalidacion, tipoDte) => {
          return (
            (tipoInvalidacion?.value === 1 || tipoInvalidacion?.value === 3) &&
            dteNecesitaReemplazo.includes(tipoDte)
          );
        },
        then: (schema) =>
          schema.required('El código de generación de remplazo es requerido'),
      }),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tipoInvalidacion: null,
      tipoDte: props?.dte?.datos_dte.identificacion.tipoDte,
      codigoGeneracionR: '',
      motivoInvalidacion: '',
      nombreResponsable: '',
      tipoDocumentoResponsable: null,
      numeroDocumentoResponsable: '',
      nombreSolicitante: '',
      tipoDocumentoSolicitante: null,
      numeroDocumentoSolicitante: '',
    },
  };

  const {
    register,
    control,
    handleSubmit,
    watch: watchForm,
    reset,
    formState: { errors },
  } = useForm(formOptions);
  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    async openModal() {
      modalRef.current.toggle();

      reset();
    },
  }));

  const onSubmit = () => {
    AlertQuestionYesNo({
      title: 'Anulación de DTE',
      text: '¿Está seguro de realizar la invalidación del DTE?',
    }).then(async (result) => {
      const anulacionData = {
        documento: {
          codigoGeneracionR: watchForm('codigoGeneracionR') || null,
          codigoGeneracion:
            props?.dte?.datos_dte?.identificacion?.codigoGeneracion,
        },
        motivo: {
          tipoAnulacion: watchForm('tipoInvalidacion')?.value,
          motivoAnulacion: watchForm('motivoInvalidacion'),
          nombreResponsable: watchForm('nombreResponsable'),
          tipDocResponsable: watchForm('tipoDocumentoResponsable')?.value,
          numDocResponsable: watchForm('numeroDocumentoResponsable'),
          nombreSolicita: watchForm('nombreSolicitante'),
          tipDocSolicita: watchForm('tipoDocumentoSolicitante')?.value,
          numDocSolicita: watchForm('numeroDocumentoSolicitante'),
        },
      };

      if (result) {
        const headers = {
          'x-ambiente': props?.dte?.datos_dte?.identificacion?.ambiente,
          'x-branchoffice-id': props?.dte?.sucursal,
        };
        try {
          loadingReload(true, 'Generando invalidación', 'Por favor espere...');
          const { status } = await post(
            'dte/anulacion',
            anulacionData,
            headers,
          );

          if (status === 201) {
            setValue('reload_detalle', true);
            loadingReload(false);
            modalRef.current.toggle();
          }
        } catch (error) {
          loadingReload(false);
          if (error?.response?.data) {
            Alert({
              title: 'Error',
              text: error?.response?.data?.message,
              icon: 'error',
            });
          }
        }
      }
    });
  };

  const inputNumeroResponsableInvalidacion = () => {
    const duiInput = (
      <InputMask
        key={Math.random()}
        mask="________-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        className="form-control"
        id="numeroDocumentoResponsable"
        placeholder="Digite el nombre del responsable"
        {...register('numeroDocumentoResponsable')}
        onFocus={(e) => e.target.select()}
      ></InputMask>
    );
    const nitInput = (
      <InputMask
        key={Math.random()}
        mask="____-______-___-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        className="form-control"
        id="numeroDocumentoResponsable"
        placeholder="Digite el nombre del responsable"
        {...register('numeroDocumentoResponsable')}
        onFocus={(e) => e.target.select()}
      ></InputMask>
    );
    switch (maskNumeroResponsableAnulacion) {
      case 1:
        return duiInput;
      case 2:
        return nitInput;
      default:
        return (
          <input
            key={Math.random()}
            type="text"
            className="form-control"
            id="numeroDocumentoResponsable"
            placeholder="Digite el nombre del responsable"
            {...register('numeroDocumentoResponsable')}
            onFocus={(e) => e.target.select()}
          ></input>
        );
    }
  };

  const inputNumeroSolicitanteInvalidacion = () => {
    const duiInput = (
      <InputMask
        key={Math.random()}
        mask="________-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        className="form-control"
        id="numeroDocumentoSolicitante"
        placeholder="Digite el nombre del solicitante"
        {...register('numeroDocumentoSolicitante')}
        onFocus={(e) => e.target.select()}
      ></InputMask>
    );
    const nitInput = (
      <InputMask
        key={Math.random()}
        mask="____-______-___-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        className="form-control"
        id="numeroDocumentoSolicitante"
        placeholder="Digite el nombre del solicitante"
        {...register('numeroDocumentoSolicitante')}
        onFocus={(e) => e.target.select()}
      ></InputMask>
    );
    switch (maskNumeroSolicitanteAnulacion) {
      case 1:
        return duiInput;
      case 2:
        return nitInput;
      default:
        return (
          <input
            key={Math.random()}
            type="text"
            className="form-control"
            id="numeroDocumentoSolicitante"
            placeholder="Digite el nombre del solicitante"
            {...register('numeroDocumentoSolicitante')}
            onFocus={(e) => e.target.select()}
          ></input>
        );
    }
  };

  const configureInputMaskForResponsableInvalidacion = (documentType) => {
    if (documentType === '13') {
      setMaskNumeroResponsableAnulacion(1);
    } else if (documentType === '36.2') {
      //Dui homologado a NIT
      setMaskNumeroResponsableAnulacion(1);
    } else if (documentType === '36') {
      setMaskNumeroResponsableAnulacion(2);
    } else {
      setMaskNumeroResponsableAnulacion(0);
    }
  };

  const configureInputMaskForSolicitanteInvalidacion = (documentType) => {
    if (documentType === '13') {
      setMaskNumeroSolicitanteAnulacion(1);
    } else if (documentType === '36.2') {
      //Dui homologado a NIT
      setMaskNumeroSolicitanteAnulacion(1);
    } else if (documentType === '36') {
      setMaskNumeroSolicitanteAnulacion(2);
    } else {
      setMaskNumeroSolicitanteAnulacion(0);
    }
  };

  return (
    <>
      <ModalWrapper title={`Evento de invalidación de DTE`} ref={modalRef}>
        <CardWrapperTools
          title={`Evento de invalidación: ${props.tipoDte} - ${props.codigoGeneracion}`}
          footer={
            <div className="row">
              <div className="col-lg-12 text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Generar invalidación
                </button>
              </div>
            </div>
          }
        >
          <div className="row">
            <div className="col-12">
              <CardWrapperTools title="Detalles de invalidación">
                <div className="row g-3">
                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="tipoInvalidacion">
                      Tipo invalidación:
                    </label>
                    <Controller
                      name="tipoInvalidacion"
                      control={control}
                      render={({ field }) => (
                        <Select
                          value={field.value}
                          isClearable={true}
                          options={[
                            {
                              value: 1,
                              label:
                                'Error en la Información del DTE a invalidar',
                            },
                            {
                              value: 2,
                              label: 'Rescindir de la operación realizada',
                            },
                            { value: 3, label: 'Otro' },
                          ]}
                          placeholder="Seleccione una opción"
                          {...field}
                        />
                      )}
                    />
                    {errors.tipoInvalidacion && (
                      <div className="invalid-feedback">
                        {errors.tipoInvalidacion?.message}
                      </div>
                    )}
                  </div>

                  {(watchForm('tipoInvalidacion')?.value === 1 ||
                    watchForm('tipoInvalidacion')?.value === 3) &&
                  dteNecesitaReemplazo.includes(watchForm('tipoDte')) ? (
                    <div className="col-lg-6 form-group">
                      <label htmlFor="">
                        Codigo Generación que reemplaza al documento con error
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="codigoGeneracionR"
                        placeholder="Cod. de generación del DTE de reemplazo"
                        {...register('codigoGeneracionR')}
                        onFocus={(e) => e.target.select()}
                      />
                      {errors.codigoGeneracionR && (
                        <div className="invalid-feedback">
                          {errors.codigoGeneracionR?.message}
                        </div>
                      )}
                    </div>
                  ) : null}

                  <div className="col-lg-12 form-group">
                    <label htmlFor="">Motivo invalidación del DTE:</label>
                    <textarea
                      className="form-control"
                      id="motivoInvalidacion"
                      placeholder="Escriba el motivo de la invalidación del DTE"
                      {...register('motivoInvalidacion')}
                      onFocus={(e) => e.target.select()}
                      rows={6}
                    />
                    {errors.motivoInvalidacion && (
                      <div className="invalid-feedback">
                        {errors.motivoInvalidacion?.message}
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                    <WarningAnulacion
                      tipoDte={props?.dte?.datos_dte.identificacion.tipoDte}
                      tipoInvalidacion={watchForm('tipoInvalidacion')?.value}
                    />
                  </div>
                </div>
              </CardWrapperTools>

              <CardWrapperTools title="Datos del responsable de la invalidación del DTE">
                <div className="row g-3">
                  <div className="col-lg-4 form-group">
                    <label htmlFor="">Nombre:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nombreResponsable"
                      placeholder="Digite el nombre del responsable"
                      {...register('nombreResponsable')}
                    />
                    {errors.nombreResponsable && (
                      <div className="invalid-feedback">
                        {errors.nombreResponsable?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label
                      className="form-label"
                      htmlFor="tipoDocumentoResponsable"
                    >
                      Tipo documento:
                    </label>
                    <Controller
                      name="tipoDocumentoResponsable"
                      control={control}
                      render={({ field }) => (
                        <Select
                          value={field.value}
                          options={documentosReceptor}
                          placeholder="Seleccione una opción"
                          {...field}
                          onChange={(event) => {
                            const documentType = event?.value;
                            configureInputMaskForResponsableInvalidacion(
                              documentType,
                            );
                            //Si cambia de tipo de documento limpiamos el numero de documento
                            setValue('numeroDocumentoResponsable', '');
                            field.onChange(event);
                          }}
                        />
                      )}
                    />
                    {errors.tipoDocumentoResponsable && (
                      <div className="invalid-feedback">
                        {errors.tipoDocumentoResponsable?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 form-group">
                    <label htmlFor="">Número de documento:</label>
                    {inputNumeroResponsableInvalidacion()}

                    {errors.numeroDocumentoResponsable && (
                      <div className="invalid-feedback">
                        {errors.numeroDocumentoResponsable?.message}
                      </div>
                    )}
                  </div>
                </div>
              </CardWrapperTools>

              <CardWrapperTools title="Datos del solicitante de la invalidación del DTE">
                <div className="row g-3">
                  <div className="col-lg-4 form-group">
                    <label htmlFor="">Nombre:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nombreSolicitante"
                      placeholder="Digite el nombre del solicitante"
                      {...register('nombreSolicitante')}
                      onFocus={(e) => e.target.select()}
                    />
                    {errors.nombreSolicitante && (
                      <div className="invalid-feedback">
                        {errors.nombreSolicitante?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label
                      className="form-label"
                      htmlFor="tipoDocumentoSolicitante"
                    >
                      Tipo documento:
                    </label>
                    <Controller
                      name="tipoDocumentoSolicitante"
                      control={control}
                      render={({ field }) => (
                        <Select
                          value={field.value}
                          options={documentosReceptor}
                          placeholder="Seleccione una opción"
                          {...field}
                          onChange={(event) => {
                            const documentType = event?.value;
                            configureInputMaskForSolicitanteInvalidacion(
                              documentType,
                            );
                            //Si cambia de tipo de documento limpiamos el numero de documento
                            setValue('numeroDocumentoSolicitante', '');
                            field.onChange(event);
                          }}
                        />
                      )}
                    />
                    {errors.tipoDocumentoSolicitante && (
                      <div className="invalid-feedback">
                        {errors.tipoDocumentoSolicitante?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 form-group">
                    <label htmlFor="">Número de documento:</label>
                    {inputNumeroSolicitanteInvalidacion()}

                    {errors.numeroDocumentoSolicitante && (
                      <div className="invalid-feedback">
                        {errors.numeroDocumentoSolicitante?.message}
                      </div>
                    )}
                  </div>
                </div>
              </CardWrapperTools>
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </>
  );
});
export default ModalOpcionesAnulacion;
