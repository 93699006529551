/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import {
  getActividadesEconomicas,
  getDepartamentos,
  getMunicipios,
  loadActividadesEconomicas,
} from '../utils/NotaCredito';
import { enqueueSnackbar } from 'notistack';
import AsyncSelect from 'react-select/async';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import BuscadorClientes from '../../components/BuscadorClientes/BuscadorClientes';
import PhoneInput from 'react-phone-input-2';
import { InputMask } from '@react-input/mask';
import httpMH from '../../../../../services/http/http-mh-axios';

const ReceptorForm = () => {
  const {
    setValue,
    register,
    control,
    reset,
    formState: { errors },
  } = useFormContext();
  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [actividadesEconomicasCliente, setActividadesEconomicasCliente] =
    useState([]);
  const [nit, setNit] = useState('');
  const [timer, setTimer] = useState(null);
  const [blockingInput, setBlockingInput] = useState(false);
  const [maskNumeroNit, setMaskNumeroNit] = useState(0); //0-other, 1- DUI, 2-NIT

  const getDataSelect = useCallback(async () => {
    try {
      const [dataDepartamentos, dataMunicipios] = await Promise.all([
        getDepartamentos(),
        getMunicipios(),
      ]);

      setDepartamentos(dataDepartamentos.data);
      setMunicipios(dataMunicipios);
      setValue('departamentoReceptor', dataDepartamentos.default);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los datos generales!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, [setValue]);

  useEffect(() => {
    async function getData() {
      await getDataSelect();
    }

    getData();
    setMaskNumeroNit(2);
  }, [getDataSelect]);

  const onChangeDepartamento = async (event) => {
    if (event) {
      try {
        const { id } = event;
        const dataMunicipios = await getMunicipios(id);
        setMunicipios(dataMunicipios);
        setValue('municipioReceptor', null);
      } catch (error) {
        enqueueSnackbar(
          'Se ha producido un error al momento de obtener los datos de municipios!',
          {
            variant: 'error',
            preventDuplicate: true,
          },
        );
      }
    } else {
      setValue('municipioReceptor', null);
      setMunicipios([]);
    }
  };
  const onSelectClient = (data) => {
    const actividadEconomicaReceptor =
      typeof data.actividad_economica !== 'undefined' &&
      data.actividad_economica.length > 0
        ? {
            label:
              data.actividad_economica.find((item) => item.default)?.value ||
              data.actividad_economica[0]?.value,
            value:
              data.actividad_economica.find((item) => item.default)?.code ||
              data.actividad_economica[0]?.code,
          }
        : null;

    const departamentoReceptor = data.departamento.code
      ? {
          label: data.departamento.value,
          value: data.departamento.code,
          id: data.departamento.id,
        }
      : null;

    const municipioReceptor = data.municipio.code
      ? {
          label: data.municipio.value,
          value: data.municipio.code,
        }
      : null;

    setActividadesEconomicasCliente(
      data.actividad_economica.map((item) => ({
        label: item.value,
        value: item.code,
      })),
    );
    if (data.nit?.length <= 10) {
      setValue('tipo_documento_nit', {
        label: 'NIT (DUI Homologado)',
        value: '36.2',
      });
      configureInputMaskForNumeroNit('36.2');
    } else {
      setValue('tipo_documento_nit', {
        label: 'NIT',
        value: '36',
      });
      configureInputMaskForNumeroNit('36');
    }

    setValue('numeroDocumentoReceptor', data.nit.trim());
    setValue('actividadEconomicaReceptor', actividadEconomicaReceptor);
    setValue('nitReceptor', data.nit.trim());
    setValue('nrcReceptor', data.nrc);
    setValue('nombreClienteReceptor', data.nombre_cliente);
    setValue('nombreComercialReceptor', data.nombre_comercial);
    setValue('correoElectronicoReceptor', data.correo);
    setValue('telefonoReceptor', data.telefono);
    setValue('complementoReceptor', data.direccion);
    setValue('departamentoReceptor', departamentoReceptor);

    getMunicipios(departamentoReceptor.id).then((dataMunicipios) => {
      setMunicipios(dataMunicipios);
    });

    setValue('municipioReceptor', municipioReceptor);
  };
  const nitNumeroDocumento = () => {
    const duiInput = (
      <InputMask
        key={maskNumeroNit}
        mask="________-_"
        showMask
        replacement={{ _: /\d/ }}
        className="form-control"
        name="nitReceptor"
        id="nitReceptor"
        type="text"
        placeholder="Digite el NIT"
        {...register('nitReceptor', {
          onChange: (e) => {
            setNit(e.target.value);
          },
        })}
      ></InputMask>
    );
    const nitInput = (
      <InputMask
        key={maskNumeroNit}
        mask="____-______-___-_"
        showMask
        replacement={{ _: /\d/ }}
        className="form-control"
        name="nitReceptor"
        id="nitReceptor"
        type="text"
        placeholder="Digite el NIT"
        {...register('nitReceptor')}
      ></InputMask>
    );
    switch (maskNumeroNit) {
      case 1:
        return duiInput;
      case 2:
        return nitInput;
      default:
        return (
          <input
            key={maskNumeroNit}
            className="form-control"
            name="nitReceptor"
            id="nitReceptor"
            type="text"
            placeholder="Digite el NIT"
            {...register('nitReceptor')}
          ></input>
        );
    }
  };
  const updateInputMaskConfig = (value) => {
    if (maskNumeroNit === value) {
      return;
    }
    setMaskNumeroNit(value);
    setValue('nitReceptor', '');
  };
  const configureInputMaskForNumeroNit = (documentType) => {
    if (documentType === '13') {
      updateInputMaskConfig(1);
    } else if (documentType === '36.2') {
      //Dui homologado a NIT
      updateInputMaskConfig(1);
    } else if (documentType === '36') {
      updateInputMaskConfig(2);
    } else {
      updateInputMaskConfig(0);
    }
  };
  const onDeleteClient = () => {
    setActividadesEconomicasCliente([]);
    setValue('actividadEconomicaReceptor', null);
    setValue('nitReceptor', '');
    setValue('nrcReceptor', '');
    setValue('nombreClienteReceptor', '');
    setValue('nombreComercialReceptor', '');
    setValue('correoElectronicoReceptor', '');
    setValue('telefonoReceptor', '');
    setValue('complementoReceptor', '');
    setValue('departamentoReceptor', null);
    setValue('municipioReceptor', null);
  };

  useEffect(() => {
    if (nit.length > 0) {
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          handleNitContribuyente(nit);
        }, 1000),
      );
    }
  }, [nit]);

  const handleNitContribuyente = async (nit) => {
    const { data } = await httpMH.get(`/nit/${nit.replace(/-/g, '')}`);
    if (data?.nit) {
      setBlockingInput(true);
      const departamento = departamentos.find(
        (item) => item.value === data.municipio.departamento.codigo,
      );

      const municipio = municipios.find(
        (item) => item.value === data.municipio.codigo,
      );

      const actividadEconomicaDefault = data.listActEconomica.filter(
        (item) => item.primaria === true,
      );

      const actividadEconomica = await getActividadesEconomicas(
        actividadEconomicaDefault[0]?.descripcion,
      );

      setValue('nombreClienteReceptor', data.nombreCompleto, {
        shouldValidate: true,
      });
      setValue('nrcReceptor', data.nrc);
      setValue('nombreComercialReceptor', data.nombreComercial);
      setValue('correoElectronicoReceptor', data.correo);
      setValue('complementoReceptor', data.direccion);
      setValue('departamentoReceptor', {
        label: departamento.label,
        value: departamento.value,
        slug: departamento.slug,
        id: departamento.id,
      });
      setValue('municipioReceptor', {
        label: municipio.label,
        value: municipio.value,
        slug: municipio.slug,
        id: municipio.id,
        departamento: departamento.id,
      });
      setValue('actividadEconomicaReceptor', actividadEconomica[0]);
      enqueueSnackbar('Contribuyente encontrado, verifique los datos', {
        variant: 'success',
        preventDuplicate: true,
      });
    }
    if (data === null || data === undefined || data === '') {
      reset();
      enqueueSnackbar(
        'No se ha encontrado el contribuyente con el NIT/DUI ingresado',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  };

  return (
    <>
      <CardWrapperTools
        title="Receptor"
        tools={
          <>
            <BuscadorClientes
              onSelectClient={onSelectClient}
              showCleanButton={actividadesEconomicasCliente.length > 0}
              onDeleteClient={onDeleteClient}
              contribuyente={true}
            />
          </>
        }
      >
        {actividadesEconomicasCliente.length > 1 && (
          <div className="row">
            <div className="col-lg-12">
              <div className="alert alert-warning text-center" role="alert">
                <i className="fa fa-exclamation-triangle"></i>
                <span className="p-1">
                  El cliente tiene más de una actividad económica, seleccione la
                  correcta.
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="alias">
                Tipo de NIT:
              </label>
              <Controller
                key={101}
                name="tipo_documento_nit"
                id="tipo_documento_nit"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    cacheOptions
                    options={[
                      {
                        value: '36',
                        label: 'NIT',
                        code: '36',
                        slug: 'nit',
                      },
                      {
                        value: '36.2',
                        label: 'NIT (DUI Homologado)',
                        code: '36.2',
                        slug: 'dui',
                      },
                    ]}
                    defaultValue={{
                      value: '36',
                      label: 'NIT',
                      code: '36',
                      slug: 'nit',
                    }}
                    placeholder="Tipo NIT"
                    onChange={(event) => {
                      const documentType = event?.code; // es code el codigo
                      configureInputMaskForNumeroNit(documentType);
                      field.onChange(event);
                    }}
                  />
                )}
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label">NIT:</label>
              {nitNumeroDocumento()}
              {errors.nitReceptor && (
                <div className="invalid-feedback">
                  {errors.nitReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label">Nombre del cliente:</label>
              <input
                className="form-control"
                name="nombreClienteReceptor"
                id="nombreClienteReceptor"
                type="text"
                placeholder="Digite el nombre del cliente"
                readOnly={blockingInput}
                style={
                  blockingInput
                    ? {
                        backgroundColor: '#e9ecef',
                        color: '#6c757d',
                        cursor: 'not-allowed',
                      }
                    : {}
                }
                {...register('nombreClienteReceptor')}
              />
              {errors.nombreClienteReceptor && (
                <div className="invalid-feedback">
                  {errors.nombreClienteReceptor?.message}
                </div>
              )}
            </div>

            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label">NRC:</label>
              <input
                className="form-control"
                name="nrcReceptor"
                id="nrcReceptor"
                type="number"
                placeholder="Digite el NRC"
                readOnly={blockingInput}
                style={
                  blockingInput
                    ? {
                        backgroundColor: '#e9ecef',
                        color: '#6c757d',
                        cursor: 'not-allowed',
                      }
                    : {}
                }
                {...register('nrcReceptor')}
              />
              {errors.nrcReceptor && (
                <div className="invalid-feedback">
                  {errors.nrcReceptor?.message}
                </div>
              )}
            </div>

            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label">Nombre comercial:</label>
              <input
                className="form-control"
                name="nombreComercialReceptor"
                id="nombreComercialReceptor"
                type="text"
                placeholder="Digite el nombre comercial"
                readOnly={blockingInput}
                style={
                  blockingInput
                    ? {
                        backgroundColor: '#e9ecef',
                        color: '#6c757d',
                        cursor: 'not-allowed',
                      }
                    : {}
                }
                {...register('nombreComercialReceptor')}
              />
              {errors.nombreComercialReceptor && (
                <div className="invalid-feedback">
                  {errors.nombreComercialReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label
                className="form-label"
                htmlFor="receptorTipoDocumentoValue"
              >
                Actividad económica:
              </label>

              {actividadesEconomicasCliente.length <= 0 && (
                <Controller
                  name="actividadEconomicaReceptor"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      loadOptions={loadActividadesEconomicas}
                      defaultOptions
                      cacheOptions
                      placeholder="Seleccione"
                      className={
                        errors.actividadEconomicaReceptor && 'is-invalid'
                      }
                    />
                  )}
                />
              )}
              {actividadesEconomicasCliente.length > 0 && (
                <Controller
                  name="actividadEconomicaReceptor"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={actividadesEconomicasCliente ?? []}
                      placeholder="Seleccione"
                      className={
                        errors.actividadEconomicaReceptor && 'is-invalid'
                      }
                    />
                  )}
                />
              )}
              {errors.actividadEconomicaReceptor && (
                <div className="invalid-feedback">
                  {errors.actividadEconomicaReceptor?.message}
                </div>
              )}
            </div>

            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="correoElectronicoReceptor">
                Correo electrónico:
              </label>
              <input
                className="form-control"
                name="correoElectronicoReceptor"
                id="correoElectronicoReceptor"
                type="email"
                placeholder="mail@example.com"
                {...register('correoElectronicoReceptor')}
              />
              {errors.correoElectronicoReceptor && (
                <div className="invalid-feedback">
                  {errors.correoElectronicoReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="telefonoReceptor">
                Teléfono:
              </label>
              <Controller
                name="telefonoReceptor"
                id="telefonoReceptor"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PhoneInput
                    placeholder="0000-0000"
                    country={'sv'}
                    enableSearch={false}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    inputStyle={{ width: '100%' }}
                  />
                )}
              />

              {errors.telefonoReceptor && (
                <div className="invalid-feedback">
                  {errors.telefonoReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="departamentoReceptor">
                Departamento:
              </label>
              <Controller
                name="departamentoReceptor"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    options={departamentos}
                    placeholder="Seleccione"
                    onChange={(event) => {
                      onChangeDepartamento(event);
                      field.onChange(event);
                    }}
                    isDisabled={blockingInput}
                    styles={
                      blockingInput
                        ? {
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: '#e9ecef',
                              color: '#6c757d',
                              cursor: 'not-allowed',
                            }),
                          }
                        : {}
                    }
                  />
                )}
              />
              {errors.departamentoReceptor && (
                <div className="invalid-feedback">
                  {errors.departamentoReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="municipioReceptor">
                Municipio:
              </label>

              <Controller
                name="municipioReceptor"
                control={control}
                render={({ field }) => (
                  <Select
                    isDisabled={!municipios.length || blockingInput}
                    options={municipios}
                    placeholder="Seleccione"
                    {...field}
                    styles={
                      blockingInput
                        ? {
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: '#e9ecef',
                              color: '#6c757d',
                              cursor: 'not-allowed',
                            }),
                          }
                        : {}
                    }
                  />
                )}
              />
              {errors.municipioReceptor && (
                <div className="invalid-feedback">
                  {errors.municipioReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <label className="form-label" htmlFor="complementoReceptor">
                Dirección:
              </label>
              <textarea
                className="form-control"
                name="complementoReceptor"
                id="complementoReceptor"
                rows={5}
                type="text"
                placeholder="Dirección: Calle, Avenida, Colonia, etc."
                {...register('complementoReceptor')}
              />
              {errors.complementoReceptor && (
                <div className="invalid-feedback">
                  {errors.complementoReceptor?.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default ReceptorForm;
