export function convertirNumeroALetras(numero) {
  const unidades = [
    '',
    'Un',
    'Dos',
    'Tres',
    'Cuatro',
    'Cinco',
    'Seis',
    'Siete',
    'Ocho',
    'Nueve',
  ];
  const decenas = [
    '',
    'Diez',
    'Veinte',
    'Treinta',
    'Cuarenta',
    'Cincuenta',
    'Sesenta',
    'Setenta',
    'Ochenta',
    'Noventa',
  ];
  const especiales = [
    'Diez',
    'Once',
    'Doce',
    'Trece',
    'Catorce',
    'Quince',
    'Dieciséis',
    'Diecisiete',
    'Dieciocho',
    'Diecinueve',
  ];
  const centenas = [
    '',
    'Ciento',
    'Doscientos',
    'Trescientos',
    'Cuatrocientos',
    'Quinientos',
    'Seiscientos',
    'Setecientos',
    'Ochocientos',
    'Novecientos',
  ];

  const convertirUnidades = (numero) => unidades[numero];
  const convertirDecenas = (numero) => {
    if (numero < 10) {
      return convertirUnidades(numero);
    } else if (numero >= 10 && numero < 20) {
      return especiales[numero - 10];
    } else {
      const unidad = numero % 10;
      const decena = Math.floor(numero / 10);
      return `${decenas[decena]}${
        unidad !== 0 ? ' y ' + convertirUnidades(unidad) : ''
      }`;
    }
  };

  const convertirCentenas = (numero) => {
    if (numero === 100) {
      return 'Cien';
    } else {
      const centena = Math.floor(numero / 100);
      const resto = numero % 100;
      return `${centenas[centena]}${
        resto !== 0 ? ' ' + convertirDecenas(resto) : ''
      }`;
    }
  };

  function convertirMiles(num) {
    var miles = Math.floor(num / 1000);
    var resto = num % 1000;
    return (
      (miles !== 0
        ? convertirCentenas(miles) + (miles === 1 ? ' Mil' : ' Mil')
        : '') + (resto !== 0 ? ' ' + convertirCentenas(resto) : '')
    );
  }

  const convertirMillones = (numero) => {
    if (numero >= 1000000) {
      const millones = Math.floor(numero / 1000000);
      const resto = numero % 1000000;
      return `${convertirMiles(millones)} ${
        millones === 1 ? ' Millón de' : ' Millones de'
      }${resto !== 0 ? ' ' + convertirMiles(resto) : ''}`;
    } else {
      return convertirCentenas(numero);
    }
  };

  const convertirParteEntera = (numero) => {
    if (numero === 0) {
      return 'Cero';
    } else if (numero === 1) {
      return 'Un dólar';
    } else if (numero < 10) {
      return convertirUnidades(numero);
    } else if (numero >= 10 && numero < 100) {
      return convertirDecenas(numero);
    } else if (numero >= 100 && numero < 1000) {
      return convertirCentenas(numero);
    } else if (numero >= 1000 && numero < 1000000) {
      return convertirMiles(numero);
    } else if (numero >= 1000000) {
      return convertirMillones(numero);
    }
  };

  const convertirParteDecimal = (decimal) => {
    const decimalNumber = Number(decimal.toFixed(2));
    const centavos = Math.floor(decimalNumber * 100);
    return centavos !== 0 ? `${centavos}/100` : '00/100';
  };

  const parteEntera = Math.floor(numero);
  const parteDecimal = numero - parteEntera;
  const resultadoParteEntera = convertirParteEntera(parteEntera);
  const resultadoParteDecimal = convertirParteDecimal(parteDecimal);

  if (resultadoParteDecimal !== '') {
    return `${resultadoParteEntera} ${
      resultadoParteEntera === 'Un dólar' ? '' : 'dólares'
    } ${resultadoParteDecimal}`;
  } else {
    return `${resultadoParteEntera} ${
      resultadoParteEntera === 'Un dólar' ? '' : 'dólares'
    }`;
  }
}

// Ejemplo de uso
// const numero = 1.13;
// const resultado = convertirNumeroALetras(numero);
