import React, { useCallback, useEffect, useState } from 'react';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import {
  getTributos,
  getUnidadesMedida,
  tiposProductos,
  tiposVenta,
} from '../../utils/FacturaV2';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Alert } from '../../../../../../services/alerts/alerts';
import { calcularIvaEnTotal } from '../../../../../../utils/Utils';
import BuscadorProductos from '../../../components/BuscadorProductos/BuscadorProductos';

const tributoDefault = {
  value: '20',
  label: 'Impuesto al Valor Agregado 13%',
  clear: false,
  mode: 'porcentaje',
  amount: [0.13],
  type: [1],
  measurements: [],
  calculate: false,
};

const TabProductosServicios = () => {
  const [tributosCalculados, setTributosCalculados] = useState([]);

  const { getValues, setValue, watch: watchCtx } = useFormContext();
  const validationSchema = Yup.object().shape({
    documentosRelacionados: Yup.array(),
    tipo: Yup.object().required('El tipo es requerido'),
    unidadMedida: Yup.object().required('La unidad de medida es requerida'),
    producto: Yup.string().required('El producto es requerido'),
    cantidad: Yup.number()
      .typeError('La cantidad debe ser un número')
      .required('La cantidad es requerida')
      .min(1, 'El precio debe ser mayor a 0.00'),
    precio: Yup.number()
      .typeError('El precio debe ser un número')
      .required('El precio es requerido')
      .min(0.01, 'El precio debe ser mayor a 0.00'),
    descuento: Yup.number().typeError('El descuento debe ser un número'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tipo: {
        value: 1,
        label: 'Bien',
      },
      unidadMedida: {
        value: 59,
        label: 'Unidad',
      },
      tipoVenta: {
        value: 1,
        label: 'Gravado',
      },
      producto: '',
      cantidad: '0',
      precio: '0.00',
      descuento: '0.00',
      tributo: [tributoDefault],
      documentoRelacionado: null,
    },
  };
  const {
    control,
    register,
    setValue: setValueForm,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [tributos, setTributos] = useState([]);

  const getDataSelect = useCallback(async () => {
    const [unidadesMedidaData, tributosData] = await Promise.all([
      getUnidadesMedida(),
      getTributos(),
    ]);

    setUnidadesMedida(unidadesMedidaData);
    setTributos(tributosData);
    try {
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los datos generales de panel de producto!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, []);

  useEffect(() => {
    async function getData() {
      await getDataSelect();
    }

    getData();
  }, [getDataSelect]);

  const styles = {
    multiValue: (base, state) => {
      return !state.data.clear ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return !state.data.clear
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return !state.data.clear ? { ...base, display: 'none' } : base;
    },
  };

  const calcularTributos = (tributo) => {
    if (tributo.mode === 'porcentaje') {
      const total = watch('precio') * watch('cantidad');
      const totalSinIva = total - calcularIvaEnTotal(total);
      return {
        id: tributo.value,
        nombre: tributo.label,
        type: tributo.type,
        amount: tributo.amount,
        mode: tributo.mode,
        valor: (totalSinIva - watch('descuento')) * tributo.amount[0],
      };
    }

    if (tributo.mode === 'libre') {
      return {
        id: tributo.value,
        nombre: tributo.label,
        mode: tributo.mode,
        type: tributo.type,
        amount: tributo.amount,
        valor: 0,
      };
    }

    if (tributo.mode === 'fijo') {
      return {
        id: tributo.value,
        nombre: tributo.label,
        mode: tributo.mode,
        type: tributo.type,
        amount: tributo.amount,
        valor: tributo.amount[0],
      };
    }

    if (tributo.mode === 'unidad') {
      if (tributo.measurements.length === 0) {
        return {
          id: tributo.value,
          nombre: tributo.label,
          mode: tributo.mode,
          type: tributo.type,
          amount: tributo.amount,
          valor: 0,
        };
      }

      const measurement = tributo.measurements.find(
        (item) => Number(item) === watch('unidadMedida')?.value,
      );

      if (!measurement) {
        Alert({
          title: 'Error',
          text: 'La unidad de medida seleccionada no tiene un valor asignado para el tributo seleccionado',
          icon: 'error',
        });
        setValueForm('tributo', [
          ...watch('tributo')?.filter((item) => item.value !== tributo.value),
        ]);
      }

      return {
        id: tributo.value,
        nombre: tributo.label,
        mode: tributo.mode,
        type: tributo.type,
        amount: tributo.amount,
        valor: watch('cantidad') * tributo.amount[0],
      };
    }
  };

  const handleCalcularTributos = () => {
    const tributosCalculadosData = watch('tributo')
      ?.filter((item) => item.value !== '20')
      .map((item) => {
        return calcularTributos(item);
      });

    setTributosCalculados(tributosCalculadosData);
  };

  const deleteDuplicateTributos = (tributos) => {
    return tributos.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.codigo === item.codigo),
    );
  };

  const onSubmit = () => {
    //Calculamos datos del item
    const cantidad = Number(watch('cantidad'));
    const precioUnitario = Number(watch('precio'));
    const descuento = Number(watch('descuento')) || 0;
    const totalVenta = precioUnitario * cantidad - descuento;

    const tipoDeVenta = watch('tipoVenta');
    //Si es venta gravada se calcula el iva
    const totalIva =
      tipoDeVenta.value === 1 ? calcularIvaEnTotal(totalVenta) : 0;

    //Calculamos los aportes del item al resumen
    const subtotal = totalVenta; // + ?;
    const totalImpuestos = tributosCalculados.reduce(
      (acc, item) => acc + item.valor,
      0,
    );
    const montoTotalOperacion = subtotal + totalImpuestos; // monto total de la operación es el subtotal + los impuestos
    const totalPagar = montoTotalOperacion; //iria ventas no gravadas pero eso no se usa en este tab

    //Obtenemos la data el item que se agregará al cuerpo del documento
    const itemCuerpoDocumento = {
      numItem: null,
      tipoItem: watch('tipo')?.value,
      numeroDocumento: watch('documentoRelacionado')?.value,
      cantidad: cantidad,
      codigo: new Date().getTime(),
      codTributo: null,
      uniMedida: watch('unidadMedida')?.value,
      descripcion: watch('producto'),
      precioUni: precioUnitario,
      montoDescu: descuento,
      ventaNoSuj: tipoDeVenta?.value === 3 ? totalVenta : 0,
      ventaExenta: tipoDeVenta?.value === 2 ? totalVenta : 0,
      ventaGravada: tipoDeVenta?.value === 1 ? totalVenta : 0,
      tributos:
        tributosCalculados.length > 0
          ? tributosCalculados.map((item) => item.id)
          : null,
      tributosData: tributosCalculados, //Info de tributos
      itemResumenDocumento: null, // Info de lo que aporta al resumen este item
      noGravado: 0,
      ivaItem: totalIva,
    };

    const resumenDocumento = getValues('resumen') || {};
    const tributosItem = tributosCalculados.map((item) => ({
      codigo: item.id,
      descripcion: item.nombre,
      valor: item.valor,
      type: item.type,
      amount: item.amount,
      mode: item.mode,
    }));
    const tributosResumen = deleteDuplicateTributos([
      ...(resumenDocumento.tributos ?? []),
      ...tributosItem,
    ]);

    const itemResumenDocumento = {
      tributos: tributosItem,
      subTotal: subtotal, // el item aporta la venta al subtotal
      totalDescu: descuento, // el item aporta el descuento al total de descuentos
      totalNoSuj: tipoDeVenta.value === 3 ? totalVenta : 0,
      totalExenta: watch('tipoVenta')?.value === 2 ? totalVenta : 0,
      totalGravada: watch('tipoVenta')?.value === 1 ? totalVenta : 0,
      montoTotalOperacion: montoTotalOperacion,
      totalPagar: totalPagar,
      ivaItem: totalIva,
      subTotalVentas: subtotal,
      reteRenta: 0,
      ivaRete1: 0,
      totalNoGravado: 0,
    };
    //Enlazamos los datos del item  a los datos que aporta el item al resumen
    itemCuerpoDocumento.itemResumenDocumento = itemResumenDocumento;

    //Guardamos el item
    const cuerpoDocumento = getValues('cuerpoDocumento') || [];
    setValue('cuerpoDocumento', [...cuerpoDocumento, itemCuerpoDocumento]);

    //Guardamos el resumen
    setValue('resumen', {
      tributos: tributosResumen,
      subTotal:
        (resumenDocumento?.subTotal ?? 0) + itemResumenDocumento.subTotal,

      totalDescu:
        (resumenDocumento?.totalDescu ?? 0) + itemResumenDocumento.totalDescu, // el item aporta el descuento al total de descuentos
      totalNoSuj:
        (resumenDocumento?.totalNoSuj ?? 0) + itemResumenDocumento.totalNoSuj,
      totalExenta:
        (resumenDocumento?.totalExenta ?? 0) + itemResumenDocumento.totalExenta,
      totalGravada:
        (resumenDocumento?.totalGravada ?? 0) +
        itemResumenDocumento.totalGravada,
      subTotalVentas:
        (resumenDocumento?.subTotalVentas ?? 0) +
        itemResumenDocumento.subTotalVentas,
      montoTotalOperacion:
        (resumenDocumento?.montoTotalOperacion ?? 0) +
        itemResumenDocumento.montoTotalOperacion,
      totalPagar:
        (resumenDocumento?.totalPagar ?? 0) + itemResumenDocumento.totalPagar,
      totalIva:
        (resumenDocumento?.totalIva ?? 0) + itemResumenDocumento.ivaItem,
      reteRenta:
        (resumenDocumento?.reteRenta ?? 0) + itemResumenDocumento.reteRenta,
      ivaRete1:
        (resumenDocumento?.ivaRete1 ?? 0) + itemResumenDocumento.ivaRete1,
      totalNoGravado:
        (resumenDocumento?.totalNoGravado ?? 0) +
        itemResumenDocumento.totalNoGravado,
    });

    reset();
  };

  const onSelectProduct = (data) => {
    const unidadMedida = data.unidad_medida.code
      ? {
          label: data.unidad_medida.value,
          value: data.unidad_medida.code,
        }
      : null;

    setValueForm('producto', data.nombre_producto);
    setValueForm('precio', data.precio_iva); // para factura precio con iva
    setValueForm('cantidad', 1);
    setValueForm('unidadMedida', unidadMedida);
  };
  return (
    <>
      <CardWrapperTools
        title="Adición detalle de DTE de productos y servicios"
        tools={
          <>
            <BuscadorProductos
              onSelectProduct={onSelectProduct}
              showCleanButton={false}
            />
          </>
        }
        footer={
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-lg"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              <span className="fas fa-plus me-1"></span>
              Agregar
            </button>
          </div>
        }
      >
        <div className="container-fluid">
          <div className="row g-3 mb-3">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="tipo">
                Tipo:
              </label>
              <Controller
                name="tipo"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    options={tiposProductos}
                    {...field}
                  />
                )}
              />
              {errors.tipo && (
                <div className="invalid-feedback">{errors.tipo?.message}</div>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="unidadMedida">
                Unidad de medida:
              </label>
              <Controller
                name="unidadMedida"
                control={control}
                render={({ field }) => (
                  <Select options={unidadesMedida} {...field} />
                )}
              />
              {errors.unidadMedida && (
                <div className="invalid-feedback">
                  {errors.unidadMedida?.message}
                </div>
              )}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="producto">
                Producto/Servicio:
              </label>
              <input
                className="form-control"
                name="producto"
                id="producto"
                type="text"
                placeholder="Digite el nombre del producto o servicio"
                {...register('producto')}
                onFocus={(e) => e.target.select()}
              />
              {errors.producto && (
                <div className="invalid-feedback">
                  {errors.producto?.message}
                </div>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="cantidad">
                Cantidad:
              </label>
              <input
                className="form-control"
                name="cantidad"
                id="cantidad"
                type="number"
                placeholder="Digite la cantidad"
                {...register('cantidad')}
                onChange={(e) => {
                  setValueForm('cantidad', e.target.value);
                  handleCalcularTributos();
                }}
                onWheel={(e) => e.target.blur()}
                onFocus={(e) => e.target.select()}
              />
              {errors.cantidad && (
                <div className="invalid-feedback">
                  {errors.cantidad?.message}
                </div>
              )}
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="precio">
                Precio:
              </label>

              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  name="precio"
                  id="precio"
                  type="number"
                  {...register('precio')}
                  onChange={(e) => {
                    setValueForm('precio', e.target.value);
                    handleCalcularTributos();
                  }}
                  onWheel={(e) => e.target.blur()}
                  onFocus={(e) => e.target.select()}
                  onBlur={(e) => {
                    setValueForm(
                      'precio',
                      parseFloat(e.target.value).toFixed(2),
                    );
                    if (e.target.value === '') {
                      setValueForm('precio', parseFloat(0).toFixed(2));
                    }
                  }}
                  placeholder="Digite el precio"
                />
              </div>
              {errors.precio && (
                <div className="invalid-feedback">{errors.precio?.message}</div>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="tipoVenta">
                Tipo venta:
              </label>
              <Controller
                name="tipoVenta"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    options={tiposVenta}
                    placeholder="Seleccione el tipo de venta"
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);

                      if (event.value === 1) {
                        if (watch('tributo')?.length > 0) {
                          setValueForm('tributo', [
                            tributoDefault,
                            ...watch('tributo')?.filter(
                              (item) => item.value !== '20',
                            ),
                          ]);
                          handleCalcularTributos();
                          return;
                        }

                        setValueForm('tributo', [tributoDefault]);
                        handleCalcularTributos();
                        return;
                      }

                      if (watch('tributo')?.length > 0) {
                        setValueForm('tributo', [
                          ...watch('tributo')?.filter(
                            (item) => item.value !== '20',
                          ),
                        ]);
                        handleCalcularTributos();
                        return;
                      }
                      handleCalcularTributos();
                      setValueForm('tributo', []);
                    }}
                  />
                )}
              />
              {errors.tipoVenta && (
                <div className="invalid-feedback">
                  {errors.tipoVenta?.message}
                </div>
              )}
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="descuento">
                Descuento:
              </label>

              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  name="descuento"
                  id="descuento"
                  type="number"
                  placeholder="Digite el descuento"
                  {...register('descuento')}
                  onChange={(e) => {
                    setValueForm('descuento', e.target.value);
                    handleCalcularTributos();
                  }}
                  onWheel={(e) => e.target.blur()}
                  onFocus={(e) => e.target.select()}
                  onBlur={(e) => {
                    setValueForm(
                      'descuento',
                      parseFloat(e.target.value).toFixed(2),
                    );
                    handleCalcularTributos();
                    if (e.target.value === '') {
                      setValueForm('descuento', parseFloat(0).toFixed(2));
                      handleCalcularTributos();
                    }
                  }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="tributo">
                Información de los tributos:
              </label>
              <Controller
                name="tributo"
                control={control}
                render={({ field }) => (
                  <Select
                    className="basic-multi-select"
                    placeholder="Seleccione los tributos"
                    {...field}
                    isMulti
                    options={
                      watch('tipoVenta')?.value === 1
                        ? tributos
                        : tributos.filter((item) => item.value !== '20')
                    }
                    isDisabled={
                      !watch('tipoVenta')?.value ||
                      !watch('unidadMedida')?.value
                    }
                    onChange={(event) => {
                      field.onChange(event);
                      handleCalcularTributos();
                      if (watch('tipoVenta')?.value === 1) {
                        setValueForm('tributo', [
                          tributoDefault,
                          ...watch('tributo').filter(
                            (item) => item.value !== '20',
                          ),
                        ]);
                        handleCalcularTributos();
                      }
                    }}
                    styles={styles}
                    isClearable={watch('tributo')?.some((item) => item.clear)}
                  />
                )}
              />
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12 text-center">
              <label htmlFor="">SubTotal:</label>
              <h3 className="text-primary">
                $
                {watch('cantidad') > 0 && watch('precio') > 0
                  ? (
                      watch('precio') * watch('cantidad') -
                      watch('descuento')
                    ).toFixed(2)
                  : '0.00'}
              </h3>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 text-center">
              <label htmlFor="">Total:</label>
              <h3 className="text-primary">
                $
                {watch('cantidad') > 0 && watch('precio') > 0
                  ? (
                      watch('precio') * watch('cantidad') -
                      watch('descuento') +
                      tributosCalculados
                        .filter((item) => item.mode !== 'libre')
                        .filter((itemTipo) => itemTipo?.type.includes(1))
                        .reduce((acc, item) => acc + item.valor, 0)
                    ).toFixed(2)
                  : '0.00'}
              </h3>
            </div>
            {watch('tributo').length > 0 &&
            watch('cantidad') > 0 &&
            watch('precio') > 0 &&
            getValues('documentoRelacionado')?.tipoDocumentoRel !== '07' ? (
              <div className="col-lg-2">
                <div className="table-responsive scrollbar">
                  <table className="table table-hover table-striped overflow-hidden">
                    <tbody>
                      {tributosCalculados
                        ?.filter((item) => item.mode !== 'fijo')
                        .filter(
                          (itemTipo) =>
                            itemTipo?.type.includes(1) &&
                            !itemTipo.mode === 'libre',
                        )
                        .map((item) => (
                          <tr key={item.id}>
                            <td>{item.nombre}</td>
                            <td>${parseFloat(item.valor).toFixed(2)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}

            {watchCtx('documentoRelacionado') &&
            watchCtx('documentoRelacionado').length > 0 ? (
              <div className="col-lg-4">
                <label className="form-label" htmlFor="documentoRelacionado">
                  Documento Relacionado:
                </label>
                <Controller
                  name="documentoRelacionado"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable
                      value={field.value}
                      options={watchCtx('documentoRelacionado').map((item) => ({
                        value: item.numeroDocumentoRel,
                        label: item.numeroDocumentoRel,
                      }))}
                      placeholder="Seleccione el documento relacionado"
                      {...field}
                    />
                  )}
                />
                {errors.documentoRelacionado && (
                  <div className="invalid-feedback">
                    {errors.documentoRelacionado?.message}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default TabProductosServicios;
