import React, { useEffect } from 'react';
import { get, post } from '../../services/http/http.service';
import {
  destroyItemSession,
  getSession,
  setSession,
} from '../../services/encrytion/encryption.service';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { LoaderComponent } from 'react-fullscreen-loader';
import 'react-fullscreen-loader/src/loader.css';

const clearSession = () => {
  destroyItemSession('clientes');
  destroyItemSession('modulos');
  destroyItemSession('token');
  destroyItemSession('user');
  destroyItemSession('email');
  destroyItemSession('rol');
  destroyItemSession('alias');
  destroyItemSession('selectClient');
};

const SelectionClient = () => {
  const navigate = useNavigate();

  const [clientes, setClientes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    async function getClientesUser() {
      try {
        setLoading(true);
        const { data } = await get('clientes/usuarios?type=1,3');
        setClientes(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        clearSession();
        navigate('/login');
        enqueueSnackbar(
          'Se ha producido un error al momento de obtener los clientes!',
          {
            variant: 'error',
            preventDuplicate: true,
          },
        );
      }
    }

    if (!getSession('selectClient')) {
      navigate('/login');
    } else {
      getClientesUser();
    }
  }, [navigate]);

  async function refreshToken(dataCliente) {
    try {
      setLoading(true);
      const { data } = await post('auth/refresh-token', {
        clienteId: dataCliente.id,
      });

      const clienteSeleccionado = data.payload.clientes?.find(
        (cliente) => cliente?.id === dataCliente.id,
      );

      setSession('token', data.access_token);
      setSession('clienteSeleccionado', dataCliente);
      setSession('sucursal', clienteSeleccionado?.sucursal || null);
      setLoading(false);
      destroyItemSession('selectClient');
      window.location.href = '/';
    } catch (error) {
      setLoading(false);
      clearSession();
      navigate('/login');
      enqueueSnackbar(
        'Se ha producido un error al momento de seleccionar cliente!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }
  return (
    <main className="main" id="top">
      <LoaderComponent loading={loading} loadingColor="blue" />
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="../../../assets/img/bg-shape.png"
              alt="shape"
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="../../../assets/img/shape-1.png"
              alt="shape"
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-md-12 flex-center">
                    <div className="p-4">
                      <div className="text-center text-md-start">
                        <h4 className="mb-0"> Seleccione un cliente:</h4>
                        <p className="mb-4">
                          Debe seleccionar un cliente para poder ingresar al
                          sistema.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-12">
                          <div className="table-responsive">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                              <thead>
                                <tr>
                                  <th scope="col" className="text-center">
                                    Nombre
                                  </th>
                                  <th scope="col" className="text-center">
                                    NIT
                                  </th>
                                  <th scope="col" className="text-center">
                                    NRC
                                  </th>
                                  <th scope="col" className="text-center">
                                    Teléfono
                                  </th>
                                  <th scope="col" className="text-center">
                                    Opciones
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {clientes.map((cliente) => {
                                  return (
                                    <tr key={cliente.id}>
                                      <td>{cliente.nombre}</td>
                                      <td className="text-center">
                                        {cliente.nit}
                                      </td>
                                      <td className="text-center">
                                        {cliente.nrc}
                                      </td>
                                      <td className="text-center">
                                        {cliente.telefono}
                                      </td>
                                      <td className="text-center">
                                        <button
                                          className="btn btn-primary"
                                          onClick={refreshToken.bind(
                                            this,
                                            cliente,
                                          )}
                                        >
                                          Seleccionar
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <button
                            className="btn btn-light mt-4"
                            onClick={() => {
                              clearSession();
                              navigate('/login');
                            }}
                          >
                            Volver a inicio de sesión
                            <span className="d-inline-block ms-1">→</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SelectionClient;
