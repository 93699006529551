import React, { useRef } from 'react';

import { TableFilterV2Services } from '../../../../../Components/Common/Tables/TableFilterV2.service';
import { getSession } from '../../../../../services/encrytion/encryption.service';
import { CardWrapper } from '../../../../../Components/Common/Cards';
export default function ClientesTable({ onSelect, contribuyente }) {
  const tablaRef = useRef();

  const clienteSeleccionado = getSession('clienteSeleccionado');

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.nombre_cliente,
    },
    {
      name: 'Nombre Comercial',
      selector: (row) => row.nombre_comercial,
    },
    {
      name: 'NIT/Documento',
      selector: (row) =>
        row.contribuyente === true ? row.nit : row.numero_documento,
    },
    {
      name: 'NRC',
      selector: (row) => (row.nrc ? row.nrc : 'N/A'),
    },
    {
      name: 'Correo',
      selector: (row) => row.correo,
    },

    {
      name: 'Contribuyente',
      selector: (row) =>
        row.contribuyente ? (
          <span className="badge rounded-pill badge-subtle-success">Si</span>
        ) : (
          <span className="badge rounded-pill badge-subtle-danger">No</span>
        ),
    },
    {
      name: 'Acciones',
      selector: (row) => (
        <>
          <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Seleccionar"
            onClick={() => handleSelect(row)}
          >
            Seleccionar
          </button>
        </>
      ),
    },
  ];
  const handleSelect = (row) => {
    onSelect(row);
  };

  const customFilterData = (data) => {
    if (data) return data.filter((item) => item.activo === true);
    else return data;
  };
  let url = `mis-clientes?params=${clienteSeleccionado.id}`;
  if (typeof contribuyente !== 'undefined' && contribuyente !== null) {
    url = `mis-clientes?params=${clienteSeleccionado.id}&contribuyente=${contribuyente}`;
  }
  return (
    <CardWrapper title="Listado de mis clientes">
      <TableFilterV2Services
        columns={columns}
        endpoint={url}
        showPagesUrl={true}
        ref={tablaRef}
        customFilterData={customFilterData}
      />
    </CardWrapper>
  );
}
