import React, { useRef } from 'react';
import { CardWrapper } from '../../../Components/Common/Cards';
import { TableFilterV2 } from '../../../Components/Common/Tables/TableFilterV2';

import { useNavigate } from 'react-router-dom';

export default function Clientes() {
  const tablaRef = useRef();
  const navigate = useNavigate();

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
    },
    {
      name: 'Nombre comercial',
      selector: (row) => row.nombre_comercial,
    },
    {
      name: 'NRC',
      selector: (row) => row.nrc,
    },
    {
      name: 'NIT',
      selector: (row) => row.nit,
    },
    {
      name: 'Acciones',
      selector: (row) => (
        <>
          <button
            className="btn btn-link p-0"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Edit"
            onClick={() => handleEdit(row?._id)}
          >
            <span className="text-500 fas fa-edit"></span>
          </button>
        </>
      ),
      defaultFlex: 1,
      maxWidth: 200,
    },
  ];
  function handleEdit(id) {
    navigate(`/admin/clientes/detalles/${id}`);
  }

  return (
    <>
      <CardWrapper title="Listado de clientes" urlForm="/admin/clientes/create">
        <TableFilterV2 columns={columns} endpoint="clientes" ref={tablaRef} />
      </CardWrapper>
    </>
  );
}
