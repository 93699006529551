import "@inovua/reactdatagrid-community/index.css";
import { CardWrapper } from "../../../../../Components/Common/Cards";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const CondicionContadoForm = ({ formasDePago, addPago }) => {
  const notSelectedValue = null;
  const validationSchema = Yup.object().shape({
    formaPago: Yup.object()
      .notOneOf([notSelectedValue])
      .required("La forma de pago es obligatoria"),
    monto: Yup.number().positive().required("El monto es obligatoria"),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      formaPago: formasDePago.find((x) => x.value === "01"),
      monto: 0,
      numeroDocumento: "",
    },
  };

  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    const pago = {
      formaPago: data.formaPago,
      monto: data.monto,
      documento: data.numeroDocumento,
      plazo: null,
      periodo: null,
    };

    addPago(pago);
    setValue("formaPago", formasDePago.find((x) => x.value === "01"));
    setValue("monto", 0);
    setValue("numeroDocumento", "");

  };

  return (
    <>
      <CardWrapper title="Contado">
        <div className="row">
          <div className="col-3">
            <label className="form-label" htmlFor="exampleFormControlTextarea1">
              Forma pago:
            </label>
            <Controller
              name="formaPago"
              control={control}
              render={({ field }) => (
                <Select {...field} options={formasDePago} />
              )}
            />
            {errors.formaPago && (
              <div className="invalid-feedback">
                {errors.formaPago?.message}
              </div>
            )}
          </div>
          <div className="col-2">
            <label className="form-label" htmlFor="monto">
              Monto:
            </label>
            <input
              className="form-control"
              name="monto"
              id="monto"
              type="number"
              placeholder="0.00"
              {...register('monto')}
              onWheel={(event) => event.target.blur()}
              onClick={(event) => event.target.select()}
            />
            {errors.monto && (
              <div className="invalid-feedback">{errors.monto?.message}</div>
            )}
          </div>
          {watch('formaPago')?.value === '01' ? (
            <></>
          ) : (
            <div className="col-3">
              <label className="form-label" htmlFor="numeroDocumento">
                N° Documento;
              </label>
              <input
                className="form-control"
                name="numeroDocumento"
                id="numeroDocumento"
                type="text"
                placeholder="Número"
                {...register('numeroDocumento')}
              />
              {errors.numeroDocumento && (
                <div className="invalid-feedback">
                  {errors.numeroDocumento?.message}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="row">
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-lg btn-primary"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              <span className="fas fa-plus"></span>
              <span className=""> Añadir pago</span>
            </button>
          </div>
        </div>
      </CardWrapper>
    </>
  );
};

export { CondicionContadoForm };
