// import axios from 'axios';

export const createContribuyente = async (contribuyente) => {
  // make a mock request and a mock response
  const response = {
    data: {},
    status: 200,
  };

  //   const API_URL = 'http://localhost:3000';
  //   const response = await axios.post(`${API_URL}/contribuyentes`, contribuyente);
  return response;
};
