import React, { useCallback } from 'react';
import InformationSystem from './Components/InformationSystem';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoaderComponent } from 'react-fullscreen-loader';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { Alert } from '../../services/alerts/alerts';
import { post } from '../../services/http/http.service';
const ForgotPassword = () => {
  const [loading, setLoading] = React.useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('El correo ingresado no es válido')
      .required('Correo electrónico es requerido'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const ButtonRecapcha = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        Alert({
          title: 'Error de verificación de seguridad',
          text: 'Por favor, vuelva a cargar la página',
          icon: 'error',
        });
        return;
      }

      const token = await executeRecaptcha('yourAction');

      try {
        setLoading(true);
        const login = await post(
          'auth/recovery-password',
          {
            email: watch('email'),
          },
          {
            'x-recaptcha-authorization': `reCAPTCHA ${token}`,
          },
        );

        const { data } = login;

        if (data) {
          Alert({
            title: 'Enlace de reinicio enviado',
            icon: 'success',
            text: 'Se ha enviado un enlace de reinicio a su correo electrónico',
          });
          setLoading(false);

          navigate('/login');
        }
      } catch (e) {
        Alert({
          title: 'Error al iniciar sesión',
          text: 'No se pudo iniciar sesión, por favor intente de nuevo, si el problema persiste contacte al administrador.',
          icon: 'error',
        });
        setLoading(false);
      }
    }, [executeRecaptcha, navigate]);

    return (
      <button
        className="btn btn-primary d-block w-100 mt-3"
        type="button"
        name="forgot-password"
        onClick={handleSubmit(handleReCaptchaVerify)}
      >
        Enviar enlace de reinicio
      </button>
    );
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPCHA_KEY}>
      <main className="main" id="top">
        <LoaderComponent loading={loading} loadingColor="blue" />
        <div className="container-fluid">
          <div className="row min-vh-100 flex-center g-0">
            <div className="col-lg-8 col-xxl-5 py-3 position-relative">
              <img
                className="bg-auth-circle-shape"
                src="../../../assets/img/bg-shape.png"
                alt="shape"
                width={250}
              />
              <img
                className="bg-auth-circle-shape-2"
                src="../../../assets/img/shape-1.png"
                alt="shape"
                width={150}
              />
              <div className="card overflow-hidden z-1">
                <div className="card-body p-0">
                  <div className="row g-0 h-100">
                    <InformationSystem />

                    <div className="col-md-7 d-flex flex-center">
                      <div className="p-4 p-md-5 flex-grow-1">
                        <div className="text-center text-md-start">
                          <h4 className="mb-0"> ¿Olvidó su contraseña?</h4>
                          <p className="mb-4">
                            Ingrese su correo electrónico y le enviaremos un
                            enlace de reinicio.
                          </p>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-sm-8 col-md">
                            <form className="mb-3">
                              <input
                                className="form-control"
                                type="email"
                                placeholder="Correo electrónico"
                                {...register('email')}
                              />
                              {errors.email && (
                                <div className="invalid-feedback">
                                  {errors.email.message}
                                </div>
                              )}
                              <div className="mb-3" />
                              <ButtonRecapcha />
                            </form>
                            <Link className="fs--1 text-600" to="/login">
                              Volver a inicio de sesión
                              <span className="d-inline-block ms-1">→</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </GoogleReCaptchaProvider>
  );
};

export default ForgotPassword;
