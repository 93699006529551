import CryptoJS from 'crypto-js';
import SecureStorage from 'secure-web-storage';
//Clase para encriptar

// se crea la instancia de la clase SecureStore y se pasa el argumento de la sessionStore que es lo que queremos encriptar
const secure = new SecureStorage(localStorage, {
  // se configura el tipo de hash a utilizar y se coloca la llave de encriptacion
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, process.env.REACT_APP_KEY);

    return key.toString();
  },
  //Funcion para encriptar la session
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, process.env.REACT_APP_KEY);

    data = data.toString();

    return data;
  },
  //funcion para desencriptar la sesion
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, process.env.REACT_APP_KEY);

    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  },
});
//funcion para encriptar texto
export const encrypt = (text) => {
  const encrypt_text = CryptoJS.AES.encrypt(text, process.env.REACT_APP_KEY);
  return encrypt_text;
}; //!NO SE USA POR AHORA
//funcion para desecriptar texto
export const decrypt = (text) => {
  const bytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_KEY);
  const decrypt_text = bytes.toString(CryptoJS.enc.Utf8);
  return decrypt_text;
}; //!NO SE USA POR AHORA

//funcion para crear variables de session encriptadas
export const setSession = (key, value) => {
  secure.setItem(key, value);
};

//funcion para obtener la variable de session que fue encripta pero ya en texto plano
export const getSession = (key) => {
  return secure.getItem(key);
};

//Funcion para destruir variable de sesion especifica
export const destroyItemSession = (key) => {
  secure.removeItem(key);
};

//Funcion para borrar todas las variables de seccion
export const clearSession = () => {
  secure.clear();
};
