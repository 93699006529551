/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ModalWrapper from '../../../Components/Common/Modals/Modal';

import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';

import AsyncSelect from 'react-select/async';
import {
  Alert,
  AlertQuestionYesNo,
  loadingReload,
} from '../../../services/alerts/alerts';
import { get, patch, post } from '../../../services/http/http.service';
import { Controller, useForm } from 'react-hook-form';
import { getSession } from '../../../services/encrytion/encryption.service';

export default function ActividadEconomicaCliente({ id, readOnly = false }) {
  const { register, handleSubmit, control, reset } = useForm();
  useEffect(() => {
    if (id) {
      loadActividadEconomicaCliente(id);
    } else {
      const { id } = getSession('clienteSeleccionado') ?? {};
      loadActividadEconomicaCliente(id);
    }
  }, [id]);
  const modalRef = useRef();

  const [actividadEconomica, setActividadEconomica] = useState([]);

  const handleOpenModal = () => {
    if (modalRef.current) {
      reset();
      modalRef.current.toggle();
    }
  };
  const handleSaveActividadEconomica = async (datos) => {
    try {
      loadingReload(true, 'Guardando Actividad Economica');

      const payload = {
        clienteId: id,
        code: datos.actividadEconomica.value,
        value: datos.actividadEconomica.label,
        default: datos.predeterminado,
      };
      const { data } = await post('/clientes/actividad-economica', payload);

      if (data._id) {
        loadingReload(false, null);
        loadActividadEconomicaCliente(id);
        modalRef.current.toggle();
        Alert({
          title: 'Guardado',
          text: 'Se guardo la actividad economica',
          icon: 'success',
        });
      }
      return data;
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo guardar la actividad economica',
        icon: 'error',
      });
    }
  };
  const loadActividadEconomica = async (inputValue) => {
    try {
      const { data } = await get(
        `/generales/actividades-economicas?search=${inputValue}`,
      );
      const options = data.map((item) => ({
        value: item.code,
        label: item.value,
      }));
      return options;
    } catch (error) {
      Alert('error', 'Error', 'No se pudo cargar las actividades economicas');
    }
  };

  const loadActividadEconomicaCliente = async (id) => {
    try {
      if (id) {
        const { data } = await get(`clientes/actividad-economica/${id}`);
        setActividadEconomica(data);
      }
    } catch (error) {
      Alert(
        'error',
        'Error',
        'No se pudo cargar las actividades economicas del cliente',
      );
    }
  };

  const renderizarEtiqueta = (esPredeterminado) => {
    return esPredeterminado ? (
      <span className="badge rounded-pill badge-subtle-primary">Si</span>
    ) : (
      <span className="badge rounded-pill badge-subtle-warning">No</span>
    );
  };
  const handleStatus = async (idStatus, activo) => {
    try {
      const question = await AlertQuestionYesNo({
        title: 'Cambio de estado',
        text: '¿Esta seguro de cambiar el estado?',
        icon: 'warning',
      });
      if (!question) return;
      loadingReload(true, 'Desactivando DTE');
      const data = await patch(
        `clientes/actividad-economica/status/${idStatus}?status=${!activo}`,
      );
      if (data) {
        loadingReload(false, null);
        loadActividadEconomicaCliente(id);
        return Alert({
          title: 'Desactivado',
          text: 'Se desactivo el DTE',
          icon: 'success',
        });
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo cambiar el estado',
        icon: 'error',
      });
    }
  };
  return (
    <div>
      <CardWrapperTools
        title={
          readOnly
            ? 'Detalle de sus actividades económicas'
            : 'Actividades Economicas'
        }
        tools={
          !readOnly && (
            <div className="d-flex align-items-center">
              <div className="me-3">
                <div className="icon-shape icon-sm bg-body-tertiary text-center rounded">
                  <button className="btn btn-primary" onClick={handleOpenModal}>
                    <i
                      className="fa fa-plus"
                      style={{ marginRight: '3px' }}
                    ></i>
                    Asignar Actividad Economica
                  </button>
                </div>
              </div>
            </div>
          )
        }
      >
        <div className="row g-3">
          <div className="table-responsive scrollbar">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">Código</th>
                  <th className="text-center">Nombre</th>
                  <th className="text-center">Predeterminado</th>
                  <th className="text-center">Activo</th>
                  {!readOnly && <th className="text-center">Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {actividadEconomica.map((dato, index) => (
                  <tr key={index}>
                    <td>{dato.code}</td>
                    <td>{dato.value}</td>
                    <td className="text-center">
                      {renderizarEtiqueta(dato.default)}
                    </td>
                    <td>
                      {dato.activo ? (
                        <span className="badge rounded-pill badge-subtle-success">
                          Activo
                        </span>
                      ) : (
                        <span className="badge rounded-pill badge-subtle-danger">
                          Inactivo
                        </span>
                      )}
                    </td>
                    {!readOnly && (
                      <td>
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleStatus(dato.id, dato.activo)}
                          >
                            <span className="far fa-trash-alt"></span>
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </CardWrapperTools>
      <ModalWrapper ref={modalRef}>
        <CardWrapperTools
          title="Asignacion de Actividad economicas"
          footer={
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={handleSubmit(handleSaveActividadEconomica)}
                  >
                    <span className="far fa-paper-plane"></span> Guardar
                  </button>
                </div>
              </div>
            </div>
          }
        >
          <div className="row g-5">
            <div className="col-lg-6">
              <label className="form-label">Actividad Economica:</label>
              <div className="form-group">
                <Controller
                  name="actividadEconomica"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      cacheOptions
                      loadOptions={loadActividadEconomica}
                      defaultOptions
                      placeholder="Seleccione una actividad economica"
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <label className="form-label">Predeterminado:</label>
              <div className="form-group">
                <div className="form-check form-switch">
                  <input
                    {...register('predeterminado')}
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Si
                  </label>
                </div>
              </div>
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </div>
  );
}
