import React from 'react';

const CardWrapperTools = ({
  children,
  title,
  col = 12,
  footer,
  tools,
  marginTop,
  hideHeader = false, // Nuevo prop para ocultar el encabezado
}) => {
  return (
    <>
      <div className="row g-0 ">
        {/* Condición para mostrar u ocultar el encabezado */}
        {!hideHeader && <div className={`col-lg-${col} pe-lg-2`}></div>}
        <div className="card mb-3 border border-1">
          {/* Condición para mostrar u ocultar el encabezado */}
          {!hideHeader && (
            <div className="card-header">
              <div className="row justify-content-between align-items-center">
                <div className="col-md">
                  <h5 className="mb-2 mb-md-0">{title}</h5>
                </div>
                <div className="col-auto">
                  {tools && (
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <div className="icon-shape icon-sm bg-body-tertiary text-center rounded">
                            {tools}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="card-body bg-body-tertiary">{children}</div>
          {footer && <div className="card-footer">{footer}</div>}
        </div>
      </div>
    </>
  );
};

export default CardWrapperTools;
