import React from 'react';
import ModalOpcionesAnulacion from './modales/ModalOpcionesAnulacion';
import { getDteType } from '../../../../../utils/dte/dte.util';
import { validarFechas } from '../utils/consulta.util';
import { Alert } from '../../../../../services/alerts/alerts';
import ModalDetalleInvalidacion from './modales/ModalDetalleInvalidacion';

const Anulacion = ({
  dte,
  refModal,
  notaCredito,
  notaDebito,
  invalidado,
  refModalDetalle,
}) => {
  return (
    <>
      {invalidado === false ? (
        <button
          className="dropdown-item"
          type="button"
          onClick={() => {
            if (notaCredito === false || !notaDebito === false) {
              Alert({
                title: 'Advertencia',
                text: 'No se puede invalidar el documento porque hay documentos relacionados que deben ser invalidados primero.',
                icon: 'warning',
              });

              return 0;
            }
            const openModal = validarFechas(
              dte?.respuesta_mh,
              dte?.datos_dte?.identificacion?.tipoDte,
            );

            if (openModal) {
              refModal.current.openModal();
            }
          }}
        >
          Invalidar DTE
        </button>
      ) : (
        <button
          className="dropdown-item"
          onClick={() => refModalDetalle.current.openModal()}
        >
          Ver detalles de invalidación
        </button>
      )}

      <ModalOpcionesAnulacion
        ref={refModal}
        tipoDte={getDteType(dte?.datos_dte?.identificacion?.tipoDte)}
        codigoGeneracion={dte?.datos_dte?.identificacion?.codigoGeneracion}
        dte={dte}
      />

      <ModalDetalleInvalidacion dte={dte} ref={refModalDetalle} />
    </>
  );
};

export default Anulacion;
