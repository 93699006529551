import React from 'react';
import { Link } from 'react-router-dom';
import { getSession } from '../../../../services/encrytion/encryption.service';
import { CollapseNav } from '../../../../utils/Front.Utils';

const AdminMenu = () => {
  const modulos = getSession('modulos');
  return (
    <li className="nav-item">
      {/* label*/}
      {modulos.includes('3c9987a2-d74f-4ab1-92c5-4da1e78341be') && (
        <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
          <div className="col-auto navbar-vertical-label">
            Panel de Administración
          </div>
          <div className="col ps-0">
            <hr className="mb-0 navbar-vertical-divider" />
          </div>
        </div>
      )}
      {/* parent pages*/}

      {modulos.includes('3c9987a2-d74f-4ab1-92c5-4da1e78341be') && (
        <a
          className="nav-link dropdown-indicator"
          href="#admin"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          aria-controls="admin"
        >
          <div className="d-flex align-items-center">
            <span className="nav-link-icon">
              <span className="fab fa-whmcs" />
            </span>
            <span className="nav-link-text ps-1">Administración</span>
          </div>
        </a>
      )}
      <ul className="nav collapse" id="admin">
        {/* {modulos.includes('3c9987a2-d74f-4ab1-92c5-4da1e78341be') && (
          <li className="nav-item">
            <Link className="nav-link" to="/admin/modulos" role="button">
              <div className="d-flex align-items-center">
                <span className="nav-link-icon">
                  <span className="fas fa-toolbox" />
                </span>
                <span className="nav-link-text ps-1">Módulos</span>
              </div>
            </Link>
          </li>
        )} */}

        {modulos.includes('f0b84fdb-fc73-4daa-b3b9-479f44f93490') && (
          <li className="nav-item">
            <Link className="nav-link" to="/admin/roles" role="button">
              <div className="d-flex align-items-center" {...CollapseNav()}>
                <span className="nav-link-icon">
                  <span className="fas fa-key" />
                </span>
                <span className="nav-link-text ps-1">Roles</span>
              </div>
            </Link>
          </li>
        )}

        {modulos.includes('df733611-13da-41cf-8fb9-437a0e9a8a2d') && (
          <li className="nav-item">
            <Link className="nav-link" to="/admin/usuarios" role="button">
              <div className="d-flex align-items-center" {...CollapseNav()}>
                <span className="nav-link-icon">
                  <span className="fas fa-user" />
                </span>
                <span className="nav-link-text ps-1">Usuarios</span>
              </div>
            </Link>
          </li>
        )}
        {modulos.includes('ab415703-6653-4733-bfc6-2baa9c5dd913') && (
          <li className="nav-item">
            <Link className="nav-link" to="/admin/clientes" role="button">
              <div
                className="d-flex align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#navbarVerticalCollapse"
              >
                <span className="nav-link-icon">
                  <span className="fas fa-users" />
                </span>
                <span className="nav-link-text ps-1">Clientes</span>
              </div>
            </Link>
          </li>
        )}
        {modulos.includes('ab415703-6653-4733-bfc6-2baa9c5dd913') && (
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/admin/carga-productos"
              role="button"
            >
              <div
                className="d-flex align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#navbarVerticalCollapse"
              >
                <span className="nav-link-icon">
                  <span className="fas fa-cash-register" />
                </span>
                <span className="nav-link-text ps-1">Carga productos</span>
              </div>
            </Link>
          </li>
        )}
      </ul>
    </li>
  );
};

export default AdminMenu;
