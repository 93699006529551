import React from 'react';
import { getSession } from '../../../../services/encrytion/encryption.service';
import { NavLink } from 'react-router-dom';
import { CollapseNav } from '../../../../utils/Front.Utils';

const ContabilidadMenu = () => {
  const modulos = getSession('modulos');
  return (
    <li className="nav-item">
      {/* label*/}
      {modulos.includes('5bbf40c0-323d-4561-a006-55a8745dc7b1') && (
        <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
          <div className="col-auto navbar-vertical-label">
            Administración contable
          </div>
          <div className="col ps-0">
            <hr className="mb-0 navbar-vertical-divider" />
          </div>
        </div>
      )}

      {modulos.includes('5bbf40c0-323d-4561-a006-55a8745dc7b1') && (
        <NavLink className="nav-link" to="/contabilidad/gestion-clientes">
          <div className="d-flex align-items-center" {...CollapseNav()}>
            <span className="nav-link-icon">
              <span className="fas fa-user-friends" />
            </span>
            <span className="nav-link-text ps-1">Gestión de Clientes</span>
          </div>
        </NavLink>
      )}
    </li>
  );
};

export default ContabilidadMenu;
