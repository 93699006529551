import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ModalWrapper from '../../../../../../Components/Common/Modals/Modal';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { useForm, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '../../../../../../services/alerts/alerts';

const ModalDescuento = forwardRef((props, ref) => {
  const { setValue, watch, getValues: getValuesCtx } = useFormContext();

  const validationSchema = Yup.object().shape({
    ventasGravadas: Yup.number(),
    ventasExentas: Yup.number(),
    ventasNoSujetas: Yup.number(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    formState: { errors },
    handleSubmit,
    register,
    getValues,
    setValue: setValueForm,
  } = useForm(formOptions);
  const modalRef = useRef(null);

  const onSubmit = () => {
    // limpiar();

    if (getValues('ventasGravadas') > watch('resumen')?.totalGravada) {
      Alert({
        title: 'Error',
        text: 'El total de descuentos a ventas gravadas no puede ser mayor al total gravado',
        icon: 'error',
      });
      return;
    }

    if (getValues('ventasExentas') > watch('resumen')?.totalExenta) {
      Alert({
        title: 'Error',
        text: 'El total de descuentos a ventas exentas no puede ser mayor al total exento',
        icon: 'error',
      });
      return;
    }

    if (getValues('ventasNoSujetas') > watch('resumen')?.totalNoSuj) {
      Alert({
        title: 'Error',
        text: 'El total de descuentos a ventas no sujetas no puede ser mayor al total no sujeta',
        icon: 'error',
      });
      return;
    }
    const totalDescuentos =
      parseFloat(getValues('ventasGravadas')) +
      parseFloat(getValues('ventasExentas')) +
      parseFloat(getValues('ventasNoSujetas'));
    if (totalDescuentos > watch('resumen')?.totalPagar) {
      Alert({
        title: 'Error',
        text: 'El total de los descuentos no puede ser mayor al total a pagar',
        icon: 'error',
      });
      return;
    }
    setValue(
      'descuentoGravada',
      parseFloat(getValues('ventasGravadas')).toFixed(2),
    );
    setValue(
      'descuentoExenta',
      parseFloat(getValues('ventasExentas')).toFixed(2),
    );
    setValue(
      'descuentoNoSujeta',
      parseFloat(getValues('ventasNoSujetas')).toFixed(2),
    );
    modalRef.current.toggle();
  };
  useImperativeHandle(ref, () => ({
    async openModal() {
      setValueForm('ventasGravadas', getValuesCtx('descuentoGravada'));
      setValueForm('ventasExentas', getValuesCtx('descuentoExenta'));
      setValueForm('ventasNoSujetas', getValuesCtx('descuentoNoSujeta'));
      modalRef.current.toggle();
    },
  }));

  return (
    <>
      <ModalWrapper title={`Descuentos Generales al Resumen`} ref={modalRef}>
        <CardWrapperTools
          title="Descuentos al total del documento"
          footer={
            <div className="row">
              <div className="col-lg-12 text-center">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Agregar descuentos
                </button>
              </div>
            </div>
          }
        >
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <label htmlFor="">Ventas gravadas:</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="number"
                    className="form-control"
                    id="ventasGravadas"
                    placeholder="Digite el valor del descuento"
                    {...register('ventasGravadas')}
                    onWheel={(e) => e.target.blur()}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => {
                      setValueForm(
                        'ventasGravadas',
                        parseFloat(e.target.value).toFixed(2),
                      );
                      if (e.target.value === '') {
                        setValueForm(
                          'ventasGravadas',
                          parseFloat(0).toFixed(2),
                        );
                      }
                    }}
                  />
                </div>
                {errors.ventasGravadas && (
                  <div className="invalid-feedback">
                    {errors.ventasGravadas?.message}
                  </div>
                )}
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                <label htmlFor="">Ventas exentas:</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="number"
                    className="form-control"
                    id="ventasExentas"
                    placeholder="Digite el valor del descuento"
                    {...register('ventasExentas')}
                    onWheel={(e) => e.target.blur()}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => {
                      setValueForm(
                        'ventasExentas',
                        parseFloat(e.target.value).toFixed(2),
                      );
                      if (e.target.value === '') {
                        setValueForm('ventasExentas', parseFloat(0).toFixed(2));
                      }
                    }}
                  />
                </div>
                {errors.ventasExentas && (
                  <div className="invalid-feedback">
                    {errors.ventasExentas?.message}
                  </div>
                )}
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                <label htmlFor="">Ventas sujetas:</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="number"
                    className="form-control"
                    id="ventasNoSujetas"
                    placeholder="Digite el valor del descuento"
                    {...register('ventasNoSujetas')}
                    onWheel={(e) => e.target.blur()}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => {
                      setValueForm(
                        'ventasNoSujetas',
                        parseFloat(e.target.value).toFixed(2),
                      );
                      if (e.target.value === '') {
                        setValueForm(
                          'ventasNoSujetas',
                          parseFloat(0).toFixed(2),
                        );
                      }
                    }}
                  />
                </div>
                {errors.ventasNoSujetas && (
                  <div className="invalid-feedback">
                    {errors.ventasNoSujetas?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </>
  );
});
export default ModalDescuento;
