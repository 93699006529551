import React, { useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import ModalWrapper from '../../../Components/Common/Modals/Modal';
import { TableFilterV2 } from '../../../Components/Common/Tables/TableFilterV2';
import {
  Alert,
  loadingReload,
  AlertQuestionYesNo,
} from '../../../services/alerts/alerts';
import { get } from '../../../services/http/http.service';
import http from '../../../services/http/http.auth.services';
import { getSession } from '../../../services/encrytion/encryption.service';
export default function ClienteSucursales({ id, readOnly = false }) {
  const modalRef = useRef();
  const tablaRef = useRef();
  const cliente = getSession('clienteSeleccionado') ?? {};

  const { handleSubmit, control, register, setValue, reset } = useForm();

  const [municipioOptions, setMunicipioOptions] = useState([]);
  const [editId, setEditId] = useState(null);

  const handleOpenModal = () => {
    if (modalRef.current) {
      reset();

      modalRef.current.toggle();
    }
  };

  const loadEstablecimiento = async (inputValue) => {
    try {
      const { data } = await get(
        `/generales/tipos-establecimientos?search=${inputValue}`,
      );
      const options = data.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.value,
      }));
      return options;
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al cargar los establecimientos',
        icon: 'error',
      });
    }
  };

  const loadDepartamento = async (inputValue) => {
    try {
      const { data } = await get(
        `generales/departamentos?search=${inputValue}`,
      );
      const options = data.map((item) => ({
        code: item.code,
        value: item?.id,
        label: item.value,
      }));
      return options;
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al cargar los departamentos',
        icon: 'error',
      });
    }
  };

  const fetchMunicipios = async (value) => {
    try {
      const { data } = await get(
        `generales/municipios?departamentoId=${value}`,
      );
      const options = data.map((item) => ({
        code: item.code,
        value: item.id,
        label: item.value,
        departamentoId: value,
      }));
      setMunicipioOptions(options);
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al cargar los municipios',
        icon: 'error',
      });
    }
  };

  const onSubmit = async (datos) => {
    try {
      loadingReload(true, 'Guardando Sucursal');
      const payload = {
        codigoEstablecimientoMh: datos.codigoEstablecimientoMh,
        codigoPuntoVentaMh: datos.codigoPuntoVentaMh,
        tipoEstablecimiento: {
          id: datos.establecimiento.id,
          code: datos.establecimiento.value,
          value: datos.establecimiento.label,
        },
        departamento: {
          id: datos.departamento.value,
          code: datos.departamento.code,
          value: datos.departamento.label,
        },
        municipio: {
          id: datos.municipios.value,
          code: datos.municipios.code,
          value: datos.municipios.label,
          departamentoId: datos.departamento.departamentoId,
        },
        direccion: datos.direccion,
        codigoEstablecimiento: datos.codigoEstablecimiento,
        codigoPuntoVenta: datos.codigoPuntoVenta,
        clienteId: id,
        default: datos.predeterminado,
      };
      if (editId) {
        const { data } = await http.patch(`/sucursales/${editId}`, payload);
        if (data) {
          modalRef.current.toggle();
          tablaRef.current.refreshData();
          loadingReload(false, null);
          Alert({
            title: 'Guardado',
            text: 'Se guardo la sucursal',
            icon: 'success',
          });
        }
      } else {
        const { data } = await http.post('/sucursales', payload);
        if (data) {
          modalRef.current.toggle();
          tablaRef.current.refreshData();
          loadingReload(false, null);
          Alert({
            title: 'Guardado',
            text: 'Se guardo la sucursal',
            icon: 'success',
          });
        }
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al guardar la sucursal',
        icon: 'error',
      });
    }
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row._id,
    },
    {
      name: 'Tipo de Establecimiento',
      selector: (row) => row.tipo_establecimiento.value,
    },
    {
      name: 'Departamento',
      selector: (row) => row.departamento.value,
    },
    {
      name: 'Municipio',
      selector: (row) => row.municipio.value,
    },
    {
      name: 'Dirección',
      selector: (row) => row.direccion,
    },
    {
      name: 'Default',
      selector: (row) => (row.default ? 'SI' : 'NO'),
    },
    {
      name: 'Estado',
      selector: (value) =>
        value.activo ? (
          <span className="badge rounded-pill badge-subtle-primary">
            Activo
          </span>
        ) : (
          <span className="badge rounded-pill badge-subtle-warning">
            Inactivo
          </span>
        ),
    },
    !readOnly === true && {
      name: 'Acciones',

      selector: (row) =>
        !readOnly === true ? (
          <div className="text-center">
            <button
              className="btn btn-link p-0"
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit"
              onClick={() => handleEdit(row?._id)}
            >
              <span className="text-500 fas fa-edit"></span>
            </button>
            <button
              className="btn btn-link p-0 ms-2"
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete"
              onClick={() => handleStatus(row?._id, row.activo)}
            >
              <span className="text-500 fas fa-trash-alt"></span>
            </button>
          </div>
        ) : (
          <>
            <div className="text-center">
              <span className="text-center">N/A</span>
            </div>
          </>
        ),
    },
  ];

  const handleEdit = async (idSucursal) => {
    try {
      const { data } = await http.get(`/sucursales/${id}/${idSucursal}`);

      if (data) {
        setEditId(data._id);
        setValue('codigoEstablecimientoMh', data.codigo_establecimiento_mh);
        setValue('codigoPuntoVentaMh', data.codigo_punto_venta_mh);
        setValue('establecimiento', {
          id: data.tipo_establecimiento.id,
          value: data.tipo_establecimiento.code,
          label: data.tipo_establecimiento.value,
        });
        setValue('departamento', {
          code: data.departamento.code,
          value: data.departamento.id,
          label: data.departamento.value,
        });
        await fetchMunicipios(data.departamento.id);
        setValue('municipios', {
          code: data.municipio.code,
          value: data.municipio.id,
          label: data.municipio.value,
          departamentoId: data.departamento.departamentoId,
        });
        setValue('direccion', data.direccion);
        setValue('codigoEstablecimiento', data.codigo_establecimiento);
        setValue('codigoPuntoVenta', data.codigo_punto_venta);
        setValue('predeterminado', data.default);

        if (modalRef.current) {
          modalRef.current.toggle();
        }
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al cargar los municipios',
        icon: 'error',
      });
    }
  };

  const handleStatus = async (id, status) => {
    try {
      const question = await AlertQuestionYesNo({
        title: 'Desactivar',
        text: '¿Desea desactivar la sucursal?',
        icon: 'warning',
      });
      if (!question) return;
      loadingReload(true, 'Desactivando Sucursal');
      const { data } = await http.patch(`/sucursales/status/${id}`, {
        status: !status,
      });
      if (data) {
        tablaRef.current.refreshData();
        loadingReload(false, null);
        return Alert({
          title: 'Guardado',
          text: 'Se inactivo la sucursal',
          icon: 'success',
        });
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al desactivar la sucursal',
        icon: 'error',
      });
    }
  };
  return (
    <div>
      <CardWrapperTools
        title="Sucursales"
        tools={
          !readOnly && (
            <div className="d-flex align-items-center">
              <div className="me-3">
                <div className="icon-shape icon-sm bg-body-tertiary text-center rounded">
                  <button className="btn btn-primary" onClick={handleOpenModal}>
                    <i
                      className="fa fa-plus"
                      style={{ marginRight: '3px' }}
                    ></i>
                    Agregar Sucursal
                  </button>
                </div>
              </div>
            </div>
          )
        }
      >
        <div className="row">
          <div className="col-lg-12">
            <TableFilterV2
              endpoint={cliente?.id ? `sucursales/${id ?? cliente?.id}` : null}
              columns={columns}
              ref={tablaRef}
              showPagesUrl={false}
            />
          </div>
        </div>
        <ModalWrapper ref={modalRef}>
          <CardWrapperTools
            title="Administrador de sucursales"
            footer={
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <span className="far fa-paper-plane"></span> Guardar
                    </button>
                  </div>
                </div>
              </div>
            }
          >
            <div className="row g-4">
              <div className="col-lg-4">
                <label htmlFor="codigoEstablecimientoMh">
                  Código de Establecimiento Ministerio de Hacienda:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Código de establecimiento"
                  name={'codigoEstablecimientoMh'}
                  {...register('codigoEstablecimientoMh')}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="codigoPuntoVentaMh">
                  Código de Punto de Venta Ministerio de Hacienda:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Código de punto de venta"
                  name="codigoPuntoVentaMh"
                  {...register('codigoPuntoVentaMh')}
                />
              </div>

              <div className="col-lg-4">
                <label htmlFor="establecimiento">
                  Tipo de Establecimiento Ministerio de Hacienda:
                </label>
                <Controller
                  name="establecimiento"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      cacheOptions
                      loadOptions={loadEstablecimiento}
                      defaultOptions
                      placeholder="Seleccione un establecimiento"
                    />
                  )}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="departamento">Departamento:</label>
                <Controller
                  name="departamento"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      cacheOptions
                      loadOptions={loadDepartamento}
                      defaultOptions
                      placeholder="Departamento"
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        fetchMunicipios(
                          selectedOption ? selectedOption.value : null,
                        );
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="municipios">Municipios:</label>
                <Controller
                  name="municipios"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      cacheOptions
                      options={municipioOptions}
                      defaultOptions
                      placeholder="Municipios"
                    />
                  )}
                />
              </div>

              <div className="col-lg-4">
                <label htmlFor="codigoEstablecimiento">
                  Codigo del establecimiento
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Codigo del establecimiento"
                  name="codigoEstablecimiento"
                  {...register('codigoEstablecimiento')}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="codigoPuntoVenta">
                  Codigo de punto de venta
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Codigo de punto de venta"
                  name="codigoPuntoVenta"
                  {...register('codigoPuntoVenta')}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="predeterminado">Predeterminado:</label>
                <div className="form-group">
                  <div className="form-check form-switch">
                    <input
                      {...register('predeterminado')}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Si
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <label htmlFor="direccion">Direccion:</label>
                <textarea
                  rows={5}
                  type="text"
                  className="form-control"
                  placeholder="Direccion"
                  name="direccion"
                  {...register('direccion')}
                />
              </div>
            </div>
          </CardWrapperTools>
        </ModalWrapper>
      </CardWrapperTools>
    </div>
  );
}
