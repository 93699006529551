import React from 'react';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import { useFormContext } from 'react-hook-form';
import { InputMask } from '@react-input/mask';

const InformacionEntregaForm = () => {
  const { register } = useFormContext();
  return (
    <>
      <CardWrapperTools title="Información de entrega">
        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <label
                className="form-label"
                htmlFor="numeroDocumentoResponsableEmisor"
              >
                Número documento de responsable que entrega:
              </label>
              <InputMask
                key={Math.random()}
                mask="________-_"
                showMask
                replacement={{ _: /\d/ }}
                className="form-control"
                name="numeroDocumentoResponsableEmisor"
                id="numeroDocumentoResponsableEmisor"
                type="text"
                {...register('numeroDocumentoResponsableEmisor')}
                placeholder="Responsable de emitir el documento"
              ></InputMask>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="nombreResponsableEmisor">
                Nombre del responsable que entrega:
              </label>
              <input
                className="form-control"
                name="nombreResponsableEmisor"
                id="nombreResponsableEmisor"
                type="text"
                {...register('numeroDocumentoResponsableEmisor')}
                placeholder="Responsable de emitir el documento"
              />
            </div>
            <div className="col-6">
              <label
                className="form-label"
                htmlFor="numeroDocumentoResponsableReceptor"
              >
                Número documento de responsable que recibe:
              </label>
              <InputMask
                key={Math.random()}
                mask="________-_"
                showMask
                replacement={{ _: /\d/ }}
                className="form-control"
                name="numeroDocumentoResponsableReceptor"
                id="numeroDocumentoResponsableReceptor"
                type="text"
                {...register('numeroDocumentoResponsableReceptor')}
                placeholder="Responsable de recibir el documento"
              ></InputMask>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="nombreResponsableReceptor">
                Nombre del responsable que recibe:
              </label>
              <input
                className="form-control"
                name="nombreResponsableReceptor"
                id="nombreResponsableReceptor"
                type="text"
                {...register('nombreResponsableReceptor')}
                placeholder="Responsable de recibir el documento"
              />
            </div>
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default InformacionEntregaForm;
