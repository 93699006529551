import React, { useRef } from 'react';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import ModalDocumentoFisico from './Modales/ModalDocumentoFisico';
import ModalDocumentoElectronico from './Modales/ModalDocumentoElectronico';
import { useFormContext } from 'react-hook-form';
import { getDteType } from '../../../../../utils/dte/dte.util';
import {
  Alert,
  AlertQuestionYesNo,
} from '../../../../../services/alerts/alerts';
import { getLocalDate } from '../../../../../services/date/date';

const DocumentoRelacionadoForm = () => {
  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const modalDocumentoFisicoRef = useRef(null);
  const modalDocumentoElectronicoRef = useRef(null);

  const validationDocumentoRelacionado = () => {
    const dataRelacionado = getValues('documentoRelacionado');

    if (dataRelacionado) {
      Alert({
        title: 'Advertencia: Ya existe un documento relacionado',
        text: '¡Debe eliminar el documento actual para agregar uno nuevo!',
        icon: 'warning',
      });
      return true;
    }

    return false;
  };

  const eliminarDocumentoRelacionado = () => {
    AlertQuestionYesNo({
      title: 'Eliminar documento relacionado',
      text: '¿Está seguro de eliminar el documento relacionado?',
    }).then((result) => {
      if (result) {
        setValue('documentoRelacionado', undefined);
        setValue('cuerpoDocumento', undefined);
        setValue('resumen', undefined);
        setValue('condicionOperacion', '');
      }
    });
  };
  return (
    <>
      {errors.documentoRelacionado && (
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="alert alert-danger" role="alert">
              {errors.documentoRelacionado.message}
            </div>
          </div>
        </div>
      )}
      <CardWrapperTools
        title="Documento relacionado seleccionado"
        tools={
          <div className="btn-group">
            <button
              className="btn dropdown-toggle mb-2 btn-primary"
              type="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Agregar documento
            </button>
            <div className="dropdown-menu">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  if (validationDocumentoRelacionado()) {
                    return;
                  }
                  if (!getValues('nitReceptor')) {
                    Alert({
                      title: 'Advertencia: No se ha agregado un receptor',
                      text: '¡Debe agregar un receptor para poder agregar un documento relacionado!',
                      icon: 'warning',
                    });
                    return;
                  }

                  modalDocumentoElectronicoRef.current.openModal();
                }}
              >
                Documento electrónico
              </button>
              <div className="dropdown-divider" />
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  if (validationDocumentoRelacionado()) {
                    return;
                  }
                  modalDocumentoFisicoRef.current.openModal();
                }}
              >
                Documento físico
              </button>
            </div>
          </div>
        }
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive scrollbar">
                <table className="table table-hover table-striped table-bordered border-3 overflow-hidden">
                  <thead className="table-primary">
                    <tr>
                      <th scope="col" className="text-center">
                        Tipo de documento
                      </th>
                      <th scope="col" className="text-center">
                        Tipo de generación
                      </th>
                      <th scope="col" className="text-center">
                        Código de Generación / Número de Correlativo
                      </th>
                      <th scope="col" className="text-center">
                        Fecha de Generación
                      </th>
                      <th scope="col" className="text-center">
                        Opciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {watch('documentoRelacionado') ? (
                      <tr className="align-middle">
                        <td className="text-center">
                          {getDteType(
                            watch('documentoRelacionado').tipoDocumentoRel,
                          )}
                        </td>
                        <td className="text-center">
                          {watch('documentoRelacionado').tipoGeneracion}
                        </td>
                        <td className="text-center">
                          {watch('documentoRelacionado').numeroDocumentoRel}
                        </td>
                        <td className="text-center">
                          {getLocalDate(
                            watch('documentoRelacionado').fechaGeneracionRel,
                            'DD/MM/YYYY',
                          )}
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              eliminarDocumentoRelacionado();
                            }}
                          >
                            <span className="fas fa-trash-alt"></span> Eliminar
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <tr className="align-middle">
                        <td colSpan="4" className="text-center">
                          <h3>
                            No se ha seleccionado ningún documento relacionado
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CardWrapperTools>
      <ModalDocumentoFisico ref={modalDocumentoFisicoRef} />
      <ModalDocumentoElectronico ref={modalDocumentoElectronicoRef} />
    </>
  );
};

export default DocumentoRelacionadoForm;
