import React, { useEffect } from 'react';
import InformationSystem from './Components/InformationSystem';
import AuthServiceAxios from '../../services/http/AuthServiceAxios';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '../../services/alerts/alerts';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
const SetPassword = () => {
  const { token, user } = useParams();
  const navigate = useNavigate();

  const [error, setError] = React.useState(false);

  const validationSchema = Yup.object().shape({
    confirm_password: Yup.string().required(
      'Confirmar contraseña es obligatoria',
    ),
    password: Yup.string()
      .required('Nueva contraseña es obligatoria')
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .max(20, 'La contraseña debe tener máximo 20 caracteres'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    async function fetchData() {
      try {
        const body = {
          token,
          usuario: user,
        };
        await AuthServiceAxios.post('usuarios/verify-token-password', body);
      } catch (error) {
        Alert({
          title: 'Error de verificación',
          text: 'El código de verificación ha expirado',
          icon: 'error',
        });
        navigate('/login');
      }
    }
    fetchData();
  }, [navigate, token, user]);

  const onSubmit = async (data) => {
    const body = {
      id: user,
      token: token,
      newPassword: watch('password'),
    };

    try {
      const changePassword = await AuthServiceAxios.patch(
        'usuarios/update-password',
        body,
      );

      if (changePassword.status === 200) {
        Alert({
          title: 'Contraseña actualizada',
          text: 'La contraseña se ha actualizado correctamente',
          icon: 'success',
        });
        navigate('/login');
      } else if (
        changePassword.status === 401 ||
        changePassword.status === 400
      ) {
        Alert({
          title: 'Error de verificación',
          text: 'El código de verificación ha expirado',
          icon: 'error',
        });
      }
    } catch (e) {
      if (e.response.status === 401) {
      }
    }
  };

  const blurPassword = (e) => {
    if (
      watch('password') !== '' &&
      watch('confirm_password') !== '' &&
      watch('password') !== watch('confirm_password')
    ) {
      setError(true);
    }
  };
  const togglePasswordVisibility = (passwordId, iconId) => {
    const passwordInput = document.getElementById(passwordId);
    const showPasswordIcon = document.getElementById(iconId);

    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
      showPasswordIcon.classList.remove('fa-eye');
      showPasswordIcon.classList.add('fa-eye-slash');
    } else {
      passwordInput.type = 'password';
      showPasswordIcon.classList.remove('fa-eye-slash');
      showPasswordIcon.classList.add('fa-eye');
    }
  };

  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="../../../assets/img/bg-shape.png"
              alt="shape"
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="../../../assets/img/shape-1.png"
              alt="shape"
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0 h-100">
                  <InformationSystem />
                  <div className="col-md-7 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <h3>Creación de Contraseña</h3>
                      <div className="col-lg-12">
                        {error && (
                          <div
                            className="alert alert-danger border-2 d-flex align-items-center"
                            role="alert"
                          >
                            <div className="bg-danger me-3 icon-item">
                              <span className="fas fa-times-circle text-white fs-3"></span>
                            </div>
                            <p className="mb-0 flex-1">
                              Las contraseñas no coinciden.
                            </p>
                            <button
                              className="btn-close"
                              type="button"
                              onClick={() => setError(false)}
                            ></button>
                          </div>
                        )}
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
                        <div className="mb-2">
                          <label className="form-label" htmlFor="password1">
                            Nueva Contraseña:
                          </label>
                        </div>
                        <div className="mb-3 input-icon">
                          <input
                            id="password1"
                            className="form-control"
                            type="password"
                            {...register('password', { required: true })}
                          />
                          <button
                            onClick={() =>
                              togglePasswordVisibility(
                                'password1',
                                'eye-password1',
                              )
                            }
                            className="toggle-password"
                            type="button"
                          >
                            <span
                              id="eye-password1"
                              className="fas fa-eye"
                            ></span>
                          </button>
                        </div>
                        {errors.password && (
                          <div className="invalid-feedback">
                            {errors.password?.message}
                          </div>
                        )}
                        <div className="mb-2">
                          <label className="form-label" htmlFor="password2">
                            Confirmar Contraseña:
                          </label>
                        </div>
                        <div className="mb-3 input-icon">
                          <input
                            className="form-control"
                            id="password2"
                            {...register('confirm_password')}
                            type="password"
                            onBlur={blurPassword}
                          />
                          <button
                            onClick={() =>
                              togglePasswordVisibility(
                                'password2',
                                'eye-password2',
                              )
                            }
                            className="toggle-password"
                            type="button"
                          >
                            <span
                              id="eye-password2"
                              className="fas fa-eye"
                            ></span>
                          </button>
                        </div>
                        {errors.confirm_password && (
                          <div className="invalid-feedback">
                            {errors.confirm_password?.message}
                          </div>
                        )}
                        <button
                          className="btn btn-primary d-block w-100 mt-3"
                          type="submit"
                          name="submit"
                        >
                          Crear Contraseña
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SetPassword;
