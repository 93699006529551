import httpAuth from '../../../../../services/http/http.auth.services';
import { get } from '../../../../../services/http/http.service';
import * as Yup from 'yup';
import { capitalizeWords } from '../../facturav2/utils/FacturaV2';
const defaultOptions = {
  defaultDepartemento: '6505204d62bac256b1348960',
};
export const getTiposDocumentosReceptor = async () => {
  const { data } = await get(
    'generales/tipos-documentos-identificacion-receptor',
  );

  return data;
};

export const getActividadesEconomicas = async (inputValue = '') => {
  const { data } = await get(
    `generales/actividades-economicas?search=${inputValue}`,
  );

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
  }));
  return options;
};

let timeOutActividadesEconomicas = null;
export const loadActividadesEconomicas = (inputValue) =>
  new Promise((resolve) => {
    clearTimeout(timeOutActividadesEconomicas);
    timeOutActividadesEconomicas = setTimeout(() => {
      resolve(getActividadesEconomicas(inputValue));
    }, 300);
  });

export const getDepartamentos = async () => {
  const { data } = await get(`generales/departamentos`);

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
    id: item.id,
  }));
  return {
    data: options,
    default: options.find(
      (item) => item.id === defaultOptions.defaultDepartemento,
    ),
  };
};

export const getMunicipios = async (
  departamentoId = defaultOptions.defaultDepartemento,
) => {
  const { data } = await get(
    `generales/municipios?departamentoId=${departamentoId}`,
  );

  const options = data.map((item) => ({
    value: item.code,
    label: capitalizeWords(item.value),
  }));
  return options;
};

export const getActividadesEconomicasEmisor = async (clienteId) => {
  const { data } = await get(`clientes/actividad-economica/${clienteId}`);

  const options = data
    .filter((actividad) => actividad.activo === true)
    .map((item) => ({
      value: item.code,
      label: `${item.code} - ${item.value}`,
      default: item.default,
    }));
  return {
    data: options,
    default: options.find((item) => item.default === true),
  };
};

export const getDataEmisor = async (clienteId) => {
  const { data } = await get(`clientes/${clienteId}`);

  return data;
};

export const getSucursalesEmisor = async (
  clienteId,
  sucursalDefault = null,
) => {
  const { data } = await httpAuth.get(`sucursales/${clienteId}`);

  const options = data?.results
    .filter((sucursal) => sucursal.activo === true)
    .map((item) => ({
      value: item._id,
      label:
        item.codigo_punto_venta_mh +
        ' - ' +
        item.tipo_establecimiento.value +
        ' - ' +
        item.direccion,
      default: item.default,
    }));

  return {
    data: options,
    default: !sucursalDefault
      ? options.find((item) => item.default === true)
      : sucursalDefault,
  };
};

export const getDatosDte = async ({
  clienteId,
  ambiente,
  nitReceptor,
  tipoDocumento,
  fechaGeneracionDesde,
  fechaGeneracionHasta,
}) => {
  const { data } = await get(`dte/${clienteId}/by-cliente-id`, {
    limit: 1000,
    ambiente,
    tipo: tipoDocumento,
    nitReceptor,
    estado: 'ENVIADO',
    startDate: fechaGeneracionDesde,
    endDate: fechaGeneracionHasta,
  });

  return data;
};

export const notSelectedValue = null;
export const validationSchema = Yup.object().shape({
  nombreClienteReceptor: Yup.string().required(
    'Nombre de receptor es requerido',
  ),
  nitReceptor: Yup.string()
    .required('NIT es requerido')
    .test('len', 'NIT es requerido', (val) => {
      const lengthExpected = val.length === 10 ? 9 : 14;
      return (
        val?.replaceAll('-', '').replaceAll('_', '').length === lengthExpected
      );
    }),
  nrcReceptor: Yup.number()
    .required('NRC es requerido')
    .max(99999999, 'Máximo 8 caracteres'),
  telefonoReceptor: Yup.string().required('Teléfono de receptor es requerido'),
  actividadEconomicaReceptor: Yup.object()
    .notOneOf([notSelectedValue])
    .required('Actividad económica es requerida'),
  correoElectronicoReceptor: Yup.string()
    .required('Correo electrónico es requerido')
    .email('Correo electrónico con formato incorrecto'),

  departamentoReceptor: Yup.object()
    .notOneOf([notSelectedValue])
    .required('El departamento es requerido'),
  municipioReceptor: Yup.object()
    .notOneOf([notSelectedValue])
    .required('El municipio es requerido'),
  complementoReceptor: Yup.string().required('La dirección es requerida'),

  actividadEconomicaEmisor: Yup.object().required(
    'Actividad económica es requerida',
  ),
  establecimientoEmisor: Yup.object().required('Establecimiento es requerido'),
  correoElectronicoEmisor: Yup.string()
    .required('Correo electrónico es requerido')
    .email('Correo electrónico con formato incorrecto'),

  telefonoEmisor: Yup.string().required('Teléfono de emisor es requerido'),
  documentoRelacionado: Yup.object().required(
    'Documento relacionado es requerido',
  ),

  cuerpoDocumento: Yup.array().required(
    'Los datos de la nota de crédito son requeridos',
  ),

  condicionOperacion: Yup.string().required(
    'Condición de operación es requerida',
  ),
});

export const getTipoGeneracionDte = (tipo) => {
  if (tipo?.toLowerCase() === 'documento electrónico') {
    return 2;
  }

  if (tipo?.toLowerCase() === 'documento físico') {
    return 1;
  }
};

export const tiposProductos = [
  {
    value: 1,
    label: 'Bien',
  },
  {
    value: 2,
    label: 'Servicio',
  },
  {
    value: 3,
    label: 'Bien y servicio',
  },
];

export const tiposVenta = [
  {
    value: 1,
    label: 'Gravada',
  },
  {
    value: 2,
    label: 'Exenta',
  },
  {
    value: 3,
    label: 'No Sujeta',
  },
];

export const getUnidadesMedida = async () => {
  const { data } = await get(`generales/unidades-medida`);

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
  }));
  return options;
};

export const getTributos = async () => {
  const { data } = await get(`generales/tributos`);

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
    clear: true,
    mode: item.mode,
    amount: item.amount,
    type: item.type,
    measurements: item.measurements,
    calculate: item.calculate,
  }));
  return options.filter((item) => tributosHabilitados.includes(item.value));
};

export const tributosHabilitados = ['20', '59', '71', 'D1', 'C8', 'D5', 'D4'];

export const getTributosAfeccionIva = async () => {
  const { data } = await get(`generales/tributos`);

  const options = data.map((item) => ({
    value: item.code,
    label: item.value,
    clear: true,
    mode: item.mode,
    amount: item.amount,
    type: item.type,
    measurements: item.measurements,
    calculate: item.calculate,
  }));
  return options.filter((item) =>
    tributosAfeccionIvaHabilitados.includes(item.value),
  );
};

export const tributosAfeccionIvaHabilitados = [
  'D4',
  'A8',
  '57',
  '90',
  'D5',
  'A6',
];
