/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';

import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import AsyncSelect from 'react-select/async';
import httpAuth from '../../../services/http/http.auth.services';
import {
  Alert,
  alertEmpty,
  loadingReload,
} from '../../../services/alerts/alerts';

export default function RolesCreate() {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('Nombre es requerido'),
    modulos: Yup.array(
      Yup.object({
        value: Yup.string().required(),
        label: Yup.string().required(),
      }),
    ).required('Módulos es requerido'),
  });
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadRolById(id);
    }
  }, [id]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    loadingReload(true, id ? 'Actualizando rol' : 'Guardando rol');
    const modulos = data.modulos.map((item) => item.value);
    data.modulos = modulos;

    try {
      if (!id) {
        const response = await httpAuth.post('/roles', data);
        if (response) {
          loadingReload(false, null);
          Alert({
            title: 'Rol guardado',
            icon: 'success',
            text: 'El rol se guardo correctamente',
          });
          navigate('/admin/roles');
        }
      } else {
        data.id = id;
        const response = await httpAuth.put('/roles', data);
        if (response) {
          loadingReload(false, null);
          Alert({
            title: 'Rol actualizado',
            icon: 'success',
            text: 'El rol se actualizo correctamente',
          });
          navigate('/admin/roles');
        }
      }
    } catch (error) {
      loadingReload(false, null);
      alertEmpty(
        '!Error al guardar el usuario!',
        error.response.data.message ?? 'Ocurrio un error al guardar el rol',
        'info',
      );
    }
  };

  const loadModulos = async (inputValue) => {
    const { data } = await httpAuth.get(
      `/modulos?activo=true&search=${inputValue}&limit=10`,
    );
    const options = data.results.map((item) => ({
      value: item._id,
      label: item.nombre,
    }));
    return options;
  };

  const loadRolById = async (id) => {
    const { data } = await httpAuth.get(`/roles/${id}`);
    setValue('nombre', data.nombre);
    setValue(
      'modulos',
      data.modulos.map((item) => ({ value: item._id, label: item.nombre })),
    );

    return data;
  };
  return (
    <CardWrapperTools
      title="Crear rol"
      footer={
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-secondary btn-lg"
                style={{ marginRight: '20px' }}
                onClick={() => navigate('/admin/roles')}
              >
                <span className="fas fa-arrow-left"></span> Salir
              </button>
              <button
                className="btn btn-primary btn-lg"
                onClick={handleSubmit(onSubmit)}
              >
                <span
                  style={{ marginRight: '5px' }}
                  className="fas fa-paper-plane"
                ></span>
                {id ? 'Actualizar' : 'Guardar'}
              </button>
            </div>
          </div>
        </div>
      }
    >
      <div className="row">
        <div className="col-md-4">
          <label className="form-label" htmlFor="nombre">
            Nombre:
          </label>
          <input
            className={`form-control ${errors.nombre ? 'is-invalid' : ''}`}
            id="nombre"
            placeholder="Nombre"
            {...register('nombre')}
          />
          {errors.nombre && (
            <div className="invalid-feedback">{errors.nombre.message}</div>
          )}
        </div>
        <div className="col-md-4">
          <label htmlFor="modulo">módulos:</label>
          <Controller
            name="modulos"
            control={control}
            render={({ field }) => (
              <AsyncSelect
                {...field}
                isMulti
                loadOptions={loadModulos}
                defaultOptions
                placeholder="Seleccione módulos"
                className="basic-multi-select"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: errors.modulos ? 'red' : provided.borderColor,
                  }),
                }}
              />
            )}
          />
          {errors.modulos && (
            <div className="invalid-feedback">{errors.modulos.message}</div>
          )}
        </div>
      </div>
    </CardWrapperTools>
  );
}
