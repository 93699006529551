import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from '../../services/http/http.service';
import { enqueueSnackbar } from 'notistack';
import { LoaderComponent } from 'react-fullscreen-loader';
import {
  getSession,
  setSession,
  destroyItemSession,
} from '../../services/encrytion/encryption.service';
import CardWrapperTools from '../../Components/Common/Cards/CardWrapperTools';

function Dte() {
  const navigate = useNavigate();

  const [dteClientes, setDteClientes] = React.useState([]);
  const [loading, setLoading] = React.useState(null);
  useEffect(() => {
    destroyItemSession('ambiente');
    async function getDte() {
      try {
        const cliente = getSession('clienteSeleccionado');

        setLoading(true);
        const { data } = await get(`clientes/dte/${cliente.id}`);
        setDteClientes(data);
        setLoading(false);
      } catch (error) {
        enqueueSnackbar(
          'Se ha producido un error al momento de obtener los clientes!',
          {
            variant: 'error',
            preventDuplicate: true,
          },
        );
      }
    }

    getDte();
  }, [navigate]);

  async function redirectToDte(ambiente, code) {
    setSession('ambiente', ambiente);

    //validar aqui a que pagina redirige segun el code del dte
    // navigate("/dte/emitir");

    //01 = factura electronica
    //05 = nota de credito
    //14 = factura de sujeto excluido
    switch (code) {
      case '01':
        navigate('/dte/factura');
        break;

      case '05':
        navigate('/dte/nota-credito');
        break;

      case '03':
        navigate('/dte/credito-fiscal');
        break;

      case '14':
        navigate('/dte/factura-sujeto-excluido');
        break;

      default:
        break;
    }
  }

  const getDteImg = (code, ambiente) => {
    if (ambiente === '00') {
      switch (code) {
        case '01':
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/pruebas/factura.jpg`;

        case '05':
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/pruebas/nota-credito.jpg`;

        case '03':
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/pruebas/credito-fiscal.jpg`;

        case '14':
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/pruebas/sujeto-excluido.jpg`;

        default:
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/no-disponible.jpg`;
      }
    }

    if (ambiente === '01') {
      switch (code) {
        case '01':
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/produccion/factura.jpg`;

        case '05':
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/produccion/nota-credito.jpg`;

        case '03':
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/produccion/credito-fiscal.jpg`;

        case '14':
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/produccion/sujeto-excluido.jpg`;

        default:
          return `${process.env.PUBLIC_URL}/assets/img/dte-icons/no-disponible.jpg`;
      }
    }
  };

  return (
    <Fragment>
      <CardWrapperTools
        title={
          <div className="d-flex justify-content-start">
            <h4 className="text-info-emphasis">
              Seleccione la opción de DTE que desea emitir
            </h4>
          </div>
        }
        tools={
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              onClick={() => navigate('/dte/consulta')}
            >
              <span className="fas fa-search me-1"></span>
              Consultar Documentos Emitidos
            </button>
          </div>
        }
      >
        <div className="container-fluid">
          <div className="row g-4">
            <LoaderComponent loading={loading} loadingColor="blue" />
            {dteClientes.length > 0 &&
            dteClientes.filter((d) => d.activo === true).length > 0 ? (
              dteClientes
                .filter((d) => d.activo === true)
                .map((dte, index) => (
                  <div
                    className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 mx-auto"
                    key={index}
                  >
                    <div
                      className="card"
                      onClick={() => redirectToDte(dte.ambiente, dte.code)}
                    >
                      <div className="card-img-top">
                        <img
                          className="img-fluid"
                          src={getDteImg(dte.code, dte.ambiente)}
                          alt="card-img-top"
                        />
                      </div>
                    </div>
                  </div>
                ))
            ) : loading === false ? (
              <div className="col-12 mx-auto text-center">
                <h3>No tiene permisos para emitir documentos</h3>
                <p>
                  Para poder emitir documentos debe solicitar permisos al
                  administrador del sistema
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </CardWrapperTools>
    </Fragment>
  );
}

export default Dte;
