import React, { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { get, post } from '../../../services/http/http.service';
import { enqueueSnackbar } from 'notistack';
import io from 'socket.io-client';
import { loadingReload } from '../../../services/alerts/alerts';
import { saveAs } from 'file-saver';
import { Alert } from 'reactstrap';

const socket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
  transports: ['websocket'],
});

const JsonDownload = ({ cliente, year, month, ambiente }) => {
  const [loadingJson, setLoadingJson] = useState(false);
  const [loadingStateJson, setLoadingStateJson] = useState(true);
  const [dataDescarga, setDataDescarga] = useState(null);

  const handleDownload = async () => {
    try {
      setLoadingJson(true);
      const dataDescarga = {
        identificador: `${month}${year}-${cliente}-${ambiente}-json`,
        clienteId: cliente,
        ambiente,
        tipo: 'json',
        month: month?.toString(),
        year: year?.toString(),
      };
      const { data } = await post(
        'contabilidad/descarga-documentos-contables',
        dataDescarga,
      );

      setLoadingJson(false);

      if (data?.error === true) {
        enqueueSnackbar(
          'No hay registros disponibles para realizar la descarga',
          {
            variant: 'info',
            preventDuplicate: true,
          },
        );

        return;
      }
      setDataDescarga(data);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de generar la descarga',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  };

  const handleDownloadZip = async () => {
    try {
      loadingReload(true, 'Descargando archivo');

      const dataUrl = {
        url: dataDescarga?.url_json,
      };
      const { data } = await post(
        `contabilidad/descarga-archivos-contables`,
        dataUrl,
      );

      const fileName = data?.url.split('/').pop().split('?')[0];
      const response = await fetch(data?.url);
      const fileBlob = await response.blob();
      saveAs(fileBlob, fileName, {
        autoBom: true,
      });

      loadingReload(false);
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo descargar el Archivo',
        icon: 'error',
      });
      loadingReload(false);
    }
  };

  const getGestionDeDescarga = useCallback(async () => {
    try {
      const { data } = await get(`contabilidad/descarga-documentos-contables`, {
        identificador: `${month}${year}-${cliente}-${ambiente}-json`,
      });

      setDataDescarga(data);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los dte!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, [month, year, cliente, ambiente]);
  // Simulamos un delay para la carga inicial del estado de los archivos
  useEffect(() => {
    async function getGestionDescarga() {
      setLoadingStateJson(true);
      await getGestionDeDescarga();
      setLoadingStateJson(false);
    }

    getGestionDescarga();
  }, [getGestionDeDescarga, ambiente, year, month, cliente]);

  useEffect(() => {
    async function getGestionDescarga() {
      await getGestionDeDescarga();
    }
    socket.on('connect', () => {});

    socket.on(`${month}${year}-${cliente}-${ambiente}-json`, (msg) => {
      if (msg?.reload) {
        getGestionDescarga();
      }
    });

    socket.on('disconnect', () => {});

    return () => {
      socket.off('connect');
      socket.off('message');
      socket.off('disconnect');
    };
  }, [ambiente, year, month, cliente, getGestionDeDescarga]);

  return (
    <div className="col-md-6">
      {dataDescarga?.url_json && loadingJson === false ? (
        <div className="alert alert-info text-justify mt-3" role="alert">
          Generar nuevamente los archivos JSON, si se han agregado nuevos DTE en
          el mes seleccionado.
        </div>
      ) : null}
      <div
        className={`card ${
          !dataDescarga?.url_json && loadingJson === false ? 'mt-3' : ''
        }`}
      >
        <div className="card-header text-center">
          <h4>Descargar JSON</h4>
        </div>

        <div className="card-body text-center">
          {loadingStateJson ? (
            <Skeleton height={150} />
          ) : dataDescarga?.generando === true || loadingJson === true ? (
            <div>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Generando descarga...</span>
              </div>
              <p>Generando descarga...</p>
              <p>Esto puede tardar unos minutos</p>
            </div>
          ) : dataDescarga?.url_json ? (
            <div>
              <p>La descarga del archivo JSON está lista.</p>

              <div className="btn btn-group">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    handleDownloadZip();
                  }}
                >
                  <i className="fas fa-download"></i> Descargar JSON
                </button>

                <button
                  className="btn btn-secondary"
                  onClick={() => handleDownload()}
                >
                  <i className="fas fa-sync"></i> Volver a Generar
                </button>
              </div>
            </div>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => handleDownload()}
            >
              <i className="fas fa-file-code"></i> Generar Descarga JSON
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default JsonDownload;
