import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import Select from 'react-select';

const TabItemNoAfecto = () => {
  const validationSchema = Yup.object().shape({
    descripcion: Yup.string().required('La descripción es requerida'),
    monto: Yup.number()
      .typeError('El precio debe ser un número')
      .required('El precio es requerido')
      .min(0.01, 'El precio debe ser mayor a 0.00'),
    documentosRelacionados: Yup.array(),
    documentoRelacionado: Yup.object()
      .optional()
      .when('documentosRelacionados', (documentosRelacionados, schema) => {
        const [documentosRelacionadosValue] = documentosRelacionados;
        return documentosRelacionadosValue.length > 0
          ? schema.required('documento relacionado es requerido')
          : schema.nullable();
      }),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      descripcion: '',
      monto: '0.00',
    },
  };

  const { getValues, setValue, watch, formState } = useFormContext();

  const {
    register,
    control,
    setValue: setValueForm,
    handleSubmit,
    watch: watchForm,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = () => {
    const itemCuerpoDocumento = {
      numItem: null,
      tipoItem: 3,
      numeroDocumento: watchForm('documentoRelacionado')?.value,
      cantidad: 1,
      codigo: new Date().getTime(),
      codTributo: null,
      uniMedida: 99,
      descripcion: watchForm('descripcion'),
      precioUni: 0,
      montoDescu: 0,
      ventaNoSuj: 0,
      ventaExenta: 0,
      ventaGravada: 0,
      noGravado: watchForm('monto'),
      tributos: [],
      tributosData: [],
      itemResumenDocumento: null,
    };

    const resumenDocumento = getValues('resumen') || {};

    const itemResumenDocumento = {
      tributos: [],
      subTotal: 0,
      ivaRete1: 0,
      ivaPerci1: 0,
      reteRenta: 0,
      descuNoSuj: 0,
      totalDescu: 0,
      totalNoSuj: 0,
      descuExenta: 0,
      totalExenta: 0,
      subTotalVentas: 0,
      descuGravada: 0,
      totalGravada: 0,
      totalNoGravado: Number(watchForm('monto')),
      montoTotalOperacion: 0,
      totalPagar: Number(watchForm('monto')),
    };

    const cuerpoDocumento = getValues('cuerpoDocumento') || [];
    itemCuerpoDocumento.itemResumenDocumento = itemResumenDocumento;

    setValue('cuerpoDocumento', [...cuerpoDocumento, itemCuerpoDocumento]);

    setValue('resumen', {
      tributos: resumenDocumento?.tributos || [],
      subTotal:
        (resumenDocumento?.subTotal ?? 0) + itemResumenDocumento.subTotal,
      ivaRete1:
        (resumenDocumento?.ivaRete1 ?? 0) + itemResumenDocumento.ivaRete1,
      ivaPerci1:
        (resumenDocumento?.ivaPerci1 ?? 0) + itemResumenDocumento.ivaPerci1,
      reteRenta:
        (resumenDocumento?.reteRenta ?? 0) + itemResumenDocumento.reteRenta,
      descuNoSuj:
        (resumenDocumento?.descuNoSuj ?? 0) + itemResumenDocumento.descuNoSuj,
      totalDescu:
        (resumenDocumento?.totalDescu ?? 0) + itemResumenDocumento.totalDescu,
      totalNoSuj:
        (resumenDocumento?.totalNoSuj ?? 0) + itemResumenDocumento.totalNoSuj,
      descuExenta:
        (resumenDocumento?.descuExenta ?? 0) + itemResumenDocumento.descuExenta,
      totalExenta:
        (resumenDocumento?.totalExenta ?? 0) + itemResumenDocumento.totalExenta,
      totalLetras:
        (resumenDocumento?.totalLetras ?? 0) + itemResumenDocumento.totalLetras,
      descuGravada:
        (resumenDocumento?.descuGravada ?? 0) +
        itemResumenDocumento.descuGravada,
      totalGravada:
        (resumenDocumento?.totalGravada ?? 0) +
        itemResumenDocumento.totalGravada,
      subTotalVentas:
        (resumenDocumento?.subTotalVentas ?? 0) +
        itemResumenDocumento.subTotalVentas,
      condicionOperacion: null,
      montoTotalOperacion:
        (resumenDocumento?.montoTotalOperacion ?? 0) +
        itemResumenDocumento.montoTotalOperacion,
      totalNoGravado:
        (resumenDocumento?.totalNoGravado ?? 0) +
        itemResumenDocumento.totalNoGravado,
      totalPagar:
        (resumenDocumento?.totalPagar ?? 0) + itemResumenDocumento.totalPagar,
    });

    reset();
  };

  useEffect(() => {
    setValueForm('documentosRelacionados', watch('documentoRelacionado'));

    if (
      Array.isArray(watch('documentoRelacionado')) &&
      watch('documentoRelacionado').length > 0
    ) {
      setValueForm('documentoRelacionado', {
        label: watch('documentoRelacionado')[0].numeroDocumentoRel,
        value: watch('documentoRelacionado')[0].numeroDocumentoRel,
      });
    }
  }, [formState, setValueForm, watch]);


  return (
    <>
      <CardWrapperTools
        title="Adición detalle de DTE de otros montos no afectos"

        footer={
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-lg"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              <span className="fa fa-plus me-1" />
              Agregar
            </button>
          </div>
        }
      >
        <div className="container">
          <div className="row g-3 mb-3">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="descripcion">
                Descripción:
              </label>
              <input
                className="form-control"
                name="descripcion"
                id="descripcion"
                type="text"
                placeholder="Digite la descripción"
                {...register('descripcion')}
                onFocus={(e) => e.target.select()}
              />
              {errors.descripcion && (
                <div className="invalid-feedback">
                  {errors.descripcion?.message}
                </div>
              )}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="monto">
                Monto:
              </label>

              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  name="monto"
                  id="monto"
                  type="number"
                  {...register('monto')}
                  onWheel={(e) => e.target.blur()}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setValueForm('monto', e.target.value);
                  }}
                  onBlur={(e) => {
                    setValueForm(
                      'monto',
                      parseFloat(e.target.value).toFixed(2),
                    );
                    if (e.target.value === '') {
                      setValueForm('monto', parseFloat(0).toFixed(2));
                    }
                  }}
                  placeholder="Digite el monto"
                />
              </div>
              {errors.monto && (
                <div className="invalid-feedback">{errors.monto?.message}</div>
              )}
            </div>

            {watch('documentoRelacionado') &&
            watch('documentoRelacionado').length > 0 ? (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label className="form-label" htmlFor="documentoRelacionado">
                  Documento Relacionado:
                </label>
                <Controller
                  name="documentoRelacionado"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      options={watch('documentoRelacionado').map((item) => ({
                        value: item.numeroDocumentoRel,
                        label: item.numeroDocumentoRel,
                      }))}
                      placeholder="Seleccione el documento relacionado"
                      {...field}
                    />
                  )}
                />
                {errors.documentoRelacionado && (
                  <div className="invalid-feedback">
                    {errors.documentoRelacionado?.message}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default TabItemNoAfecto;
