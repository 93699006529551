import React, { useCallback, useEffect, useRef, useState } from 'react';
import httpAuth from '../../../../services/http/http.auth.services';
import { get } from '../../../../services/http/http.service';
import { enqueueSnackbar } from 'notistack';
import ModalLibroIva from './ModalLibroIva';
import { loadingReload } from '../../../../services/alerts/alerts';

const DetalleLibrosIva = ({
  clienteId,
  month,
  year,
  ambiente,
  clienteNombre,
}) => {
  const [sucursales, setSucursales] = useState(null);
  const [sucursalId, setSucursalId] = useState(null);
  const modalLibroIvaRef = useRef(null);

  const getSucursales = useCallback(async () => {
    const { data } = await httpAuth.get(`sucursales/${clienteId}`);
    setSucursales(data);
  }, [clienteId]);

  useEffect(() => {
    async function fetchData() {
      await getSucursales();
    }

    fetchData();
  }, [getSucursales]);

  const handleObtenerLibro = async ({
    clienteId,
    sucursalId,
    month,
    year,
    ambiente,
    url,
    title,
  }) => {
    if (!sucursalId) {
      enqueueSnackbar(
        '¡Por favor seleccione una sucursal para generar el libro de IVA!',
        {
          variant: 'warning',
          preventDuplicate: true,
        },
      );
      return;
    }
    try {
      loadingReload(true, `Generando ${title}`);
      const { data } = await get(
        `${url}/${clienteId}/${sucursalId}?month=${month}&year=${year}&ambiente=${ambiente}`,
      );

      loadingReload(false);

      modalLibroIvaRef.current.generarReporte(
        data,
        title,
        `${title} ${clienteNombre} ${month}-${year}-${
          sucursales.results.find((sucursal) => sucursal._id === sucursalId)
            .tipo_establecimiento.value
        } ${
          sucursales.results.find((sucursal) => sucursal._id === sucursalId)
            .direccion
        }`,
      );
    } catch (error) {
      if (error?.response?.status === 404) {
        enqueueSnackbar(`¡No se encontraron datos para generar el ${title}!`, {
          variant: 'warning',
          preventDuplicate: true,
        });
        loadingReload(false);

        return;
      }
      enqueueSnackbar(`¡Ocurrió un error al generar el ${title}!`, {
        variant: 'error',
        preventDuplicate: true,
      });
      loadingReload(false);
    }
  };

  return (
    <div className="row g-3">
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-4">
        <label htmlFor="sucursal">Seleccione una sucursal:</label>
        <select
          id="sucursal"
          className="form-select"
          onChange={(e) => {
            setSucursalId(e.target.value);
          }}
        >
          <option value="" disabled selected>
            Seleccione una sucursal
          </option>

          {sucursales?.results
            ? sucursales?.results.map((sucursal) => (
                <option key={sucursal._id} value={sucursal._id}>
                  {sucursal.tipo_establecimiento?.value} - {sucursal.direccion}
                </option>
              ))
            : []}
        </select>
      </div>
      {sucursalId && (
        <div className="col-12">
          <div className="col-auto ms-auto">
            <div className="table-responsive">
              <table className="table table-striped border-bottom fs-1">
                <thead data-bs-theme="light">
                  <tr className="bg-primary text-white dark__bg-100">
                    <th className="border-0 text-center">Descripción</th>
                    <th className="border-0 text-center">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>LIBRO DE VENTAS A CONTRIBUYENTES</td>
                    <td className="text-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleObtenerLibro({
                            clienteId,
                            sucursalId,
                            month,
                            year,
                            ambiente,
                            url: 'libros-iva/contribuyente',
                            title: 'Libro de IVA Venta a Contribuyentes',
                          });
                        }}
                      >
                        Generar
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>LIBRO DE VENTAS A CONSUMIDOR FINAL</td>
                    <td className="text-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleObtenerLibro({
                            clienteId,
                            sucursalId,
                            month,
                            year,
                            ambiente,
                            url: 'libros-iva/consumidor-final',
                            title: 'Libro de IVA Venta consumidor final',
                          });
                        }}
                      >
                        Generar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <ModalLibroIva ref={modalLibroIvaRef} />
    </div>
  );
};

export default DetalleLibrosIva;
