import React, { useCallback, useEffect, useState } from 'react';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { getFormaDePago } from '../utils/FacturaV2';
import { enqueueSnackbar } from 'notistack';
import Select from 'react-select';
import { Alert } from '../../../../../services/alerts/alerts';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
const FormaPagoForm = () => {
  const {
    watch,
    setValue,
    formState,
    formState: { errors },
  } = useFormContext();

  const validationSchema = Yup.object().shape({
    condicionOperacion: Yup.string(),
    formaPago: Yup.object()
      .optional()
      .when('condicionOperacion', (condicionOperacion, schema) => {
        const [condicionOperacionValue] = condicionOperacion;
        return condicionOperacionValue === '1' ||
          condicionOperacionValue === '3'
          ? schema.required('Forma de pago es requerida')
          : schema.nullable();
      }),

    monto: Yup.number()
      .typeError('El precio debe ser un número')
      .required('El precio es requerido')
      .min(0.01, 'El precio debe ser mayor a 0.00'),

    plazo: Yup.object()
      .optional()
      .when('condicionOperacion', (condicionOperacion, schema) => {
        const [condicionOperacionValue] = condicionOperacion;
        return condicionOperacionValue === '2'
          ? schema.required('Plazo es requerido')
          : schema.nullable();
      }),

    periodo: Yup.string().when(
      'condicionOperacion',
      (condicionOperacion, schema) => {
        const [condicionOperacionValue] = condicionOperacion;
        return condicionOperacionValue === '2'
          ? schema
              .typeError('El período debe ser un número')
              .required('Período es requerido')
          : schema.nullable();
      },
    ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      formaPago: {
        label: 'Billetes y monedas',
        value: '01',
      },

      monto: '0.00',
      numeroDocumento: '',
      plazo: null,
      periodo: '',
    },
  };
  const {
    register: registerForm,
    control,
    setValue: setValueForm,
    getValues: getValuesForm,
    formState: { errors: errorsForm },
    handleSubmit,
    reset,
  } = useForm(formOptions);
  const [formasPago, setFormasPago] = useState([]);

  const getDataSelect = useCallback(async () => {
    try {
      const [dataFormasPago] = await Promise.all([getFormaDePago()]);

      setFormasPago(dataFormasPago);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los datos generales!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, []);

  useEffect(() => {
    async function getData() {
      await getDataSelect();
    }

    getData();
  }, [getDataSelect]);

  useEffect(() => {
    if (watch('pagos') && watch('pagos').length === 0) {
      setValueForm(
        'monto',

        watch('resumen') && watch('resumen')?.totalPagar
          ? parseFloat(
              watch('resumen')?.totalPagar -
                watch('descuentoGravada') -
                watch('descuentoExenta') -
                watch('descuentoNoSujeta') -
                watch('resumen')
                  ?.tributos.filter((t) => t.mode === 'porcentaje')
                  .reduce(
                    (acc, item) =>
                      acc + watch('descuentoGravada') * item.amount[0],
                    0,
                  ),
            ).toFixed(2)
          : '0.00',
      );
      return;
    }

    setValueForm('monto', '0.00');
  }, [formState, setValueForm, watch]);

  const onSubmit = () => {
    if (
      !watch('cuerpoDocumento') ||
      (watch('cuerpoDocumento') && watch('cuerpoDocumento').length === 0)
    ) {
      Alert({
        title: 'Error al agregar forma de pago',
        text: 'Debe agregar al menos un producto o servicio',
        icon: 'error',
      });
    }

    const itemPago = {
      codigo: getValuesForm('formaPago')?.value,
      formaPago: getValuesForm('formaPago'),
      montoPago: getValuesForm('monto'),
      referencia: getValuesForm('numeroDocumento'),
      plazo: getValuesForm('plazo') || null,
      periodo: getValuesForm('periodo') || null,
    };

    const pagos = watch('pagos') || [];

    pagos.push(itemPago);

    setValue('pagos', pagos);
    reset();
  };

  return (
    <>
      <CardWrapperTools title="Condición de la operación">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-4">
              <label htmlFor="">Condición de la operación:</label>

              <select
                id="condicionOperacion"
                onChange={(e) => {
                  setValue('condicionOperacion', e.target.value);
                  setValueForm('condicionOperacion', e.target.value);

                  if (e.target.value === '1') {
                    setValueForm('periodo', '');
                    setValueForm('plazo', null);
                    setValueForm('numeroDocumento', '');

                    setValueForm('formaPago', {
                      label: 'Billetes y monedas',
                      value: '01',
                    });
                  }

                  if (e.target.value === '2') {
                    setValueForm('formaPago', {
                      label: 'Billetes y monedas',
                      value: '01',
                    });
                  }

                  if (e.target.value === '3') {
                    setValueForm('formaPago', {
                      label: 'Billetes y monedas',
                      value: '01',
                    });
                  }
                }}
                defaultValue={'1'}
                className="form-select"
              >
                <option value="" disabled={true}>
                  Seleccione un opción
                </option>
                <option value="1">Contado</option>
                <option value="2">A crédito</option>
                <option value="3">Otro</option>
              </select>

              {errors.condicionOperacion && (
                <div className="invalid-feedback">
                  {errors.condicionOperacion?.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardWrapperTools>

      <CardWrapperTools
        title="Formas de pago"
        footer={
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-lg"
              onClick={handleSubmit(onSubmit)}
            >
              <span className="fas fa-plus me-1"></span>
              Agregar
            </button>
          </div>
        }
      >
        {errors.pagos && (
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="alert alert-danger" role="alert">
                {errors.pagos.message}
              </div>
            </div>
          </div>
        )}
        <div className="container">
          <div className="row g-3">
            {watch('condicionOperacion') === '1' ||
            watch('condicionOperacion') === '3' ? (
              <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                <label className="form-label" htmlFor="formaPago">
                  Forma de pago:
                </label>
                <Controller
                  name="formaPago"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      defaultValue={{
                        label: 'Billetes y monedas',
                        value: '01',
                      }}
                      options={formasPago}
                      placeholder="Seleccione o busque una opción"
                    />
                  )}
                />
                {errorsForm.formaPago && (
                  <div className="invalid-feedback">
                    {errorsForm.formaPago?.message}
                  </div>
                )}
              </div>
            ) : null}

            <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
              <label className="form-label" htmlFor="monto">
                Monto:
              </label>

              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  name="monto"
                  id="monto"
                  type="number"
                  placeholder="Digite el monto"
                  {...registerForm('monto')}
                  onWheel={(e) => e.target.blur()}
                  onFocus={(e) => e.target.select()}
                  onBlur={(e) => {
                    setValueForm(
                      'monto',
                      parseFloat(e.target.value).toFixed(2),
                    );

                    if (e.target.value === '') {
                      setValueForm('monto', parseFloat(0).toFixed(2));
                    }
                  }}
                />
              </div>

              {errorsForm.monto && (
                <div className="invalid-feedback">
                  {errorsForm.monto?.message}
                </div>
              )}
            </div>

            {watch('condicionOperacion') === '2' ||
            watch('condicionOperacion') === '3' ? (
              <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                <label htmlFor="">Número de documento:</label>
                <input
                  className="form-control"
                  id="numeroDocumento"
                  name="numeroDocumento"
                  type="text"
                  placeholder="Digite el número de documento"
                  {...registerForm('numeroDocumento')}
                />
              </div>
            ) : null}

            {watch('condicionOperacion') === '2' ||
            watch('condicionOperacion') === '3' ? (
              <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                <label className="form-label" htmlFor="plazo">
                  Plazo:
                </label>
                <Controller
                  name="plazo"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      options={[
                        { label: 'Días', value: '01' },
                        { label: 'Meses', value: '02' },
                        { label: 'Años', value: '03' },
                      ]}
                      placeholder="Seleccione una opción"
                    />
                  )}
                />

                {errorsForm.plazo && (
                  <div className="invalid-feedback">
                    {errorsForm.plazo?.message}
                  </div>
                )}
              </div>
            ) : null}

            {watch('condicionOperacion') === '2' ||
            watch('condicionOperacion') === '3' ? (
              <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                <label htmlFor="">Período:</label>
                <input
                  className="form-control"
                  id="periodo"
                  name="periodo"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onFocus={(e) => e.target.select()}
                  placeholder="Digite el período"
                  {...registerForm('periodo')}
                />
                {errorsForm.periodo && (
                  <div className="invalid-feedback">
                    {errorsForm.periodo?.message}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </CardWrapperTools>

      {watch('pagos') && watch('pagos').length > 0 && (
        <CardWrapperTools title="Detalles de pago">
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-hover table-striped table-bordered border-3 overflow-hidden">
                    <thead className="table-primary">
                      <tr>
                        <th scope="col" className="text-center">
                          Forma de Pago
                        </th>
                        <th scope="col" className="text-center">
                          Monto
                        </th>
                        <th scope="col" className="text-center">
                          Número de Documento
                        </th>
                        <th scope="col" className="text-center">
                          Plazo
                        </th>
                        <th scope="col" className="text-center">
                          Período
                        </th>
                        <th scope="col" className="text-center">
                          Acciones
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {watch('pagos') &&
                        watch('pagos').map((pago, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              {pago.formaPago.label}
                            </td>
                            <td className="text-center">${pago.montoPago}</td>
                            <td className="text-center">
                              {pago.referencia || 'N/A'}
                            </td>
                            <td className="text-center">
                              {pago.plazo?.label || 'N/A'}
                            </td>
                            <td className="text-center">
                              {pago.periodo || 'N/A'}
                            </td>
                            <td className="text-center">
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                  const pagos = watch('pagos');

                                  pagos.splice(index, 1);

                                  setValue('pagos', pagos);
                                }}
                              >
                                <span className="fas fa-trash"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </CardWrapperTools>
      )}
    </>
  );
};

export default FormaPagoForm;
