import React, { useCallback, useEffect, useState } from 'react';
import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import { get, patch } from '../../../services/http/http.service';
import {
  Alert,
  AlertQuestionYesNo,
  loadingReload,
} from '../../../services/alerts/alerts';

const ColorPicker = ({ id }) => {
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [textColor, setTextColor] = useState('#ffffff');

  const handleBackgroundColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleTextColorChange = (e) => {
    setTextColor(e.target.value);
  };

  const getColores = useCallback(async () => {
    const { data } = await get(`/clientes/colores/${id}`);
    setBackgroundColor(data.color_tabla);
    setTextColor(data.color_tabla_texto);
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      await getColores();
    }

    fetchData();
  }, [getColores]);

  const updateClientData = async () => {
    const question = await AlertQuestionYesNo({
      title: 'Color de documentos',
      text: '¿Esta seguro de cambiar los colores de los documentos?',
      icon: 'question',
    });

    if (!question) return;
    loadingReload(true, 'Actualizando colores');

    const payload = {
      clienteId: id,
      colorTable: backgroundColor,
      colorText: textColor,
    };
    const data = await patch(`clientes/colores`, payload);

    loadingReload(false, null);

    if (data.status === 200) {
      loadingReload(false, null);
      Alert({
        title: 'Guardado',
        text: 'Se guardaron los colores de los documentos correctamente',
        icon: 'success',
      });
    }
  };

  return (
    <CardWrapperTools
      title="Configuración de colores de factura"
      footer={
        <div className="d-flex justify-content-center">
          <button
            type="button btn-lg"
            className="btn btn-primary"
            onClick={updateClientData}
          >
            <i className="fas fa-save" /> Guardar
          </button>
        </div>
      }
    >
      <div className="container">
        <div className="row">
          {/* Color selection for background */}
          <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <label htmlFor="backgroundColor">Color de fondo Tabla (Hex):</label>
            <input
              type="text"
              className="form-control"
              id="backgroundColor"
              value={backgroundColor}
              onChange={handleBackgroundColorChange}
              placeholder="#ffffff"
            />
          </div>

          {/* Color selection for text */}
          <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <label htmlFor="textColor">Color de texto (Hex):</label>
            <input
              type="text"
              className="form-control"
              id="textColor"
              value={textColor}
              onChange={handleTextColorChange}
              placeholder="#000000"
            />
          </div>
        </div>

        {/* Example text to display the selected colors */}
        <div
          className="p-3 mt-4 col-12"
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
            border: '1px solid #ccc',
          }}
        >
          <p>Esto es un ejemplo de texto con los colores seleccionados.</p>
        </div>
      </div>
    </CardWrapperTools>
  );
};

export default ColorPicker;
