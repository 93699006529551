import React, { forwardRef, useImperativeHandle } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

const ModalWrapper = forwardRef(
  (
    {
      title,
      children,
      backdrop = 'static',
      size = 'xl',
      footer = null,
      style,
      setValue,
    },
    ref,
  ) => {
    const [visible, setVisible] = React.useState(false);
    const toggle = () => {
      if (visible && setValue) {
        setValue('reload_detalle', true);
      }
      setVisible(!visible);
    };

    useImperativeHandle(ref, () => ({
      toggle() {
        setVisible(!visible);
        document.body.className = '';
        document.body.style = '';
      },
    }));
    return (
      <Modal
        isOpen={visible}
        toggle={toggle}
        backdrop={backdrop}
        size={size}
        style={style}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>{footer ? footer : defaultFooter(toggle)}</ModalFooter>
      </Modal>
    );
  },
);

const defaultFooter = (toggle) => {
  return (
    <div className="align-content-end">
      <button type="button" onClick={toggle} className="btn btn-secondary">
        Cerrar
      </button>
    </div>
  );
};
export default ModalWrapper;
