import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { redondearA2Decimales } from '../../../../../utils/Utils';
import { enqueueSnackbar } from 'notistack';
import ModalWrapper from '../../../../../Components/Common/Modals/Modal';

const DescuentoGeneralForm = forwardRef(
  ({ resumen, updateResumenDataOnglobalDiscount }, ref) => {
    const modalRef = useRef(null);

    const validationSchema = Yup.object().shape({
      descuentoVentaExenta: Yup.number()
        .min(0, 'El descuento debe ser mayor o igual a 0')
        .max(
          resumen.totalVentaExenta,
          'El descuento no puede ser mayor al total de ventas exentas',
        )
        .required('El descuento es obligatorio'),
      descuentoVentaGravada: Yup.number()
        .min(0, 'El descuento debe ser mayor o igual a 0')
        .max(
          resumen.totalVentaGravada,
          'El descuento no puede ser mayor al total de ventas gravadas',
        )
        .required('El descuento es obligatorio'),
      descuentoVentaNoSujeta: Yup.number()
        .min(0, 'El descuento debe ser mayor o igual a 0')
        .max(
          resumen.totalVentaNoSujeta,
          'El descuento no puede ser mayor al total de ventas no sujetas',
        )
        .required('El descuento es obligatorio'),
    });
    const formOptions = {
      resolver: yupResolver(validationSchema),
      defaultValues: {
        descuentoVentaExenta: 0.0,
        descuentoVentaGravada: 0.0,
        descuentoVentaNoSujeta: 0.0,
      },
    };
    const {
      getValues,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm(formOptions);

    const onSubmit = async () => {
      try {
        let descuentoVentaExenta = redondearA2Decimales(
          getValues('descuentoVentaExenta'),
        );
        let descuentoVentaGravada = redondearA2Decimales(
          getValues('descuentoVentaGravada'),
        );
        let descuentoVentaNoSujeta = redondearA2Decimales(
          getValues('descuentoVentaNoSujeta'),
        );
        updateResumenDataOnglobalDiscount(
          descuentoVentaGravada,
          descuentoVentaExenta,
          descuentoVentaNoSujeta,
        );
        enqueueSnackbar('Descuentos aplicados correctamente', {
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (error) {
        enqueueSnackbar('Error al aplicar descuentos', {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    };
    useImperativeHandle(ref, () => ({
      async showDescuentoModal() {
        modalRef.current.toggle();
      },
    }));

    return (
      <ModalWrapper
        ref={modalRef}
        title={'Descuentos generales'}
        footer={(toggle) => {
          return customFooter(toggle, handleSubmit, onSubmit);
        }}
      >
        <div className="p-2">
          <div className="row g-3">
            {renderDescuentoExento(resumen.totalVentaExenta, register, errors)}
            {renderDescuentoGravado(
              resumen.totalVentaGravada,
              register,
              errors,
            )}
            {renderDescuentoNoSujeto(
              resumen.totalVentaNoSujeta,
              register,
              errors,
            )}
            {renderDefault(
              resumen.totalVentaNoSujeta,
              resumen.totalVentaExenta,
              resumen.totalVentaGravada,
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  },
);

const customFooter = (toggle, handleSubmit, onSubmit) => {
  return (
    <div className="d-flex align-items-center justify-content-end">
      <button
        className="btn btn-lg btn-primary"
        type="button"
        onClick={(e) => {
          handleSubmit(async () => {
            onSubmit();
            toggle();
          })();
        }}
      >
        <span className="fas fa-plus"></span>
        <span className=""> Actualizar descuentos</span>
      </button>
    </div>
  );
};

const renderDescuentoGravado = (totalGravado, register, errors) => {
  if (totalGravado != null && totalGravado > 0) {
    return (
      <div className="col-4">
        <label className="form-label" htmlFor="descuentoVentaGravada">
          Ventas Gravadas
        </label>
        <input
          className="form-control"
          id="descuentoVentaGravada"
          type="number"
          placeholder="0.00"
          {...register('descuentoVentaGravada')}
          onWheel={(event) => event.target.blur()}
          onClick={(event) => event.target.select()}
        />
        {errors.descuentoVentaGravada && (
          <div className="invalid-feedback">
            {errors.descuentoVentaGravada?.message}
          </div>
        )}
      </div>
    );
  }
};

const renderDescuentoExento = (totalExento, register, errors) => {
  if (totalExento != null && totalExento > 0) {
    return (
      <div className="col-4">
        <label className="form-label" htmlFor="descuentoVentaExenta">
          Ventas Exentas
        </label>
        <input
          className="form-control"
          id="descuentoVentaExenta"
          type="number"
          placeholder="0.00"
          {...register('descuentoVentaExenta')}
          onWheel={(event) => event.target.blur()}
          onClick={(event) => event.target.select()}
        />
        {errors.descuentoVentaExenta && (
          <div className="invalid-feedback">
            {errors.descuentoVentaExenta?.message}
          </div>
        )}
      </div>
    );
  }
};

const renderDescuentoNoSujeto = (totalNoSujeto, register, errors) => {
  if (totalNoSujeto != null && totalNoSujeto > 0) {
    return (
      <div className="col-4">
        <label className="form-label" htmlFor="descuentoVentaNoSujeta">
          Ventas No Sujetas
        </label>
        <input
          className="form-control"
          id="descuentoVentaNoSujeta"
          type="number"
          placeholder="0.00"
          {...register('descuentoVentaNoSujeta')}
          onWheel={(event) => event.target.blur()}
          onClick={(event) => event.target.select()}
        />
        {errors.descuentoVentaNoSujeta && (
          <div className="invalid-feedback">
            {errors.descuentoVentaNoSujeta?.message}
          </div>
        )}
      </div>
    );
  }
};

const renderDefault = (totalNoSujeto, totalExento, totalGravado) => {
  if (totalNoSujeto <= 0 && totalExento <= 0 && totalGravado <= 0) {
    return (
      <div className="col-12">
        <span>Debes agregar ventas para agregar descuentos</span>
      </div>
    );
  }
};

export default DescuentoGeneralForm;
