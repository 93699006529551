import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { clearSession } from '../encrytion/encryption.service';

const httpConfig = axios.create({
  baseURL: process.env.REACT_APP_API_BACKEND_SERVICE_URL, // URL base para el servicio 1
  headers: {
    'Content-Type': 'application/json',
    // Otros encabezados personalizados para el servicio 1
  },
});

// Agregar un interceptor para manejar las respuestas
httpConfig.interceptors.response.use(
  (response) => {
    // Si la respuesta es exitosa, la devueleve directamente
    return response;
  },
  (error) => {
    // Si hay un error en la respuesta, verificar si el status es 401
    if (error.response && error.response.status === 401) {
      // Verificar si la URL actual no es la de inicio de sesión
      if (window.location.pathname !== '/login') {
        // Si no es la URL de inicio de sesión, redirige a la página de inicio de sesión
        enqueueSnackbar(
          'Token de sesión expirado, por favor inicie sesión nuevamente.',
          {
            variant: 'error',
            preventDuplicate: true,
          },
        );
        clearSession();
        window.location.href = '/login';
      }
    }

    // Si no es un error 401, simplemente pasa el error
    return Promise.reject(error);
  },
);

export default httpConfig;
