/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import CardWrapperTools from '../../Components/Common/Cards/CardWrapperTools';
import { get, patch, post } from '../../services/http/http.service';
import { Alert, loadingReload } from '../../services/alerts/alerts';
import { getSession } from '../../services/encrytion/encryption.service';

const UNIDAD_MEDIDA_DEFAULT = {
  value: '6513ab12db1b25f1cb239ca0',
  code: 59,
  slug: 'unidad',
  label: 'Unidad',
};

const CreateProductos = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [unidadMedida, setUnidadMedida] = useState([]);

  const validationSchema = Yup.object().shape({
    nombre_producto: Yup.string().required('Nombre producto es requerido.'),
    unidad_medida: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
    tipo: Yup.object()
      .shape({
        label: Yup.string().required('Tipo de producto es requerido'),
        value: Yup.string().required('Tipo de producto es requerido'),
      })
      .required('Tipo de producto es requerido'),
    precio: Yup.string().required('Precio es requerido'),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [cliente, setCliente] = useState({});

  useEffect(() => {
    loadUnidadMedida();
    if (id) {
      loadProductosById(id);
    }
    const cliente = getSession('clienteSeleccionado');
    setCliente(cliente);
  }, [watch, setValue]);

  const loadProductosById = async (id) => {
    const { data } = await get(`productos/${id}`);
    const options = {
      value: data.tipo.id,
      label: data.tipo.value,
      code: data.tipo.code,
      slug: data.tipo.slug,
    };
    setValue('nombre_producto', data.nombre_producto);
    setValue('codigoProducto1', data.codigo_producto);
    setValue('codigoProducto2', data.codigo_producto2);
    setValue('codigoProducto3', data.codigo_producto3);
    setValue('precio', data.precio);
    setValue('precio_iva', data.precio_iva);
    setValue('tipo', options);
    setValue('unidad_medida', {
      value: data.unidad_medida?.id,
      label: data.unidad_medida?.value,
      code: data.unidad_medida?.code,
      slug: data.unidad_medida?.slug,
    });
  };

  const loadTipoProducto = async () => {
    const { data } = await get('generales/tipos-item');

    const options = data.map((item) => ({
      code: item.code,
      value: item.id,
      slug: item.slug,
      label: item.value,
    }));
    return options;
  };

  const loadUnidadMedida = async () => {
    const { data } = await get('generales/unidades-medida');
    const options = data.map((item) => ({
      code: item.code,
      slug: item.slug,
      value: item.id,
      label: item.value,
    }));
    setUnidadMedida(options);
    return options;
  };

  const onSubmit = async (datos) => {
    loadingReload(true, id ? 'Actualizando Producto' : 'Guardando Producto');
    try {
      const payload = {
        nombre_producto: datos.nombre_producto.trim(),
        slug: datos.nombre_producto.toLowerCase().trim().replace(/ /g, '-'),
        codigo_producto: datos?.codigoProducto1.trim() ?? '',
        codigo_producto2: datos?.codigoProducto2.trim() ?? '',
        codigo_producto3: datos?.codigoProducto3.trim() ?? '',
        precio: Number(datos.precio),
        precio_iva: Number(datos.precio_iva),
        tipo: {
          id: datos.tipo.value,
          code: datos.tipo.code,
          slug: datos.tipo.slug,
          value: datos.tipo.label,
        },
        unidad_medida: {
          id: datos.unidad_medida.value ?? UNIDAD_MEDIDA_DEFAULT.value,
          code: datos.unidad_medida.code ?? UNIDAD_MEDIDA_DEFAULT.code,
          slug: datos.unidad_medida.slug ?? UNIDAD_MEDIDA_DEFAULT.slug,
          value: datos.unidad_medida.label ?? UNIDAD_MEDIDA_DEFAULT.label,
        },
        cliente_id: cliente.id,
        cliente: {
          id: cliente.id,
          nit: cliente.nit,
          nrc: cliente.nrc,
          correo: cliente.correo,
          nombre: cliente.nombre,
          nombre_comercial: cliente.nombre_comercial,
          telefono: cliente.telefono,
        },
      };
      if (id) {
        const { data } = await patch(`/productos/${id}`, payload);
        if (data) {
          loadingReload(false, null);
          Alert({
            title: 'Creación de producto',
            icon: 'success',
            text: 'producto se creardo correctamente',
          });
          navigate('/productos');
        }
      } else {
        const { data } = await post('/productos', payload);
        if (data) {
          loadingReload(false, null);
          Alert({
            title: 'Creación de producto',
            icon: 'success',
            text: 'producto se creardo correctamente',
          });
          navigate('/productos');
        }
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al guardar los datos',
        icon: 'error',
      });
    }
  };

  return (
    <Fragment>
      <CardWrapperTools
        title="Crear Producto"
        footer={
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-secondary btn-lg"
                  style={{ marginRight: '20px' }}
                  onClick={() => navigate('/productos')}
                >
                  <span className="fas fa-arrow-left"></span> Salir
                </button>
                <button
                  className="btn btn-primary btn-lg"
                  onClick={handleSubmit(onSubmit)}
                >
                  <span
                    style={{ marginRight: '5px' }}
                    className="fas fa-paper-plane"
                  ></span>
                  {id ? 'Actualizar' : 'Guardar'}
                </button>
              </div>
            </div>
          </div>
        }
      >
        <div className="row g-3">
          <div className="col-md-3">
            <label htmlFor="nombre">Nombre:</label>
            <input
              type="text"
              name="nombre_producto"
              id="nombre_producto"
              placeholder="Nombre de producto"
              className={`form-control ${
                errors.nombre_producto ? 'is-invalid' : ''
              }`}
              {...register('nombre_producto')}
            />
            {errors.nombre_producto && (
              <div className="invalid-feedback">
                {errors.nombre_producto.nombre_producto}
              </div>
            )}
          </div>
          <div className="col-md-3">
            <label className="form-label" htmlFor="tipo">
              Unidad de medida:
            </label>

            <Controller
              name="unidad_medida"
              id="unidad_medida"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={unidadMedida}
                  defaultValue={UNIDAD_MEDIDA_DEFAULT}
                  placeholder="Unidad de medida"
                />
              )}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label" htmlFor="tipo">
              Tipo de producto:
            </label>

            <Controller
              name="tipo"
              id="tipo"
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  cacheOptions
                  loadOptions={loadTipoProducto}
                  defaultOptions
                  placeholder="Tipo de producto"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: errors.tipo ? 'red' : provided.borderColor,
                    }),
                  }}
                />
              )}
            />
            {errors.tipo && (
              <div className="invalid-feedback">
                {'Tipo de producto es requerido'}
              </div>
            )}
          </div>
          <div className="col-md-3">
            <label htmlFor="precio">Precio sin IVA:</label>
            <input
              type="text"
              name="precio"
              id="precio"
              placeholder="Precio sin IVA"
              className={`form-control ${errors.precio ? 'is-invalid' : ''}`}
              {...register('precio')}
              onChange={(e) => {
                const precioIva = e.target.value !== '' ? e.target.value : 0;

                const tasaIva = parseFloat(process.env.REACT_APP_IVA) || 13;

                const porcentajeIva = tasaIva / 100;
                const divisorIva = 1 + porcentajeIva;
                const precioSinIva = precioIva * divisorIva;
                setValue('precio_iva', precioSinIva.toFixed(2).toString());
              }}
            />
            {errors.precio && (
              <div className="invalid-feedback">{errors.precio.precio}</div>
            )}
          </div>

          <div className="col-md-3">
            <label htmlFor="precio_iva">Precio IVA incluido:</label>
            <input
              type="text"
              name="precio_iva"
              id="precio_iva"
              placeholder="Precio + IVA"
              className={`form-control ${
                errors.precio_iva ? 'is-invalid' : ''
              }`}
              {...register('precio_iva')}
              onChange={(e) => {
                const precioIva = e.target.value !== '' ? e.target.value : 0;

                const tasaIva = parseFloat(process.env.REACT_APP_IVA) || 13;

                const porcentajeIva = tasaIva / 100;
                const divisorIva = 1 + porcentajeIva;
                const precioSinIva = precioIva / divisorIva;
                setValue('precio', precioSinIva.toFixed(2).toString());
              }}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="codigoProducto1">Código:</label>
            <input
              type="text"
              name="codigoProducto1"
              id="codigoProducto1"
              placeholder="Código de producto"
              className={`form-control ${
                errors.codigoProducto1 ? 'is-invalid' : ''
              }`}
              {...register('codigoProducto1')}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="precio_iva">Código 2:</label>
            <input
              type="text"
              name="codigoProducto2"
              id="codigoProducto2"
              placeholder="Código 2"
              className={`form-control ${
                errors.codigoProducto2 ? 'is-invalid' : ''
              }`}
              {...register('codigoProducto2')}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="precio_iva">Código 3:</label>
            <input
              type="text"
              name="codigoProducto2"
              id="codigoProducto2"
              placeholder="Código 3"
              className={`form-control ${
                errors.codigoProducto2 ? 'is-invalid' : ''
              }`}
              {...register('codigoProducto3')}
            />
          </div>
        </div>
      </CardWrapperTools>
    </Fragment>
  );
};

export { CreateProductos };
