import React from 'react';
import { getSession } from '../../../../services/encrytion/encryption.service';
import { NavLink } from 'react-router-dom';
import { CollapseNav } from '../../../../utils/Front.Utils';

const DteMenu = () => {
  const modulos = getSession('modulos');
  return (
    <li className="nav-item">
      {/* label*/}
      <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
        <div className="col-auto navbar-vertical-label">
          Sistema de facturación
        </div>
        <div className="col ps-0">
          <hr className="mb-0 navbar-vertical-divider" />
        </div>
      </div>
      {/* parent pages*/}
      <a
        className="nav-link dropdown-indicator"
        href="#dte"
        role="button"
        data-bs-toggle="collapse"
        aria-expanded="false"
        aria-controls="dte"
      >
        <div className="d-flex align-items-center">
          <span className="nav-link-icon">
            <span className="fas fa-file-invoice-dollar" />
          </span>
          <span className="nav-link-text ps-1">Facturador</span>
        </div>
      </a>

      <ul className="nav collapse" id="dte">
        {modulos.includes('f6670a5f-777c-41a9-a251-9473dcfb88f9') && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/dte">
              <div className="d-flex align-items-center" {...CollapseNav()}>
                <span className="nav-link-text ps-1">Envío de DTE</span>
              </div>
            </NavLink>
            {/* more inner pages*/}
          </li>
        )}

        {modulos.includes('aab63cb6-8aa8-422c-801a-20f1154a3b56') && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/dte/consulta">
              <div className="d-flex align-items-center" {...CollapseNav()}>
                <span className="nav-link-text ps-1">Consulta de DTE</span>
              </div>
            </NavLink>
            {/* more inner pages*/}
          </li>
        )}

        {modulos.includes('432de068-2908-4c49-9507-b81dbc34ea7d') && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/dte/contingencia">
              <div className="d-flex align-items-center" {...CollapseNav()}>
                <span className="nav-link-text ps-1">
                  Envío de DTE en contigencia
                </span>
              </div>
            </NavLink>
            {/* more inner pages*/}
          </li>
        )}
      </ul>
      {modulos.includes('f6670a5f-777c-41a9-a251-9473dcfb88f9') && (
        <NavLink className="nav-link" to="/productos">
          <div className="d-flex align-items-center" {...CollapseNav()}>
            <span className="nav-link-icon">
              <span className="fas fa-cash-register" />
            </span>
            <span className="nav-link-text ps-1">Productos/Servicios</span>
          </div>
        </NavLink>
      )}
      {modulos.includes('f6670a5f-777c-41a9-a251-9473dcfb88f9') && (
        <NavLink className="nav-link" to="/mis-clientes">
          <div className="d-flex align-items-center" {...CollapseNav()}>
            <span className="nav-link-icon">
              <span className="fas fa-user-friends" />
            </span>
            <span className="nav-link-text ps-1">Mis clientes</span>
          </div>
        </NavLink>
      )}
    </li>
  );
};

export default DteMenu;
