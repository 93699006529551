import React, { useCallback, useEffect, useState } from 'react';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import {
  getUnidadesMedida,
  tiposProductos,
} from '../../utils/FacturaSujetoExcluido';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const TabProductosServicios = () => {
  const { getValues, setValue, watch: watchCtx, formState } = useFormContext();
  const validationSchema = Yup.object().shape({
    tipo: Yup.object().required('El tipo es requerido'),
    unidadMedida: Yup.object().required('La unidad de medida es requerida'),
    producto: Yup.string().required('El producto es requerido'),
    cantidad: Yup.number()
      .typeError('La cantidad debe ser un número')
      .required('La cantidad es requerida')
      .min(1, 'El precio debe ser mayor a 0.00'),
    precio: Yup.number()
      .typeError('El precio debe ser un número')
      .required('El precio es requerido')
      .min(0.01, 'El precio debe ser mayor a 0.00'),
    descuento: Yup.number().typeError('El descuento debe ser un número'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tipo: {
        value: 1,
        label: 'Bien',
      },
      unidadMedida: {
        value: 59,
        label: 'Unidad',
      },
      tipoVenta: {
        value: 1,
        label: 'Gravado',
      },
      producto: '',
      cantidad: '0',
      precio: '0.00',
      descuento: '0.00',
    },
  };
  const {
    control,
    register,
    setValue: setValueForm,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const [unidadesMedida, setUnidadesMedida] = useState([]);

  const getDataSelect = useCallback(async () => {
    const [unidadesMedidaData] = await Promise.all([getUnidadesMedida()]);

    setUnidadesMedida(unidadesMedidaData);
    try {
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los datos generales de panel de producto!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, []);

  useEffect(() => {
    async function getData() {
      await getDataSelect();
    }

    getData();
  }, [getDataSelect]);

  const onSubmit = () => {
    const itemCuerpoDocumento = {
      numItem: null,
      tipoItem: watch('tipo')?.value,
      cantidad: Number(watch('cantidad')),
      codigo: Date.now(),
      uniMedida: watch('unidadMedida')?.value,
      descripcion: watch('producto'),
      precioUni: watch('precio'),
      montoDescu: watch('descuento'),
      compra: watch('precio') * watch('cantidad') - watch('descuento'),
      itemResumenDocumento: null,
    };

    const descuento = Number(watch('descuento')) || 0;
    const resumenDocumento = getValues('resumen') || {};
    const precioConDescuento = watch('precio') * watch('cantidad') - descuento;
    const itemResumenDocumento = {
      totalCompra: precioConDescuento,
      subTotal: precioConDescuento,
      descu: descuento,
      ivaRete1: 0,
      reteRenta: 0,
      totalPagar: precioConDescuento,
    };

    const cuerpoDocumento = getValues('cuerpoDocumento') || [];
    itemCuerpoDocumento.itemResumenDocumento = itemResumenDocumento;
    setValue('cuerpoDocumento', [...cuerpoDocumento, itemCuerpoDocumento]);

    const totalAPagarSinRenta =
      (resumenDocumento?.subTotal ?? 0) + itemResumenDocumento.totalPagar;
    setValue('resumen', {
      ivaRete1: 0,
      reteRenta: '0.00',

      totalLetras: resumenDocumento?.totalLetras,
      descu: resumenDocumento?.descu ?? 0,
      totalDescuItems:
        (resumenDocumento?.totalDescuItems ?? 0) + itemResumenDocumento.descu,
      totalDescu:
        (resumenDocumento?.totalDescu ?? 0) + +itemResumenDocumento.descu,
      totalCompra:
        (resumenDocumento?.totalCompra ?? 0) + itemResumenDocumento.totalCompra,
      condicionOperacion: resumenDocumento.condicionOperacion,
      subTotal:
        (resumenDocumento?.subTotal ?? 0) + itemResumenDocumento.subTotal,
      totalPagar: totalAPagarSinRenta,
    });

    reset();
  };

  useEffect(() => {
    setValueForm('documentosRelacionados', watchCtx('documentoRelacionado'));
  }, [formState, setValueForm, watchCtx]);
  return (
    <>
      <CardWrapperTools
        title="Adición detalle de DTE de productos y servicios"
        footer={
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-lg"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              <span className="fa fa-plus me-1"></span>
              Agregar
            </button>
          </div>
        }
      >
        <div className="container-fluid">
          <div className="row g-3 mb-3">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="tipo">
                Tipo:
              </label>
              <Controller
                name="tipo"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    options={tiposProductos}
                    {...field}
                  />
                )}
              />
              {errors.tipo && (
                <div className="invalid-feedback">{errors.tipo?.message}</div>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="unidadMedida">
                Unidad de medida:
              </label>
              <Controller
                name="unidadMedida"
                control={control}
                render={({ field }) => (
                  <Select options={unidadesMedida} {...field} />
                )}
              />
              {errors.unidadMedida && (
                <div className="invalid-feedback">
                  {errors.unidadMedida?.message}
                </div>
              )}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="producto">
                Producto/Servicio:
              </label>
              <input
                className="form-control"
                name="producto"
                id="producto"
                type="text"
                placeholder="Digite el nombre del producto o servicio"
                {...register('producto')}
                onFocus={(e) => e.target.select()}
              />
              {errors.producto && (
                <div className="invalid-feedback">
                  {errors.producto?.message}
                </div>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="cantidad">
                Cantidad:
              </label>
              <input
                className="form-control"
                name="cantidad"
                id="cantidad"
                type="number"
                placeholder="Digite la cantidad"
                {...register('cantidad')}
                onChange={(e) => {
                  setValueForm('cantidad', e.target.value);
                }}
                onWheel={(e) => e.target.blur()}
                onFocus={(e) => e.target.select()}
              />
              {errors.cantidad && (
                <div className="invalid-feedback">
                  {errors.cantidad?.message}
                </div>
              )}
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="precio">
                Precio:
              </label>

              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  name="precio"
                  id="precio"
                  type="number"
                  {...register('precio')}
                  onChange={(e) => {
                    setValueForm('precio', e.target.value);
                  }}
                  onWheel={(e) => e.target.blur()}
                  onFocus={(e) => e.target.select()}
                  onBlur={(e) => {
                    setValueForm(
                      'precio',
                      parseFloat(e.target.value).toFixed(2),
                    );
                    if (e.target.value === '') {
                      setValueForm('precio', parseFloat(0).toFixed(2));
                    }
                  }}
                  placeholder="Digite el precio"
                />
              </div>
              {errors.precio && (
                <div className="invalid-feedback">{errors.precio?.message}</div>
              )}
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12">
              <label className="form-label" htmlFor="descuento">
                Descuento:
              </label>

              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input
                  className="form-control"
                  name="descuento"
                  id="descuento"
                  type="number"
                  placeholder="Digite el descuento"
                  {...register('descuento')}
                  onWheel={(e) => e.target.blur()}
                  onFocus={(e) => e.target.select()}
                  onBlur={(e) => {
                    setValueForm(
                      'descuento',
                      parseFloat(e.target.value).toFixed(2),
                    );

                    if (e.target.value === '') {
                      setValueForm('descuento', parseFloat(0).toFixed(2));
                    }
                  }}
                />
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12 text-center">
              <label htmlFor="">SubTotal:</label>
              <h3 className="text-primary">
                $
                {watch('cantidad') > 0 && watch('precio') > 0
                  ? (watch('precio') * watch('cantidad')).toFixed(2)
                  : '0.00'}
              </h3>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 text-center">
              <label htmlFor="">Total:</label>
              <h3 className="text-primary">
                $
                {watch('cantidad') > 0 && watch('precio') > 0
                  ? (
                      watch('precio') * watch('cantidad') -
                      watch('descuento')
                    ).toFixed(2)
                  : '0.00'}
              </h3>
            </div>
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default TabProductosServicios;
