import { get } from '../../../services/http/http.service';

export const getAnexoVentaConsumidorFinal = async ({
  clienteId,
  month,
  year,
  ambiente,
}) => {
  const { data } = await get(`anexos/consumidor-final/${clienteId}`, {
    clienteId,
    month,
    year,
    ambiente,
  });

  return data;
};

export const getAnexoVentaContribuyente = async ({
  clienteId,
  month,
  year,
  ambiente,
}) => {
  const { data } = await get(`anexos/contribuyentes/${clienteId}`, {
    clienteId,
    month,
    year,
    ambiente,
  });

  return data;
};

export const getAnexoAnulacionesRequest = async ({
  clienteId,
  month,
  year,
  ambiente,
}) => {
  const { data } = await get(`anexos/anulaciones/${clienteId}`, {
    clienteId,
    month,
    year,
    ambiente,
  });

  return data;
};

export const getAnexoComprasSujetoExcluidoRequest = async ({
  clienteId,
  month,
  year,
  ambiente,
}) => {
  const { data } = await get(`anexos/sujetos-excluidos/${clienteId}`, {
    clienteId,
    month,
    year,
    ambiente,
  });

  return data;
};
