import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es';
import { Alert } from '../../../../../services/alerts/alerts';
import { post } from '../../../../../services/http/http.service';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const documentosReceptor = [
  {
    value: '13',
    label: 'DUI',
  },
  {
    value: '36',
    label: 'NIT',
  },
  {
    value: '03',
    label: 'Pasaporte',
  },
  {
    value: '02',
    label: 'Carnet de Residente',
  },

  {
    value: '37',
    label: 'Otro',
  },
];

export const DteType = {
  COMPROBANTE_CREDITO_FISCAL: '03',
  COMPROBANTE_DONACION: '15',
  COMPROBANTE_LIQUIDACION: '08',
  COMPROBANTE_RETENCION: '07',
  DOCUMENTO_CONTABLE_LIQUIDACION: '09',
  FACTURA: '01',
  FACTURA_EXPORTACION: '11',
  FACTURA_SUJETO_EXCLUIDO: '14',
  NOTA_CREDITO: '05',
  NOTA_DEBITO: '06',
  NOTA_REMISION: '04',
};

export const dteUnMes = [
  DteType.COMPROBANTE_CREDITO_FISCAL,
  DteType.NOTA_CREDITO,
  DteType.NOTA_DEBITO,
  DteType.NOTA_REMISION,
  DteType.COMPROBANTE_RETENCION,
  DteType.COMPROBANTE_LIQUIDACION,
  DteType.DOCUMENTO_CONTABLE_LIQUIDACION,
  DteType.FACTURA_SUJETO_EXCLUIDO,
  DteType.COMPROBANTE_DONACION,
];

export const dteTresMeses = [DteType.FACTURA_EXPORTACION, DteType.FACTURA];

export const validarFechas = (respuestaMH, tipoDte) => {
  let openModal = true;
  if (!respuestaMH || !respuestaMH.selloRecibido) {
    Alert({
      title: 'Error',
      text: 'No es posible anular el DTE porque ha sido rechazado o no ha sido recibido por el Ministerio de Hacienda',
      icon: 'error',
    });
    openModal = false;
    return 0;
  }

  const formato = 'DD/MM/YYYY HH:mm:ss';

  const fecha = dayjs(respuestaMH.fhProcesamiento, formato).tz(
    'America/El_Salvador',
  );
  const fechaActual = dayjs().tz('America/El_Salvador');
  if (dteUnMes.includes(tipoDte)) {
    const fechaLimite = fecha.add(1, 'month').endOf('day');
    if (fechaActual.isAfter(fechaLimite)) {
      Alert({
        title: 'Error',
        text: 'No es posible anular el DTE porque ya paso el plazo de un mes para realizar la anulación',
        icon: 'info',
      });
      openModal = false;

      return;
    }
  }

  if (dteTresMeses.includes(tipoDte)) {
    const fechaLimite = fecha.add(3, 'month').endOf('day');

    if (fechaActual.isAfter(fechaLimite)) {
      Alert({
        title: 'Error',
        text: 'No es posible anular el DTE porque ya paso el plazo de tres meses para realizar la anulación',
        icon: 'info',
      });
      openModal = false;

      return;
    }
  }

  return openModal;
};

export const obtenerFechaLimite = (data) => {
  const respuestaMH = data?.respuesta_mh;
  const tipoDte = data?.datos_dte?.identificacion?.tipoDte;
  if (!respuestaMH || !respuestaMH.selloRecibido) {
    return null;
  }

  const formato = 'DD/MM/YYYY HH:mm:ss';

  const fecha = dayjs(respuestaMH.fhProcesamiento, formato).tz(
    'America/El_Salvador',
  );
  const fechaActual = dayjs().tz('America/El_Salvador');

  let vencida = false;
  let fechaLimite = null;
  if (dteUnMes.includes(tipoDte)) {
    fechaLimite = fecha.add(1, 'month').endOf('day');
    if (fechaActual.isAfter(fechaLimite)) {
      vencida = true;
    }
  }

  if (dteTresMeses.includes(tipoDte)) {
    fechaLimite = fecha.add(3, 'month').endOf('day');

    if (fechaActual.isAfter(fechaLimite)) {
      vencida = true;
    }
  }

  return { fechaLimite: fechaLimite.format(formato), vencida };
};

export const dteNecesitaReemplazo = [
  DteType.FACTURA,
  DteType.FACTURA_EXPORTACION,
  DteType.NOTA_DEBITO,
  DteType.NOTA_REMISION,
  DteType.COMPROBANTE_RETENCION,
  DteType.COMPROBANTE_CREDITO_FISCAL,
  DteType.COMPROBANTE_LIQUIDACION,
  DteType.DOCUMENTO_CONTABLE_LIQUIDACION,
  DteType.FACTURA_SUJETO_EXCLUIDO,
  DteType.COMPROBANTE_DONACION,
];

export const motivosInvalidacion = [
  {
    code: 1,

    value: 'Error en la Información del DTE a invalidar',
  },
  {
    code: 3,
    value: 'Otro',
  },
  {
    code: 2,
    value: 'Rescindir de la operación realizada',
  },
];

export const consultarDTE = async (dataConsulta) => {
  const dataDocumento = {
    codigoGeneracion: dataConsulta?.codigoGeneracion,
    id: dataConsulta?.id,
  };

  const headers = {
    'x-ambiente': dataConsulta?.ambiente,
    'x-branchoffice-id': dataConsulta?.sucursal,
  };

  const data = await post(`dte/consulta`, dataDocumento, headers);

  return data;
};

export const getClasificacionErrores = (tipo) => {
  const data = [
    {
      tipo: '10',
      descripcion: 'Recepción exitosa',
    },

    {
      tipo: '11',
      descripcion: 'Errores de datos del DTE',
    },
    {
      tipo: '12',
      descripcion: 'Errores en los datos del emisor',
    },
    {
      tipo: '13',
      descripcion: 'Errores en los datos del receptor',
    },
    {
      tipo: '14',
      descripcion: 'Errores en los datos de Doc. Relacionados',
    },
    {
      tipo: '15',
      descripcion: 'Errores en los datos de items',
    },
    {
      tipo: '16',
      descripcion: 'Errores en los datos de resumen',
    },
    {
      tipo: '17',
      descripcion: 'Errores en los datos de extensión',
    },
    {
      tipo: '18',
      descripcion: 'Errores en los datos de apendice',
    },
    {
      tipo: '19',
      descripcion: 'Errores relacionado a seguridad',
    },
    {
      tipo: '20',
      descripcion: 'Errores relacionados a la firma electrónica',
    },
    {
      tipo: '21',
      descripcion: 'Errores en los datos de venta a terceros',
    },
    {
      tipo: '98',
      descripcion: 'Errores no cumplen esquema',
    },
    {
      tipo: '99',
      descripcion: 'Errores generales del servicio',
    },
  ];

  const item = data.find((item) => item.tipo === tipo);

  return item?.descripcion;
};
