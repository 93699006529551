import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ModalWrapper from '../../../../../../Components/Common/Modals/Modal';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import { motivosInvalidacion } from '../../utils/consulta.util';
import { getDateLocaleFormated } from '../../../../../../services/date/date';

const ModalDetalleInvalidacion = forwardRef((props, ref) => {
  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    async openModal() {
      modalRef.current.toggle();

      // reset();
    },
  }));
  return (
    <>
      <ModalWrapper
        title={`Detalles de envento de invalidación de DTE`}
        ref={modalRef}
        size={'xl'}
        style={{ maxWidth: '90%' }}
      >
        <CardWrapperTools title="Datos de transmición de invalidación">
          <div className="row">
            <div className="col-12">
              <div className="col-sm-auto ms-auto">
                <div className="table-responsive">
                  <table className="table table-striped border-bottom">
                    <thead data-bs-theme="light">
                      <tr className="bg-secondary text-white dark__bg-1000 fs-1">
                        <th className="border-0 text-center">
                          Código generación de invalidación
                        </th>
                        <th className="border-0 text-center">
                          Sello de recepción
                        </th>
                        <th className="border-0 text-center">
                          Fecha de procesamiento
                        </th>
                        <th className="border-0 text-center">
                          Tipo de invalidación
                        </th>
                        <th className="border-0 text-center">
                          Motivo de invalidación
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="fs-1">
                        <td className="text-center">
                          {props?.dte?.respuesta_invalidacion?.codigoGeneracion}
                        </td>
                        <td className="text-center">
                          {props?.dte?.respuesta_invalidacion?.selloRecibido}
                        </td>
                        <td className="text-center">
                          {getDateLocaleFormated({
                            date: props?.dte?.respuesta_invalidacion
                              ?.fhProcesamiento,
                            formatIn: 'DD/MM/YYYY HH:mm:ss',
                            formatOut: 'dddd DD MMMM YYYY, h:mm:ss a',
                          })}
                        </td>
                        <td className="text-center">
                          {
                            motivosInvalidacion.find(
                              (motivo) =>
                                motivo.code ===
                                props?.dte?.datos_invalidacion?.motivo
                                  ?.tipoAnulacion,
                            )?.value
                          }
                        </td>
                        <td className="text-center">
                          {
                            props?.dte?.datos_invalidacion?.motivo
                              ?.motivoAnulacion
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </>
  );
});

export default ModalDetalleInvalidacion;
