/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Controller, useForm } from 'react-hook-form';

import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';

import { get } from '../../../services/http/http.service';
import ModalWrapper from '../../../Components/Common/Modals/Modal';
import http from '../../../services/http/http.auth.services.js';
import {
  Alert,
  loadingReload,
  AlertQuestionYesNo,
} from '../../../services/alerts/alerts';
import { getSession } from '../../../services/encrytion/encryption.service.js';

const TableRow = ({ cliente, readOnly, handleEdit, handleStatus }) => {
  return (
    <tr>
      <td>{cliente.value}</td>
      <td>
        <span
          className={`badge rounded-pill ${
            cliente.ambiente === '00'
              ? 'badge-subtle-danger'
              : 'badge-subtle-success'
          }`}
        >
          {cliente.ambiente === '00' ? 'Pruebas' : 'Producción'}
        </span>
      </td>
      <td>
        <span
          className={`badge rounded-pill ${
            cliente.activo ? 'badge-subtle-primary' : 'badge-subtle-warning'
          }`}
        >
          {cliente.activo ? 'Activo' : 'Inactivo'}
        </span>
      </td>
      {readOnly && (
        <td>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-sm me-2"
              onClick={() => handleEdit(cliente.id, cliente?.ambiente)}
            >
              <span className="fas fa-edit"></span>
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleStatus(cliente.id, cliente.activo)}
            >
              <span className="fas fa-trash-alt"></span>
            </button>
          </div>
        </td>
      )}
    </tr>
  );
};

export default function DteClientes({ id, readOnly = false }) {
  const modalRef = useRef();

  useEffect(() => {
    if (id) {
      loadDteClientes(id);
    } else {
      const { id } = getSession('clienteSeleccionado') ?? {};
      loadDteClientes(id);
    }
  }, []);
  const { handleSubmit, control, reset } = useForm();

  const [dteCliente, setDteCliente] = useState([]);

  const loadDteClientes = async (id) => {
    try {
      if (id) {
        const { data } = await get(`/clientes/dte/${id}`);
        setDteCliente(data);
        return data;
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo cargar los DTE del cliente',
        icon: 'error',
      });
    }
  };

  const loadDte = async (inputValue) => {
    try {
      const { data } = await get(
        `/generales/tipos-documento-tributario?search=${inputValue}`,
      );
      const options = data.map((item) => ({
        value: item.code,
        label: item.value,
      }));
      return options;
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo cargar los DTE',
        icon: 'error',
      });
    }
  };

  const loadAmbientes = async (inputValue) => {
    try {
      const { data } = await get(
        `/generales/ambientes-destino?search=${inputValue}`,
      );
      const options = data.map((item) => ({
        value: item.code,
        label: item.value,
      }));
      return options;
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo cargar los ambientes',
        icon: 'error',
      });
    }
  };

  const handleSaveDte = async (datos) => {
    loadingReload(true, 'Guardando DTE');
    try {
      const payload = {
        clienteId: id,
        code: datos.dte.value,
        value: datos.dte.label,
        ambiente: datos.ambiente.value,
      };
      const { data } = await http.post('/clientes/asignar-dte', payload);
      loadingReload(false, null);
      if (data._id) {
        Alert({
          title: 'Guardado',
          text: 'Se guardo el DTE',
          icon: 'success',
        });
        modalRef.current.toggle();
      }
      await loadDteClientes(id);

      return data;
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo guardar el DTE',
        icon: 'error',
      });
    }
  };

  const handleStatus = async (idStatus, activo) => {
    try {
      const question = await AlertQuestionYesNo({
        title: 'Cambio de estado',
        text: '¿Esta seguro de cambiar el estado?',
        icon: 'warning',
      });
      if (!question) return;
      loadingReload(true, 'Desactivando DTE');
      const { data } = await http.patch(
        `/clientes/dte/status/${idStatus}?status=${!activo}`,
      );
      if (data) {
        loadingReload(false, null);
        await loadDteClientes(id);
        Alert({
          title: 'Desactivado',
          text: 'Se desactivo el DTE',
          icon: 'success',
        });
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo cambiar el estado',
        icon: 'error',
      });
    }
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      reset();
      modalRef.current.toggle();
    }
  };

  const handleEdit = async (idValue, ambiente) => {
    const valueAmbiente = ambiente === '00' ? '01' : '00';
    try {
      const question = await AlertQuestionYesNo({
        title: 'Cambio de ambiente',
        text: '¿Esta seguro de cambiar el ambiente?',
        icon: 'warning',
      });
      if (!question) return;
      loadingReload(true, 'Cambiando ambiente');
      const { data } = await http.patch(
        `/clientes/dte/ambiente/${idValue}?ambiente=${valueAmbiente}`,
      );
      if (data) {
        loadingReload(false, null);
        await loadDteClientes(id);
        Alert({
          title: 'Cambiado',
          text: 'Se cambio el ambiente',
          icon: 'success',
        });
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo cambiar el estado',
        icon: 'error',
      });
    }
  };
  return (
    <div>
      <CardWrapperTools
        title={
          readOnly
            ? 'Documentos Tributarios Electrónicos Autorizados'
            : 'DTE del cliente'
        }
        tools={
          !readOnly && (
            <div className="d-flex align-items-center">
              <div className="me-3">
                <div className="icon-shape icon-sm bg-body-tertiary text-center rounded">
                  <button className="btn btn-primary" onClick={handleOpenModal}>
                    <i
                      className="fa fa-plus"
                      style={{ marginRight: '3px' }}
                    ></i>
                    Asignar DTE
                  </button>
                </div>
              </div>
            </div>
          )
        }
      >
        <div className="row g-3">
          <div className="table-responsive scrollbar">
            <table className="table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Ambiente</th>
                  <th>Estado</th>
                  {!readOnly && <th>Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {dteCliente.map((cliente, index) => (
                  <TableRow
                    key={index}
                    cliente={cliente}
                    readOnly={!readOnly}
                    handleEdit={handleEdit}
                    handleStatus={handleStatus}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <ModalWrapper ref={modalRef}>
          <CardWrapperTools
            title="Asignacion de DTE"
            footer={
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={handleSubmit(handleSaveDte)}
                    >
                      <span className="far fa-paper-plane"></span> Guardar
                    </button>
                  </div>
                </div>
              </div>
            }
          >
            <div className="row g-2">
              <div className="col-lg-6">
                <label className="form-label">DTE:</label>
                <div className="form-group">
                  <Controller
                    name="dte"
                    control={control}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        cacheOptions
                        defaultOptions
                        placeholder="Seleccione un documento tributario electronico"
                        loadOptions={loadDte}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label className="form-label">Ambiente:</label>
                <div className="form-group">
                  <Controller
                    name="ambiente"
                    control={control}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        cacheOptions
                        loadOptions={loadAmbientes}
                        defaultOptions
                        placeholder="Seleccione un ambiente"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </CardWrapperTools>
        </ModalWrapper>
      </CardWrapperTools>
    </div>
  );
}
