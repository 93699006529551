import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSession } from '../../../../../services/encrytion/encryption.service';
import { getDteType } from '../../../../../utils/dte/dte.util';
import { saveAs } from 'file-saver';
import * as slug from 'slug';
import { Alert, loadingReload } from '../../../../../services/alerts/alerts';
import { get } from '../../../../../services/http/http.service';

const Action = ({ dte, cliente, modalReporteRef }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleObtenerReporte = (clienteId, tipoDocumento, codigoGeneracion) => {
    modalReporteRef.current.generarReporte(
      clienteId,
      tipoDocumento,
      codigoGeneracion,
    );
  };

  const redirectToDetalle = (codigoGeneracion, clienteId) => {
    setSession('queryParamsConsulta', getQueryParams());
    navigate(`/dte/consulta/${codigoGeneracion}/detalle/${clienteId}`);
  };

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);

    // Convierte los parámetros de la consulta en un objeto JavaScript
    const queryParams = {};
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }

    return queryParams;
  };

  const handleDownloadJson = async () => {
    try {
      loadingReload(true, 'Descargando JSON');

      const { data } = await get(
        `dte/json/${dte.codigo_generacion}/cliente-id/${cliente?.id}`,
      );
      const response = await fetch(data);
      const fileBlob = await response.blob();
      saveAs(
        fileBlob,
        `${slug(getDteType(dte?.datos_dte?.identificacion?.tipoDte))}-${
          dte?.codigo_generacion
        }.json`,
        {
          autoBom: true,
        },
      );

      loadingReload(false);
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo descargar el JSON',
        icon: 'error',
      });
      loadingReload(false);
    }
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-sm btn-falcon-default dropdown-toggle dropdown-caret-none"
        type="button"
        id="ticket-layout"
        data-bs-toggle="dropdown"
      >
        <span className="me-1">Acciones</span>
        <span className="fas fa-chevron-down"></span>
      </button>
      <div
        className="dropdown-menu dropdown-toggle-item dropdown-menu-end border py-2"
        aria-labelledby="ticket-layout"
      >
        <button
          className="dropdown-item"
          type="button"
          onClick={() => redirectToDetalle(dte.codigo_generacion, cliente?.id)}
        >
          Ver Detalle
        </button>

        {dte.respuesta_mh?.estado === 'PROCESADO' ? (
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              handleObtenerReporte(
                cliente?.id,
                getDteType(dte?.datos_dte?.identificacion?.tipoDte),
                dte?.codigo_generacion,
              );
            }}
          >
            Generar PDF
          </button>
        ) : null}

        {dte.respuesta_mh?.estado === 'PROCESADO' ? (
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              handleDownloadJson();
            }}
          >
            Descargar JSON
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Action;
