import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getSession } from '../../../services/encrytion/encryption.service';
import { deleted, get, post } from '../../../services/http/http.service';
import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import ModalWrapper from '../../../Components/Common/Modals/Modal';
import {
  Alert,
  AlertQuestionYesNo,
  loadingReload,
} from '../../../services/alerts/alerts';

const FirmaMh = ({ id, readOnly = false }) => {
  const modalRef = useRef();
  const [fileDevelop, setFileDevelop] = useState(null);
  const [fileProduction, setFileProduction] = useState(null);
  const [cliente, setCliente] = useState([]);

  const loadClientById = useCallback(async (id) => {
    if (id) {
      const { data } = await get(`/clientes/${id}`);

      setCliente(data);
      return data;
    }
  }, []);
  const handleOpenModal = useCallback(() => {
    if (modalRef.current) {
      setFileDevelop(null);
      setFileProduction(null);
      if (id) {
        loadClientById(id);
      } else {
        const { id } = getSession('clienteSeleccionado') ?? {};
        loadClientById(id);
      }
      modalRef.current.toggle();
    }
  }, [id, loadClientById, modalRef]);
  useEffect(() => {
    if (id) {
      loadClientById(id);
    } else {
      const { id } = getSession('clienteSeleccionado') ?? {};
      loadClientById(id);
    }
  }, [id, loadClientById, modalRef]);

  const handleFileDevelopChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileDevelop(selectedFile);
  };

  const handleFileProductionChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileProduction(selectedFile);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (fileDevelop || fileProduction) {
      const formData = new FormData();

      if (fileDevelop) {
        formData.append('files', fileDevelop, 'develop.crt');
      }

      if (fileProduction) {
        formData.append('files', fileProduction, 'produccion.crt');
      }

      // Aquí puedes enviar formData a tu servidor o hacer lo que necesites con él
      try {
        loadingReload(true, 'Cargando certificados');
        const { data } = await post(
          `clientes/firma/certificado/${id}`,
          formData,
          {
            'Content-Type': 'multipart/form-data',
          },
        );

        if (data) {
          loadingReload(false);
          Alert({
            title: 'Éxito',
            text: 'Certificados cargados correctamente.',
            icon: 'success',
          });
          handleOpenModal();
        }
      } catch (error) {
        loadingReload(false);
        Alert({
          title: 'Error',
          text: 'Ocurrió un error al cargar los certificados.',
          icon: 'error',
        });
      }
    } else {
      Alert({
        title: 'Error',
        text: 'Por favor seleccione al menos un archivo para cargar',
        icon: 'error',
      });
    }
  };

  const deleteCertificado = async (tipo) => {
    AlertQuestionYesNo({
      title: 'Eliminar certificado',
      text: '¿Está seguro que desea eliminar este certificado?',
      icon: 'question',
    }).then(async (result) => {
      if (result) {
        try {
          loadingReload(true, 'Eliminando certificado');
          const { data } = await deleted(
            `clientes/firma/certificado/${id}?type=${tipo}`,
          );

          if (data) {
            Alert({
              title: 'Éxito',
              text: 'Certificado eliminado correctamente.',
              icon: 'success',
            });
            loadClientById(id);
          }
        } catch (error) {
          loadingReload(false);
          Alert({
            title: 'Error',
            text: 'Ocurrió un error al eliminar el certificado.',
            icon: 'error',
          });
        }
      }
    });
  };
  return (
    <>
      <CardWrapperTools
        title="Certificados Ministerio de Hacienda"
        tools={
          !readOnly ? (
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" onClick={handleOpenModal}>
                <span className="fas fa-plus"></span> Cargar Certificados
              </button>
            </div>
          ) : null
        }
      >
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <CardWrapperTools
              title="Certificado de pruebas"
              tools={
                !readOnly && cliente?.tieneCertificadoDevelop ? (
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        deleteCertificado(0);
                      }}
                    >
                      <span className="fas fa-trash"></span> Eliminar
                    </button>
                  </div>
                ) : null
              }
            >
              {(cliente?.tieneCertificadoDevelop ?? 0) > 0 ? (
                <div
                  className="alert alert-success border-2 d-flex align-items-center"
                  role="alert"
                >
                  <p className="mb-0 flex-1">Configurado correctamente.</p>
                </div>
              ) : (
                <div
                  className="alert alert-danger border-2 d-flex align-items-center"
                  role="alert"
                >
                  <p className="mb-0 flex-1">Sin configuración.</p>
                </div>
              )}
            </CardWrapperTools>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <CardWrapperTools
              title="Certificado de producción"
              tools={
                !readOnly && cliente?.tieneCertificadoProduccion ? (
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        deleteCertificado(1);
                      }}
                    >
                      <span className="fas fa-trash"></span> Eliminar
                    </button>
                  </div>
                ) : null
              }
            >
              {(cliente?.tieneCertificadoProduccion ?? 0) > 0 ? (
                <div
                  className="alert alert-success border-2 d-flex align-items-center"
                  role="alert"
                >
                  <p className="mb-0 flex-1">Configurado correctamente.</p>
                </div>
              ) : (
                <div
                  className="alert alert-danger border-2 d-flex align-items-center"
                  role="alert"
                >
                  <p className="mb-0 flex-1">Sin configuración.</p>
                </div>
              )}
            </CardWrapperTools>
          </div>
        </div>
      </CardWrapperTools>

      <ModalWrapper
        ref={modalRef}
        title="Carga de Certificados Ministerio de Hacienda"
      >
        <div className="row g-3">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label htmlFor="">Certificado Ambiente de pruebas:</label>
            <input
              type="file"
              className="form-control file"
              id="fileDevelop"
              onChange={handleFileDevelopChange}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label htmlFor="">Certificado Ambiente de produccion:</label>
            <input
              type="file"
              className="form-control file"
              id="fileProduction"
              onChange={handleFileProductionChange}
            />
          </div>
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary btn-lg"
                onClick={(e) => onSubmit(e)}
              >
                <span className="fas fa-upload"></span> Cargar
              </button>
            </div>
          </div>
        </div>
        {/* <CardWrapperTools
          title="Carga de Certificados Ministerio de Hacienda"
          footer={
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary btn-lg"
                    //onClick={handleSubmit(onSubmit)}
                  >
                    <span className="far fa-paper-plane"></span> Cargar
                  </button>
                </div>
              </div>
            </div>
          }
        >

        </CardWrapperTools> */}
      </ModalWrapper>
    </>
  );
};

export default FirmaMh;
