import React, { useRef } from 'react';
import { CardWrapper } from '../../../Components/Common/Cards';
import { TableFilterV2 } from '../../../Components/Common/Tables/TableFilterV2';

import { useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertQuestionYesNo,
  loadingReload,
} from '../../../services/alerts/alerts';
import httpAuth from '../../../services/http/http.auth.services';
export default function Roles() {
  const tablaRef = useRef();
  const navigate = useNavigate();
  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
    },
    {
      name: 'Activo',
      selector: (row) =>
        row.activo ? (
          <span className="badge rounded-pill badge-subtle-primary">
            Activo
          </span>
        ) : (
          <span className="badge rounded-pill badge-subtle-warning">
            Inactivo
          </span>
        ),
    },
    {
      name: 'Acciones',
      selector: (row) => (
        <>
          <button
            className="btn btn-link p-0"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Edit"
            onClick={() => handleEdit(row?._id)}
          >
            <span className="text-500 fas fa-edit"></span>
          </button>
          <button
            className="btn btn-link p-0 ms-2"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Delete"
            onClick={() => handleDelete(row?._id, row.activo)}
          >
            <span className="text-500 fas fa-trash-alt"></span>
          </button>
        </>
      ),
    },
  ];
  const handleEdit = (id, status) => {
    navigate(`/admin/roles/update/${id}`);
  };
  const handleDelete = async (id, activo) => {
    try {
      const question = await AlertQuestionYesNo({
        title: activo ? 'Desactivar ' : 'Activar ',
        text: activo ? '¿Desea desactivar el rol?' : '¿Desea activar el rol?',
        icon: 'warning',
      });
      if (!question) return;
      loadingReload(true, 'Desactivando el rol');
      const { data } = await httpAuth.patch('/roles/status', {
        activo: !activo,
        id,
      });
      if (data) {
        loadingReload(false, null);
        tablaRef.current.clear();
        Alert({
          title: 'Rol desactivado',
          icon: 'success',
          text: 'El rol se desactivo correctamente',
        });
      }
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al desactivar el rol',
        icon: 'error',
      });
    }
  };
  return (
    <CardWrapper title="Listdo de roles" urlForm={'/admin/roles/create'}>
      <TableFilterV2 columns={columns} endpoint="roles" ref={tablaRef} />
    </CardWrapper>
  );
}
