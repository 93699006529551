import React from 'react';

const ValidateErrorDte = ({ dte }) => {
  if (
    dte.respuesta_mh?.estado === 'PROCESADO' &&
    dte.respuesta_mh?.observaciones.length > 0
  ) {
    return (
      <span className="badge badge-subtle-warning fs--1">
        <span className="fas fa-check me-1" data-fa-transform="shrink-4" />
        <span>Con observaciones</span>
      </span>
    );
  }
  if (
    dte.respuesta_mh &&
    dte.respuesta_mh?.estado === 'PROCESADO' &&
    dte.respuesta_mh?.observaciones.length === 0
  ) {
    return (
      <span className="badge badge-subtle-success fs--1">
        <span className="fas fa-check me-1" data-fa-transform="shrink-4" />
        <span>Procesado</span>
      </span>
    );
  }

  if (dte.respuesta_mh && dte.respuesta_mh?.estado === 'RECHAZADO') {
    return (
      <span className="badge badge-subtle-danger fs--1">
        <span className="fas fa-check me-1" data-fa-transform="shrink-4" />
        <span>Rechazado</span>
      </span>
    );
  }
  if (
    dte.respuesta_mh &&
    (dte.respuesta_mh?.estado === null ||
      dte.respuesta_mh?.estado === 'PENDIENTE')
  ) {
    return (
      <span className="badge badge-subtle-info fs--1">
        <span className="fas fa-check me-1" data-fa-transform="shrink-4" />
        <span>Procesando...</span>
      </span>
    );
  }

  if (!dte.respuesta_mh) {
    return (
      <span className="badge badge-subtle-warning fs-0">
        <span className="fas fa-check me-1" data-fa-transform="shrink-4" />
        <span>Sin respuesta de MH</span>
      </span>
    );
  }
};

export default ValidateErrorDte;
