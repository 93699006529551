import React, { useRef, useState } from 'react';
import CardWrapperTools from '../../../../../../Components/Common/Cards/CardWrapperTools';
import ModalWrapper from '../../../../../../Components/Common/Modals/Modal';
import {
  consultarDTE,
  getClasificacionErrores,
} from '../../utils/consulta.util';
import { Alert, loadingReload } from '../../../../../../services/alerts/alerts';
import { useFormContext } from 'react-hook-form';

const ModalDetalleDte = ({ codigoGeneracion, ambiente, sucursal, id }) => {
  const modalRef = useRef(null);
  const [detallesDte, setDetallesDte] = useState(null);

  const { setValue } = useFormContext();
  const getDte = async () => {
    loadingReload(true, 'Consultando DTE', 'Por favor espere...');
    const dataConsulta = {
      codigoGeneracion,
      ambiente,
      sucursal,
      id,
    };

    try {
      const { data, status } = await consultarDTE(dataConsulta);

      if (status === 201) {
        setDetallesDte(data);
        loadingReload(false);
        modalRef.current.toggle();
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        Alert({
          title: 'Error',
          text: error?.response?.data?.descripcionMsg,
          icon: 'error',
        });
      }
      loadingReload(false);
    }
  };
  return (
    <>
      <button
        className="dropdown-item"
        onClick={() => {
          getDte();
        }}
      >
        Consultar DTE
      </button>

      <ModalWrapper
        title={`Panel de consulta de DTE`}
        ref={modalRef}
        size={'xl'}
        style={{ maxWidth: '90%' }}
        setValue={setValue}
      >
        <CardWrapperTools title={`Detalles de transmición de DTE`}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-sm-auto ms-auto">
                <div className="table-responsive">
                  <table className="table table-striped border-bottom">
                    <thead data-bs-theme="light">
                      <tr className="bg-secondary text-white dark__bg-1000 fs-1">
                        <th className="border-0 text-center">
                          Versión de JSON
                        </th>
                        <th className="border-0 text-center">Estado</th>
                        <th className="border-0 text-center">
                          Clasificación del Mensaje
                        </th>

                        <th className="border-0 text-center">
                          Código del Mensaje
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="fs-1">
                        <td className="text-center">{detallesDte?.version}</td>
                        <td className="text-center">{detallesDte?.estado}</td>
                        <td className="text-center">
                          {getClasificacionErrores(detallesDte?.clasificaMsg)}
                        </td>
                        <td className="text-center">
                          {detallesDte?.codigoMsg}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="">
                    Respuesta del ministerio de hacienda:
                  </label>

                  <textarea
                    name="respuesta"
                    readOnly
                    className="form-control"
                    cols="30"
                    rows="4"
                    value={detallesDte?.descripcionMsg}
                  ></textarea>
                </div>

                {detallesDte?.observaciones?.length ? (
                  <div className="col-12">
                    <label htmlFor="">Observaciones:</label>

                    {detallesDte?.observaciones.map((observacion, index) => (
                      <div
                        key={index}
                        className="alert alert-danger"
                        role="alert"
                      >
                        {observacion}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </>
  );
};

export default ModalDetalleDte;
