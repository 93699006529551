import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ItemOrigin } from '../../../../../utils/Utils';

const MontoNoAfectoForm = ({ addItem }) => {
  const validationSchema = Yup.object().shape({
    descripcion: Yup.string().required('La descripción es obligatoria'),
    monto: Yup.number()
      .positive('El monto debe ser mayor a 0')
      .required('El monto es obligatorio'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      descripcion: '',
      monto: 0.0,
    },
  };

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    const item = {
      origen: ItemOrigin.NO_AFECTO,
      descripcion: watch('descripcion'),
      subTotal: watch('monto'),
      cantidad: 1,
    };
    addItem(item);
    setValue('descripcion', '');
    setValue('monto', 0.0);
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-4">
          <label className="form-label" htmlFor="descripcionExento">
            Descripción:
          </label>
          <input
            className="form-control"
            id="descripcionExento"
            type="text"
            placeholder="Descripción"
            {...register('descripcion')}
          />
          {errors.descripcion && (
            <div className="invalid-feedback">
              {errors.descripcion?.message}
            </div>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label" htmlFor="montoExento">
            Monto:
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text">$</span>
            <input
              className="form-control"
              id="montoExento"
              type="number"
              min={0}
              placeholder="0.00"
              {...register('monto')}
              onWheel={(event) => event.target.blur()}
              onClick={(event) => event.target.select()}
            />
          </div>

          {errors.monto && (
            <div className="invalid-feedback">{errors.monto?.message}</div>
          )}
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="d-flex align-items-center justify-content-end">
              <button
                className="btn btn-lg btn-primary"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="fas fa-plus"></span>
                <span className=""> Añadir item</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { MontoNoAfectoForm };
