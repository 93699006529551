import { forwardRef, useImperativeHandle, useRef } from 'react';
import ModalWrapper from '../../../../../Components/Common/Modals/Modal';
import ClientesTable from './ClientesTable';

const ModalBuscadorClientes = forwardRef((props, ref) => {
  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    async openModal() {
      modalRef.current.toggle();
    },
  }));

  const handleClientSelection = (data) => {
    props.onSelect(data);
    modalRef.current.toggle();
   }

  return (
    <div>
      <ModalWrapper
        title="Buscar clientes"
        ref={modalRef}
        size={'xl'}
        style={{ maxWidth: '90%' }}
      >
        <ClientesTable
          onSelect={handleClientSelection}
          contribuyente={props.contribuyente}
        ></ClientesTable>
      </ModalWrapper>
    </div>
  );
});
export default ModalBuscadorClientes;
