import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import {
  getDepartamentos,
  getMunicipios,
  getTiposDeDocumentoIdentificacionReceptor,
  loadActividadesEconomicas,
} from '../utils/FacturaSujetoExcluido';
import { enqueueSnackbar } from 'notistack';
import AsyncSelect from 'react-select/async';
import BuscadorClientes from '../../components/BuscadorClientes/BuscadorClientes';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import PhoneInput from 'react-phone-input-2';
import { InputMask } from '@react-input/mask';

const ReceptorForm = () => {
  const {
    setValue,
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [actividadesEconomicasCliente, setActividadesEconomicasCliente] =
    useState([]);
  const [maskNumeroReceptor, setMaskNumeroReceptor] = useState(0); //0-other, 1- DUI, 2-NIT

  const getDataSelect = useCallback(async () => {
    try {
      const [dataDepartamentos, dataMunicipios, dataTiposDocumentos] =
        await Promise.all([
          getDepartamentos(),
          getMunicipios(),
          getTiposDeDocumentoIdentificacionReceptor(),
        ]);

      setDepartamentos(dataDepartamentos.data);
      setMunicipios(dataMunicipios);
      setTiposDocumentos(dataTiposDocumentos);
      setValue('departamentoReceptor', dataDepartamentos.default);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los datos generales!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, [setValue]);

  useEffect(() => {
    async function getData() {
      await getDataSelect();
    }

    getData();
  }, [getDataSelect]);

  const onChangeDepartamento = async (event) => {
    if (event) {
      try {
        const { id } = event;
        const dataMunicipios = await getMunicipios(id);
        setMunicipios(dataMunicipios);
        setValue('municipioReceptor', null);
      } catch (error) {
        enqueueSnackbar(
          'Se ha producido un error al momento de obtener los datos de municipios!',
          {
            variant: 'error',
            preventDuplicate: true,
          },
        );
      }
    } else {
      setValue('municipioReceptor', null);
      setMunicipios([]);
    }
  };
  const onSelectClient = (data) => {
    const tipoDocumento = data.tipo_documento.value
      ? {
          label: data.tipo_documento.value,
          value: data.tipo_documento.code,
        }
      : null;

    const departamentoReceptor = data.departamento.code
      ? {
          label: data.departamento.value,
          value: data.departamento.code,
          id: data.departamento.id,
        }
      : null;

    const municipioReceptor = data.municipio.code
      ? {
          label: data.municipio.value,
          value: data.municipio.code,
        }
      : null;

    const actividadEconomicaReceptor =
      typeof data.actividad_economica !== 'undefined' &&
      data.actividad_economica.length > 0
        ? {
            label:
              data.actividad_economica.find((item) => item.default)?.value ||
              data.actividad_economica[0]?.value,
            value:
              data.actividad_economica.find((item) => item.default)?.code ||
              data.actividad_economica[0]?.code,
          }
        : null;
    setActividadesEconomicasCliente(
      data.actividad_economica?.map((item) => ({
        label: item.value,
        value: item.code,
      })) ?? [],
    );

    if (data.contribuyente === true) {
      //Por defecto en sujeto excluido no deberia entrar aqui
      if (data.nit?.length === 10) {
        setValue('tipoDocumentoReceptor', {
          label: 'NIT (DUI Homologado)',
          value: '36.2',
        });
        configureInputMaskForNumeroDocumento('36.2');
      } else {
        setValue('tipoDocumentoReceptor', {
          label: 'NIT',
          value: '36',
        });
        configureInputMaskForNumeroDocumento('36');
      }

      setValue('numeroDocumentoReceptor', data.nit.trim());
    } else {
      configureInputMaskForNumeroDocumento(tipoDocumento.value);
      setValue('tipoDocumentoReceptor', tipoDocumento);
      setValue('numeroDocumentoReceptor', data.numero_documento.trim());
    }

    setValue('nombreClienteReceptor', data.nombre_cliente);
    setValue('correoElectronicoReceptor', data.correo);
    setValue('telefonoReceptor', data.telefono);
    setValue('complementoReceptor', data.direccion);
    setValue('departamentoReceptor', departamentoReceptor);

    getMunicipios(departamentoReceptor.id).then((dataMunicipios) => {
      setMunicipios(dataMunicipios);
    });

    setValue('municipioReceptor', municipioReceptor);
    setValue('actividadEconomicaReceptor', actividadEconomicaReceptor);
  };

  const onDeleteClient = () => {
    setActividadesEconomicasCliente([]);
    setValue('tipoDocumentoReceptor', null);
    setValue('numeroDocumentoReceptor', '');
    setValue('nombreClienteReceptor', '');
    setValue('correoElectronicoReceptor', '');
    setValue('telefonoReceptor', '');
    setValue('complementoReceptor', '');
    setValue('departamentoReceptor', null);
    setValue('municipioReceptor', null);
    setValue('actividadEconomicaReceptor', null);
  };

  const inputNumeroReceptor = () => {
    const duiInput = (
      <InputMask
        key={maskNumeroReceptor}
        mask="________-_"
        showMask
        replacement={{ _: /\d/ }}
        className="form-control"
        name="numeroDocumentoReceptor"
        id="numeroDocumentoReceptor"
        type="text"
        placeholder="Número de documento"
        {...register('numeroDocumentoReceptor')}
      ></InputMask>
    );
    const nitInput = (
      <InputMask
        key={maskNumeroReceptor}
        mask="____-______-___-_"
        showMask
        replacement={{ _: /\d/ }}
        className="form-control"
        name="numeroDocumentoReceptor"
        id="numeroDocumentoReceptor"
        type="text"
        placeholder="Número de documento"
        {...register('numeroDocumentoReceptor')}
      ></InputMask>
    );
    switch (maskNumeroReceptor) {
      case 1:
        return duiInput;
      case 2:
        return nitInput;
      default:
        return (
          <input
            key={maskNumeroReceptor}
            className="form-control"
            name="numeroDocumentoReceptor"
            id="numeroDocumentoReceptor"
            type="text"
            placeholder="Número de documento"
            {...register('numeroDocumentoReceptor')}
          ></input>
        );
    }
  };
  const updateInputMaskConfig = (value) => {
    if (maskNumeroReceptor === value) {
      return;
    }
    setMaskNumeroReceptor(value);
    setValue('numeroDocumentoReceptor', '');
  };
  const configureInputMaskForNumeroDocumento = (documentType) => {
    if (documentType === '13') {
      updateInputMaskConfig(1);
    } else if (documentType === '36.2') {
      //Dui homologado a NIT
      updateInputMaskConfig(1);
    } else if (documentType === '36') {
      updateInputMaskConfig(2);
    } else {
      updateInputMaskConfig(0);
    }
  };

  return (
    <>
      <CardWrapperTools
        title="Sujeto Excluido"
        tools={
          <>
            <BuscadorClientes
              onSelectClient={onSelectClient}
              showCleanButton={actividadesEconomicasCliente.length > 0}
              onDeleteClient={onDeleteClient}
              contribuyente={false}
            />
          </>
        }
      >
        {actividadesEconomicasCliente.length > 1 && (
          <div className="row">
            <div className="col-lg-12">
              <div className="alert alert-warning text-center" role="alert">
                <i className="fa fa-exclamation-triangle"></i>
                <span className="p-1">
                  El cliente tiene más de una actividad económica, seleccione la
                  correcta.
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label">Tipo Documento:</label>
              <Controller
                name="tipoDocumentoReceptor"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isDisabled={!tiposDocumentos.length}
                    options={tiposDocumentos}
                    placeholder="Seleccione"
                    {...field}
                    onChange={(event) => {
                      const documentType = event?.value;
                      configureInputMaskForNumeroDocumento(documentType);
                      //Si cambia de tipo de documento limpiamos el numero de documento
                      field.onChange(event);
                    }}
                  />
                )}
              />
              {errors.tipoDocumentoReceptor && (
                <div className="invalid-feedback">
                  {errors.tipoDocumentoReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label">Numero Documento:</label>
              {inputNumeroReceptor()}

              {errors.numeroDocumentoReceptor && (
                <div className="invalid-feedback">
                  {errors.numeroDocumentoReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label">Nombre del cliente:</label>
              <input
                className="form-control"
                name="nombreClienteReceptor"
                id="nombreClienteReceptor"
                type="text"
                placeholder="Digite el nombre del cliente"
                {...register('nombreClienteReceptor')}
              />
              {errors.nombreClienteReceptor && (
                <div className="invalid-feedback">
                  {errors.nombreClienteReceptor?.message}
                </div>
              )}
            </div>

            <div className="col-lg-3 col-md-4 col-sm-12">
              <label
                className="form-label"
                htmlFor="actividadEconomicaReceptor"
              >
                Actividad económica:
              </label>
              {actividadesEconomicasCliente.length <= 0 && (
                <Controller
                  name="actividadEconomicaReceptor"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      loadOptions={loadActividadesEconomicas}
                      defaultOptions
                      cacheOptions
                      placeholder="Seleccione"
                      className={
                        errors.actividadEconomicaReceptor && 'is-invalid'
                      }
                    />
                  )}
                />
              )}
              {actividadesEconomicasCliente.length > 0 && (
                <Controller
                  name="actividadEconomicaReceptor"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={actividadesEconomicasCliente ?? []}
                      placeholder="Seleccione"
                      className={
                        errors.actividadEconomicaReceptor && 'is-invalid'
                      }
                    />
                  )}
                />
              )}
              {errors.actividadEconomicaReceptor && (
                <div className="invalid-feedback">
                  {errors.actividadEconomicaReceptor?.message}
                </div>
              )}
            </div>

            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="correoElectronicoReceptor">
                Correo electrónico:
              </label>
              <input
                className="form-control"
                name="correoElectronicoReceptor"
                id="correoElectronicoReceptor"
                type="email"
                placeholder="mail@example.com"
                {...register('correoElectronicoReceptor')}
              />
              {errors.correoElectronicoReceptor && (
                <div className="invalid-feedback">
                  {errors.correoElectronicoReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="telefonoReceptor">
                Teléfono:
              </label>
              <Controller
                name="telefonoReceptor"
                id="telefonoReceptor"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PhoneInput
                    placeholder="0000-0000"
                    country={'sv'}
                    enableSearch={false}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    inputStyle={{ width: '100%' }}
                  />
                )}
              />
              {errors.telefonoReceptor && (
                <div className="invalid-feedback">
                  {errors.telefonoReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="departamentoReceptor">
                Departamento:
              </label>
              <Controller
                name="departamentoReceptor"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    options={departamentos}
                    placeholder="Seleccione"
                    onChange={(event) => {
                      onChangeDepartamento(event);
                      field.onChange(event);
                    }}
                  />
                )}
              />
              {errors.departamentoReceptor && (
                <div className="invalid-feedback">
                  {errors.departamentoReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <label className="form-label" htmlFor="municipioReceptor">
                Municipio:
              </label>

              <Controller
                name="municipioReceptor"
                control={control}
                render={({ field }) => (
                  <Select
                    isDisabled={!municipios.length}
                    options={municipios}
                    placeholder="Seleccione"
                    {...field}
                  />
                )}
              />
              {errors.municipioReceptor && (
                <div className="invalid-feedback">
                  {errors.municipioReceptor?.message}
                </div>
              )}
            </div>
            <div className="col-12">
              <label className="form-label" htmlFor="complementoReceptor">
                Dirección:
              </label>
              <textarea
                className="form-control"
                name="complementoReceptor"
                id="complementoReceptor"
                type="text"
                rows={5}
                placeholder="Dirección: Calle, Avenida, Colonia, etc."
                {...register('complementoReceptor')}
              />
              {errors.complementoReceptor && (
                <div className="invalid-feedback">
                  {errors.complementoReceptor?.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardWrapperTools>
    </>
  );
};

export default ReceptorForm;
