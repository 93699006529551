import React, { useState, useCallback, useRef } from 'react';
import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import { TableSelectableRows } from '../../../Components/Common/Tables/TableFilterSelectRows';
import ModalWrapper from '../../../Components/Common/Modals/Modal';
import { Alert, loadingReload } from '../../../services/alerts/alerts';
import { post } from '../../../services/http/http.service';

export default function CargaProductos() {
  const tablaRef = useRef();
  const modalRef = useRef();

  const [selectedRows, setSelectedRows] = useState([]);
  const [fileXlsx, setFileXlfileXlsx] = useState(null);

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
    },
    {
      name: 'Nombre comercial',
      selector: (row) => row.nombre_comercial,
    },
    {
      name: 'NRC',
      selector: (row) => row.nrc,
    },
    {
      name: 'NIT',
      selector: (row) => row.nit,
    },
  ];

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileXlfileXlsx(selectedFile);
  };

  // Manejar la selección de filas
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.toggle();
    }
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (fileXlsx) {
      const formData = new FormData();
      if (fileXlsx) {
        formData.append('clienteId', selectedRows[0]._id);

        formData.append('file', fileXlsx, fileXlsx?.name);
      }

      try {
        loadingReload(true, 'Cargando logo');
        const { data } = await post(`productos/file`, formData, {
          'Content-Type': 'multipart/form-data',
        });

        if (data) {
          loadingReload(false);
          Alert({
            title: 'Éxito',
            text: 'Fichero cargado correctamente.',
            icon: 'success',
          });

          modalRef.current.toggle();
        }
      } catch (error) {
        loadingReload(false);
        Alert({
          title: 'Error',
          text: 'Ocurrió un error al cargar el Fichero.',
          icon: 'error',
        });
      }
    } else {
      Alert({
        title: 'Error',
        text: 'Por favor seleccione un archivo para cargar',
        icon: 'error',
      });
    }
  };

  return (
    <div>
      <CardWrapperTools
        title={'Carga de productos desde archivo Excel'}
        tools={
          <div className="d-flex align-items-center">
            <div className="me-3">
              <div className="icon-shape icon-sm bg-body-tertiary text-center rounded">
                <button className="btn btn-primary" onClick={handleOpenModal}>
                  <i className="fa fa-plus" style={{ marginRight: '3px' }}></i>
                  Cargar fichero
                </button>
              </div>
            </div>
          </div>
        }
      >
        {selectedRows?.length > 0 && (
          <div className="mb-3">
            <strong>Cliente seleccionado: </strong>
            {selectedRows[0]?.nombre}
          </div>
        )}

        <ModalWrapper
          ref={modalRef}
          title={`Clinete seleccionado: ${selectedRows[0]?.nombre}`}
        >
          <CardWrapperTools
            title="El archivo solo puede ser *.xls o *.xlsx, según el siguiente"
            footer={
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={(e) => {
                        uploadFile(e);
                      }}
                    >
                      <span
                        className="far fa-paper-plane"
                        style={{ marginRight: '3px' }}
                      >
                        {' '}
                      </span>
                      Subir fichero
                    </button>
                  </div>
                </div>
              </div>
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mx-auto">
                  <input
                    type="file"
                    className="form-control file"
                    id="fileXlsx"
                    accept=".xlsx"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </CardWrapperTools>
        </ModalWrapper>

        <TableSelectableRows
          columns={columns}
          endpoint="clientes"
          onRowSelected={handleRowSelected} // Pasar la función de manejo de selección
          ref={tablaRef}
        />
      </CardWrapperTools>
    </div>
  );
}
