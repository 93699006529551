/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';

import http from '../../../services/http/http.auth.services';
import {
  Alert,
  loadingReload,
  alertEmpty,
} from '../../../services/alerts/alerts';
import { CardForm } from '../../../Components/Common/Cards/CardForm';

const CreateUsuario = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    alias: Yup.string().required('Alias es requerido'),
    email: Yup.string().required('Email es requerido').email('Email inválido'),
    roles: Yup.object().shape({
      value: Yup.string().required('Rol es requerido'),
      label: Yup.string().required('Rol es requerido'),
    }),
    nombre: Yup.string(),
    cvpcpa: Yup.string(),
  });

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      loadUserById(id);
    }
  }, [id]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const loadRoles = async (inputValue) => {
    const { data } = await http.get(
      `/roles?activo=true&search=${inputValue}&limit=10`,
    );
    const options = data.results.map((item) => ({
      value: item._id,
      label: item.nombre,
    }));
    return options;
  };

  const loadUserById = async (id) => {
    const { data } = await http.get(`/usuarios/${id}`);
    setValue('alias', data.alias);
    setValue('email', data.correo);
    setValue('nombre', data.nombre);
    setValue('cvpcpa', data.cvpcpa);
    setValue('roles', { value: data.rol._id, label: data.rol.nombre });

    return data;
  };
  const onSubmit = async (data) => {
    loadingReload(true, 'Guardando Usuario');
    const payload = {
      alias: data.alias,
      correo: data.email,
      rolId: data.roles.value,
      nombre: data.nombre,
      cvpcpa: data.cvpcpa,
    };
    if (!id) {
      const result = await http.post('/usuarios', payload);
      if (result.status !== 200)
        alertEmpty(
          '!Error al guardar el usuario!',
          'Este usuatuio ya existe',
          'info',
        );

      loadingReload(false);

      Alert({
        title: '¡Usuario guardado con éxito!',
        text: 'Administración de usuarios',
        icon: 'success',
      });
      navigate('/admin/usuarios');
    } else {
      payload.id = id;
      const result = await http.put(`/usuarios/`, payload);
      if (result.status !== 200)
        alertEmpty(
          '!Error al guardar el usuario!',
          'Este usuatuio ya existe',
          'info',
        );

      loadingReload(false);

      Alert({
        title: '¡Usuario actualizado con éxito!',
        text: 'Administración de usuarios',
        icon: 'success',
      });
      navigate('/admin/usuarios');
    }
  };

  const footer = (
    <div>
      <button className="btn btn-primary me-2" type="submit">
        Guardar
      </button>
      <button
        className="btn btn-secondary"
        onClick={() => {
          navigate('/admin/usuarios');
        }}
      >
        Salir
      </button>
    </div>
  );
  return (
    <>
      <CardForm
        title="Crear usuario"
        footer={footer}
        submit={handleSubmit(onSubmit)}
      >
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label" htmlFor="alias">
              Alias:
            </label>
            <input
              className={`form-control ${errors.alias ? 'is-invalid' : ''}`}
              id="alias"
              {...register('alias')}
              placeholder="Alias"
            />
            {errors.alias && (
              <div className="invalid-feedback">{errors.alias.message}</div>
            )}
          </div>

          <div className="col-md-4">
            <label className="form-label" htmlFor="email">
              Email:
            </label>
            <input
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              id="email"
              {...register('email')}
              placeholder="Email"
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email.message}</div>
            )}
          </div>

          <div className="col-md-4">
            <label className="form-label" htmlFor="roles">
              Roles:
            </label>
            <Controller
              name="roles"
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  cacheOptions
                  loadOptions={loadRoles}
                  defaultOptions
                  placeholder="Roles"
                  className={errors.roles && 'is-invalid'}
                />
              )}
            />
            {errors.roles && (
              <div className="invalid-feedback">Rol es requerido</div>
            )}
          </div>

          <div className="col-md-4">
            <label className="form-label" htmlFor="nombre">
              Nombre:
            </label>
            <input
              className={`form-control ${errors.nombre ? 'is-invalid' : ''}`}
              id="nombre"
              {...register('nombre')}
              placeholder="John Doe"
            />
            {errors.nombre && (
              <div className="invalid-feedback">{errors.nombre.message}</div>
            )}
          </div>

          <div className="col-md-4">
            <label className="form-label" htmlFor="cvpcpa">
              C.V.P.C.P.A: (Aplica si es Contador)
            </label>
            <input
              className={`form-control ${errors.cvpcpa ? 'is-invalid' : ''}`}
              id="cvpcpa"
              {...register('cvpcpa')}
              placeholder="2024"
            />
            {errors.cvpcpa && (
              <div className="invalid-feedback">{errors.cvpcpa.message}</div>
            )}
          </div>
        </div>
      </CardForm>
    </>
  );
};

export default CreateUsuario;
