import React, { useRef } from 'react';

import { TableFilterV2Services } from '../../../../../Components/Common/Tables/TableFilterV2.service';
import { getSession } from '../../../../../services/encrytion/encryption.service';
import { CardWrapper } from '../../../../../Components/Common/Cards';
export default function ProductosTable({ onSelect }) {
  const tablaRef = useRef();
  const clienteSeleccionado = getSession('clienteSeleccionado');

  const columns = [
    {
      name: 'Código',
      selector: (row) => row.codigo_producto || 'Sin código',
    },
    {
      name: 'Código 2',
      selector: (row) => row.codigo_producto2 || 'Sin código',
    },
    {
      name: 'Código 3',
      selector: (row) => row.codigo_producto3 || 'Sin código',
    },
    {
      name: 'Nombre',
      selector: (row) => row.nombre_producto,
      width: '300px',
    },
    {
      name: 'Precio',
      selector: (row) => '$' + parseFloat(row.precio).toFixed(2),
    },
    {
      name: 'Precio con IVA',
      selector: (row) => '$' + parseFloat(row.precio_iva).toFixed(2),
    },
    {
      name: 'Unidad',
      selector: (row) => {
        return row.unidad_medida ? row.unidad_medida.value : '';
      },
    },
    {
      name: 'Acciones',
      width: '200px',
      selector: (row) => (
        <>
          <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Seleccionar"
            onClick={() => handleSelect(row)}
          >
            Seleccionar
          </button>
        </>
      ),
    },
  ];

  const handleSelect = (row) => {
    onSelect(row);
  };

  const customFilterData = (data) => {
    if (data) return data.filter((item) => item.activo === true);
    else return data;
  };

  return (
    <CardWrapper title="Productos">
      <TableFilterV2Services
        columns={columns}
        endpoint={`productos?params=${clienteSeleccionado.id}`}
        ref={tablaRef}
        customFilterData={customFilterData}
      />
    </CardWrapper>
  );
}
