import React, { useEffect } from 'react';
import {
  destroyItemSession,
  getSession,
} from '../../../../services/encrytion/encryption.service';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import CardWrapperTools from '../../../../Components/Common/Cards/CardWrapperTools';
import CollapseCard from '../../../../Components/Common/Cards/CollapseCard';
import ReceptorForm from './components/ReceptorForm';
import EmisorForm from './components/EmisorForm';
import DatosFacturaSujetoExcluidoForm from './components/DatosFacturaSujetoExcluidoForm';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  processNumeroDocumentoReceptor,
  validationSchema,
} from './utils/FacturaSujetoExcluido';
import { convertirNumeroALetras } from '../../../../utils/NumeroLetras';
import {
  Alert,
  AlertQuestionYesNo,
  loadingReload,
} from '../../../../services/alerts/alerts';
import { enqueueSnackbar } from 'notistack';
import { post } from '../../../../services/http/http.service';
const FacturaSujetoExcluido = () => {
  const ambiente = getSession('ambiente');
  const cliente = getSession('clienteSeleccionado');
  const navigate = useNavigate();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      reteRenta: '0.00',
      descu: '0.00',
      condicionOperacion: '1',
      pagos: [],
      documentoRelacionado: [],
    },
  };

  const methods = useForm(formOptions);

  const onSubmit = () => {
    const cuerpoDocumento = methods.getValues('cuerpoDocumento');
    const resumen = methods.getValues('resumen');

    const tipoDocumentoReceptor = methods.getValues(
      'tipoDocumentoReceptor',
    )?.value;
    const numDocumentoReceptor =
      methods.getValues('numeroDocumentoReceptor') === ''
        ? null
        : methods.getValues('numeroDocumentoReceptor');

    //Procesamos el numeroDocumentoReceptor
    const documentoReceptorProcesado = processNumeroDocumentoReceptor(
      tipoDocumentoReceptor,
      numDocumentoReceptor,
    );
    const facturaSujetoExcluidoData = {
      emisor: {
        codActividad: methods.getValues('actividadEconomicaEmisor')?.value,
        descActividad: methods.getValues('actividadEconomicaEmisor')?.label,
      },
      sujetoExcluido: {
        numDocumento: documentoReceptorProcesado.numero,
        tipoDocumento: documentoReceptorProcesado.tipo,
        correo: methods.getValues('correoElectronicoReceptor'),
        nombre: methods.getValues('nombreClienteReceptor'),
        telefono: methods.getValues('telefonoReceptor'),
        direccion: {
          municipio: methods.getValues('municipioReceptor')?.value,
          complemento: methods.getValues('complementoReceptor'),
          departamento: methods.getValues('departamentoReceptor')?.value,
        },
        codActividad: methods.getValues('actividadEconomicaReceptor')?.value,
        descActividad: methods.getValues('actividadEconomicaReceptor')?.label,
      },

      cuerpoDocumento: cuerpoDocumento.map((item, index) => ({
        numItem: index + 1,
        tipoItem: item.tipoItem,
        cantidad: item.cantidad,
        codigo: null,
        uniMedida: item.uniMedida,
        descripcion: item.descripcion,
        precioUni: Number(parseFloat(item.precioUni).toFixed(2)),
        montoDescu: Number(parseFloat(item.montoDescu).toFixed(2)),
        compra: Number(parseFloat(item.compra).toFixed(2)),
      })),
      resumen: {
        pagos: methods.getValues('pagos')
          ? methods.getValues('pagos').map((item) => ({
              codigo: item.codigo,
              montoPago: Number(parseFloat(item.montoPago).toFixed(2)),
              referencia: item.referencia,
              plazo: item.plazo?.value ?? null,
              periodo: Number(item.periodo),
            }))
          : [],
        totalCompra: Number(parseFloat(resumen.totalCompra).toFixed(2)),
        descu: Number(parseFloat(resumen.descu).toFixed(2)),
        totalDescu: Number(parseFloat(resumen.totalDescu).toFixed(2)),
        subTotal: Number(parseFloat(resumen.subTotal).toFixed(2)),
        ivaRete1: Number(parseFloat(resumen.ivaRete1).toFixed(2)),
        reteRenta: Number(
          parseFloat(methods.getValues('reteRenta')).toFixed(2),
        ),
        totalLetras: convertirNumeroALetras(
          Number(parseFloat(resumen.totalPagar)).toFixed(2),
        )?.trim(),
        totalPagar: Number(parseFloat(resumen.totalPagar).toFixed(2)),
        condicionOperacion: Number(methods.getValues('condicionOperacion')),
        observaciones: methods.getValues('observaciones') ?? null,
      },
    };

    AlertQuestionYesNo({
      title: 'Generar documento',
      text: '¿Está seguro que desea generar  la Factura de Sujeto Excluido?',
      icon: 'info',
    }).then(async (result) => {
      if (result) {
        try {
          const headers = {
            'x-ambiente': ambiente,
            'x-branchoffice-id': methods.getValues('establecimientoEmisor')
              ?.value,
          };

          loadingReload(
            true,
            'Generando documento',
            'Espere mientras se genera el documento, esto puede tardar unos segundos',
          );
          const { data, status } = await post(
            'dte/sujeto-excluido',
            facturaSujetoExcluidoData,
            headers,
          );

          if (status === 201) {
            if (data?.selloRecibido) {
              loadingReload(false);
              enqueueSnackbar(
                'La Factura de Sujeto Excluido fue generada con éxito',
                {
                  variant: 'success',
                  preventDuplicate: true,
                },
              );
              navigate(
                `/dte/consulta/${data?.codigoGeneracion}/detalle/${cliente?.id}`,
              );

              return;
            }
            loadingReload(false);
            Alert({
              title:
                'El documento fue generado con éxito, pero tiene observaciones',
              text: 'Por favor contacte con el administrador del sistema para más información',
              icon: 'warning',
            });

            navigate(
              `/dte/consulta/${data?.codigoGeneracion}/detalle/${cliente?.id}`,
            );

            return;
          } else {
            loadingReload(false);
            Alert({
              title: 'Error al generar el documento',
              text: 'Por favor contacte con el administrador del sistema para más información',
              icon: 'error',
            });
          }
        } catch (error) {
          loadingReload(false);
          Alert({
            title: 'Error al generar el documento',
            text: 'Por favor contacte con el administrador del sistema para más información',
            icon: 'error',
          });
        }
      }
    });
  };

  useEffect(() => {
    if (!ambiente) {
      navigate('/dte');
    }

    return () => {
      destroyItemSession('ambiente');
    };
  }, [ambiente, navigate]);
  return (
    <FormProvider {...methods}>
      <CardWrapperTools
        title="Panel de emisión de Factura de Sujeto Excluido Electrónica"
        footer={
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={methods.handleSubmit(onSubmit)}
                  >
                    <span className="far fa-paper-plane"></span> Generar
                    documento
                  </button>
                </div>
              </div>
            </div>
            {ambiente === '00' && (
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="alert alert-warning text-center" role="alert">
                    <i className="fa fa-exclamation-triangle"></i>
                    <span className="p-1">
                      Este documento será generado como prueba, no tiene ninguna
                      validez fiscal.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        }
        tools={
          ambiente === '00' ? (
            <span className="badge badge-subtle-warning fs--1">
              <i className="fa fa-exclamation-triangle"></i>
              <span className="p-1">DTE de prueba</span>
            </span>
          ) : null
        }
      >
        <div className="row">
          <div className="col-lg-12">
            <CollapseCard title={'Datos de emisor y receptor'}>
              <div className="row mt-2">
                <div className="col-12">
                  <>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          href="#datosReceptor"
                          role="tab"
                          aria-controls="datosReceptor"
                          aria-selected="true"
                        >
                          Datos del Receptor
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          href="#datosEmisor"
                          role="tab"
                          aria-controls="datosEmisor"
                          aria-selected="false"
                        >
                          Datos del Emisor
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="datosReceptor"
                        role="tabpanel"
                        aria-labelledby="datosReceptor"
                      >
                        <ReceptorForm />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="datosEmisor"
                        role="tabpanel"
                        aria-labelledby="datosEmisor"
                      >
                        <EmisorForm />
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </CollapseCard>
          </div>

          <div className="col-lg-12">
            <CollapseCard title={'Datos de la Factura de Sujeto Excluido'}>
              <div className="row">
                <div className="col-lg-12">
                  <DatosFacturaSujetoExcluidoForm />
                </div>
              </div>
            </CollapseCard>
          </div>
        </div>
      </CardWrapperTools>
    </FormProvider>
  );
};

export default FacturaSujetoExcluido;
