import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InformationSystem from './Components/InformationSystem';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  destroyItemSession,
  getSession,
  setSession,
} from '../../services/encrytion/encryption.service';
import { post } from '../../services/http/http.service';
import { LoaderComponent } from 'react-fullscreen-loader';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { Alert } from '../../services/alerts/alerts';
const clearSession = () => {
  destroyItemSession('clientes');
  destroyItemSession('modulos');
  destroyItemSession('token');
  destroyItemSession('user');
  destroyItemSession('email');
  destroyItemSession('rol');
  destroyItemSession('alias');
  destroyItemSession('selectClient');
};

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [error, setError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Correo electrónico es obligatorio')
      .email('El correo electrónico no es válido'),
    password: Yup.string().required('Contraseña es obligatoria'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: process.env.REACT_APP_USER_DEV || '',
      password: process.env.REACT_APP_PASSWORD_DEV || '',
    },
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  const togglePasswordVisibility = () => {
    const password = document.getElementById('card-password');
    const showPasswordIcon = document.getElementById('eye-password');
    if (password.type === 'password') {
      password.type = 'password';
      showPasswordIcon.classList.add('fa-eye-slash');
      showPasswordIcon.classList.remove('fa-eye');
      setShowPassword(true);
    } else {
      password.type = 'password';
      showPasswordIcon.classList.remove('fa-eye-slash');
      showPasswordIcon.classList.add('fa-eye');
      setShowPassword(false);
    }
  };

  useEffect(() => {
    if (getSession('token')) {
      if (!getSession('clienteSeleccionado')) {
        clearSession();
        return null;
      }
      window.location.href = '/';
    }
  }, [navigate]);

  const ButtonRecapcha = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        Alert({
          title: 'Error de verificación de seguridad',
          text: 'Por favor, vuelva a cargar la página',
          icon: 'error',
        });
        return;
      }

      const token = await executeRecaptcha('yourAction');

      const body = {
        email: watch('email'),
        password: watch('password'),
      };

      try {
        setLoading(true);
        const login = await post('auth/login', body, {
          'x-recaptcha-authorization': `reCAPTCHA ${token}`,
        });

        const { data } = login;
        setSession('clientes', data.payload.clientes);
        setSession('modulos', data.payload.modulos);
        setSession('token', data.access_token);
        setSession('user', data.payload.userId);
        setSession('email', data.payload.email);
        setSession('rol', data.payload.rolId);
        setSession('alias', data.payload.alias);

        setLoading(false);
        if (data.payload.clientes.length > 1) {
          setSession('selectClient', true);
          navigate('/selection-client');
        }
        if (data.payload.clientes.length === 1) {
          const clienteSeleccionado = data.payload.clientes[0];
          setSession('clienteSeleccionado', clienteSeleccionado);
          setSession('sucursal', clienteSeleccionado?.sucursal || null);
          window.location.href = '/';
        }
        if (data.payload.clientes.length === 0) {
          window.location.href = '/';
        }
      } catch (e) {
        if (e?.response?.status === 401) {
          setError(true);
          setLoading(false);

          return;
        }

        Alert({
          title: 'Error al iniciar sesión',
          text: 'No se pudo iniciar sesión, por favor intente de nuevo, si el problema persiste contacte al administrador.',
          icon: 'error',
        });
        setLoading(false);
      }
    }, [executeRecaptcha]);

    return (
      <button
        className="btn btn-primary d-block w-100 mt-3"
        type="button"
        name="submit"
        onClick={handleSubmit(handleReCaptchaVerify)}
      >
        Iniciar Sesión
      </button>
    );
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPCHA_KEY}>
      <main className="main" id="top">
        <LoaderComponent loading={loading} loadingColor="blue" />
        <div className="container-fluid">
          <div className="row min-vh-100 flex-center g-0">
            <div className="col-lg-8 col-xxl-5 py-3 position-relative">
              <img
                className="bg-auth-circle-shape"
                src="../../../assets/img/bg-shape.png"
                alt="shape"
                width={250}
              />
              <img
                className="bg-auth-circle-shape-2"
                src="../../../assets/img/shape-1.png"
                alt="shape"
                width={150}
              />
              <div className="card overflow-hidden z-1">
                <div className="card-body p-0">
                  <div className="row g-0 h-100">
                    <InformationSystem />
                    <div className="col-md-7 d-flex flex-center">
                      <div className="p-4 p-md-5 flex-grow-1">
                        <div className="row flex-between-center">
                          <div className="col-auto">
                            <h3>Iniciar Sesión</h3>
                          </div>
                        </div>
                        {error && (
                          <div
                            className="alert alert-danger border-2 d-flex align-items-center"
                            role="alert"
                          >
                            <div className="bg-danger me-3 icon-item">
                              <span className="fas fa-times-circle text-white fs-3"></span>
                            </div>
                            <p className="mb-0 flex-1">
                              Correo electrónico o contraseña son incorrectos.
                            </p>
                            <button
                              className="btn-close"
                              type="button"
                              onClick={() => setError(false)}
                            ></button>
                          </div>
                        )}
                        <form>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="card-email">
                              Correo Electrónico:
                            </label>
                            <input
                              className="form-control"
                              id="card-email"
                              {...register('email')}
                              type="text"
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email?.message}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 pwd-container">
                            <label
                              className="form-label"
                              htmlFor="card-password"
                            >
                              Contraseña:
                            </label>
                            <div className="input-group">
                              <input
                                id="card-password"
                                className="form-control"
                                type={showPassword ? 'text' : 'password'}
                                {...register('password', { required: true })}
                                style={{ paddingRight: '2.5rem' }}
                              />
                              <button
                                onClick={togglePasswordVisibility}
                                className="btn position-absolute"
                                type="button"
                                style={{
                                  right: 0,
                                  top: 0,
                                  zIndex: 10,
                                  height: '100%',
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  outline: 'none',
                                  paddingRight: '1rem',
                                }}
                              >
                                <span
                                  id="eye-password"
                                  className="fas fa-eye"
                                  style={{ pointerEvents: 'none' }}
                                ></span>
                              </button>
                            </div>
                            {errors.password && (
                              <div className="invalid-feedback">
                                {errors.password?.message}
                              </div>
                            )}
                          </div>
                          <div className="row flex-between-center">
                            <div className="col-auto">
                              <Link className="fs--1" to={'/forgot-password'}>
                                ¿Olvidó su contraseña?
                              </Link>
                            </div>
                          </div>
                          <div className="mb-3">
                            <ButtonRecapcha />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </GoogleReCaptchaProvider>
  );
};

export default Login;
