/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import ModalWrapper from '../../../Components/Common/Modals/Modal';
import CardWrapperTools from '../../../Components/Common/Cards/CardWrapperTools';
import { Alert, loadingReload } from '../../../services/alerts/alerts';
import { get, patch } from '../../../services/http/http.service';
import { Controller, useForm } from 'react-hook-form';
import { getSession } from '../../../services/encrytion/encryption.service';
import ReactSelect from 'react-select';
import { InputMask } from '@react-input/mask';
import { ERROR_TYPE_MESSAGE } from '../../../config/constants';

export default function ClienteUpdate({ id, readOnly = false }) {
  const modalRef = useRef();
  const [cliente, setCliente] = useState([]);
  const { register, handleSubmit, setValue, control } = useForm();
  const [maskNumeroNit, setMaskNumeroNit] = useState(0); //0-other, 1- DUI, 2-NIT

  useEffect(() => {
    if (id) {
      loadClientById(id);
    } else {
      const { id } = getSession('clienteSeleccionado') ?? {};
      loadClientById(id);
    }
  }, []);

  const loadClientById = async (id) => {
    if (id) {
      const { data } = await get(`/clientes/${id}`);
      if (data.nit?.length <= 10) {
        setValue('tipo_documento_nit', {
          label: 'NIT (DUI Homologado)',
          value: '36.2',
        });
        configureInputMaskForNumeroNit('36.2');
      } else {
        setValue('tipo_documento_nit', {
          label: 'NIT',
          value: '36',
        });
        configureInputMaskForNumeroNit('36');
      }
      setValue('nit', data.nit);
      setValue('nrc', data.nrc);
      setValue('email', data.correo);
      setValue('nombre', data.nombre);
      setValue('nombre_comercial', data.nombreComercial);
      setValue('telefono', data.telefono);
      setCliente(data);
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      nit: data.nit,
      nrc: data.nrc,
      correo: data.email,
      nombre: data.nombre,
      nombreComercial: data.nombre_comercial,
      telefono: data.telefono,
    };

    loadingReload(true, 'Guardando datos');

    try {
      const { data: response } = await patch(`clientes/${id}`, payload);
      if (response?.clientId) {
        loadingReload(false, null);
        loadClientById(id);
        modalRef.current.toggle();
        Alert({
          title: 'Datos guardados',
          text: 'Los datos se guardaron correctamente',
          icon: 'success',
        });
      }
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      const errorKeys = error?.response?.data?.keys;
      let errorMessage = '';
      if (ERROR_TYPE_MESSAGE.includes(errorCode)) {
        errorMessage = `Error al guardar el campo ${errorKeys[0].toUpperCase()}`;
      }
      Alert({
        title: errorCode ? 'Verifique los datos' : 'Error al guardar',
        text: errorMessage,
        icon: errorCode ? 'warning' : 'error',
      });
    }
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      loadClientById(id);
      modalRef.current.toggle();
    }
  };
  const updateInputMaskConfig = (value) => {
    if (maskNumeroNit === value) {
      return;
    }
    setMaskNumeroNit(value);
    setValue('nit', '');
  };
  const configureInputMaskForNumeroNit = (documentType) => {
    if (documentType === '13') {
      updateInputMaskConfig(1);
    } else if (documentType === '36.2') {
      //Dui homologado a NIT
      updateInputMaskConfig(1);
    } else if (documentType === '36') {
      updateInputMaskConfig(2);
    } else {
      updateInputMaskConfig(0);
    }
  };
  const nitNumeroDocumento = () => {
    const duiInput = (
      <InputMask
        key={maskNumeroNit}
        mask="________-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        className="form-control"
        id="nit"
        placeholder="NIT"
        {...register('nit')}
      ></InputMask>
    );
    const nitInput = (
      <InputMask
        key={maskNumeroNit}
        mask="____-______-___-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        className="form-control"
        id="nit"
        placeholder="NIT"
        {...register('nit')}
      ></InputMask>
    );
    switch (maskNumeroNit) {
      case 1:
        return duiInput;
      case 2:
        return nitInput;
      default:
        return (
          <input
            key={maskNumeroNit}
            type="text"
            className="form-control"
            id="nit"
            placeholder="NIT"
            {...register('nit')}
          ></input>
        );
    }
  };

  return (
    <CardWrapperTools
      title={readOnly ? 'Detalles de su cuenta' : 'Detalles del cliente'}
      tools={
        !readOnly && (
          <div className="d-flex align-items-center">
            <div className="me-3">
              <div className="icon-shape icon-sm bg-body-tertiary text-center rounded">
                <button className="btn btn-primary" onClick={handleOpenModal}>
                  <i className="far fa-edit" style={{ marginRight: '3px' }}></i>{' '}
                  Actualizar datos
                </button>
              </div>
            </div>
          </div>
        )
      }
    >
      <div className="row row-cols-1 row-cols-md-2 g-3">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">NIT:</h5>
              <p className="card-text">{cliente.nit}</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">NRC:</h5>
              <p className="card-text">{cliente.nrc}</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Correo electrónico:</h5>
              <p className="card-text">{cliente.correo}</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Nombre empresa:</h5>
              <p className="card-text">{cliente.nombre}</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Nombre comercial:</h5>
              <p className="card-text">{cliente.nombreComercial}</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Teléfono:</h5>
              <p className="card-text">{cliente.telefono}</p>
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper ref={modalRef}>
        <CardWrapperTools
          title="Actualizar datos de cliente"
          footer={
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <span className="far fa-paper-plane"></span> Actualizar
                  </button>
                </div>
              </div>
            </div>
          }
        >
          <div className="row g-3">
            <div className="col-md-3">
              <label className="form-label" htmlFor="alias">
                Tipo de NIT:
              </label>
              <Controller
                key={101}
                name="tipo_documento_nit"
                id="tipo_documento_nit"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    cacheOptions
                    options={[
                      {
                        value: '36',
                        label: 'NIT',
                        code: '36',
                        slug: 'nit',
                      },
                      {
                        value: '36.2',
                        label: 'NIT (DUI Homologado)',
                        code: '36.2',
                        slug: 'dui',
                      },
                    ]}
                    defaultValue={{
                      value: '36',
                      label: 'NIT',
                      code: '36',
                      slug: 'nit',
                    }}
                    placeholder="Tipo NIT"
                    onChange={(event) => {
                      const documentType = event?.code; // es code el codigo
                      configureInputMaskForNumeroNit(documentType);
                      field.onChange(event);
                    }}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="nit" className="form-label">
                Nit:
              </label>
              {nitNumeroDocumento()}
            </div>
            <div className="col-md-6">
              <label htmlFor="nrc" className="form-label">
                NRC:
              </label>
              <input
                type="number"
                className="form-control"
                id="nrc"
                {...register('nrc')}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                {...register('email')}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="nombre" className="form-label">
                Nombre:
              </label>
              <input
                type="text"
                className="form-control"
                id="nombre"
                {...register('nombre')}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="nombre_comercial" className="form-label">
                Nombre Comercial:
              </label>
              <input
                type="text"
                className="form-control"
                id="nombre_comercial"
                {...register('nombre_comercial')}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="telefono" className="form-label">
                Teléfono:
              </label>
              <Controller
                name="telefono"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PhoneInput
                    country={'sv'}
                    enableSearch={true}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                  />
                )}
              />
            </div>
          </div>
        </CardWrapperTools>
      </ModalWrapper>
    </CardWrapperTools>
  );
}
