import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { getUnidadMedida } from '../../../../../utils/dte/dte.util';
import CardWrapperTools from '../../../../../Components/Common/Cards/CardWrapperTools';
import ItemsDteForm from './ItemsDteForm';
import { Alert } from '../../../../../services/alerts/alerts';
import FormaPagoForm from './FormaPagoForm';
import ModalDescuento from './Modales/ModalDescuento';

const DatosCreditoFiscalForm = () => {
  const {
    watch,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const modalDescuentoRef = useRef(null);

  const calcularTotal = () => {
    const ivaPercivido = Number(watch('ivaPerci1')) || 0;
    const ivaRetenido = Number(watch('ivaRete1')) || 0;
    const reteRenta = Number(watch('reteRenta')) || 0;
    const resumen = watch('resumen');

    resumen.ivaPerci1 = ivaPercivido;
    resumen.ivaRete1 = ivaRetenido;
    resumen.reteRenta = reteRenta;
    resumen.montoTotalOperacion =
      resumen?.subTotal +
      resumen.tributos
        .filter((t) => t.type.includes(1))
        .reduce((acc, item) => {
          if (item.mode === 'libre') {
            return acc + Number(getValues(item.codigo));
          }
          return acc + item.valor;
        }, 0);

    resumen.totalPagar =
      resumen?.subTotal +
      resumen.tributos
        .filter((t) => t.type.includes(1))
        .reduce((acc, item) => {
          if (item.mode === 'libre') {
            return acc + Number(getValues(item.codigo));
          }
          return acc + item.valor;
        }, 0) +
      ivaPercivido -
      ivaRetenido -
      reteRenta;

    const itemResumen = {
      ...resumen,
    };

    if (resumen.montoTotalOperacion < 0) {
      Alert({
        title: 'Error',
        message: 'El monto total de la operación no puede ser negativo',
        type: 'error',
      });
    }
    setValue('resumen', itemResumen);
  };
  return (
    <>
      {errors.cuerpoDocumento && (
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="alert alert-danger" role="alert">
              {errors.cuerpoDocumento.message}
            </div>
          </div>
        </div>
      )}

      <CardWrapperTools title="Items DTE">
        <ItemsDteForm />
      </CardWrapperTools>

      <CardWrapperTools
        title="Datos de productos y servicios de comprobante de crédito fiscal"
        tools={
          watch('cuerpoDocumento') && watch('cuerpoDocumento').length > 0 ? (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={() => {
                  modalDescuentoRef.current.openModal();
                }}
                className="btn btn-primary"
              >
                Agregar descuento
              </button>
            </div>
          ) : null
        }
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive scrollbar">
              <table className="table table-hover table-striped overflow-hidden">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">
                      Cantidad
                    </th>
                    <th scope="col" className="text-center">
                      Descripción
                    </th>
                    <th scope="col" className="text-center">
                      Precio unitario
                    </th>

                    <th scope="col" className="text-center">
                      Descuento
                    </th>
                    <th scope="col" className="text-center">
                      Unidad de medida
                    </th>
                    <th scope="col" className="text-center">
                      Subtotal no sujeto
                    </th>
                    <th scope="col" className="text-center">
                      Subtotal no afecto
                    </th>
                    <th scope="col" className="text-center">
                      Subtotal exento
                    </th>
                    <th scope="col" className="text-center">
                      Subtotal gravado
                    </th>

                    <th scope="col" className="text-center">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {watch('cuerpoDocumento') &&
                  watch('cuerpoDocumento').length > 0 ? (
                    watch('cuerpoDocumento').map((cuerpo, index) => (
                      <tr key={index}>
                        <td className="text-center">{cuerpo.cantidad}</td>
                        <td className="text-center">{cuerpo.descripcion}</td>
                        <td className="text-center">
                          ${parseFloat(cuerpo.precioUni).toFixed(2)}
                        </td>

                        <td className="text-center">
                          ${parseFloat(cuerpo.montoDescu).toFixed(2)}
                        </td>
                        <td className="text-center">
                          {getUnidadMedida(cuerpo.uniMedida)}
                        </td>

                        <td className="text-center">
                          ${parseFloat(cuerpo.ventaNoSuj).toFixed(2)}
                        </td>
                        <td className="text-center">
                          ${parseFloat(cuerpo.noGravado).toFixed(2)}
                        </td>
                        <td className="text-center">
                          ${parseFloat(cuerpo.ventaExenta).toFixed(2)}
                        </td>
                        <td className="text-center">
                          ${parseFloat(cuerpo.ventaGravada).toFixed(2)}
                        </td>

                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              setValue('reteRenta', '0.00');
                              setValue('ivaRete1', '0.00');
                              setValue('ivaPerci1', '0.00');
                              const findItem = watch('cuerpoDocumento').find(
                                (findCuerpo) =>
                                  findCuerpo.codigo === cuerpo.codigo,
                              );
                              const resumenDocumento =
                                getValues('resumen') || {};
                              const cuerpoDocumento = watch('cuerpoDocumento');
                              const tributosCalculados =
                                resumenDocumento?.tributos.map((item) => {
                                  if (item.mode !== 'fijo') {
                                    const elementoCorrespondiente =
                                      findItem.tributosData.find(
                                        (e) => e.id === item.codigo,
                                      );
                                    if (elementoCorrespondiente) {
                                      item.valor =
                                        item.valor -
                                        elementoCorrespondiente.valor;
                                    }
                                  }
                                  if (item.mode === 'fijo') {
                                    if (
                                      cuerpoDocumento.filter(
                                        (cuerpo) =>
                                          cuerpo.tributosData.filter(
                                            (e) =>
                                              e.id === item.codigo &&
                                              e.mode === 'fijo',
                                          ).length > 0,
                                      ).length === 1
                                    ) {
                                      const elementoCorrespondiente =
                                        findItem.tributosData.find(
                                          (e) => e.id === item.codigo,
                                        );
                                      item.valor =
                                        item.valor -
                                        elementoCorrespondiente.valor;
                                    }
                                  }
                                  return item;
                                });

                              cuerpoDocumento.splice(index, 1);

                              // Obtén los tributos del item a borrar
                              const tributoItemBorrado = findItem.tributosData
                                .filter(
                                  (tributo) =>
                                    (tributo &&
                                      tributo.type &&
                                      tributo.type.includes(2)) ||
                                    tributo.type.includes(3),
                                )
                                .map((tributo) => {
                                  setValue(tributo.id, '0.00');
                                  return tributo.id;
                                });

                              // Obtén los ids de los tributos del objeto uno
                              const idsTributos = cuerpoDocumento.reduce(
                                (acc, registro) => {
                                  const tributosEnRegistro = registro?.tributos
                                    ? registro.tributos.filter((tributo) =>
                                        tributoItemBorrado.includes(tributo),
                                      )
                                    : [];

                                  if (tributosEnRegistro.length > 0) {
                                    const ids = tributosEnRegistro.map(
                                      (tributo) =>
                                        tributoItemBorrado.find(
                                          (t) => t === tributo,
                                        ),
                                    );
                                    acc.push(...ids);
                                  }
                                  return acc;
                                },
                                [],
                              );

                              // Obtén los tributos que se mantienen
                              const registrosFiltrados =
                                tributosCalculados.filter((registro) =>
                                  idsTributos.includes(registro.codigo),
                                );

                              setValue('cuerpoDocumento', cuerpoDocumento);

                              setValue('resumen', {
                                tributos: [
                                  ...tributosCalculados.filter(
                                    (tc) => tc.valor > 0,
                                  ),
                                  ...registrosFiltrados,
                                ],
                                subTotal: cuerpoDocumento.reduce(
                                  (acc, item) =>
                                    acc +
                                    Number(item.precioUni) *
                                      Number(item.cantidad) -
                                    Number(item.montoDescu),
                                  0,
                                ),
                                ivaRete1:
                                  Number(resumenDocumento?.ivaRete1) -
                                  Number(
                                    findItem?.itemResumenDocumento?.ivaRete1,
                                  ),
                                ivaPerci1:
                                  Number(resumenDocumento?.ivaPerci1) -
                                  Number(
                                    findItem?.itemResumenDocumento?.ivaPerci1,
                                  ),
                                reteRenta:
                                  Number(resumenDocumento?.reteRenta) -
                                  Number(
                                    findItem?.itemResumenDocumento?.reteRenta,
                                  ),
                                descuNoSuj:
                                  Number(resumenDocumento?.descuNoSuj) -
                                  Number(
                                    findItem?.itemResumenDocumento?.descuNoSuj,
                                  ),
                                totalDescu:
                                  Number(resumenDocumento?.totalDescu) -
                                  Number(
                                    findItem?.itemResumenDocumento?.totalDescu,
                                  ),
                                totalNoSuj:
                                  Number(resumenDocumento?.totalNoSuj) -
                                  Number(
                                    findItem?.itemResumenDocumento?.totalNoSuj,
                                  ),
                                descuExenta:
                                  Number(resumenDocumento?.descuExenta) -
                                  Number(
                                    findItem?.itemResumenDocumento?.descuExenta,
                                  ),
                                totalExenta:
                                  Number(resumenDocumento?.totalExenta) -
                                  Number(
                                    findItem?.itemResumenDocumento?.totalExenta,
                                  ),
                                totalLetras: null,
                                descuGravada:
                                  Number(resumenDocumento?.descuGravada) -
                                  Number(
                                    findItem?.itemResumenDocumento
                                      ?.descuGravada,
                                  ),
                                totalGravada:
                                  Number(resumenDocumento?.totalGravada) -
                                  Number(
                                    findItem?.itemResumenDocumento
                                      ?.totalGravada,
                                  ),
                                subTotalVentas:
                                  Number(resumenDocumento?.subTotalVentas) -
                                  Number(
                                    findItem?.itemResumenDocumento
                                      ?.subTotalVentas,
                                  ),
                                condicionOperacion: null,
                                montoTotalOperacion:
                                  cuerpoDocumento.reduce(
                                    (acc, item) =>
                                      acc +
                                      Number(item.precioUni) *
                                        Number(item.cantidad) -
                                      Number(item.montoDescu),
                                    0,
                                  ) +
                                  tributosCalculados
                                    .filter((tributosType) =>
                                      tributosType.type.includes(1),
                                    )
                                    .reduce((acc, item) => acc + item.valor, 0),

                                totalPagar:
                                  cuerpoDocumento.reduce(
                                    (acc, item) =>
                                      acc +
                                      Number(item.precioUni) *
                                        Number(item.cantidad) -
                                      Number(item.montoDescu),
                                    0,
                                  ) +
                                  tributosCalculados
                                    .filter((tributosType) =>
                                      tributosType.type.includes(1),
                                    )
                                    .reduce((acc, item) => acc + item.valor, 0),
                              });
                            }}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        No hay items
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex justify-content-end mb-2">
              <div className="row">
                <div className="col-lg-12">
                  <table className="table table-hover table-striped overflow-hidden">
                    <tbody>
                      <tr>
                        <td className="text-end text-black">Total Gravadas</td>
                        <td className="text-end">
                          $
                          {parseFloat(
                            watch('resumen')?.totalGravada || 0,
                          ).toFixed(2)}
                        </td>
                      </tr>

                      {watch('descuentoGravada') > 0 && (
                        <tr>
                          <td className="text-end text-black">
                            Descuento Gravadas
                          </td>
                          <td className="text-end">
                            ${parseFloat(watch('descuentoGravada')).toFixed(2)}
                          </td>
                        </tr>
                      )}

                      {watch('resumen')?.totalExenta > 0 ? (
                        <tr>
                          <td className="text-end text-black">Total Exentas</td>
                          <td className="text-end">
                            $
                            {parseFloat(watch('resumen')?.totalExenta).toFixed(
                              2,
                            )}
                          </td>
                        </tr>
                      ) : null}

                      {watch('descuentoExenta') > 0 && (
                        <tr>
                          <td className="text-end text-black">
                            Descuento Exentas
                          </td>
                          <td className="text-end">
                            ${parseFloat(watch('descuentoExenta')).toFixed(2)}
                          </td>
                        </tr>
                      )}

                      {watch('resumen')?.totalNoSuj > 0 ? (
                        <tr>
                          <td className="text-end text-black">
                            Total No Sujetas
                          </td>
                          <td className="text-end">
                            $
                            {parseFloat(watch('resumen')?.totalNoSuj).toFixed(
                              2,
                            )}
                          </td>
                        </tr>
                      ) : null}

                      {watch('descuentoNoSujeta') > 0 && (
                        <tr>
                          <td className="text-end text-black">
                            Descuento No Sujetas
                          </td>
                          <td className="text-end">
                            ${parseFloat(watch('descuentoNoSujeta')).toFixed(2)}
                          </td>
                        </tr>
                      )}
                      {watch('resumen')?.totalNoGravado > 0 ? (
                        <tr>
                          <td className="text-end text-black">
                            Total Otros Montos No Afectos
                          </td>
                          <td className="text-end">
                            $
                            {parseFloat(
                              watch('resumen')?.totalNoGravado,
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ) : null}
                      {watch('resumen') &&
                        watch('resumen')?.tributos &&
                        watch('resumen')?.tributos.map((item, index) => {
                          if (
                            item.type.includes(1) &&
                            (item.mode === 'fijo' || item.mode === 'porcentaje')
                          ) {
                            return (
                              <tr key={index}>
                                <td className="text-end text-black">
                                  {item.descripcion}
                                </td>
                                {item.mode === 'porcentaje' ? (
                                  <td className="text-end">
                                    $
                                    {parseFloat(
                                      item.valor -
                                        watch('descuentoGravada') *
                                          item.amount[0],
                                    ).toFixed(2)}
                                  </td>
                                ) : (
                                  <td className="text-end">
                                    ${parseFloat(item.valor).toFixed(2)}
                                  </td>
                                )}
                              </tr>
                            );
                          }
                          if (
                            (item.type.includes(2) || item.type.includes(3)) &&
                            item.mode === 'libre'
                          ) {
                            return (
                              <tr key={index}>
                                <td className="text-end text-black">
                                  {item.descripcion}
                                </td>
                                <td className="text-end">
                                  <div className="input-group mb-3">
                                    <span className="input-group-text">$</span>
                                    <input
                                      className="form-control"
                                      name={item.codigo}
                                      id={item.codigo}
                                      type="number"
                                      placeholder="Digite el valor del tributo"
                                      {...register(item.codigo)}
                                      onWheel={(e) => e.target.blur()}
                                      onFocus={(e) => e.target.select()}
                                      onBlur={(e) => {
                                        setValue(
                                          item.codigo,
                                          parseFloat(e.target.value).toFixed(2),
                                        );

                                        if (e.target.value === '') {
                                          setValue(
                                            item.codigo,
                                            parseFloat(0).toFixed(2),
                                          );
                                        }
                                      }}
                                      onChange={(e) => {
                                        setValue(item.codigo, e.target.value);
                                        calcularTotal();
                                        if (e.target.value === '') {
                                          setValue(
                                            item.codigo,
                                            parseFloat(0).toFixed(2),
                                          );
                                          calcularTotal();
                                        }
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          }

                          return null;
                        })}

                      <tr>
                        <td className="text-end text-black">Retención Renta</td>
                        <td className="text-end">
                          <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                            <input
                              className="form-control"
                              name="reteRenta"
                              id="reteRenta"
                              type="number"
                              {...register('reteRenta')}
                              disabled={true}
                              onChange={(e) => {
                                setValue('reteRenta', e.target.value);
                                calcularTotal();
                                if (e.target.value === '') {
                                  setValue('reteRenta', 0);
                                  calcularTotal();
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              onFocus={(e) => e.target.select()}
                              onBlur={(e) => {
                                setValue(
                                  'reteRenta',
                                  parseFloat(e.target.value).toFixed(2),
                                );
                                if (e.target.value === '') {
                                  setValue(
                                    'reteRenta',
                                    parseFloat(0).toFixed(2),
                                  );
                                }
                              }}
                              placeholder="Digite el valor de renta retenida"
                            />
                            {watch('cuerpoDocumento') &&
                              watch('cuerpoDocumento')?.length > 0 && (
                                <span className="input-group-text">
                                  {watch('cuerpoDocumento') &&
                                  watch('cuerpoDocumento')?.length > 0 ? (
                                    <>
                                      <button
                                        className="btn btn-primary me-1"
                                        onClick={() => {
                                          setValue(
                                            'reteRenta',
                                            parseFloat(
                                              (watch('resumen')?.subTotal -
                                                watch('descuentoGravada') -
                                                watch('descuentoExenta') -
                                                watch('descuentoNoSujeta') ||
                                                0) * 0.1,
                                            ).toFixed(2),
                                          );
                                          calcularTotal();
                                        }}
                                      >
                                        <span className="fas fa-calculator me-2"></span>
                                        Calc. 10%
                                      </button>

                                      <button
                                        className="btn btn-primary me-1"
                                        onClick={() => {
                                          setValue(
                                            'reteRenta',
                                            parseFloat(
                                              (watch('resumen')?.subTotal -
                                                watch('descuentoGravada') -
                                                watch('descuentoExenta') -
                                                watch('descuentoNoSujeta') ||
                                                0) * 0.05,
                                            ).toFixed(2),
                                          );
                                          calcularTotal();
                                        }}
                                      >
                                        <span className="fas fa-calculator me-2"></span>
                                        Calc. 5%
                                      </button>
                                    </>
                                  ) : null}
                                  {watch('reteRenta') > 0 && (
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        setValue('reteRenta', '0.00');
                                        calcularTotal();
                                      }}
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  )}
                                </span>
                              )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">IVA Retenido</td>
                        <td className="text-end">
                          <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                            <input
                              className="form-control"
                              name="ivaRete1"
                              id="ivaRete1"
                              type="number"
                              {...register('ivaRete1')}
                              disabled={
                                !watch('cuerpoDocumento') ||
                                watch('cuerpoDocumento')?.length === 0
                              }
                              onChange={(e) => {
                                setValue('ivaRete1', e.target.value);
                                calcularTotal();
                                if (e.target.value === '') {
                                  setValue('ivaRete1', 0);
                                  calcularTotal();
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              onFocus={(e) => e.target.select()}
                              onBlur={(e) => {
                                setValue(
                                  'ivaRete1',
                                  parseFloat(e.target.value).toFixed(2),
                                );
                                if (e.target.value === '') {
                                  setValue(
                                    'ivaRete1',
                                    parseFloat(0).toFixed(2),
                                  );
                                }
                              }}
                              placeholder="Digite el 1% de IVA retenido"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">Percepción IVA</td>
                        <td className="text-end">
                          <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                            <input
                              className="form-control"
                              name="ivaPerci1"
                              id="ivaPerci1"
                              type="number"
                              {...register('ivaPerci1')}
                              disabled={
                                !watch('cuerpoDocumento') ||
                                watch('cuerpoDocumento')?.length === 0
                              }
                              onChange={(e) => {
                                setValue('ivaPerci1', e.target.value);
                                calcularTotal();
                                if (e.target.value === '') {
                                  setValue('ivaPerci1', 0);
                                  calcularTotal();
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              onFocus={(e) => e.target.select()}
                              onBlur={(e) => {
                                setValue(
                                  'ivaPerci1',
                                  parseFloat(e.target.value).toFixed(2),
                                );
                                if (e.target.value === '') {
                                  setValue(
                                    'ivaPerci1',
                                    parseFloat(0).toFixed(2),
                                  );
                                }
                              }}
                              placeholder="Digite el 1% de IVA percibido"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">Sub Total</td>
                        <td className="text-end">
                          $
                          {parseFloat(
                            watch('resumen')?.subTotal -
                              watch('descuentoGravada') -
                              watch('descuentoExenta') -
                              watch('descuentoNoSujeta') || 0,
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end text-black">Total a pagar</td>
                        <td className="text-end">
                          $
                          {parseFloat(
                            (watch('resumen')?.totalPagar ?? 0) -
                              watch('descuentoGravada') -
                              watch('descuentoExenta') -
                              watch('descuentoNoSujeta') -
                              (watch('resumen')?.tributos
                                ? watch('resumen')
                                    ?.tributos?.filter(
                                      (t) => t.mode === 'porcentaje',
                                    )
                                    .reduce(
                                      (acc, item) =>
                                        acc +
                                        watch('descuentoGravada') *
                                          item.amount[0],
                                      0,
                                    )
                                : 0),
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardWrapperTools>

      <CardWrapperTools title="Observaciones">
        <div className="row">
          <div className="col-12">
            <label className="form-label" htmlFor="observaciones">
              Observaciones:
            </label>
            <textarea
              className="form-control"
              name="observaciones"
              id="observaciones"
              type="text"
              placeholder="Digite las observaciones"
              {...register('observaciones')}
            />
          </div>
        </div>
      </CardWrapperTools>

      <FormaPagoForm />
      <ModalDescuento ref={modalDescuentoRef} />
    </>
  );
};

export default DatosCreditoFiscalForm;
