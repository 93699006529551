import React, { useRef } from 'react';
import { ServicioProductoDetalleForm } from './ServicioProductoDetalleForm';
import { MontoNoAfectoForm } from './MontoNoAfectoForm';

import DescuentoGeneralForm from './DescuentoGeneralForm';
import { ImpuestoAfectacionIvaForm } from './ImpuestoAfectacionIvaForm';
import { redondearA2Decimales } from '../../../../../utils/Utils';

const TablaDetalle = ({
  title = 'Title',
  data,
  resumen,
  updateResumenDataOnglobalDiscount,
  tiposServicioProducto,
  unidadesDeMedida,
  tiposVenta,
  impuestos,
  addItem,
  deleteItem,
  retencionIva,
  setRetencionIva,
  retencionRenta,
  setRetencionRenta,
  tributosLibres,
  updateTributosLibres,
  recalculateResumen,
}) => {
  const descuentoModalRef = useRef(null);
  let headers;

  headers = [
    '#',
    'Cantidad',
    'Unidad',
    //"tipo",
    'Descripción',

    'Precio',
    //"Descuento",
    'Sub Total',
    //"NS",
    //"E",
    //"G",
    //"psv",
    //"NG",
    //"iva",
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                Item DTE - Añadir nuevo item al DTE
              </h5>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    href="#productoServicio"
                    role="tab"
                    aria-controls="productoServicio"
                    aria-selected="true"
                  >
                    Producto o Servicio
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    href="#montoNoAfecto"
                    role="tab"
                    aria-controls="montoNoAfecto"
                    aria-selected="false"
                  >
                    Monto no Afecto
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    href="#impuestoTasasAfectacionIva"
                    role="tab"
                    aria-controls="impuestoTasasAfectacionIva"
                    aria-selected="false"
                  >
                    Impuesto/Tasas con Afectación al IVA
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body pt-0">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="productoServicio"
                  role="tabpanel"
                  aria-labelledby="productoServicio"
                >
                  <ServicioProductoDetalleForm
                    addItem={addItem}
                    tiposServiciosProductos={tiposServicioProducto}
                    unidadesDeMedida={unidadesDeMedida}
                    tiposVenta={tiposVenta}
                    impuestos={impuestos}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="montoNoAfecto"
                  role="tabpanel"
                  aria-labelledby="montoNoAfecto"
                >
                  <MontoNoAfectoForm addItem={addItem} />
                </div>
                <div
                  className="tab-pane fade"
                  id="impuestoTasasAfectacionIva"
                  role="tabpanel"
                  aria-labelledby="impuestoTasasAfectacionIva"
                >
                  <ImpuestoAfectacionIvaForm
                    tiposVenta={tiposVenta}
                    impuestos={impuestos}
                    addItem={addItem}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-2">
          <div className="card">
            <div className="card-header">
              <div className="col">
                <h4 className="card-title">{title}</h4>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive scrollbar">
                <table className="table overflow-hidden">
                  <thead>
                    <tr>
                      {headers.map((header, index) => (
                        <th key={index} scope="col">
                          {header.charAt(0).toUpperCase() + header.slice(1)}
                        </th>
                      ))}
                    </tr>
                    <tr></tr>
                  </thead>
                  <tbody>
                    {data != null && data.length > 0 ? (
                      data.map((row, rowIndex) => (
                        <tr key={row['numItem']}>
                          <td>{row['numItem']}</td>
                          <td>{row['cantidad'].toFixed(2)}</td>
                          <td>{row['uniMedida'].label}</td>
                          {/*<td>{row["tipoItem"].label}</td> */}
                          <td>{row['descripcion']}</td>

                          <td>{row['precioUni'].toFixed(2)}</td>
                          {/*<td>{row["montoDescu"].toFixed(2)}</td> */}
                          <td>{row['subTotal'].toFixed(2)}</td>
                          {/* <td>{row["ventaNoSuj"].toFixed(2)}</td>
                            <td>{row["ventaExenta"].toFixed(2)}</td>
                            <td>{row["ventaGravada"].toFixed(2)}</td>
                            <td>{row["psv"].toFixed(2)}</td>
                            <td>{row["noGravado"].toFixed(2)}</td>
                            <td>{row["ivaItem"].toFixed(2)}</td> */}

                          <td key={rowIndex}>
                            <button
                              className="btn btn-link p-0"
                              type="button"
                              title="Delete"
                              onClick={() => {
                                deleteItem({ numItem: row['numItem'] });
                              }}
                            >
                              <span className="text-500 fas fa-trash-alt"></span>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No hay elementos
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="table-response scrollbar">
                <table className="table overflow-hidden">
                  <tbody>
                    {showImpuestos(
                      data,
                      updateTributosLibres,
                      tributosLibres,
                      recalculateResumen,
                    )}
                    {resumen.descuentoVentaGravada > 0 ? (
                      <tr>
                        <th colSpan="5" className="text-end" scope="row">
                          Descuento Venta Gravada
                        </th>
                        <td colSpan="1">
                          {resumen.descuentoVentaGravada.toFixed(2)}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {resumen.descuentoVentaExenta > 0 ? (
                      <tr>
                        <th colSpan="5" className="text-end" scope="row">
                          Descuento Venta Exenta
                        </th>
                        <td colSpan="1">
                          {resumen.descuentoVentaExenta.toFixed(2)}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {resumen.descuentoVentaNoSujeta > 0 ? (
                      <tr>
                        <th colSpan="5" className="text-end" scope="row">
                          Descuento Venta No Sujeta
                        </th>
                        <td colSpan="1">
                          {resumen.descuentoVentaNoSujeta.toFixed(2)}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {resumen.totalVentaGravada <= 0 ? (
                      <></>
                    ) : (
                      <tr>
                        <th colSpan="5" className="text-end" scope="row">
                          Retención IVA
                        </th>
                        <td colSpan="1">
                          <input
                            type="number"
                            value={retencionIva}
                            placeholder="0.00"
                            min={0}
                            step={0.01}
                            className="form-control"
                            onChange={(event) =>
                              setRetencionIva(event.target.value)
                            }
                            onWheel={(event) => event.target.blur()}
                            onClick={(event) => event.target.select()}
                          />
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th colSpan="5" className="text-end" scope="row">
                        Retención Renta{' '}
                      </th>
                      <td colSpan="1">
                        <input
                          className="form-control"
                          type="number"
                          value={retencionRenta}
                          placeholder="0.00"
                          onChange={(event) => {
                            setRetencionRenta(event.target.value);
                          }
                          }
                          onWheel={(event) => event.target.blur()}
                          onClick={(event) => event.target.select()}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th colSpan="5" className="text-end" scope="row">
                        Sub Total
                      </th>
                      <td colSpan="1">{resumen.subTotal.toFixed(2)}</td>
                    </tr>

                    <tr>
                      <th colSpan="5" className="text-end" scope="row">
                        Monto total de la operación
                      </th>
                      <td colSpan="1">
                        {resumen.montoTotalOperacion.toFixed(2)}
                      </td>
                    </tr>
                    {resumen.totalNoGravado > 0 ? (
                      <tr>
                        <th colSpan="5" className="text-end" scope="row">
                          Total Otros Montos No Afectos
                        </th>
                        <td colSpan="1">{resumen.totalNoGravado.toFixed(2)}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <th colSpan="5" className="text-end" scope="row">
                        Total a pagar
                      </th>
                      <td colSpan="1">{resumen.totalPagar.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th colSpan="5" className="text-end" scope="row">
                        Total en letras
                      </th>
                      <td colSpan="1">{resumen.totalEnLetras}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              {data != null && data.length > 0 ? (
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    className="btn btn-lg btn-primary"
                    type="button"
                    onClick={() => {
                      descuentoModalRef.current.showDescuentoModal();
                    }}
                  >
                    <span className="fas fa-plus"></span>
                    <span className=""> Descuentos</span>
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <DescuentoGeneralForm
        ref={descuentoModalRef}
        updateResumenDataOnglobalDiscount={updateResumenDataOnglobalDiscount}
        resumen={resumen}
      />
    </>
  );
};

const showImpuestos = (
  items,
  setTributosLibres,
  tributosLibres,
  recalculateResumen,
) => {
  const mapImpuestos = {};

  for (const item of items) {
    if (item.impuestosInfo == null) continue;
    for (const impuesto of item.impuestosInfo) {
      if (impuesto.code in mapImpuestos) {
        mapImpuestos[impuesto.code].push(impuesto);
      } else {
        mapImpuestos[impuesto.code] = [];
        mapImpuestos[impuesto.code].push(impuesto);
      }
    }
  }

  return Object.keys(mapImpuestos).map((impuestoCode, index) => {
    const impuesto = mapImpuestos[impuestoCode];
    return (
      <tr>
        <th colSpan="5" className="text-end" scope="row">
          {impuesto[0].label}
        </th>
        <td colSpan="1">
          {impuesto[0].inputInResumen ? (
            <input
              type="number"
              value={tributosLibres[impuestoCode]?.value || 0}
              placeholder="0.00"
              min={0}
              step={0.01}
              className="form-control"
              onChange={(event) => {
                let state = { ...tributosLibres };
                state[impuestoCode] = {
                  label: impuesto[0].label,
                  value: event.target.value,
                };
                setTributosLibres(state);
              }}
              onWheel={(event) => event.target.blur()}
              onClick={(event) => event.target.select()}
            />
          ) : (
            redondearA2Decimales(
              impuesto.reduce((acc, imp) => acc + imp.value, 0),
            )
          )}
        </td>
      </tr>
    );
  });
};

export { TablaDetalle };
