import React from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ItemOrigin } from '../../../../../utils/Utils';

const ImpuestoAfectacionIvaForm = ({ tiposVenta, impuestos, addItem }) => {
  const notSelectedValue = null;
  const validationSchema = Yup.object().shape({
    impuesto: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El impuesto es obligatorio'),
    descripcion: Yup.string().required('La descripción obligatoria'),
    tipoVenta: Yup.object()
      .notOneOf([notSelectedValue])
      .required('El tipo de venta es obligatorio'),
    monto: Yup.number().positive().required('El monto es obligatorio'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      impuesto: null,
      descripcion: '',
      monto: 0.0,
      tipoVenta: null,
    },
  };

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    const monto = watch('monto');
    const item = {
      origen: ItemOrigin.CON_AFECTACION_AL_IVA,
      descripcion: watch('descripcion'),
      subTotal: monto,
      tipoVenta: watch('tipoVenta'),
      impuesto: watch('impuesto'),
      cantidad: 1,
      precio: monto,
    };

    addItem(item);
    setValue('descripcion', '');
    setValue('monto', 0.0);
    setValue('tipoVenta', null);
    setValue('impuesto', null);
  };
  return (
    <>
      <div className="row g-3">
        <div className="col-md-3">
          <label className="form-label" htmlFor="impuesto">
            Impuesto:
          </label>

          <Controller
            name="impuesto"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Seleccionar"
                className="basic-multi-select"
                {...field}
                options={impuestos.filter((x) => {
                  //Mostrar unicamente los tributos que afectan al IVA
                  return (
                    x.value === 'D4' ||
                    x.value === 'A8' ||
                    x.value === '57' ||
                    x.value === '90' ||
                    x.value === 'D5' ||
                    x.value === 'D6'
                  );
                })}
              />
            )}
          />
          {errors.impuesto && (
            <div className="invalid-feedback">{errors.impuesto?.message}</div>
          )}
        </div>
        <div className="col-4">
          <label className="form-label" htmlFor="descripcionAfectacionIva">
            Descripción:
          </label>
          <input
            className="form-control"
            id="descripcionAfectacionIva"
            type="text"
            placeholder="Descripción"
            {...register('descripcion')}
          />
        </div>
        <div className="col-2">
          <label className="form-label" htmlFor="montoAfectacionIva">
            Monto:
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text">$</span>
            <input
              className="form-control"
              id="montoAfectacionIva"
              type="number"
              min={0}
              placeholder="0.00"
              {...register('monto')}
              onWheel={(event) => event.target.blur()}
              onClick={(event) => event.target.select()}
            />
          </div>
        </div>
        <div className="col-md-2">
          <label className="form-label" htmlFor="tipoVentaAfectacionIva">
            Tipo de venta:
          </label>
          <Controller
            name="tipoVenta"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Seleccionar"
                {...field}
                isClearable
                options={tiposVenta}
              />
            )}
          />
          {errors.tipoVenta && (
            <div className="invalid-feedback">{errors.tipoVenta?.message}</div>
          )}
        </div>

        <div className="col-12">
          <div className="row">
            <div className="d-flex align-items-center justify-content-end">
              <button
                className="btn btn-lg btn-primary"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="fas fa-plus"></span>
                <span className=""> Añadir item</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ImpuestoAfectacionIvaForm };
