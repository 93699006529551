/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import { useMemo } from 'react';
import _debounce from 'lodash/debounce';

import DataTable from 'react-data-table-component';
import { useSearchParams } from 'react-router-dom';

import { get } from '../../../services/http/http.service';

const TableFilterV2Services = forwardRef(
  ({ columns, endpoint, showPagesUrl = true, customFilterData }, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState('');

    const [data, setData] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState(null);

    const pageDefault = 1;
    const limitDefault = 10;
    const [page, setPage] = useState(searchParams.get('page') || pageDefault);
    const [limit, setLimit] = useState(
      searchParams.get('limit') || limitDefault,
    );
    const [pending, setPending] = React.useState(true);

    const debouncedHandleSearch = useMemo(
      () => _debounce(setSearch, 500),
      [setSearch],
    );
    useEffect(() => {
      if (showPagesUrl) {
        searchParams.set('page', page);
        searchParams.set('limit', limit);
        setSearchParams(searchParams);
      }
    }, [page, limit, searchParams, setSearchParams]);

    useImperativeHandle(ref, () => ({
      refreshData: fetchData,
    }));

    const fetchData = useCallback(async () => {
      const { data } = await get(endpoint, { page, limit, search });
      if (data.results.length === 0) {
        setPage(pageDefault);
      }
      setData(data);
      setPending(false);
    }, [page, limit, search]);

    const setPageDefault = () => {
      setPage(pageDefault);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', pageDefault);
      setSearchParams(newSearchParams);
    };

    useEffect(() => {
      async function getData() {
        await fetchData();
      }
      getData();
    }, [fetchData]);

    const handleSearchChange = (e) => {
      setPageDefault();
      debouncedHandleSearch(e.target.value);
    };

    const setQueryParams = (name, value) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(name, value);
      setSearchParams(newSearchParams);
      if (name === 'page') {
        setPage(value);
      } else {
        setLimit(value);
      }
    };

    const conditionalRowStyles = [
      {
        when: () => true,
        style: {
          '&:hover': {
            backgroundColor: 'rgba(236,242,249,255)',
            cursor: 'pointer',
          },
        },
      },
      {
        when: (row) => row._id === selectedRowId,
        style: {
          backgroundColor: 'rgba(181,207,243,255)',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(181,207,243,255)',
          },
        },
      },
    ];

    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex justify-content-between mb-3 align-items-center">
              <div className="col-auto col-sm-5 mb-3">
                <form>
                  <div className="input-group">
                    <input
                      className="form-control form-control-lg shadow-none search"
                      type="search"
                      placeholder="Buscar..."
                      aria-label="Buscar"
                      onChange={handleSearchChange}
                    />
                    <div className="input-group-text bg-transparent">
                      <span className="fa fa-search fs--1 text-600"></span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={
              customFilterData ? customFilterData(data.results) : data.results
            }
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={(row) => setSelectedRowId(row._id)}
            progressPending={pending}
            highlightOnHover
          />
        </div>
        {data.results && showPagesUrl && data.results?.length > 0 ? (
          <div className="mt-4">
            <div className="card border border-1">
              <div className="card-body">
                <div className="row g-3 flex-center justify-content-md-between">
                  <div className="col-auto">
                    <form className="row gx-2">
                      <div className="col-auto">
                        <small>Show:</small>
                      </div>
                      <div className="col-auto">
                        <select
                          className="form-select form-select-sm"
                          aria-label="Show courses"
                          defaultValue={5}
                          value={searchParams.get('limit')}
                          onChange={(e) => {
                            setQueryParams('limit', e.target.value);
                          }}
                        >
                          <option selected="selected" value={5}>
                            5
                          </option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                        </select>
                      </div>
                    </form>
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-falcon-default btn-sm me-2"
                      type="button"
                      disabled={!data?.hasPreviousPage}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Anterior"
                      onClick={() => setQueryParams('page', data?.previousPage)}
                    >
                      Página anterior
                    </button>

                    {data?.pageLinks.map((page) =>
                      page !== '...' ? (
                        <button
                          className="btn btn-sm btn-falcon-default text-primary me-2"
                          key={page}
                          onClick={() => setQueryParams('page', page)}
                          disabled={page === data?.currentPage}
                        >
                          {page}
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-falcon-default me-2"
                          disabled={true}
                        >
                          <span className="fas fa-ellipsis-h" />
                        </button>
                      ),
                    )}

                    <button
                      className="btn btn-falcon-default btn-sm"
                      type="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      disabled={!data?.hasNextPage}
                      title="Siguiente"
                      onClick={() => setQueryParams('page', data?.nextPage)}
                    >
                      Página siguiente
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  },
);
export { TableFilterV2Services };
