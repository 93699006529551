import React, { useRef } from 'react';
import ModalBuscadorProductos from './ModalBuscadorProductos';

const BuscadorProductos = ({
  onSelectProduct,
  showCleanButton,
  onDeleteProduct,
}) => {
  const modalBuscadorProductos = useRef(null);
  return (
    <div>
      {showCleanButton && (
        <button
          onClick={() => {
            onDeleteProduct();
          }}
          className="btn btn-danger mx-2"
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          Borrar
        </button>
      )}
      <button
        onClick={() => {
          modalBuscadorProductos.current.openModal();
        }}
        className="btn btn-primary"
      >
        <i className="fa fa-search" aria-hidden="true"></i>
        Buscar
      </button>

      <ModalBuscadorProductos
        ref={modalBuscadorProductos}
        onSelect={onSelectProduct}
      />
    </div>
  );
};

export default BuscadorProductos;
